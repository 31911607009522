import { ExpandableCard } from "../container/ExpandableCard"
import React, { useEffect, useState } from "react"
import WeatherSettingsComponent from "./WeatherSettingsComponent"
import { ProjectSettings } from "../../shared/common/settings/ProjectSettings"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  data: ProjectSettings
  prop: string
  onSave: (prop: string, data: ProjectSettings) => void
}

export default function ProjectSettingsComponent(props: Props) {
  const [projectSettings, setProjectSettings] = useState(new ProjectSettings(props.data))
  const { i18n } = useSettingsProvider()

  useEffect(() => {
    if (!projectSettings) {
      setProjectSettings(props.data)
    }
  }, [projectSettings])

  const onChange = (prop: string | undefined, value: any) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return projectSettings
    }
    const newProjectSettings = new ProjectSettings({ ...projectSettings, [prop]: value })
    setProjectSettings(newProjectSettings)
    console.log(newProjectSettings)
    props.onSave(props.prop, newProjectSettings)
    return newProjectSettings
  }

  return (
    <ExpandableCard noPadding startOpen name={i18n.t("project")} closable={false}>
      <ExpandableCard noMargin startOpen noRadius name={i18n.t("weather")}>
        {props.data.weather && (
          <WeatherSettingsComponent
            onSave={(data) => {
              console.log("onSave WeatherSettings", data)
              onChange("weather", data)
            }}
            entry={props.data.weather}
          />
        )}
      </ExpandableCard>
      {/*
      //TODO: units settings
      <ExpandableCard noMargin startOpen noRadius name={i18n.t("units")}>
        {props.data.units && (
          <UnitsSettingsComponent
            onSave={(data) => {
              console.log("onSave UnitsSettings", data)
              onChange("units", data)
            }}
            entry={props.data.units}
          />
        )}
      </ExpandableCard>*/}
    </ExpandableCard>
  )
}
