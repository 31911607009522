import { DocObject } from "./DocObject"
import { getTime, YYYYMMDD } from "../utils/date"

export class Memo extends DocObject {
  text = ""
  userId = ""
  roomId = ""
  reactions: Array<Reaction> = []
  parentId?: string
  renderer?: string
  isReply = false
  timestamp = 0
  extra?: any

  constructor(data?: Partial<Memo>) {
    super()
    Object.assign(this, data)
    this.isReply = !!this.parentId
  }

  short(length = 15) {
    return this.text.length < length ? this.text : this.text.substring(0, length) + "..."
  }

  date() {
    const date = new Date(this.timestamp)
    let result = getTime(date)
    if (YYYYMMDD(new Date()) != YYYYMMDD(date)) result += " " + YYYYMMDD(date)
    return result
  }

  react(ownerId: string, reactionName: string) {
    const exists = this.reactions.findIndex((r) => ownerId == r.ownerId && reactionName == r.reactionName)
    if (exists != -1) {
      this.reactions.splice(exists, 1)
    } else {
      this.reactions.push({ ownerId, reactionName })
    }
  }

  static prepareToSend(userId: string, roomId: string, text: string, parentId?: string) {
    const memo = new Memo({ userId, text, roomId, parentId })
    memo.authorizeOwner(userId)
    memo.timestamp = Date.now()
    return memo
  }
}

type Reaction = {
  ownerId: string
  reactionName: string
}
