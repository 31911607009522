import React, { useEffect, useState } from "react"
import { ExpandableCard } from "./ExpandableCard"
import { Entry } from "../../shared/entry/Entry"
import { ActionElement } from "./ActionElement"
import { ModuleDefinition } from "../../shared/common/CustomInputDefinition"
import { GenericEntry } from "../diary/GenericEntry"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export interface Props {
  definitions: ModuleDefinition
  data: Array<Entry & { k: number }> | undefined
  onSave: (data: Array<Entry>) => void
  icon?: React.ReactNode
  info?: string
}

export function ExpandableGenericListCard(props: Props) {
  const { i18n } = useSettingsProvider()
  const [customData, setCustomData] = useState([] as Array<Entry & { k: number }>)

  useEffect(() => {
    if (props.data) setCustomData(Array.from(props.data))
  }, [props.data])

  console.log("props.data", JSON.stringify(props.data), JSON.stringify(customData))
  if ((props.definitions?.inputDefinitions ?? []).length == 0 && customData.length == 0) return null

  const update = (index: number, value: Record<string, any> | undefined) => {
    const newData = Array.from(customData)
    if (value == undefined) {
      newData.splice(index, 1)
    } else {
      newData[index].customFields = { ...value }
    }
    setCustomData(newData)
  }

  const save = () => {
    console.log("newData", customData)
    props.onSave(customData)
  }

  return (
    <ExpandableCard
      name={props.definitions.name}
      noPadding
      icon={props.icon}
      info={props.info}
      offset
      actionElement={
        <ActionElement
          actionList={[
            {
              label: i18n.t("save"),
              iconName: "save",
              action: save,
            },
            {
              label: i18n.t("add"),
              iconName: "plus",
              action: () => {
                if (props.definitions && props.definitions.inputDefinitions)
                  customData.push({
                    definitions: props.definitions.inputDefinitions,
                    customFields: {},
                    k: Math.random(),
                  })
                const newData = Array.from(customData)
                setCustomData(newData)
              },

              disabled: props.definitions.inputDefinitions?.length == 0,
            },
          ]}
        />
      }
    >
      {customData.map((e, i) => (
        <GenericEntry
          key={e.k}
          label={props.definitions.name + " " + (i + 1)}
          definitions={e.definitions}
          customFields={e.customFields}
          onSave={(fields) => {
            update(i, fields)
          }}
        />
      ))}
    </ExpandableCard>
  )
}
