import { StyleSheet, TouchableOpacity, View } from "react-native"
import AppText from "../general/AppText"
import { useTheme } from "react-native-paper"
import React, { useState } from "react"
import { Theme } from "../../../types"
import { LogoutIcon } from "../svg"
import DialogWindow from "../general/DialogWindow"
import { useUserProvider } from "../../providers/UserProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = { visible?: boolean; style?: any }

export default function LogoutMenuComponent({ style, visible }: Props) {
  const { signOut } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const [visibleLogout, setVisibleLogout] = useState(false)
  const showDialog = () => setVisibleLogout(true)
  const hideDialog = () => setVisibleLogout(false)
  const handleLogout = async (): Promise<void> => {
    hideDialog()
    await signOut()
  }

  return (
    <View style={[{ flexDirection: "row" }, style]}>
      <TouchableOpacity
        style={{ alignItems: "center", justifyContent: "center", flexDirection: "row" }}
        onPress={() => showDialog()}
      >
        <View style={styles.imageWrapper}>
          <LogoutIcon />
        </View>
        <AppText>{i18n.t("logout")}</AppText>

        <DialogWindow visible={visibleLogout} onDismiss={hideDialog} onPress={handleLogout} title={i18n.t("logout")}>
          <AppText>{i18n.t("do_you_want_to_be_logged_out") + "?"}</AppText>
        </DialogWindow>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  imageWrapper: {
    width: "50%",
    height: "50%",
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
})
