import { addDoc, collection, deleteDoc, doc, getDoc, getDocs, query, setDoc, updateDoc } from "firebase/firestore"
import { firestore } from "./FirebaseConfig"
import { DocObject } from "../shared/entity/DocObject"
import { QueryConstraint } from "@firebase/firestore"

export class FirebaseObject {
  static async create(obj: DocObject) {
    try {
      console.debug("FirebaseObject.create DbObject:", JSON.stringify(obj))
      if (obj.id) {
        const data = { ...obj.getRaw(), id: obj.id }
        await setDoc(doc(firestore, obj.getPathSegment(), obj.id), data)
      } else {
        const docRef = await addDoc(collection(firestore, obj.getPathSegment()), obj.getRaw())
        obj.id = docRef.id
        const data = { ...obj.getRaw(), id: obj.id }
        await updateDoc(doc(firestore, obj.getPathSegment(), obj.id), data)
      }
    } catch (error) {
      console.error("FirebaseObject.create error:", error)
      throw error
    }
  }

  static addFirebaseTimestamps(obj: DocObject, docData: any) {
    console.debug("FirebaseObject createTime", JSON.stringify(docData["_document"]["createTime"]))
    const createdAt = docData["_document"]["createTime"]["timestamp"]
    console.debug("FirebaseObject version", JSON.stringify(docData["_document"]["version"]))
    const updatedAt = docData["_document"]["version"]["timestamp"]
    console.debug("FirebaseObject readTime", JSON.stringify(docData["_document"]["readTime"]))
    const readAt = docData["_document"]["readTime"]["timestamp"]
    obj.assign({ ...docData.data(), createdAt: createdAt, updatedAt: updatedAt, readAt: readAt })
    return obj
  }

  static async read(obj: DocObject) {
    try {
      if (!obj.id) throw Error("Object must have id DbObject:" + JSON.stringify(obj))
      console.debug("FirebaseObject.read DbObject:", JSON.stringify(obj))
      const docData = await getDoc(doc(firestore, obj.getPathSegment(), obj.id))
      if (!docData.data()) throw Error("Object is not found" + JSON.stringify(obj))
      FirebaseObject.addFirebaseTimestamps(obj, docData)
      console.debug("FirebaseObject.reading", JSON.stringify(obj))
    } catch (error) {
      console.error("FirebaseObject.read error:", error)
      throw error
    }
    return obj
  }

  static async readById<T extends DocObject>(
    id: string,
    pathSegment: string,
    constructorFn: (data: any) => T
  ): Promise<T> {
    try {
      if (!id) throw Error("id must exists: " + id)
      console.debug("readById:", id)
      const snapshot = await getDoc(doc(firestore, pathSegment, id))
      if (!snapshot || !snapshot.data()) throw Error("Object was not found")
      const obj = constructorFn(snapshot.data())
      FirebaseObject.addFirebaseTimestamps(obj, snapshot)
      console.debug("readById found:", JSON.stringify(obj))
      return obj
    } catch (error) {
      console.error("readById error:", error)
      throw error
    }
  }

  static async update(obj: DocObject) {
    try {
      if (obj.id == undefined) throw Error("Object must have id DbObject:" + JSON.stringify(obj))
      console.debug("FirebaseObject.update DbObject:", JSON.stringify(obj))
      await updateDoc(doc(firestore, obj.getPathSegment(), obj.id), { ...obj.getRaw() })
      return true
    } catch (error) {
      console.error("FirebaseObject.update error:", error)
      throw error
    }
  }

  static async merge<T>(obj: DocObject, key: string, data: any) {
    console.debug("FirebaseObject.merge obj.id:", obj.id, "withData:", JSON.stringify(data))
    //set<T>(documentRef: DocumentReference<T>, data: PartialWithFieldValue<T>, options: SetOptions): this;
    try {
      if (obj.id == undefined) throw Error("Object must have id DbObject:" + JSON.stringify(obj))
      const partialObj = JSON.parse(JSON.stringify(data))
      await setDoc(doc(firestore, obj.getPathSegment(), obj.id), { [key]: partialObj }, { merge: true })
    } catch (error) {
      console.error("FirebaseObject.update error:", error)
      throw error
    }
  }

  static async softDelete(obj: DocObject) {
    try {
      if (obj.id == undefined) throw Error("Object must have id DbObject:" + JSON.stringify(obj))
      console.debug("FirebaseObject.softDelete DbObject:", JSON.stringify(obj))
      await updateDoc(doc(firestore, obj.getPathSegment(), obj.id), { deletedAt: new Date() })
      return true
    } catch (error) {
      console.error("FirebaseObject.softDelete error:", error)
      throw error
    }
  }

  static async delete(obj: DocObject) {
    try {
      if (obj.id == undefined) throw Error("Object must have id DbObject:" + JSON.stringify(obj))
      console.debug("FirebaseObject.delete DbObject:", JSON.stringify(obj))
      await deleteDoc(doc(firestore, obj.getPathSegment(), obj.id))
    } catch (error) {
      console.error("FirebaseObject.delete error:", error)
      throw error
    }
  }

  static async findFirst(path: string, queryConstraints: QueryConstraint[]) {
    const q = query(collection(firestore, path), ...queryConstraints)
    const querySnapshot = await getDocs(q)
    return querySnapshot.size > 0
  }

  static async findAll<T extends DocObject>(
    path: string,
    constructorFn: (data: any) => T,
    queryConstraints: QueryConstraint[]
  ) {
    const q = query(collection(firestore, path), ...queryConstraints)
    const querySnapshot = await getDocs(q)
    return querySnapshot.docs.map((value) => constructorFn(value.data()))
  }
}
