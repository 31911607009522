import { IExporter, ITableExportable } from "../export/export"
import { getConditionText, getWeatherIcon } from "../common/Weather"
import { CustomEntry } from "./Entry"

/**
 * @param datetime {String} Datetime
 * @param conditions {String} Conditions
 * @param temp {String} Number celsius
 * @param windspeed {String} Number km/h
 * @param humidity {Number} Number percentage
 * @param precip {String} Number mm
 * @param icon {String} Icon
 */
export class WeatherEntryData extends CustomEntry implements ITableExportable {
  datetime?: string
  conditions?: string
  temp?: string
  windspeed?: string
  humidity?: number
  precip?: string
  icon?: string

  constructor(data?: any) {
    super(data)
    Object.assign(this, data)
  }

  fieldNames(): string[] {
    return ["Čas", "Počasí", "Teplota" /*"Vítr", "Vlhkost", "Srážky"*/]
  }

  get base64Icon() {
    return getWeatherIcon(this.icon)
  }

  get conditionText() {
    return getConditionText(this.icon)
  }

  exportTableData(exporter: IExporter) {
    exporter.startCell()
    exporter.addText(this.datetime ?? "")
    exporter.endCell()
    exporter.startCell()
    exporter.addImage(this.base64Icon, 24)
    exporter.endCell()
    exporter.startCell()
    exporter.addText(this.temp ?? "")
    exporter.endCell()
  }
}
