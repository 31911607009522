import { TouchableOpacity, useWindowDimensions, View } from "react-native"
import { MARGIN, PADDING } from "../../constants/style"
import { FontAwesome } from "@expo/vector-icons"
import AppText from "../general/AppText"
import React from "react"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"

interface Props {
  actionList: Array<{ label?: string; iconName: string; action: () => void; disabled?: boolean }>
}

export function ActionElement({ actionList }: Props) {
  const { width } = useWindowDimensions()
  const theme: Theme = useTheme()

  return (
    <View style={{ flexDirection: "row", gap: MARGIN, padding: PADDING }}>
      {actionList
        .filter((a) => a != undefined)
        .map((action) => (
          <TouchableOpacity
            key={action.label + action.iconName}
            style={{
              padding: PADDING,
              flexDirection: "row",
              alignItems: "center",
              gap: MARGIN,
              opacity: action.disabled ? 0.3 : 1,
            }}
            onPress={() => {
              action.action()
            }}
            disabled={action.disabled}
          >
            <FontAwesome color={action.disabled ? "#1c1b1f" : theme.colors.white} size={24} name={action.iconName} />
            {width > 480 && action.label && (
              <AppText noPadding white>
                {action.label}
              </AppText>
            )}
          </TouchableOpacity>
        ))}
    </View>
  )
}
