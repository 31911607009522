import { dateFromDB, DocObject } from "./DocObject"
import { PARTICIPANT_TYPE } from "./Participant"
import { Signature } from "../common/Signature"
import { IExportable, IExporter } from "../export/export"
import { PlaceLocation } from "../common/PlaceLocation"
import { YYYYMMDD } from "../utils/date"
import { Company } from "./Company"
import { CompanyUser } from "./User"

export class Project extends DocObject implements IExportable {
  parentProjectId?: string
  title?: string
  titleUri?: string
  location?: PlaceLocation
  startDate?: Date
  endDate?: Date
  state?: keyof typeof PROJECT_STATE = "NEW"
  lockedAt?: Date
  participants: Record<string, PARTICIPANT_TYPE> = {}
  budget?: number
  identifier?: string
  signatures?: Signature[]
  companyId?: string

  constructor(params?: Partial<Project>) {
    super(params)
    this.assign(params)
  }

  get name() {
    return this.title || ""
  }

  set name(value: string) {
    this.title = value
  }

  get isValid(): boolean {
    return true
  }

  get isProjectClosed(): boolean {
    if (!this.state) return false
    return this.state === PROJECT_STATE.CLOSED
  }

  get getLocationAsString() {
    return this.location?.coordinates?.toString()
  }

  get getAddressAsString() {
    return this.location?.address?.address
  }

  get stateLabel(): string {
    if (this.state) return PROJECT_STATE[this.state]
    return PROJECT_STATE.NEW
  }

  getPathSegment() {
    return "Project"
  }

  assign(data: any) {
    super.assign(data)
    for (const key in data) {
      if (key == "startDate") this.startDate = dateFromDB(data["startDate"])
      if (key == "endDate") this.endDate = dateFromDB(data["endDate"])
      if (key == "lockedAt") this.lockedAt = dateFromDB(data["lockedAt"])
    }
  }

  async exportData(exporter: IExporter) {
    const b64 = await exporter.getBase64ImageFromUri(this.titleUri)
    exporter.lineBreak()
    exporter.lineBreak()
    exporter.lineBreak()
    exporter.addImage(b64, 500)
    if (this.title) exporter.addHeading(this.title, 1)
    exporter.addHeading("STAVEBNÍ DENNÍK", 1)
    exporter.pageBreak()
    exporter.addHeading("Základní list - adresář")
    exporter.addHeading("O Stavbě")
    exporter.addLine("Adresa stavby", this.getAddressAsString)
    exporter.addLine("Začátek projektu", this.startDate ? YYYYMMDD(this.startDate) : "")
    exporter.addLine("Konec projektu", this.startDate ? YYYYMMDD(this.startDate) : "")
    exporter.addHeading("O Firmě")
    const company = exporter.extraData.company as Company
    const users = exporter.extraData.companyUsers as CompanyUser[]
    exporter.addHeading(company.name, 3)
    exporter.addLine("IČO", company.ico)
    exporter.addText(company.location?.address?.address ?? "")
    for (const participant in this.participants) {
      exporter.addLine(
        PARTICIPANT_TYPE[this.participants[participant] as unknown as keyof typeof PARTICIPANT_TYPE],
        users.find((u) => u.id == participant)?.name
      )
    }

    exporter.pageBreak()
  }

  signature(participant: PARTICIPANT_TYPE) {
    const signatures = this.signatures?.filter((value) => value?.participant?.type === participant)
    if (signatures && signatures.length > 0) {
      return signatures[0]
    }
    return null
  }
}

export const PROJECT_STATE: Record<string, string> = {
  NEW: "new",
  OPEN: "active",
  CLOSED: "closed",
}
