import React, { useRef, useState } from "react"
import { Platform, View } from "react-native"
import Signature from "react-native-signature-canvas"
import SignatureCanvas from "react-signature-canvas"
import Button from "../../components/button/Button"
import DeleteButton from "../../components/button/DeleteButton"
import Screen from "../../components/general/Screen"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.DIARY_SIGNATURE>

export function DiarySignatureScreen({ navigation, route }: Props) {
  const [signature, setSignature] = useState("")
  const signatureRef: React.MutableRefObject<SignatureCanvas> = useRef(null)
  console.log("Platform.OS", Platform.OS)
  return (
    <Screen>
      {Platform.OS === "web" ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <View>
            <SignatureCanvas
              penColor="black"
              backgroundColor="white"
              canvasProps={{ width: 600, height: 300 }}
              ref={signatureRef}
              onEnd={() => setSignature(signatureRef.current.getCanvas().toDataURL("image/png"))}
            />

            <DeleteButton
              onPress={() => signatureRef.current?.clear()}
              style={{ position: "absolute", top: -20, right: -20 }}
            />
          </View>
        </View>
      ) : (
        <View style={[{ flex: 1, padding: 10 }]}>
          <View style={{ flex: 1 }}>
            <Signature
              ref={signatureRef}
              onEnd={() => signatureRef.current.readSignature()}
              onOK={setSignature}
              descriptionText=""
            />
            <DeleteButton
              //   iconStyle={{ transform: [{ rotate: Platform.isPad ? "0deg" : "90deg" }] }}
              onPress={() => signatureRef.current?.clearSignature()}
              style={[{ position: "absolute", right: 20 }, Platform.isPad ? { top: 20 } : { bottom: 20 }]}
            />
          </View>
        </View>
      )}
      <Button
        title="Uložit"
        onPress={async () => {
          route.params.onSave(signature)
        }}
      />
    </Screen>
  )
}
