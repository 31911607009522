import { View } from "react-native"
import AppText from "../general/AppText"
import { Divider } from "react-native-paper"
import Button from "../button/Button"
import React, { useState } from "react"
import { ProductPrice } from "../../shared/entity/payment/ProductPrice"
import { NumberInput } from "../input/NumberInput"
import { formatInterval } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type ProductCardProps = {
  disablePayButton?: boolean
  entry: ProductPrice
  action: (id: string, amount: number) => void
  actionButtonTitle?: string
  withInterval?: boolean
  withQuantity?: boolean
}

export default function ProductDescriptionCard({
  entry,
  action,
  disablePayButton = false,
  withInterval = true,
  withQuantity = false,
  actionButtonTitle = "Subscription",
}: ProductCardProps) {
  const { i18n } = useSettingsProvider()
  const [quantity, setQuantity] = useState(1)

  return (
    <>
      {withQuantity && (
        <View style={{ justifyContent: "space-around", flexDirection: "row" }}>
          <View style={{ alignSelf: "center" }}>
            <AppText>{i18n.t("quantity") + ":"}</AppText>
          </View>
          <NumberInput
            onChange={(_, value) => {
              setQuantity(value)
            }}
            minLimit={1}
            maxLimit={999}
            value={quantity}
            label={""}
          />
          <Divider />
        </View>
      )}
      <View style={{ justifyContent: "space-around", flexDirection: "row" }}>
        <AppText key={entry.unitAmount}>{i18n.t("price") + ":"}</AppText>
        <AppText key={entry.currency}>{entry.getPrice() * quantity + " " + entry.currency?.toUpperCase()}</AppText>
      </View>
      <Divider />
      {withInterval && (
        <View style={{ justifyContent: "space-around", flexDirection: "row" }}>
          <AppText key={entry.intervalCount}>{i18n.t("interval") + ":"}</AppText>
          <AppText key={entry.interval}>
            {entry.intervalCount} {formatInterval(entry.interval || "", i18n)}
          </AppText>
        </View>
      )}
      <Divider />
      <Button
        disabled={disablePayButton}
        onPress={() => action(entry.getId(), (entry.unitAmount || 300) * quantity)}
        title={actionButtonTitle}
      />
    </>
  )
}
