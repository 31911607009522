import { DocObject } from "./DocObject"

export class Issue extends DocObject {
  constructor(params?: Partial<Issue>) {
    super(params)
    this.assign(params)
  }

  createdBy?: string
  type?: ISSUE_TYPE
  uri?: string
  description?: string
  deviceInfo?: {
    brand?: string
    manufacturer?: string
    device?: string
    osVersion?: string
  }
  screenDimensions?: { width: number; height: number }
  windowDimensions?: { width: number; height: number }
  platform?: any
  routes?: string
  commitId?: string
  name?: string
  solved?: boolean = false

  getPathSegment() {
    return "Issue"
  }

  getLabel(): string {
    if (!this.name && !this.description) {
      return "Popis problému neuveden"
    }
    const label = this.solved ? `✓ ${this.name}: ${this.description}` : `${this.name}: ${this.description}`
    return label.length > 24 ? `${label.substring(0, 24)}...` : label
  }
}

export enum ISSUE_TYPE {
  ISSUE = "Chyba",
  IMPROVEMENT = "Vylepšení",
}
