import {
  Image,
  Platform,
  Pressable,
  StyleProp,
  StyleSheet,
  TextInput as TextInputRef,
  useWindowDimensions,
  View,
  ViewStyle,
} from "react-native"
import React, { useLayoutEffect, useRef, useState } from "react"
import { TextInput } from "./TextInput"
import { ExpandLessIcon, ExpandMoreIcon } from "../svg"
import ModalPicker, { ListItem } from "../general/ModalPicker"
import ChipSelector from "./ChipSelector"
import { MARGIN, PADDING } from "../../constants/style"

type DropdownPickerProps = {
  prop?: string
  value?: any
  label: string
  data: [] | Record<string, any>
  onChange: (selected: any) => void
  style?: StyleProp<ViewStyle>
  isMultipleSelect?: boolean
  icons?: [string]
  hintText?: string
  withIcons?: boolean
  searchable?: boolean
  colors?: Array<string>
  disabled?: boolean
  withSearchBar?: boolean
  required?: boolean
  withEmptyValue?: boolean
  withChips?: boolean
}

export default function Dropdown({
  data,
  disabled,
  withSearchBar,
  withChips = false,
  withIcons = false,
  withEmptyValue = false,
  isMultipleSelect,
  hintText,
  style,
  prop,
  colors,
  label,
  value,
  onChange,
  searchable,
  required = false,
  icons,
}: DropdownPickerProps) {
  const [text, setText] = useState("")
  const [showList, setShowList] = useState(false)
  const textInputRef = useRef<TextInputRef>(null)
  const viewRef = useRef<View>(null)
  const platform = Platform.OS
  const [position, setPosition] = useState({ x: 0, y: 0, width: 0, height: 0, pageX: 0, pageY: 0 })
  const width = useWindowDimensions().width
  const height = useWindowDimensions().height
  let dataArray: ListItem[]

  if (data instanceof Array) {
    if (withEmptyValue) {
      dataArray = [...data, { label: "", value: undefined }]
    } else {
      dataArray = data
    }
  } else {
    dataArray = Object.entries(data).map(([val, lab]) => ({
      label: lab,
      value: val,
    }))
  }

  useLayoutEffect(() => {
    if (platform === "web") {
      viewRef?.current?.measure((x, y, width, height, pageX, pageY) => {
        setPosition({ x: x, y: y, width: width, height: height, pageX: pageX, pageY: pageY })
      })
      if (position.pageY < 0 || !position.pageY) setShowList(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  })
  const handleInputChange = (value: any) => {
    setText(text)
    onChange(value)
    setShowList(true)
  }

  const chips = (
    <>
      {value &&
        value instanceof Array &&
        value.map((val: any, index: number) => (
          <ChipSelector
            icon={"close-thick"}
            key={index}
            style={{ flexDirection: "row", overflow: "visible" }}
            label={dataArray.find((e) => e.value == val)?.label ?? ""}
            onPress={() => {
              const updatedArray = value.filter((v) => v !== val)
              onChange(updatedArray)
              console.log(val.label, val.value)
            }}
          />
        ))}
    </>
  )

  const openList = () => {
    setShowList(true)
  }

  const closeList = () => {
    setShowList(false)
  }

  return (
    <View>
      <Pressable disabled={disabled} ref={viewRef} onPress={openList} style={[styles.container, style]}>
        <TextInput
          ref={textInputRef}
          onChangeText={(text) => handleInputChange(text)}
          onFocus={openList}
          hintText={!value ? hintText : undefined}
          label={!isMultipleSelect ? label : ""}
          placeholder={!isMultipleSelect ? label : ""}
          disabled={disabled ?? false}
          leftSideIcon={!isMultipleSelect && withIcons && icons && <Image key={label} source={icons[value]} />}
          editable={false}
          value={!isMultipleSelect ? dataArray.find((e) => e.value == value)?.label ?? "" : ""}
          rightSideIcon={showList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          customIconAction={() => setShowList(!showList)}
          required={required ?? false}
        />
        {showList && (
          <ModalPicker
            visible={showList}
            selectedListItem={(selected) => {
              onChange(selected)
            }}
            containerStyle={{
              width: position.width,
              top:
                position.pageY < height
                  ? position.pageY + position.height
                  : position.pageY - (position.height + position.y),
              left: position.pageX,
            }}
            withSearchBar={withSearchBar}
            listValues={!(value instanceof Array) ? [value] : value}
            listData={dataArray}
            listIcons={icons}
            fullscreen={platform !== "web"}
            isMultipleSelect={isMultipleSelect}
            onClose={closeList}
          />
        )}
      </Pressable>
      {isMultipleSelect ? (
        <View
          style={{
            height: position.height,
            flexDirection: "row",
            position: "absolute",
            flexGrow: 1,
            flexShrink: 1,
            maxWidth: position.width - position.height / 0.95,
            overflow: "hidden",
            //transform: [{ translateY: -position.height - position.height / 5 }],
            padding: PADDING * 2,
            marginRight: MARGIN * 3,
            alignItems: "center",
          }}
        >
          {chips}
        </View>
      ) : isMultipleSelect && withChips ? (
        <View
          style={{
            flexDirection: "row",
            flexWrap: "wrap",
            overflow: "visible",
          }}
        >
          {isMultipleSelect && chips}
        </View>
      ) : (
        <></>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "100%",
    justifyContent: "center",
  },
  list: {
    position: "absolute",
    top: 41,
    left: 0,
    right: 0,
    backgroundColor: "#fff",
    borderRadius: 4,
    borderWidth: 1,
    borderColor: "#ccc",
    maxHeight: 150,
  },
  listItem: {
    paddingHorizontal: 10,
    paddingVertical: 8,
  },
  listItemText: {
    fontSize: 16,
    color: "#000",
  },
  listHeader: {
    height: 8,
  },
  listFooter: {
    height: 8,
  },
})
