import React, { useContext, useState } from "react"
import AppText from "../../components/general/AppText"
import Screen from "../../components/general/Screen"
import Button from "../../components/button/Button"
import WizardProgressDots from "../../components/wizard/WizardProgressDots"
import { setDisableTutorial } from "../../utils/storage"
import { TutorialContent } from "../../components/tutorial/TutorialContent"
import { Checkbox } from "react-native-paper"
import { View } from "react-native"
import { Wizard_1, Wizard_2, Wizard_3 } from "../../components/svg"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { UserContext } from "../../providers/UserProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_TUTORIAL>

export function TutorialScreen({ navigation }: Props) {
  const [checked, setChecked] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const { currentUser } = useContext(UserContext)

  const screens = [
    <TutorialContent
      image={<Wizard_1 width="100%" height="100%" />}
      header={<>Zakládejte nové projekty</>}
      text={<>Založení projektu na pár kliknutí</>}
      key={0}
    />,
    <TutorialContent
      image={<Wizard_2 width="100%" height="100%" />}
      header={<>Přidávejte denní záznamy</>}
      text={<>V naší aplikaci můžete snadno a rychle přidávat a upravovat Vaše denní záznamy</>}
      key={1}
    />,
    <TutorialContent
      image={<Wizard_3 width="100%" height="100%" />}
      header={<>Ukládejte své záznamy</>}
      text={<>Můžete si zobrazit náhledy, ukládat záznamy v cloudu nebo je exportovat do PDF</>}
      key={2}
    />,
  ]

  const handleNext = async () => {
    setCurrentPage(currentPage + 1)
    if (currentPage >= screens.length) {
      if (navigation.canGoBack()) {
        navigation.goBack()
      } else {
        if (currentUser) {
          console.log("next: ROUTE.PROJECT_LIST")
          navigation.navigate(ROUTE.PROJECT_LIST)
        } else {
          console.log("next: ROUTE.LOGIN")
          navigation.navigate(ROUTE.USER_LOGIN)
        }
      }
    }
  }

  const handleClickDot = (i: number) => {
    setCurrentPage(i)
  }

  return (
    <Screen>
      {screens[currentPage - 1]}
      <WizardProgressDots currentIndex={currentPage - 1} count={screens.length} onClick={handleClickDot} />
      <View style={[{ flexDirection: "row" }, { justifyContent: "center" }, { alignItems: "baseline" }]}>
        <Checkbox
          status={checked ? "checked" : "unchecked"}
          onPress={() => {
            setChecked(!checked)
            setDisableTutorial(checked)
          }}
        />
        <AppText>Zobrazit toto okno i příště</AppText>
      </View>
      <Button standalone onPress={handleNext} title={currentPage === screens.length ? "Dokončit" : "Další"} />
    </Screen>
  )
}
