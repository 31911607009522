import { auth } from "../persistence/FirebaseConfig"
import { ENVIRONMENT } from "../shared/constants/enum"
import Constants from "expo-constants"
import axios from "axios"

const getToken = async (): Promise<string | undefined> => {
  try {
    if (!auth.currentUser) throw Error("auth.currentUser not exists")
    return await auth.currentUser.getIdToken()
  } catch (error) {
    console.error("Error getToken:", error)
    throw error
  }
}

export const getHeaders = async () => {
  return {
    "Authorization": `Bearer ${await getToken()}`,
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  }
}

export const getHeadersWithoutToken = async () => {
  return {
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*",
  }
}

export const getFunctionUrl = (fnName: string) => {
  const environment = Constants?.manifest?.extra?.environment
  if (!environment) throw Error("getFunctionUrl: no environment")
  const projectId = Constants?.manifest?.extra?.projectId
  if (!projectId) throw Error("getFunctionUrl: no projectId")

  try {
    let url
    if (environment === ENVIRONMENT.EMU) {
      url = `http://localhost:5001/demo-stavx/europe-west3/${fnName}`
    } else {
      url = `https://europe-west3-${projectId}.cloudfunctions.net/${fnName}`
    }
    console.debug("getFunctionUrl:", url)
    return url
  } catch (error) {
    console.error("Error getFunctionUrl:", error)
    throw error
  }
}

export const getBucketName = () => {
  const environment = Constants?.manifest?.extra?.environment
  if (!environment) throw Error("getBucketName: no environment")
  //  const projectId = Constants?.manifest?.extra?.projectId
  const storageBucket = Constants?.manifest?.extra?.storageBucket
  if (!storageBucket) throw Error("getBucketName: no storageBucket")
  if (environment === "emu") return "default-bucket"
  //return `${projectId}.appspot.com`
  return storageBucket
}

export const callFunction = async (fnName: string, data: any) => {
  try {
    const headers = await getHeaders()
    const response = await axios.post(getFunctionUrl(fnName), { ...data }, { headers })
    console.debug("callFunction response:", response)
    return response
  } catch (error) {
    console.error("Error callFunction:", error)
    throw error
  }
}
