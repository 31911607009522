import React from "react"
import { StyleProp, View, ViewStyle } from "react-native"

type Props = {
  children: React.ReactNode[] | React.ReactNode
  basis?: number[]
  minima?: number[]
  style?: StyleProp<ViewStyle>
}

export function WrapRow(props: Props) {
  let basis: number[] = []
  const minima: number[] = []
  let children = []
  if (!props.children) return <View />
  if (props.children instanceof Array<React.ReactNode>) {
    children = props.children.filter((c) => c)
  } else {
    children.push(props.children)
  }
  for (let i = 0; i < children.length; i++) {
    basis[i] = (props.basis ? props.basis[i] : 1) || 1
    minima[i] = (props.minima ? props.minima[i] : 300) || 300
  }
  const sum = basis.reduce((partialSum, a) => partialSum + a, 0)
  basis = basis.map((b) => b / sum)
  return (
    <View
      style={[
        {
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        },
        props.style,
      ]}
    >
      {children.map((c, i) => (
        <View
          key={i}
          style={{
            width: basis[i] * 100 + "%",
            minWidth: minima[i],
            flexGrow: 1,
          }}
        >
          {c}
        </View>
      ))}
    </View>
  )
}
