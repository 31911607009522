import { DocObject } from "./DocObject"
import { User } from "./User"

export enum STATUS {
  DRAFT = "V přípravě",
  INVITED = "Pozván",
  ACCEPTED = "Akceptováno",
  DECLINED = "Odmítnuto",
}

export class UserInvitation extends DocObject {
  invitedUserEmail?: string
  invitedUserName?: string
  invitedUserRoles?: string[]
  invitedIntoCompanyId?: string
  invitedIntoCompanyName?: string
  fromUserId?: string
  fromUserName?: string
  fromUserEmail?: string
  status?: STATUS

  constructor(params?: any) {
    super(params)
    this.assign(params)
  }

  assign(data: any) {
    Object.assign(this, data)
  }

  getPathSegment() {
    return "UserInvitation"
  }

  getUser(): User {
    const user = new User()
    user.name = this.invitedUserName
    user.email = this.invitedUserEmail
    return user
  }

  getLabel(): string {
    return this.invitedUserName || ""
  }
}
