import { Entypo } from "@expo/vector-icons"
import React from "react"
import { TouchableOpacity, View } from "react-native"
import { useTheme } from "react-native-paper"
import DialogWindow from "../general/DialogWindow"
import AppText from "../general/AppText"
import { Theme } from "../../../types"
import { MARGIN, PADDING } from "../../constants/style"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  title: string
  onPress?: () => void
  options: { name: string; icon: React.ReactNode; action: () => void; disabled?: boolean }[]
}

export default function OptionsMenu({ options, title, onPress }: Props) {
  const [visible, setVisible] = React.useState(false)
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  return (
    <>
      <TouchableOpacity
        onPress={() => {
          openMenu()
          onPress && onPress()
        }}
        style={{ margin: 5 }}
      >
        <Entypo name="dots-three-horizontal" size={38} color={theme.colors?.primary} />
      </TouchableOpacity>
      <DialogWindow
        visible={visible}
        onDismiss={closeMenu}
        title={title}
        disableDismissButton
        confirmButtonTitle={i18n.t("cancel")}
        onPress={closeMenu}
      >
        {options.map((option) => (
          <TouchableOpacity
            disabled={option.disabled}
            key={option.name}
            onPress={() => {
              closeMenu()
              option.action()
            }}
            style={{
              backgroundColor: theme.colors.primary,
              opacity: option.disabled ? 0.5 : 1,
              margin: MARGIN,
              padding: PADDING,
              borderRadius: 10,
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <AppText noPadding white>
              {option.name}
            </AppText>
            <View style={{ width: 24, height: 24 }}>{option.icon}</View>
          </TouchableOpacity>
        ))}
      </DialogWindow>
    </>
  )
}
