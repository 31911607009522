import { useTheme } from "react-native-paper"
import React, { ReactNode } from "react"
import { TouchableOpacity, useWindowDimensions, View } from "react-native"
import { Theme } from "../../../types"
import { ExpandableCard } from "./ExpandableCard"
import { MARGIN } from "../../constants/style"
import { EntryProps } from "../../shared/entry/Entry"
import { FontAwesome } from "@expo/vector-icons"
import AppText from "../general/AppText"
import { ActionElement } from "./ActionElement"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export interface EntryListProps<T> {
  template: (props: EntryProps<T>) => JSX.Element
  onSave: (entryList: T[]) => void
  onCreate?: () => void
  name?: ReactNode
  icon?: ReactNode
  entryList: T[]
  parentData?: any
}

export function ExpandableEntryListCard<T>(props: EntryListProps<T>) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const { width } = useWindowDimensions()

  const addEntry = (
    <TouchableOpacity
      style={{ flexDirection: "row", alignItems: "center", gap: MARGIN, marginRight: MARGIN }}
      onPress={props.onCreate}
    >
      <FontAwesome size={24} color={theme.colors.white} name={"plus"} />
      {width >= 480 && (
        <AppText noPadding white>
          {i18n.t("add")}
        </AppText>
      )}
    </TouchableOpacity>
  )

  const removeAll = (
    <TouchableOpacity style={{ flexDirection: "row", alignItems: "center", gap: MARGIN }}>
      <FontAwesome size={24} color={theme.colors.white} name={"minus"} />
      {width >= 480 && (
        <AppText noPadding white>
          {i18n.t("remove_all_entries")}
        </AppText>
      )}
    </TouchableOpacity>
  )

  return (
    <ExpandableCard
      startOpen={false}
      offset
      name={props.name}
      icon={props.icon}
      info={props.entryList?.length || 0}
      noPadding
      actionElement={addEntry}
      onPressed={() => {
        if (props.entryList.length === 0 && props.onCreate) props.onCreate()
      }}
    >
      <>
        {props.entryList &&
          props.entryList.map((entry, index) => (
            <View key={JSON.stringify(entry)}>
              <ExpandableCard
                name={props.name + " " + (1 + index)}
                startOpen
                noRadius
                noMargin
                actionElement={
                  <ActionElement
                    actionList={[
                      {
                        label: i18n.t("delete"),
                        iconName: "trash-o",
                        action: () => {
                          const index = props.entryList.indexOf(entry)
                          props.entryList.splice(index, 1)
                          console.debug("delete:", props.entryList)
                          props.onSave(props.entryList)
                        },
                      },
                    ]}
                  />
                }
              >
                {React.createElement(props.template, {
                  onSave: (newEntry: T) => {
                    props.entryList[index] = newEntry
                    props.onSave(props.entryList)
                  },
                  entry,
                  parentData: props.parentData,
                })}
              </ExpandableCard>
            </View>
          ))}
      </>
    </ExpandableCard>
  )
}
