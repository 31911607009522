import * as DocumentPicker from "expo-document-picker"
import * as ImagePicker from "expo-image-picker"
import { Platform } from "react-native"

export async function openFileStorage(type: string | string[] = "*/*") {
  const result = await DocumentPicker.getDocumentAsync({ type })
  if (result.type !== "cancel") {
    const fileExt = result.name.substring(result.name.length - 3)
    // if (!(fileExt === "pdf" || fileExt === "PDF")) {
    //   throw { message: "Soubor musí být formátu PDF!" }
    // }
    return { data: result }
  }
  return { data: null }
}

export async function openLibrary() {
  if (Platform.OS !== "web") {
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync()
    if (status !== "granted") {
      console.log("Sorry, we need camera roll permissions to make this work!")
    }
  }
  return await pickImage()
}

async function pickImage() {
  const result = await ImagePicker.launchImageLibraryAsync({
    mediaTypes: ImagePicker.MediaTypeOptions.All,
    //    quality: 1,
    //    aspect: [4,3],
    //    allowsEditing: true,
    base64: true,
  })

  console.log("PickImage result:" + JSON.stringify(result))

  if (!result.cancelled) {
    return result
  }
}
