import InputBase from "../input/InputBase"
import { TouchableOpacity, View } from "react-native"
import { FontAwesome } from "@expo/vector-icons"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import React, { useEffect, useState } from "react"
import { PADDING } from "../../constants/style"
import AppText from "../general/AppText"
import { getTimeWithSeconds } from "../../shared/utils/date"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  running: boolean
  timeFrom: Date | undefined
  onPress: () => void
  disabled?: boolean
}

export function TimeButton({ onPress, running, disabled = false, timeFrom }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const [time, setTime] = useState(new Date())

  useEffect(() => {
    const interval = setInterval(() => setTime(new Date()), 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])

  return (
    <InputBase label={i18n.t("attendance")} noPadding>
      <View style={{ flexGrow: 1, flexDirection: "row", alignItems: "center", gap: PADDING }}>
        <TouchableOpacity
          onPress={() => {
            !disabled && onPress()
          }}
          style={{
            backgroundColor: disabled ? theme.colors.background : theme.colors.primary,
            maxWidth: 75,
            height: "100%",
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <FontAwesome size={24} color={theme.colors.white} name={running ? "stop" : "play"} />
        </TouchableOpacity>
        <AppText noPadding>
          {running
            ? getTimeWithSeconds(new Date(time.valueOf() - timeFrom.valueOf() + 1000))
            : getTimeWithSeconds(new Date(0))}
        </AppText>
      </View>
    </InputBase>
  )
}
