import { CustomInputDefinition, INPUT_DEFINITION, ModuleDefinition } from "../../shared/common/CustomInputDefinition"
import TextField from "../input/TextField"
import { IconButton, useTheme } from "react-native-paper"
import React, { memo, useState } from "react"
import { Theme } from "../../../types"
import { ExpandableCard } from "../container/ExpandableCard"
import { WrapRow } from "../container/WrapRow"
import { ItemWithCustomFields } from "../flatlist/ItemWithCustomFields"
import BoolInput from "../input/BoolInput"
import Dropdown from "../input/Dropdown"
import { ActionElement } from "../container/ActionElement"
import ChipPicker from "../input/ChipPicker"
import { View } from "react-native"
import AppText from "../general/AppText"
import { OPERATION } from "../../shared/entity/Role"
import { FontAwesome5 } from "@expo/vector-icons"
import { translateEnums } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  definition: CustomInputDefinition
  setDefinition: (input: CustomInputDefinition | undefined) => void
  swap: () => void
  customModule: ModuleDefinition
  isSwappable: boolean
}

function ModuleInputDefinitionPure({ definition, setDefinition, customModule, swap, isSwappable }: Props) {
  const [newSelectOption, setNewSelectOption] = useState("")
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()

  const removeMe = (
    <ActionElement
      actionList={[
        {
          action: () => swap(),
          iconName: "arrow-up",
          label: i18n.t("scroll"),
          disabled: !isSwappable,
        },
        {
          action: () => setDefinition(undefined),
          iconName: "trash-o",
          label: i18n.t("delete"),
        },
      ]}
    />
  )

  const addOption = (
    <ActionElement
      actionList={[
        {
          action: () => {
            const p = new CustomInputDefinition(definition)
            p.values.push(newSelectOption)
            setNewSelectOption("")
            setDefinition(p)
          },
          iconName: "plus",
          label: i18n.t("add"),
        },
      ]}
    />
  )

  const renderDefaultValue = () => (
    <>
      <ItemWithCustomFields
        definition={[definition]}
        noRequireCheck
        alwaysRender
        onSave={(customFields) => {
          const p = new CustomInputDefinition(definition)
          p.defaultValue = customFields[definition.label]
          setDefinition(p)
        }}
        customFields={{ [definition.label]: definition.defaultValue }}
      />
    </>
  )

  if (!customModule.inputDefinitions) return <></>
  return (
    <ExpandableCard
      noMargin
      noRadius
      name={definition.label}
      startOpen={definition.label == ""}
      actionElement={removeMe}
    >
      <WrapRow>
        <TextField
          label={i18n.t("title")}
          value={definition.label}
          onChange={(label) => {
            const p = new CustomInputDefinition(definition)
            p.label = label
            setDefinition(p)
          }}
        />
        <BoolInput
          value={definition.required}
          label={i18n.t("required_field")}
          stateNames={{ yes: i18n.t("required"), no: i18n.t("optional") }}
          onChange={(value) => {
            const p = new CustomInputDefinition(definition)
            p.required = value
            setDefinition(p)
          }}
        />
        {(customModule.tags?.length ?? []) > 0 ? (
          <ChipPicker
            label={i18n.t("tags")}
            values={definition.tags}
            icons={(customModule.tags ?? []).map((t) =>
              t.includes(":")
                ? () => <FontAwesome5 size={theme.iconSize.regular} name={t.replace(":", "")} />
                : "circle"
            )}
            data={(customModule.tags ?? []).map((t) => ({ label: t.includes(":") ? "" : t, value: t }))}
            isMultipleSelect={true}
            onChange={(_, values) => {
              const p = new CustomInputDefinition(definition)
              p.tags = values
              setDefinition(p)
            }}
          />
        ) : undefined}
      </WrapRow>
      <WrapRow>
        <Dropdown
          label={"Typ"}
          value={definition.type}
          onChange={(v) => {
            const p = new CustomInputDefinition(definition)
            p.type = v
            if (
              !(
                INPUT_DEFINITION[definition.type] == INPUT_DEFINITION.SELECT ||
                INPUT_DEFINITION[definition.type] == INPUT_DEFINITION.MULTISELECT
              )
            ) {
              p.values = []
            }
            setDefinition(p)
            p.defaultValue = undefined
          }}
          withSearchBar={false}
          data={translateEnums(INPUT_DEFINITION, i18n)}
        />
        {renderDefaultValue()}
        {INPUT_DEFINITION[definition.type] == INPUT_DEFINITION.CONFIRMATION && (
          <Dropdown
            label={i18n.t("required_role")}
            data={OPERATION}
            value={definition.defaultValue}
            onChange={(v) => {
              const p = new CustomInputDefinition(definition)
              p.defaultValue = v
              setDefinition(p)
            }}
          />
        )}
      </WrapRow>
      {(INPUT_DEFINITION[definition.type] == INPUT_DEFINITION.SELECT ||
        INPUT_DEFINITION[definition.type] == INPUT_DEFINITION.MULTISELECT) && (
        <>
          <ExpandableCard name={i18n.t("options")} actionElement={addOption} info={definition.values.length}>
            {definition.values.map((v, index) => (
              <TextField
                key={index}
                label={i18n.t("value")}
                onChange={(v) => {
                  const p = new CustomInputDefinition(definition)
                  p.values[index] = v
                  setDefinition(p)
                }}
                value={v}
              >
                <IconButton
                  onPress={() => {
                    const p = new CustomInputDefinition(definition)
                    p.values.splice(index, 1)
                    setDefinition(p)
                  }}
                  iconColor={theme.colors.white}
                  icon={"minus"}
                />
                {index != 0 && (
                  <IconButton
                    onPress={() => {
                      const p = new CustomInputDefinition(definition)
                      const temp = p.values[index - 1]
                      p.values[index - 1] = p.values[index]
                      p.values[index] = temp
                      setDefinition(p)
                    }}
                    iconColor={theme.colors.white}
                    icon={"arrow-up"}
                  />
                )}
              </TextField>
            ))}
          </ExpandableCard>
        </>
      )}
      <View>
        <AppText>{i18n.t("conditional_display")}</AppText>
        <WrapRow>
          <Dropdown
            label={"Pole"}
            value={definition.conditionalRendering.key}
            data={[{ label: "- " + i18n.t("show") + " " + i18n.t("unconditionally") + " -", value: "" }].concat(
              customModule.inputDefinitions.map((d) => ({ label: d.label, value: d.label }))
            )}
            onChange={(v) => {
              const p = new CustomInputDefinition(definition)
              p.conditionalRendering.key = v
              p.conditionalRendering.type =
                customModule.inputDefinitions?.find((c) => c.label == p.conditionalRendering.key).type || "TEXT"
              setDefinition(p)
            }}
          />

          <ItemWithCustomFields
            definition={
              [customModule.inputDefinitions.find((d) => d.label == definition.conditionalRendering.key)].filter(
                (d) => d != undefined
              ) as CustomInputDefinition[]
            }
            conditionRender
            noRequireCheck
            onSave={(customFields) => {
              const p = new CustomInputDefinition(definition)
              p.conditionalRendering.value = customFields[definition.conditionalRendering.key]
              setDefinition(p)
            }}
            customFields={{ [definition.conditionalRendering.key]: definition.conditionalRendering.value }}
          />
        </WrapRow>
      </View>
    </ExpandableCard>
  )
}

export const ModuleInputDefinition = memo(ModuleInputDefinitionPure, (prevProps, nextProps) => {
  return (
    Object.is(prevProps.customModule, nextProps.customModule) &&
    Object.is(prevProps.isSwappable, nextProps.isSwappable) &&
    Object.is(prevProps.definition, nextProps.definition)
  )
})
