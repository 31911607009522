import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { Location } from "../shared/common/Location"
import { getCurrentPositionAsync, getForegroundPermissionsAsync, LocationObject } from "expo-location"

const initialVal: {
  location?: Location
  setLocation: (location: Location) => void
} = {
  location: undefined,
  setLocation: () => undefined,
}

export const LocationContext = createContext(initialVal)

export default function LocationProvider({ children }: { children: ReactNode }) {
  const [location, setLocation] = useState<Location>()
  const [permissions, setPermissions] = useState(false)

  useEffect(() => {
    console.log("useLocationProvider useEffect: permissions")
    grantPermissions().then((permissions) => {
      setPermissions(permissions)
      if (permissions)
        getLocation()
          .then((location: Location) => {
            setLocation(location)
          })
          .catch((e) => console.warn(e))
    })
  }, [])

  return <LocationContext.Provider value={{ location, setLocation }}>{children}</LocationContext.Provider>
}

export const useLocationProvider = () => {
  const { location, setLocation } = useContext(LocationContext)

  return { location, grantPermissions }
}

export async function grantPermissions(): Promise<boolean> {
  const { status } = await getForegroundPermissionsAsync()
  console.debug("Permission to access location is: " + status)
  return status == "granted"
}

export async function getLocation(): Promise<Location> {
  const location: LocationObject = await getCurrentPositionAsync({})
  console.debug("getLocation: ", location)
  return new Location({
    longitude: location?.coords?.longitude,
    latitude: location?.coords?.latitude,
  })
}

export async function getLocationWithPerm(): Promise<Location> {
  const { status } = await getForegroundPermissionsAsync()
  if (status !== "granted") console.warn("Permission to access location was " + status)
  console.log("Permission to access location was " + status)
  const newLocation: LocationObject = await getCurrentPositionAsync()
  console.log(newLocation)
  return new Location({
    longitude: newLocation?.coords?.longitude,
    latitude: newLocation?.coords?.latitude,
  })
}
