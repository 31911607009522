import { ProjectSettings } from "../common/settings/ProjectSettings"
import { AppearanceSettings } from "../common/settings/AppearanceSettings"
import { LanguageSettings } from "../common/settings/LanguageSettings"

export class Settings {
  userId?: string
  appearance?: AppearanceSettings
  project?: ProjectSettings
  language?: LanguageSettings
  vopFirestoreUrl?: string

  constructor(params?: Partial<Settings>) {
    Object.assign(this, params)
  }

  getPathSegment() {
    return `User/${this.userId}/Settings`
  }
}
