import React from "react"
import { Image, Platform, SafeAreaView, ScrollView, View } from "react-native"
import Screen from "./../../components/general/Screen"
import LoginForm from "./../../components/auth/LoginForm"
import { AppLogo } from "../../components/svg"
import Container from "../../components/general/Container"
import AppVersionComponent from "../../components/main/AppVersionComponent"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import { Link } from "@react-navigation/native"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_LOGIN>

export function UserLoginScreen({ route, navigation }: Props) {
  return (
    <Screen>
      <ScrollView contentContainerStyle={{ flexGrow: 1, justifyContent: "center" }}>
        <SafeAreaView style={{ justifyContent: "center", alignItems: "center" }}>
          <AppLogo style={{ maxWidth: 300, width: "80%", height: 200 }} />
        </SafeAreaView>
        <Container>
          <LoginForm navigation={navigation} />
          {Platform.OS === "web" && (
            <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <Link
                onPress={() => {
                  window.open("https://play.google.com/store/apps/details?id=com.cexbit.stavx")
                }}
                to={"https://play.google.com/store/apps/details?id=com.cexbit.stavx"}
                target={"_blank"}
              >
                <Image
                  style={{ height: 59 * 1.2, width: 152 * 1.2 }}
                  source={{
                    uri: "https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png",
                  }}
                />
              </Link>
              <Link
                onPress={() => {
                  window.open("https://apps.apple.com/cz/app/stavx/id6444315620")
                }}
                to={"https://apps.apple.com/cz/app/stavx/id6444315620"}
                target={"_blank"}
              >
                <Image
                  style={{ height: 40 * 1.2, width: 120 * 1.2 }}
                  source={{
                    uri: "https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg",
                  }}
                />
              </Link>
            </View>
          )}
        </Container>
        <AppVersionComponent />
      </ScrollView>
    </Screen>
  )
}
