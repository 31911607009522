// @ts-nocheck
import * as React from "react"
import Svg, { Circle, ClipPath, Defs, G, LinearGradient, Mask, Path, Rect, Stop, SvgProps } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const SvgComponent = (props: SvgProps) => (
  <Svg width={765} height={1548} viewBox="0 0 765 1548" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Mask
      id="mask0_166_274"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={25}
      y={2}
      width={715}
      height={1539}
    >
      <Rect width={714.331} height={1537.71} rx={87.4007} transform="matrix(-1 0 0 1 739.543 2.73047)" fill="#E6E6E6" />
    </Mask>
    <G mask="url(#mask0_166_274)">
      <Rect width={715.593} height={1526.84} rx={87.4007} transform="matrix(-1 0 0 1 740.174 2.73047)" fill="#E6E6E6" />
      <G filter="url(#filter0_d_166_274)">
        <Rect x={24.5801} y={29.8984} width={715.593} height={119.539} fill="#F5F5F5" />
      </G>
      <G clipPath="url(#clip0_166_274)">
        <Path
          d="M57.281 97.958H97.6299C99.1367 97.958 100.392 99.1957 100.392 100.732C100.392 102.269 99.1367 103.506 97.6299 103.506H57.281C55.7742 103.506 54.5186 102.269 54.5186 100.732C54.5186 99.1957 55.7742 97.958 57.281 97.958Z"
          fill="#F39719"
        />
        <Path
          d="M77.2874 73.8008C79.7151 73.8008 81.6823 75.764 81.6823 78.1967V95.7377H72.8926V78.2394C72.8926 75.8067 74.8598 73.8008 77.2874 73.8008Z"
          fill="#F39719"
        />
        <Path d="M70.7586 95.7801H57.5322C57.5322 95.7801 58.9553 81.9948 70.7586 76.916V95.7801Z" fill="#F39719" />
        <Path d="M83.9443 95.7801H97.1707C97.1707 95.7801 95.7476 81.9948 83.9443 76.916V95.7801Z" fill="#F39719" />
        <Path
          d="M90.9339 105.769V117.42H63.8114V105.769H60.1699V120.962C60.1699 124.462 63.0161 127.321 66.532 127.321H88.2132C91.7291 127.321 94.5753 124.462 94.5753 120.962V105.769H90.9339ZM77.4563 125.529C75.6565 125.529 74.1916 124.078 74.1916 122.243C74.1916 120.45 75.6565 118.956 77.4563 118.956C79.2561 118.956 80.7211 120.407 80.7211 122.243C80.7211 124.078 79.2561 125.529 77.4563 125.529Z"
          fill="#4C4B4B"
        />
        <Path
          d="M77.4571 118.997C79.2569 118.997 80.7219 120.448 80.7219 122.283C80.7219 124.076 79.2569 125.57 77.4571 125.57C75.6573 125.57 74.1924 124.119 74.1924 122.283C74.2342 120.448 75.6573 118.997 77.4571 118.997Z"
          fill="#F7F7F7"
        />
        <Path
          d="M122.574 127.318C118.891 127.318 115.333 126.891 111.943 126.08C108.511 125.227 105.707 124.16 103.488 122.794L108.26 111.954C110.353 113.149 112.655 114.13 115.25 114.899C117.803 115.624 120.314 116.008 122.742 116.008C124.165 116.008 125.295 115.923 126.09 115.709C126.886 115.539 127.513 115.24 127.89 114.899C128.267 114.557 128.434 114.13 128.434 113.618C128.434 112.807 128.016 112.21 127.137 111.74C126.258 111.271 125.086 110.887 123.663 110.588C122.24 110.289 120.649 109.948 118.933 109.564C117.217 109.18 115.501 108.753 113.785 108.155C112.069 107.558 110.478 106.79 109.013 105.808C107.59 104.826 106.418 103.589 105.539 102.01C104.66 100.43 104.242 98.4672 104.242 96.1198C104.242 93.3884 104.995 90.913 106.544 88.651C108.051 86.389 110.353 84.5965 113.366 83.2735C116.38 81.9504 120.147 81.2676 124.667 81.2676C127.639 81.2676 130.569 81.5663 133.415 82.2065C136.303 82.8467 138.898 83.7856 141.2 85.1087L136.764 95.8637C134.587 94.7541 132.494 93.9432 130.485 93.3884C128.476 92.8335 126.467 92.5775 124.584 92.5775C123.16 92.5775 122.03 92.7055 121.193 92.9616C120.356 93.2177 119.77 93.5591 119.393 93.9859C119.017 94.4127 118.849 94.8821 118.849 95.3516C118.849 96.1198 119.268 96.7173 120.147 97.1441C121.026 97.5709 122.198 97.955 123.621 98.2538C125.044 98.5525 126.634 98.8513 128.392 99.1927C130.109 99.5341 131.866 100.004 133.541 100.558C135.257 101.156 136.847 101.924 138.27 102.906C139.693 103.887 140.865 105.125 141.744 106.661C142.623 108.198 143.042 110.161 143.042 112.466C143.042 115.155 142.289 117.63 140.74 119.849C139.191 122.069 136.931 123.904 133.959 125.269C130.904 126.635 127.137 127.318 122.574 127.318Z"
          fill="#4C4B4B"
        />
        <Path
          d="M164.725 115.24C163.804 115.88 162.758 116.179 161.544 116.179C160.707 116.179 159.996 115.88 159.451 115.283C158.907 114.685 158.614 113.874 158.614 112.807V104.144H165.646V93.7727H158.614V84.1699H144.467V93.7727H139.779V104.144H144.467V112.935C144.467 117.673 145.765 121.172 148.402 123.477C151.038 125.782 154.722 126.891 159.41 126.891C161.084 126.891 162.674 126.763 164.181 126.465C165.688 126.166 167.027 125.696 168.116 125.099L164.725 115.24Z"
          fill="#4C4B4B"
        />
        <Path
          d="M196.66 95.225C193.437 92.5789 188.874 91.2559 182.973 91.2559C180.294 91.2559 177.573 91.5973 174.769 92.2802C171.965 92.963 169.579 93.9019 167.57 95.1823L172.09 104.572C173.22 103.718 174.56 103.078 176.15 102.608C177.741 102.139 179.289 101.883 180.838 101.883C183.14 101.883 184.773 102.31 185.819 103.206C186.614 103.889 187.075 104.785 187.242 105.895H181.55C177.992 105.895 175.062 106.279 172.76 107.09C170.458 107.901 168.7 109.053 167.612 110.632C166.482 112.168 165.938 114.089 165.938 116.308C165.938 118.272 166.398 120.064 167.361 121.686C168.323 123.308 169.746 124.588 171.63 125.57C173.513 126.551 175.857 127.021 178.662 127.021C181.633 127.021 183.977 126.381 185.735 125.143C186.865 124.332 187.744 123.265 188.372 121.899V126.295H201.515V107.815C201.515 102.054 199.882 97.8711 196.66 95.225ZM185.568 117.589C184.731 118.101 183.852 118.357 182.889 118.357C181.843 118.357 181.006 118.101 180.42 117.589C179.834 117.077 179.499 116.394 179.499 115.625C179.499 114.644 179.834 113.918 180.545 113.449C181.215 112.979 182.345 112.723 183.852 112.723H187.368V115.113C186.949 116.223 186.363 117.077 185.568 117.589Z"
          fill="#4C4B4B"
        />
        <Path
          d="M227.676 91.8975L220.309 110.335L213.403 91.8975H198.879L212.649 126.297H227.299L241.069 91.8975H227.676Z"
          fill="#4C4B4B"
        />
        <Path
          d="M268.819 126.297L255.927 108.628L268.526 91.8975H253.667L248.519 99.1955L243.622 91.8975H227.675L240.231 109.268L227.298 126.297H242.617L247.849 118.956L252.579 126.297H268.819Z"
          fill="#F39719"
        />
        <Path d="M63.8105 105.768H90.933V117.419H63.8105V105.768Z" fill="#F7F7F7" />
      </G>
      <Rect
        x={528.831}
        y={1301.67}
        width={212.631}
        height={211.991}
        rx={61.0094}
        fill="#F39719"
        stroke="white"
        strokeWidth={91.7435}
      />
      <Rect x={628.833} y={1383.24} width={11.9269} height={48.8504} fill="white" />
      <Rect
        x={659.701}
        y={1402.09}
        width={11.8637}
        height={49.1107}
        transform="rotate(90 659.701 1402.09)"
        fill="white"
      />
      <Rect x={61.8447} y={182.999} width={43.9592} height={5.46578} fill="#F39719" />
      <Rect x={61.8447} y={193.929} width={43.9592} height={5.46578} fill="#F39719" />
      <Rect x={61.8447} y={204.862} width={43.9592} height={5.46578} fill="#F39719" />
      <Rect x={127.781} y={173.887} width={282.072} height={45.5481} rx={22.7741} fill="#818181" />
    </G>
    <Path
      d="M678.058 16.1699C716.757 16.1699 748.128 47.5411 748.128 86.2395L748.128 1461.76C748.128 1500.46 716.757 1531.83 678.058 1531.83L86.2392 1531.83C47.5408 1531.83 16.1697 1500.46 16.1697 1461.76L16.1697 86.2394C16.1697 47.5411 47.541 16.1699 86.2394 16.1699L678.058 16.1699Z"
      stroke="url(#paint0_linear_166_274)"
      strokeWidth={32.3398}
    />
    <Rect x={243.084} y={1491.27} width={303.171} height={8.19381} rx={4.09691} fill="black" />
    <G filter="url(#filter1_d_166_274)">
      <Path
        d="M51.8936 268.664C51.8936 263.587 56.0095 259.471 61.0868 259.471H700.937C706.014 259.471 710.13 263.587 710.13 268.664V382.66C710.13 387.737 706.014 391.853 700.937 391.853H61.0868C56.0095 391.853 51.8936 387.737 51.8936 382.66V268.664Z"
        fill="#F7F7F7"
      />
    </G>
    <Rect x={72.1191} y={268.664} width={485.403} height={33.0957} rx={16.5478} fill="#A2A2A2" />
    <Rect x={79.4736} y={309.114} width={413.696} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <G filter="url(#filter2_d_166_274)">
      <Path
        d="M51.8936 577.556C51.8936 572.478 56.0095 568.362 61.0868 568.362H700.937C706.014 568.362 710.13 572.478 710.13 577.556V691.552C710.13 696.629 706.014 700.745 700.937 700.745H61.0868C56.0095 700.745 51.8936 696.629 51.8936 691.552V577.556Z"
        fill="#F7F7F7"
      />
    </G>
    <Rect x={77.6357} y={584.909} width={461.501} height={33.0957} rx={16.5478} fill="#A2A2A2" />
    <Rect x={84.0781} y={625.358} width={392.883} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <Rect x={84.0781} y={652.94} width={154.577} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <G filter="url(#filter3_d_166_274)">
      <Path
        d="M51.8936 732.004C51.8936 726.927 56.0095 722.811 61.0868 722.811H700.937C706.014 722.811 710.13 726.927 710.13 732.004V846C710.13 851.077 706.014 855.193 700.937 855.193H61.0868C56.0095 855.193 51.8936 851.077 51.8936 846V732.004Z"
        fill="#F7F7F7"
      />
    </G>
    <Rect x={77.6367} y={739.358} width={233.508} height={33.0957} rx={16.5478} fill="#A2A2A2" />
    <Rect x={82.5547} y={779.808} width={299.972} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <Rect x={82.5547} y={807.39} width={118.022} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <G filter="url(#filter4_d_166_274)">
      <Path
        d="M51.8936 423.112C51.8936 418.035 56.0095 413.919 61.0868 413.919H700.937C706.014 413.919 710.13 418.035 710.13 423.112V537.108C710.13 542.186 706.014 546.302 700.937 546.302H61.0868C56.0095 546.302 51.8936 542.186 51.8936 537.108V423.112Z"
        fill="#F7F7F7"
      />
    </G>
    <Rect x={72.1191} y={424.95} width={391.632} height={33.0957} rx={16.5478} fill="#A2A2A2" />
    <Rect x={77.6348} y={465.401} width={516.66} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <Rect x={77.6348} y={492.981} width={430.244} height={20.2251} rx={10.1126} fill="#BBBBBB" />
    <Circle cx={657.733} cy={747.631} r={8.27392} transform="rotate(90 657.733 747.631)" fill="#BBBBBB" />
    <Circle cx={681.633} cy={747.631} r={8.27392} transform="rotate(90 681.633 747.631)" fill="#BBBBBB" />
    <Circle cx={633.829} cy={747.631} r={8.27392} transform="rotate(90 633.829 747.631)" fill="#BBBBBB" />
    <Circle cx={609.925} cy={747.631} r={8.27392} transform="rotate(90 609.925 747.631)" fill="#BBBBBB" />
    <Circle cx={586.023} cy={747.631} r={8.27392} transform="rotate(90 586.023 747.631)" fill="#BBBBBB" />
    <Circle cx={657.733} cy={593.184} r={8.27392} transform="rotate(90 657.733 593.184)" fill="#BBBBBB" />
    <Circle cx={681.634} cy={593.184} r={8.27392} transform="rotate(90 681.634 593.184)" fill="#BBBBBB" />
    <Circle cx={633.829} cy={593.184} r={8.27392} transform="rotate(90 633.829 593.184)" fill="#BBBBBB" />
    <Circle cx={609.925} cy={593.184} r={8.27392} transform="rotate(90 609.925 593.184)" fill="#BBBBBB" />
    <Circle cx={586.024} cy={593.184} r={8.27392} transform="rotate(90 586.024 593.184)" fill="#BBBBBB" />
    <Circle cx={657.729} cy={436.9} r={8.27392} transform="rotate(90 657.729 436.9)" fill="#BBBBBB" />
    <Circle cx={681.63} cy={436.9} r={8.27392} transform="rotate(90 681.63 436.9)" fill="#BBBBBB" />
    <Circle cx={633.828} cy={436.9} r={8.27392} transform="rotate(90 633.828 436.9)" fill="#BBBBBB" />
    <Circle cx={609.923} cy={436.9} r={8.27392} transform="rotate(90 609.923 436.9)" fill="#BBBBBB" />
    <Circle cx={657.73} cy={282.455} r={8.27392} transform="rotate(90 657.73 282.455)" fill="#BBBBBB" />
    <Circle cx={681.631} cy={282.455} r={8.27392} transform="rotate(90 681.631 282.455)" fill="#BBBBBB" />
    <Circle cx={633.826} cy={282.455} r={8.27392} transform="rotate(90 633.826 282.455)" fill="#BBBBBB" />
    <Path
      d="M188 32.3404C188 14.4793 202.479 0 220.34 0H543.744C561.605 0 576.084 14.4793 576.084 32.3404C576.084 50.2015 561.605 64.6807 543.744 64.6807H220.34C202.479 64.6807 188 50.2015 188 32.3404Z"
      fill="url(#paint1_linear_166_274)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_166_274"
        x1={556.784}
        y1={1609.98}
        x2={-3.7731}
        y2={-12.4011}
        gradientUnits="userSpaceOnUse"
      >
        <Stop />
        <Stop offset={0.498264} stopColor="#797979" />
        <Stop offset={1} />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_166_274"
        x1={575.944}
        y1={32.2304}
        x2={155.444}
        y2={32.2304}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#212121" />
        <Stop offset={1} stopColor="#0C0C0C" />
      </LinearGradient>
      <ClipPath id="clip0_166_274">
        <Rect width={214.301} height={65.5893} fill="white" transform="translate(54.5176 66.207)" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default SvgComponent
