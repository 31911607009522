import React from "react"
import { getDateTime, getTimeWithSeconds } from "../../shared/utils/date"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { TouchableOpacity, View } from "react-native"
import AppText from "../general/AppText"
import { User } from "../../shared/entity/User"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { aggregateField, isAggregatable } from "../../shared/entry/Entry"
import { MARGIN, PADDING } from "../../constants/style"
import { FontAwesome5 } from "@expo/vector-icons"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { useTaskProvider } from "../../providers/TaskProvider"

interface Props {
  month: Date
  user: User
  onPress: () => void
}

export function AttendanceMonth({ month, user, onPress }: Props) {
  const theme: Theme = useTheme()
  const { attendances } = useAttendanceProvider()
  const { taskList } = useTaskProvider()
  const { i18n } = useSettingsProvider()
  const relevantAttendance = attendances.filter((a) => a.start?.getMonth() == month.getMonth() && a.userId == user.id)
  const aggregate: Record<string, any> = {}
  aggregate["Odpracováno"] = 0
  const icons: Record<string, string[]> = {}
  icons["Odpracováno"] = []
  for (const attendance of relevantAttendance) {
    if (!attendance.start) continue
    if (attendance.isWork()) {
      aggregate["Odpracováno"] += (attendance?.end ?? new Date()).valueOf() - attendance.start.valueOf()
    }
    for (const definition of attendance.definitions) {
      if (!isAggregatable(definition)) continue
      aggregate[definition.label] = aggregateField(
        definition,
        attendance.customFields[definition.label],
        aggregate[definition.label]
      )
      icons[definition.label] = definition.tags.filter((t) => t.includes(":")).map((t) => t.replace(":", ""))
    }
  }
  aggregate["Odpracováno"] = getTimeWithSeconds(new Date(aggregate["Odpracováno"]))
  console.log("aggregate", aggregate, icons)
  return (
    <View
      style={{
        flexDirection: "row",
        borderBottomWidth: 2,
        borderColor: theme.colors.borderPrimary,
      }}
    >
      <View
        style={{
          backgroundColor:
            new Date(new Date().setDate(0)).valueOf() < month.valueOf()
              ? theme.colors.primary
              : theme.colors.background,
          padding: PADDING,
          height: "100%",
          marginRight: MARGIN,
        }}
      />
      <TouchableOpacity
        style={{
          backgroundColor: theme.colors.background,
          flexDirection: "row",
          justifyContent: "space-between",
          borderColor: theme.colors.borderPrimary,
          flexGrow: 1,
          padding: PADDING,
        }}
        onPress={onPress}
      >
        <View>
          <AppText>{user.name}</AppText>
          <AppText>{getDateTime({ i18n: i18n, date: month, format: "MONTHANDYEAR" })}</AppText>
        </View>
        <View>
          {Object.entries(aggregate).map((pair) => (
            <AppText>
              {icons[pair[0]]?.map((s) => (
                <FontAwesome5 size={theme.iconSize.small} name={s} />
              ))}{" "}
              {pair[0] + ": " + pair[1]}
            </AppText>
          ))}
        </View>
      </TouchableOpacity>
    </View>
  )
}
