export class ModuleDefinition {
  name = "Undefined module"
  inputDefinitions?: CustomInputDefinition[]

  constructor(params?: Partial<ModuleDefinition>) {
    if (params && params.inputDefinitions) {
      for (let i = 0; i < params.inputDefinitions.length; i++) {
        params.inputDefinitions[i] = new CustomInputDefinition(params.inputDefinitions[i])
      }
    }
    // noinspection TypeScriptValidateTypes
    Object.assign(this, params)
  }

  get tags() {
    return moduleTags[this.name as keyof typeof moduleTags]
  }
}

export class CustomInputDefinition {
  label = ""
  type: keyof typeof INPUT_DEFINITION = "TEXT"
  defaultValue: any
  values: string[] = []
  required = false
  tags: string[] = []
  conditionalRendering = { key: "", value: "", type: "" as keyof typeof INPUT_DEFINITION }

  constructor(params?: Partial<CustomInputDefinition>) {
    // noinspection TypeScriptValidateTypes
    Object.assign(this, params)
    console.log("CustomInputDefinition", this)
  }
}

export enum INPUT_DEFINITION {
  TEXT = "Text",
  NUMBER = "Číslo",
  TOGGLE = "Přepínač",
  SELECT = "Výběrník",
  MULTISELECT = "Vícevýběrník",
  IMAGE = "Obrázek",
  TIME = "Čas",
  CONFIRMATION = "Potvrzení",
  TAG = "Tag",
}

export enum CUSTOM_MODULES {
  ATTENDANCE = "Docházka",
  MECHANIZATION = "Mechanizace",
  MATERIAL = "Materiál",
  WORK = "Práce",
}

export enum MODULE_TAGS {
  AGGREGATE = "Agregovat",
  ATTENDANCE_NO_WORK = "Nepracoval",
  ICON_CLOCK = ":user-clock",
  ICON_WRENCH = ":wrench",
  ICON_MEDICAL_HOME = ":clinic-medical",
  ICON_MEDICAL = ":briefcase-medical",
  ICON_BEACH = ":umbrella-beach",
}

//TODO: refactor code for translation
/*export enum INPUT_DEFINITION {
  TEXT = "text_input",
  NUMBER = "number_input",
  TOGGLE = "toggle_input",
  SELECT = "select_input",
  MULTISELECT = "multiselect_input",
  IMAGE = "image_input",
  TIME = "time_input",
  CONFIRMATION = "confirmation_input",
  TAG = "tag_input",
}

export enum CUSTOM_MODULES {
  ATTENDANCE = "attendance",
  MECHANIZATION = "mechanization",
  MATERIAL = "material",
  WORK = "work",
}

export enum MODULE_TAGS {
  AGGREGATE = "aggregate",
  ATTENDANCE_NO_WORK = "attendance_no_work",
  ICON_CLOCK = ":user-clock",
  ICON_WRENCH = ":wrench",
  ICON_MEDICAL_HOME = ":clinic-medical",
  ICON_MEDICAL = ":briefcase-medical",
  ICON_BEACH = ":umbrella-beach",
}*/

const moduleTags: Record<CUSTOM_MODULES, string[]> = {
  [CUSTOM_MODULES.ATTENDANCE]: [
    MODULE_TAGS.AGGREGATE,
    MODULE_TAGS.ATTENDANCE_NO_WORK,
    MODULE_TAGS.ICON_WRENCH,
    MODULE_TAGS.ICON_MEDICAL,
    MODULE_TAGS.ICON_MEDICAL_HOME,
    MODULE_TAGS.ICON_BEACH,
  ],
  [CUSTOM_MODULES.MECHANIZATION]: [],
  [CUSTOM_MODULES.MATERIAL]: [],
  [CUSTOM_MODULES.WORK]: [],
}

export function isInputDefinition(definition: any): definition is keyof typeof INPUT_DEFINITION {
  return definition in INPUT_DEFINITION
}

export function isRendered(
  definition: CustomInputDefinition,
  inputDefinitions: CustomInputDefinition[],
  customFields: any
) {
  return (
    definition &&
    (definition.conditionalRendering == undefined ||
      definition.conditionalRendering.key == "" ||
      isEqual(definition.label, inputDefinitions, customFields))
  )
}

export function shouldTag(
  definition: CustomInputDefinition,
  inputDefinitions: CustomInputDefinition[],
  customFields: any
) {
  return (
    isRendered(definition, inputDefinitions, customFields) &&
    (definition.type == "TAG" || customFields[definition.label])
  )
}

function isEqual(label: string, inputDefinitions: CustomInputDefinition[], customFields: any) {
  const thisField = inputDefinitions.find((p) => p.label == label)
  if (thisField == undefined) return false
  const otherField = inputDefinitions.find((p) => p.label == thisField.conditionalRendering.key)
  if (otherField == undefined) return false

  if (!isRendered(otherField, inputDefinitions, customFields)) return false
  switch (otherField.type) {
    case "TEXT":
    case "SELECT":
    case "NUMBER":
      return thisField.conditionalRendering.value == (customFields[otherField.label] ?? otherField.defaultValue)

    case "TOGGLE":
      return !thisField.conditionalRendering.value == !(customFields[otherField.label] ?? otherField.defaultValue)

    case "MULTISELECT":
      return (
        (thisField.conditionalRendering.value as unknown as string[]).sort().join() ==
        ((customFields[otherField.label] ?? otherField.defaultValue) as string[]).sort().join()
      )

    case "CONFIRMATION":
      return (
        !thisField.conditionalRendering.value ==
        !(otherField.label in customFields && "confirmedBy" in customFields[otherField.label])
      )
  }

  return false
}

export function stringFrom(value: any, definition: CustomInputDefinition) {
  switch (definition.type) {
    case "TEXT":
      return value ?? definition.defaultValue ?? ""
    case "TOGGLE":
      return value ?? definition.defaultValue ? 1 : 0
    case "NUMBER":
      return (value ?? definition.defaultValue).toString()
    case "SELECT":
      return value ?? definition.defaultValue ?? ""
    case "MULTISELECT":
      return (value ?? definition.defaultValue ?? []).join(", ")
    case "TIME":
      return value ? value.hours + ":" + value.minutes.toString().padEnd(2, "0") : ""
    case "IMAGE":
      return value ? "yes" : "no"
    case "TAG":
      return ""
    case "CONFIRMATION":
      return value ? ("confirmedBy" in value ? "yes" : "no") : "no"
  }
}
