import React, { ReactNode, useEffect } from "react"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "./types"
import { Linking } from "react-native"
import { ROUTE } from "./routes"

export default function DeepLinkRedirect({ children }: { children: ReactNode }) {
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  useEffect(() => {
    const handleRedirect = async () => {
      const url = await Linking.getInitialURL()

      if (url) {
        handleDeepLink(url)
      }
    }

    handleRedirect()
  }, [])

  const handleDeepLink = async (url: string) => {
    console.log("url:", url)
    if (url.includes("/UserLogin")) {
      redirectToLogin()
    } else if (url.includes("/DrawerProjectList")) {
      redirectToProjectList()
    } else {
      redirectToFallback()
    }
  }

  const redirectToLogin = () => {
    navigation.navigate(ROUTE.USER_LOGIN)
  }

  const redirectToProjectList = () => {
    navigation.navigate(ROUTE.PROJECT_LIST)
  }

  const redirectToFallback = () => {
    navigation.navigate(ROUTE.USER_LOGIN)
  }

  return <>{children}</>
}
