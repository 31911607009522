// @ts-nocheck
import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 96 960 960" width={24} {...props}>
    <Path fill="#F39719" d="m296 711-56-56 240-240 240 240-56 56-184-184-184 184Z" />
  </Svg>
)
export default SvgComponent
