import React, { StyleSheet, TouchableOpacity, View } from "react-native"
import { useContext } from "react"
import { UserContext } from "../providers/UserProvider"
import { RootStackParamList } from "../navigation/types"
import { ROUTE } from "../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import AppText from "../components/general/AppText"
import { Theme } from "../../types"
import { useTheme } from "react-native-paper"
import { useSettingsProvider } from "../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.NOTFOUND>

export function NotFoundScreen({ route, navigation }: Props) {
  const { currentUser } = useContext(UserContext)
  const { i18n } = useSettingsProvider()
  console.log("currentUser", currentUser)
  const theme: Theme = useTheme()

  const handleClick = () => {
    if (currentUser) {
      navigation.replace(ROUTE.DRAWER)
    } else {
      navigation.replace(ROUTE.USER_LOGIN)
    }
  }

  return (
    <View style={styles.container}>
      <AppText style={styles.title}>{i18n.t("page_not_found")}</AppText>

      <TouchableOpacity onPress={handleClick} style={styles.link}>
        <AppText style={[styles.linkText, { color: theme.colors.lightBlue }]}>{i18n.t("return")}</AppText>
      </TouchableOpacity>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
  },
})
