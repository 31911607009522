// @ts-nocheck
import * as React from "react"
import Svg, { ClipPath, Defs, G, LinearGradient, Mask, Path, Rect, Stop, SvgProps } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const SvgComponent = (props: SvgProps) => (
  <Svg width={765} height={1549} viewBox="0 0 765 1549" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Mask
      id="mask0_137_529"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={25}
      y={10}
      width={715}
      height={1531}
    >
      <Path
        d="M739.563 98.3249C739.563 50.054 700.432 10.9229 652.161 10.9229H112.623C64.3519 10.9229 25.2207 50.0541 25.2207 98.325V1453.06C25.2207 1501.33 64.352 1540.46 112.623 1540.46H652.161C700.432 1540.46 739.563 1501.33 739.563 1453.06V98.3249Z"
        fill="#E6E6E6"
      />
    </Mask>
    <G mask="url(#mask0_137_529)">
      <Path
        d="M739.562 98.3249C739.562 50.054 700.431 10.9229 652.16 10.9229H112.622C64.3509 10.9229 25.2197 50.0541 25.2197 98.325V1453.06C25.2197 1501.33 64.351 1540.46 112.622 1540.46H652.161C700.431 1540.46 739.562 1501.33 739.562 1453.06V98.3249Z"
        fill="#E6E6E6"
      />
      <G filter="url(#filter0_d_137_529)">
        <Path d="M24.5898 37.9453H740.195V156.849H24.5898V37.9453Z" fill="#F5F5F5" />
      </G>
      <G clipPath="url(#clip0_137_529)">
        <Path
          d="M57.2879 105.146H97.6375C99.1443 105.146 100.4 106.371 100.4 107.891C100.4 109.411 99.1443 110.636 97.6375 110.636H57.2879C55.7811 110.636 54.5254 109.411 54.5254 107.891C54.5254 106.371 55.7811 105.146 57.2879 105.146Z"
          fill="#F39719"
        />
        <Path
          d="M77.2943 81.2441C79.722 81.2441 81.6893 83.1866 81.6893 85.5935V102.949H72.8994V85.6358C72.8994 83.2288 74.8667 81.2441 77.2943 81.2441Z"
          fill="#F39719"
        />
        <Path d="M70.7657 102.992H57.5391C57.5391 102.992 58.9622 89.3522 70.7657 84.3271V102.992Z" fill="#F39719" />
        <Path d="M83.9512 102.992H97.1778C97.1778 102.992 95.7547 89.3522 83.9512 84.3271V102.992Z" fill="#F39719" />
        <Path
          d="M90.9422 112.873V124.401H63.8192V112.873H60.1777V127.906C60.1777 131.369 63.024 134.198 66.5399 134.198H88.2215C91.7375 134.198 94.5837 131.369 94.5837 127.906V112.873H90.9422ZM77.4644 132.424C75.6646 132.424 74.1996 130.988 74.1996 129.173C74.1996 127.399 75.6646 125.921 77.4644 125.921C79.2643 125.921 80.7292 127.357 80.7292 129.173C80.7292 130.988 79.2643 132.424 77.4644 132.424Z"
          fill="#4C4B4B"
        />
        <Path
          d="M77.463 125.964C79.2629 125.964 80.7278 127.4 80.7278 129.215C80.7278 130.989 79.2629 132.467 77.463 132.467C75.6632 132.467 74.1982 131.031 74.1982 129.215C74.2401 127.4 75.6632 125.964 77.463 125.964Z"
          fill="#F7F7F7"
        />
        <Path
          d="M122.584 134.199C118.9 134.199 115.342 133.777 111.952 132.974C108.52 132.13 105.715 131.074 103.497 129.723L108.269 118.997C110.362 120.179 112.664 121.151 115.259 121.911C117.812 122.629 120.323 123.009 122.751 123.009C124.174 123.009 125.304 122.924 126.1 122.713C126.895 122.544 127.523 122.249 127.899 121.911C128.276 121.573 128.443 121.151 128.443 120.644C128.443 119.842 128.025 119.25 127.146 118.786C126.267 118.321 125.095 117.941 123.672 117.646C122.249 117.35 120.658 117.012 118.942 116.632C117.226 116.252 115.51 115.83 113.794 115.239C112.078 114.648 110.487 113.888 109.022 112.916C107.599 111.945 106.427 110.721 105.548 109.158C104.669 107.596 104.25 105.653 104.25 103.331C104.25 100.628 105.004 98.1791 106.553 95.941C108.059 93.703 110.362 91.9295 113.375 90.6204C116.389 89.3114 120.156 88.6357 124.676 88.6357C127.648 88.6357 130.578 88.9313 133.424 89.5647C136.312 90.1981 138.908 91.1271 141.21 92.4362L136.773 103.077C134.596 101.98 132.504 101.177 130.494 100.628C128.485 100.079 126.476 99.8259 124.593 99.8259C123.17 99.8259 122.039 99.9526 121.202 100.206C120.365 100.459 119.779 100.797 119.403 101.219C119.026 101.642 118.858 102.106 118.858 102.571C118.858 103.331 119.277 103.922 120.156 104.344C121.035 104.767 122.207 105.147 123.63 105.442C125.053 105.738 126.644 106.033 128.402 106.371C130.118 106.709 131.876 107.173 133.55 107.722C135.266 108.314 136.857 109.074 138.28 110.045C139.703 111.016 140.875 112.241 141.754 113.761C142.633 115.281 143.051 117.224 143.051 119.504C143.051 122.164 142.298 124.613 140.749 126.809C139.201 129.005 136.94 130.821 133.969 132.172C130.913 133.523 127.146 134.199 122.584 134.199Z"
          fill="#4C4B4B"
        />
        <Path
          d="M164.735 122.247C163.815 122.881 162.768 123.176 161.554 123.176C160.717 123.176 160.006 122.881 159.462 122.289C158.917 121.698 158.624 120.896 158.624 119.84V111.268H165.656V101.007H158.624V91.5059H144.477V101.007H139.789V111.268H144.477V119.967C144.477 124.654 145.775 128.117 148.411 130.397C151.048 132.677 154.732 133.775 159.42 133.775C161.094 133.775 162.685 133.649 164.191 133.353C165.698 133.057 167.038 132.593 168.126 132.002L164.735 122.247Z"
          fill="#4C4B4B"
        />
        <Path
          d="M196.67 102.444C193.447 99.8256 188.885 98.5166 182.983 98.5166C180.304 98.5166 177.583 98.8544 174.779 99.5301C171.975 100.206 169.589 101.135 167.58 102.401L172.1 111.691C173.23 110.847 174.57 110.214 176.16 109.749C177.751 109.285 179.299 109.031 180.848 109.031C183.15 109.031 184.783 109.453 185.829 110.34C186.624 111.016 187.085 111.903 187.252 113.001H181.56C178.002 113.001 175.072 113.381 172.77 114.183C170.468 114.985 168.71 116.125 167.622 117.688C166.491 119.208 165.947 121.108 165.947 123.304C165.947 125.246 166.408 127.02 167.37 128.625C168.333 130.229 169.756 131.496 171.64 132.467C173.523 133.438 175.867 133.903 178.672 133.903C181.643 133.903 183.987 133.27 185.745 132.045C186.875 131.243 187.754 130.187 188.382 128.836V133.185H201.525V114.901C201.525 109.2 199.893 105.062 196.67 102.444ZM185.578 124.571C184.741 125.077 183.862 125.331 182.899 125.331C181.853 125.331 181.016 125.077 180.43 124.571C179.844 124.064 179.509 123.388 179.509 122.628C179.509 121.657 179.844 120.939 180.555 120.475C181.225 120.01 182.355 119.757 183.862 119.757H187.378V122.122C186.959 123.219 186.373 124.064 185.578 124.571Z"
          fill="#4C4B4B"
        />
        <Path
          d="M227.685 99.1494L220.318 117.392L213.412 99.1494H198.888L212.658 133.184H227.308L241.079 99.1494H227.685Z"
          fill="#4C4B4B"
        />
        <Path
          d="M268.829 133.184L255.937 115.702L268.536 99.1494H253.677L248.529 106.37L243.632 99.1494H227.684L240.241 116.336L227.308 133.184H242.627L247.859 125.921L252.589 133.184H268.829Z"
          fill="#F39719"
        />
        <Path d="M63.8203 112.87H90.9433V124.398H63.8203V112.87Z" fill="#F7F7F7" />
      </G>
      <Path
        d="M574.722 1363.73C574.722 1355.37 581.499 1348.59 589.86 1348.59H680.474C688.834 1348.59 695.612 1355.37 695.612 1363.73V1453.06C695.612 1461.42 688.834 1468.2 680.474 1468.2H589.86C581.499 1468.2 574.722 1461.42 574.722 1453.06V1363.73Z"
        fill="#F39719"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M589.86 1256.84H680.474C739.503 1256.84 787.357 1304.7 787.357 1363.73V1453.06C787.357 1512.09 739.503 1559.94 680.474 1559.94H589.86C530.83 1559.94 482.977 1512.09 482.977 1453.06V1363.73C482.977 1304.7 530.83 1256.84 589.86 1256.84ZM589.86 1348.59C581.499 1348.59 574.722 1355.37 574.722 1363.73V1453.06C574.722 1461.42 581.499 1468.2 589.86 1468.2H680.474C688.834 1468.2 695.611 1461.42 695.611 1453.06V1363.73C695.611 1355.37 688.834 1348.59 680.474 1348.59H589.86Z"
        fill="white"
      />
      <Path d="M628.853 1384.1H640.78V1432.69H628.853V1384.1Z" fill="white" />
      <Path d="M659.721 1402.84L659.721 1414.64L610.609 1414.64L610.609 1402.84L659.721 1402.84Z" fill="white" />
      <Path d="M61.8535 190.232H105.813V195.669H61.8535V190.232Z" fill="#F39719" />
      <Path d="M61.8535 201.106H105.813V206.543H61.8535V201.106Z" fill="#F39719" />
      <Path d="M61.8535 211.979H105.813V217.415H61.8535V211.979Z" fill="#F39719" />
      <Path
        d="M127.794 203.824C127.794 191.313 137.936 181.171 150.447 181.171H387.217C399.728 181.171 409.87 191.313 409.87 203.824C409.87 216.335 399.728 226.477 387.217 226.477H150.447C137.936 226.477 127.794 216.335 127.794 203.824Z"
        fill="#818181"
      />
      <G filter="url(#filter1_d_137_529)">
        <Path
          d="M54.5264 426.348C54.5264 421.281 58.6339 417.174 63.7009 417.174H701.088C706.155 417.174 710.262 421.281 710.262 426.348V538.481C710.262 543.548 706.155 547.656 701.088 547.656H63.7008C58.6339 547.656 54.5264 543.548 54.5264 538.481V426.348Z"
          fill="#F7F7F7"
        />
      </G>
      <Path
        d="M54.5264 426.348C54.5264 421.281 58.6339 417.174 63.7009 417.174H299.969V547.656H63.7009C58.6339 547.656 54.5264 543.548 54.5264 538.481V426.348Z"
        fill="#4C4B4B"
      />
      <Path
        d="M312.791 444.358C312.791 435.35 320.093 428.048 329.101 428.048H523.607C532.615 428.048 539.917 435.35 539.917 444.358C539.917 453.366 532.615 460.668 523.607 460.668H329.101C320.093 460.668 312.791 453.366 312.791 444.358Z"
        fill="#A2A2A2"
      />
      <G filter="url(#filter2_d_137_529)">
        <Path
          d="M54.5273 274.12C54.5273 269.053 58.6349 264.945 63.7018 264.945H701.089C706.156 264.945 710.263 269.053 710.263 274.12V386.253C710.263 391.32 706.156 395.427 701.089 395.427H63.7018C58.6349 395.427 54.5273 391.32 54.5273 386.253V274.12Z"
          fill="#F7F7F7"
        />
      </G>
      <Path
        d="M54.5273 274.12C54.5273 269.053 58.6349 264.945 63.7018 264.945H299.97V395.427H63.7019C58.6349 395.427 54.5273 391.32 54.5273 386.253V274.12Z"
        fill="#F39719"
      />
      <Path
        d="M312.793 290.316C312.793 281.308 320.095 274.006 329.103 274.006H541.926C550.934 274.006 558.236 281.308 558.236 290.316C558.236 299.324 550.934 306.626 541.926 306.626H329.103C320.095 306.626 312.793 299.324 312.793 290.316Z"
        fill="#A2A2A2"
      />
      <Path
        d="M316.456 323.844C316.456 318.339 320.919 313.877 326.423 313.877H515.298C520.803 313.877 525.266 318.339 525.266 323.844C525.266 329.349 520.803 333.812 515.298 333.812H326.423C320.919 333.812 316.456 329.349 316.456 323.844Z"
        fill="#BBBBBB"
      />
      <G filter="url(#filter3_d_137_529)">
        <Path
          d="M54.5264 578.576C54.5264 573.509 58.6339 569.401 63.7009 569.401H701.088C706.155 569.401 710.262 573.509 710.262 578.576V690.709C710.262 695.776 706.155 699.883 701.088 699.883H63.7008C58.6339 699.883 54.5264 695.776 54.5264 690.709V578.576Z"
          fill="#F7F7F7"
        />
      </G>
      <Path
        d="M54.5264 578.576C54.5264 573.509 58.6339 569.401 63.7009 569.401H299.969V699.883H63.7009C58.6339 699.883 54.5264 695.776 54.5264 690.709V578.576Z"
        fill="#BBBBBB"
      />
      <Path
        d="M312.794 594.772C312.794 585.764 320.096 578.462 329.104 578.462H606.035C615.043 578.462 622.345 585.764 622.345 594.772C622.345 603.78 615.043 611.082 606.035 611.082H329.104C320.096 611.082 312.794 603.78 312.794 594.772Z"
        fill="#A2A2A2"
      />
      <Path
        d="M316.456 628.3C316.456 622.796 320.919 618.333 326.423 618.333H529.952C535.457 618.333 539.919 622.796 539.919 628.3C539.919 633.805 535.457 638.268 529.952 638.268H326.423C320.919 638.268 316.456 633.805 316.456 628.3Z"
        fill="#BBBBBB"
      />
      <Path
        d="M316.456 655.482C316.456 649.977 320.919 645.515 326.423 645.515H394.409C399.913 645.515 404.376 649.977 404.376 655.482C404.376 660.987 399.913 665.449 394.409 665.449H326.423C320.919 665.449 316.456 660.987 316.456 655.482Z"
        fill="#BBBBBB"
      />
      <Path
        d="M316.456 477.883C316.456 472.379 320.919 467.916 326.423 467.916H634.357C639.862 467.916 644.324 472.379 644.324 477.883C644.324 483.388 639.862 487.851 634.357 487.851H326.423C320.919 487.851 316.456 483.388 316.456 477.883Z"
        fill="#BBBBBB"
      />
      <Path
        d="M316.456 505.068C316.456 499.563 320.919 495.101 326.423 495.101H555.595C561.1 495.101 565.562 499.563 565.562 505.068C565.562 510.573 561.1 515.035 555.595 515.035H326.423C320.919 515.035 316.456 510.573 316.456 505.068Z"
        fill="#BBBBBB"
      />
    </G>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M731.977 1461.78L731.977 86.241C731.977 56.4725 707.845 32.3404 678.076 32.3404L86.2476 32.3403C56.4791 32.3403 32.347 56.4724 32.347 86.2409L32.347 1461.78C32.347 1491.55 56.4791 1515.69 86.2476 1515.69L678.076 1515.69C707.845 1515.69 731.977 1491.55 731.977 1461.78ZM764.317 86.241C764.317 38.6114 725.706 -1.68776e-06 678.076 -3.76971e-06L86.2476 -2.96394e-05C38.618 -3.17213e-05 0.00665114 38.6113 0.00664906 86.2409L0.00658894 1461.78C0.00658685 1509.41 38.618 1548.03 86.2476 1548.03L678.076 1548.03C725.706 1548.03 764.317 1509.41 764.317 1461.78L764.317 86.241Z"
      fill="url(#paint0_linear_137_529)"
    />
    <Path
      d="M188 32.3404C188 14.4793 202.479 0 220.34 0H543.744C561.605 0 576.084 14.4793 576.084 32.3404C576.084 50.2015 561.605 64.6807 543.744 64.6807H220.34C202.479 64.6807 188 50.2015 188 32.3404Z"
      fill="url(#paint1_linear_137_529)"
    />
    <Path
      d="M243.095 1495.4C243.095 1493.13 244.929 1491.3 247.192 1491.3H542.174C544.437 1491.3 546.271 1493.13 546.271 1495.4C546.271 1497.66 544.437 1499.49 542.174 1499.49H247.192C244.929 1499.49 243.095 1497.66 243.095 1495.4Z"
      fill="black"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_137_529"
        x1={556.8}
        y1={1610.01}
        x2={-3.76635}
        y2={-12.4013}
        gradientUnits="userSpaceOnUse"
      >
        <Stop />
        <Stop offset={0.498264} stopColor="#797979" />
        <Stop offset={1} />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_137_529"
        x1={575.944}
        y1={32.2304}
        x2={155.444}
        y2={32.2304}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#212121" />
        <Stop offset={1} stopColor="#0C0C0C" />
      </LinearGradient>
      <ClipPath id="clip0_137_529">
        <Rect width={214.305} height={65.2409} fill="white" transform="translate(54.5273 74.0635)" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default SvgComponent
