import { Weather, WEATHER_TYPE } from "../shared/common/Weather"
import { callFunction } from "./general"
import { API } from "../shared/apis"
import geolib from "geolib"

const weatherCache = new Map<string, Weather>()

export async function fetchWeatherBackend(
  latitude?: number,
  longitude?: number,
  date: Date = new Date()
): Promise<Weather> {
  if (!latitude) throw Error("latitude must be defined")
  if (!longitude) throw Error("longitude must be defined")
  console.debug("fetchWeather with params latitude:", latitude, " longitude: ", longitude, " date:", date)
  const cacheKey = `${latitude.toFixed(6)}&${longitude.toFixed(6)}&${date.toISOString()}`

  const cachedWeather = Array.from(weatherCache.values()).find(
    () => geolib.getDistance({ latitude, longitude }, { latitude: latitude, longitude: longitude }) <= 1000
  )
  if (cachedWeather) {
    console.debug("using cached weather value", cachedWeather)
    return cachedWeather
  }

  try {
    const response = await callFunction(API.weatherFetch, {
      date,
      latitude,
      longitude,
    })
    console.debug("fetchWeatherBackend response:", response)

    const weather = new Weather({
      date,
      latitude,
      longitude,
      type: WEATHER_TYPE.AUTO,
      hours: response.data.data.data.days[0].hours,
      day: response.data.data.data.days[0],
      data: response.data.data.data,
    })
    console.debug("fetchWeatherBackend weather:", weather)
    weatherCache.set(cacheKey, weather)
    return weather
  } catch (error) {
    console.error("Error fetchWeather:", error)
    throw error
  }
}
