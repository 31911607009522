import axios from "axios"
import { getFunctionUrl, getHeaders } from "./general"
import { API } from "../shared/apis"

export const exportDiary = async (projectId: string): Promise<string> => {
  console.debug("exportDiary projectId", projectId)
  try {
    const headers = await getHeaders()
    const result = await axios.post(getFunctionUrl(API.projectExport), { projectId }, { headers })
    console.log("exportDiary result:", result)
    return result.data.data
  } catch (error) {
    console.error("exportDiary error:", error)
    if (error.response?.data?.message) {
      throw new Error(error.response?.data?.message)
    } else {
      throw error
    }
  }
}
