import { IExportable, IExporter } from "../export/export"
import { CustomEntry } from "./Entry"

export class RecordEntryData extends CustomEntry implements IExportable {
  description?: string
  type?: string
  uriList: string[] = []

  constructor(data?: any) {
    super(data)
    this.uriList = []
    Object.assign(this, data)
  }

  async exportData(exporter: IExporter): Promise<void> {
    exporter.pageBreak()
    exporter.addHeading("Příloha")
    exporter.addLine("Typ", this.type)
    exporter.addLine("Popis", this.description)
    const promises = await Promise.all(this.uriList.map((uri) => exporter.getBase64ImageFromUri(uri)))
    promises.forEach((img) => exporter.addImage(img, 500))
  }
}
