import { FontAwesome, FontAwesome5, MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons"
import React, { useContext, useEffect, useLayoutEffect, useState } from "react"
import { ScrollView, TouchableOpacity, View } from "react-native"
import { useTheme } from "react-native-paper"
import Screen from "../../components/general/Screen"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { WorkerEntry } from "../../components/diary/WorkerEntry"
import { WeatherEntry } from "../../components/diary/WeatherEntry"
import { RecordEntry } from "../../components/diary/RecordEntry"
import { ROUTE } from "../../navigation/routes"
import { ExpandableEntryListCard } from "../../components/container/ExpandableEntryListCard"
import HeaderMenu from "../../components/diary/HeaderMenu"
import { getDateTime, toDateString, YYYYMMDD } from "../../shared/utils/date"
import { Diary } from "../../shared/entity/Diary"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { Theme } from "../../../types"
import { SignatureEntry } from "../../components/diary/SignatureEntry"
import { showMessage } from "react-native-flash-message"
import { pathRenderer } from "../../navigation/pathRenderer"
import MaxWidth from "../../components/container/MaxWidth"
import { DateInput } from "../../components/input/DateInput"
import DialogWindow from "../../components/general/DialogWindow"
import { WeatherEntryData } from "../../shared/entry/WeatherEntryData"
import AppText from "../../components/general/AppText"
import { ExpandableCard, getStylesExpandableCard } from "../../components/container/ExpandableCard"
import { MARGIN, RADIUS } from "../../constants/style"
import { WeatherSettings } from "../../shared/common/settings/WeatherSettings"
import { UserContext, useUserProvider } from "../../providers/UserProvider"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { compareNumbers } from "../../shared/utils/common"
import { Entry } from "../../shared/entry/Entry"
import { RecordEntryData } from "../../shared/entry/RecordEntryData"
import { WorkerEntryData } from "../../shared/entry/WorkerEntryData"
import { SignatureEntryData } from "../../shared/entry/SignatureEntryData"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { CUSTOM_MODULES } from "../../shared/common/CustomInputDefinition"
import { ExpandableGenericListCard } from "../../components/container/ExpandableGenericListCard"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { ATTENDANCE_STATE } from "../../shared/entity/Attendance"
import { AttendanceEntry } from "../../components/attendance/AttendanceEntry"
import { OPERATION } from "../../shared/entity/Role"
import { useWorldProvider } from "../../providers/WorldProvider"
import { ActionElement } from "../../components/container/ActionElement"

type Props =
  | NativeStackScreenProps<RootStackParamList, ROUTE.DIARY_DETAIL>
  | NativeStackScreenProps<RootStackParamList, ROUTE.DIARY_NEW>

export function DiaryDetailScreen({ navigation, route }: Props) {
  const { i18n } = useSettingsProvider()
  const {
    selectedProject,
    nextDiary,
    previousDiary,
    readProject,
    selectProject,
    updateDiary,
    readDiary,
    createDiary,
    diaryList,
    currentDiary,
    setCurrentDiary,
  } = useProjectProvider()
  const { currentUser } = useContext(UserContext)
  const { setStatusSuccess, setStatusError } = useStatusProvider()
  const { company } = useCompanyProvider()
  const { isOperationAuthorized } = useUserProvider()
  const { weather } = useWorldProvider()
  const { attendances } = useAttendanceProvider()
  const { settings } = useSettingsProvider()
  const [duplicationDialog, setDuplicationDialog] = useState(false)
  const [duplicationDate, setDuplicationDate] = useState(new Date())
  const theme: Theme = useTheme()
  const styles = getStylesExpandableCard(theme)

  useEffect(() => {
    if ("projectId" in route.params) {
      readProject(route.params.projectId).then((project) => {
        if (project) selectProject(project)
      })
    }
  }, [])

  useEffect(() => {
    console.debug("route", route)
    if (route.name === ROUTE.DIARY_NEW) {
      const today = diaryList.find((e) => e.date && YYYYMMDD(e.date) == YYYYMMDD(new Date()))
      if (today == undefined) {
        console.debug("creating new diary ... stars")
        createDiary().then((diary) => {
          console.debug("creating new diary ... done", diary)
          setCurrentDiary(diary as Diary)
          if (selectedProject && selectedProject.id && diary) {
            navigation.navigate(ROUTE.DIARY_DETAIL, {
              projectId: selectedProject.id,
              diaryId: diary.id,
            })
          }
        })
      } else {
        navigation.navigate(ROUTE.DIARY_DETAIL, {
          projectId: today.projectId,
          diaryId: today.id,
        })
      }
    }

    if (route.name === ROUTE.DIARY_DETAIL) {
      if ("diaryId" in route.params) {
        readDiary(route.params.diaryId).then((diary) => {
          if (diary) {
            setCurrentDiary(diary)
          }
          console.debug("diary", diary)
        })
      }
    }
  }, [selectedProject?.id])

  useLayoutEffect(() => {
    navigation.setOptions({
      title: `${selectedProject?.title}`,
      headerRight: () => (
        <HeaderMenu
          id={currentDiary?.id || ""}
          onUnlock={handleUnlock}
          onDuplicate={() => {
            setDuplicationDialog(true)
            setDuplicationDate(currentDiary?.date)
          }}
        />
      ),
    })
    pathRenderer({
      navigation,
      path: [
        [i18n.t("projects"), () => navigation.popToTop()],
        [
          selectedProject?.title ?? i18n.t("project"),
          () => navigation.navigate(ROUTE.PROJECT_DETAIL, { projectId: route.params.projectId }),
        ],
        [toDateString(currentDiary?.date) || i18n.t("daily_record"), () => {}],
      ],
    })
  }, [navigation, selectedProject, currentDiary])

  function handleLock() {}

  function handleUnlock() {}

  function handleSignatureSave() {}

  function updateCurrentDiary(sectionName: string, data: Entry[]) {
    console.debug("updateCurrentDiary", data)
    const updatedEntry = new Diary({ ...currentDiary, [sectionName]: data })
    if (currentDiary) {
      console.debug("updateCurrentDiary", updatedEntry)
      setCurrentDiary(updatedEntry)
      void updateDiary(updatedEntry)
      showMessage({ message: i18n.t("saved"), type: "success" })
    }
  }

  console.log("is attendanceManager", isOperationAuthorized(OPERATION.ATTENDANCE_MANAGE))
  return (
    <Screen>
      <MaxWidth>
        {currentDiary ? (
          <ScrollView style={{ flexShrink: 0 }}>
            <View style={{ margin: MARGIN }}>
              <View
                style={[
                  {
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    borderRadius: RADIUS,
                    height: 60,
                    paddingHorizontal: 20,
                  },
                  styles.container,
                ]}
              >
                {previousDiary ? (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(ROUTE.DIARY_DETAIL, {
                        projectId: previousDiary?.projectId,
                        diaryId: previousDiary?.id,
                      })
                      setCurrentDiary(previousDiary)
                    }}
                  >
                    <FontAwesome name={"chevron-left"} color={theme.body.textColor} />
                  </TouchableOpacity>
                ) : (
                  <View></View>
                )}
                <AppText style={{ fontWeight: "500" }} type={"heading"} maxLines={1}>
                  {getDateTime({
                    i18n: i18n,
                    date: currentDiary.date,
                    format: "MONTHANDYEAR",
                  }) || getDateTime({ i18n: new Date() })}
                </AppText>
                {nextDiary ? (
                  <TouchableOpacity
                    onPress={() => {
                      navigation.navigate(ROUTE.DIARY_DETAIL, {
                        projectId: nextDiary?.projectId,
                        diaryId: nextDiary?.id,
                      })
                      setCurrentDiary(nextDiary)
                    }}
                  >
                    <FontAwesome name={"chevron-right"} color={theme.body.textColor} />
                  </TouchableOpacity>
                ) : (
                  <View></View>
                )}
              </View>
            </View>
            <ExpandableEntryListCard<WeatherEntryData>
              name={i18n.t("weather")}
              key="weatherEntry"
              icon={
                <MaterialCommunityIcons color={theme.colors.white} name="weather-sunny" size={theme.iconSize.regular} />
              }
              entryList={currentDiary.weatherEntry || []}
              template={WeatherEntry}
              onSave={(data) => {
                console.log("onSave WeatherEntry", data)
                updateCurrentDiary("weatherEntry", data)
              }}
              onCreate={() => {
                try {
                  const weatherSettings = new WeatherSettings(settings?.project?.weather)
                  if (weather && weatherSettings.automaticWeather && weatherSettings?.timeRange?.length >= 1) {
                    const weatherEntry: WeatherEntryData[] = []
                    weatherSettings.timeRange?.sort(compareNumbers).forEach((index) => {
                      if (weather?.getHours()) {
                        weatherEntry.push(weather?.getHours()[index])
                        updateCurrentDiary("weatherEntry", weatherEntry)
                      }
                    })
                    showMessage({
                      message: `${i18n.t("weather_loaded")} : ${weatherSettings.timeRange?.length}x`,
                      type: "success",
                    })
                  } else {
                    currentDiary?.weatherEntry?.push(new WeatherEntryData())
                    updateCurrentDiary("weatherEntry", currentDiary?.weatherEntry || [])
                  }
                } catch (e) {
                  console.error(e)
                }
                console.log("onCreate weatherEntry", currentDiary?.weatherEntry)
              }}
            />

            <ExpandableCard
              noPadding
              offset
              name={i18n.t("attendance")}
              actionElement={
                <ActionElement
                  actionList={[
                    {
                      label: i18n.t("manage"),
                      iconName: "cog",
                      action: () => {
                        selectedProject && navigation.navigate(ROUTE.ATTENDANCE)
                      },
                    },
                  ]}
                />
              }
              icon={<FontAwesome name={"user"} color={theme.colors.white} size={theme.iconSize.regular} />}
            >
              {attendances
                .filter(
                  (a) =>
                    a.state != ATTENDANCE_STATE.PREPARED &&
                    a.projectId == selectedProject?.id &&
                    currentDiary?.date?.toDateString() == a.start?.toDateString()
                )
                .map((a) => (
                  <AttendanceEntry key={a.id} attendance={a} />
                ))}
            </ExpandableCard>
            <ExpandableEntryListCard<WorkerEntryData>
              key="workerEntry"
              name={i18n.t("persons")}
              entryList={currentDiary.workerEntry || []}
              icon={<FontAwesome5 color={theme.colors.white} name="people-carry" size={theme.iconSize.regular} />}
              template={WorkerEntry}
              onSave={(data) => {
                console.log("onSave WorkerEntry", data)
                updateCurrentDiary("workerEntry", data)
              }}
              onCreate={() => {
                console.log("onCreate WorkerEntry", currentDiary?.workerEntry)
                currentDiary?.workerEntry?.push(new WorkerEntryData())
                updateCurrentDiary("workerEntry", currentDiary?.workerEntry || [])
              }}
            />
            <ExpandableGenericListCard
              definitions={company?.companyModules[CUSTOM_MODULES.MECHANIZATION]}
              icon={<FontAwesome color={theme.colors.white} name="gears" size={theme.iconSize.regular} />}
              data={currentDiary.mechanizationEntry}
              onSave={(data) => {
                updateCurrentDiary("mechanizationEntry", data)
              }}
            />
            <ExpandableGenericListCard
              definitions={company?.companyModules[CUSTOM_MODULES.MATERIAL]}
              icon={
                <MaterialCommunityIcons color={theme.colors.white} name="layers-triple" size={theme.iconSize.regular} />
              }
              data={currentDiary.materialEntry}
              onSave={(data) => {
                updateCurrentDiary("materialEntry", data)
              }}
            />
            <ExpandableGenericListCard
              definitions={company?.companyModules[CUSTOM_MODULES.WORK]}
              icon={
                <MaterialCommunityIcons color={theme.colors.white} name="hammer-wrench" size={theme.iconSize.regular} />
              }
              data={currentDiary.workEntry}
              onSave={(data) => {
                updateCurrentDiary("workEntry", data)
              }}
            />
            <ExpandableEntryListCard<RecordEntryData>
              name={i18n.t("records")}
              key="recordEntry"
              icon={<MaterialIcons color={theme.colors.white} name="list-alt" size={theme.iconSize.regular} />}
              entryList={currentDiary.recordEntry || []}
              template={RecordEntry}
              parentData={currentDiary}
              onSave={(data) => {
                console.log("onSave RecordEntry", data)
                updateCurrentDiary("recordEntry", data)
              }}
              onCreate={() => {
                console.log("onCreate recordEntry", currentDiary?.recordEntry)
                currentDiary?.recordEntry?.push(new RecordEntryData())
                updateCurrentDiary("recordEntry", currentDiary?.recordEntry || [])
              }}
            />
            <ExpandableEntryListCard<SignatureEntryData>
              name={i18n.t("signatures")}
              key="signatureEntry"
              icon={<FontAwesome5 name="signature" size={theme.iconSize.regular} color={theme.colors.white} />}
              entryList={currentDiary.signatureEntry || []}
              template={SignatureEntry}
              parentData={currentDiary}
              onSave={(data) => {
                console.log("onSave SignatureEntry", data)
                updateCurrentDiary("signatureEntry", data)
              }}
              onCreate={() => {
                console.log("onCreate signatureEntry", currentDiary?.signatureEntry)
                currentDiary?.signatureEntry?.push(new SignatureEntryData())
                updateCurrentDiary("signatureEntry", currentDiary?.signatureEntry || [])
              }}
            />
          </ScrollView>
        ) : (
          <></>
        )}
      </MaxWidth>
      <DialogWindow
        title={i18n.t("entry_duplication")}
        visible={duplicationDialog}
        onDismiss={() => {
          setDuplicationDialog(false)
        }}
        onPress={async () => {
          if (diaryList.find((e) => e.date && YYYYMMDD(e.date) == YYYYMMDD(duplicationDate)) == undefined) {
            const duplicate = new Diary(JSON.parse(JSON.stringify(currentDiary)))
            duplicate.id = ""
            duplicate.signatureEntry = []
            duplicate.date = duplicationDate
            duplicate.authorizeOwner(currentUser)
            await FirebaseObject.create(duplicate)
            setStatusSuccess(i18n.t("entry_duplicated"))
          } else {
            setStatusError(i18n.t("entry_already_exists"))
          }
          setDuplicationDialog(false)
        }}
      >
        <DateInput
          value={duplicationDate}
          onSave={(date) => {
            setDuplicationDate(date)
          }}
          label={i18n.t("date_of_duplicated_entry")}
        />
      </DialogWindow>
    </Screen>
  )
}
