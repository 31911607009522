import { FontAwesome } from "@expo/vector-icons"
import React, { useContext, useState } from "react"
import { errorCodes } from "../../constants/ErrorMessages"
import OptionsMenu from "../header/OptionsMenu"
import DialogWindow from "../general/DialogWindow"
import { YYYYMMDD } from "../../shared/utils/date"
import { Diary } from "../../shared/entity/Diary"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { DateInput } from "../input/DateInput"
import { UserContext } from "../../providers/UserProvider"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function HeaderMenu({ id, entry }: { id: string; entry: Diary }) {
  const { setStatusError, setStatusSuccess, setStatusUndetermined } = useStatusProvider()
  const { deleteDiary, diaryList } = useProjectProvider()
  const [duplicationDialog, setDuplicationDialog] = useState(false)
  const [duplicationDate, setDuplicationDate] = useState(new Date())
  const { currentUser } = useContext(UserContext)
  const { i18n } = useSettingsProvider()

  const handleDelete = async () => {
    try {
      setStatusUndetermined(i18n.t("deleting_the_entry"))
      await deleteDiary(id)
      setStatusSuccess(i18n.t("entry_removed"))
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }

  const onDuplicate = async () => {
    setDuplicationDialog(true)
  }

  return (
    <>
      <OptionsMenu
        title={i18n.t("entry_options")}
        options={[
          {
            name: i18n.t("duplicate"),
            icon: <FontAwesome size={24} name="copy" color={"white"} />,
            action: onDuplicate,
          },
          {
            name: i18n.t("delete"),
            icon: <FontAwesome size={24} name="trash-o" color={"white"} />,
            action: handleDelete,
          },
        ]}
      />
      <DialogWindow
        title={i18n.t("record_duplication")}
        visible={duplicationDialog}
        onDismiss={() => {
          setDuplicationDialog(false)
        }}
        onPress={async () => {
          if (diaryList.find((e) => e.date && YYYYMMDD(e.date) == YYYYMMDD(duplicationDate)) == undefined) {
            const duplicate = new Diary(JSON.parse(JSON.stringify(entry)))
            duplicate.id = ""
            duplicate.signatureEntry = []
            duplicate.date = duplicationDate
            duplicate.authorizeOwner(currentUser)
            await FirebaseObject.create(duplicate)
            setStatusSuccess(i18n.t("entry_duplicated"))
          } else {
            setStatusError(i18n.t("record_already_exists"))
          }
          setDuplicationDialog(false)
        }}
      >
        <DateInput
          value={duplicationDate}
          onSave={(date) => {
            setDuplicationDate(date)
          }}
          label={i18n.t("date_of_duplicated_entry")}
        />
      </DialogWindow>
    </>
  )
}
