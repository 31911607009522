import { DocObject } from "./DocObject"
import { PlaceLocation } from "../common/PlaceLocation"

export class Device extends DocObject {
  persistenceFilter: any = { persistenceFilter: true, id: true, companyUsers: true }
  name?: string
  location?: PlaceLocation

  constructor(params?: Partial<Device>) {
    super(params)
    this.assign(params)
  }

  getPathSegment() {
    return "Device"
  }

  public setLocation(location: PlaceLocation) {
    this.location = location
  }

  public setName(name: string) {
    this.name = name
  }

  assign(data: any) {
    super.assign(data)
  }
}
