import "expo-dev-client"
import "react-native-reanimated"
import "react-native-get-random-values"
import React from "react"
import { SafeAreaProvider } from "react-native-safe-area-context"
import ErrorBoundary from "./src/components/general/ErrorBoundary"
import { configure } from "./src/persistence/FirebaseConfig"
import StatusProvider from "./src/providers/StatusProvider"
import SettingsProvider from "./src/providers/SettingsProvider"
import UserProvider from "./src/providers/UserProvider"
import ProjectProvider from "./src/providers/ProjectProvider"
import CompanyProvider from "./src/providers/CompanyProvider"
import AdminProvider from "./src/providers/AdminProvider"
import LocationProvider from "./src/providers/LocationProvider"
import DeviceProvider from "./src/providers/DeviceProvider"
import AttendanceProvider from "./src/providers/AttendanceProvider"
import Constants from "expo-constants"
import * as Sentry from "sentry-expo"
import IssueProvider from "./src/providers/IssueProvider"
import PaymentProvider from "./src/providers/PaymentProvider"
import ApplicationProvider from "./src/providers/ApplicationProvider"
import { Platform } from "react-native"
import MemoProvider from "./src/providers/MemoProvider"
import * as TaskManager from "expo-task-manager"
import * as Notifications from "expo-notifications"
import TaskProvider from "./src/providers/TaskProvider"

const isConfigured = configure()

const BACKGROUND_NOTIFICATION_TASK = "BACKGROUND-NOTIFICATION-TASK"

TaskManager.defineTask(BACKGROUND_NOTIFICATION_TASK, ({ data, error, executionInfo }) => {
  console.log("Received a notification in the background!")
})

Notifications.registerTaskAsync(BACKGROUND_NOTIFICATION_TASK)

export default function App() {
  // const isLoadingComplete = useCachedResources()
  if (Platform.OS == "android") global.crypto.getRandomValues = global.expo.modules.ExpoCrypto.getRandomValues
  if (Platform.OS == "web") setTimeout(() => (document.getElementById("root").style.overflow = "hidden"), 300)
  Sentry.init({
    environment: Constants.manifest?.environment,
    dsn: "https://e537c87b2dce417d9040fb5dc3d40851@o4505000621703168.ingest.sentry.io/4505000633630720",
    tracesSampleRate: 1.0,
    enableInExpoDevelopment: Constants?.manifest?.extra?.environment !== "local",
    debug: true,
    enabled: Constants?.manifest?.extra?.environment !== "local",
  })

  return (
    <ErrorBoundary>
      <SafeAreaProvider>
        <StatusProvider>
          <LocationProvider>
            <DeviceProvider>
              <UserProvider>
                <PaymentProvider>
                  <IssueProvider>
                    <MemoProvider>
                      <SettingsProvider>
                        <CompanyProvider>
                          <TaskProvider>
                            <ProjectProvider>
                              <AttendanceProvider>
                                <AdminProvider>
                                  <ApplicationProvider />
                                </AdminProvider>
                              </AttendanceProvider>
                            </ProjectProvider>
                          </TaskProvider>
                        </CompanyProvider>
                      </SettingsProvider>
                    </MemoProvider>
                  </IssueProvider>
                </PaymentProvider>
              </UserProvider>
            </DeviceProvider>
          </LocationProvider>
        </StatusProvider>
      </SafeAreaProvider>
    </ErrorBoundary>
  )
  // }
}
