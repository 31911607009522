import axios from "axios"

import { getFunctionUrl, getHeaders } from "./general"
import { API } from "../shared/apis"
import { PlaceLocation } from "../shared/common/PlaceLocation"

type CompanyDetailFromAres = {
  name: string
  location: PlaceLocation
}

export async function fetchCompanyDetailFromAres(ico: string) {
  try {
    const headers = await getHeaders()
    const response = await axios.post(getFunctionUrl(API.aresFetch), { ico }, { headers })
    return parseCompanyDetailFromAres(response.data)
  } catch (error) {
    console.error("Error fetchCompanyDetailFromAres:", error)
    throw error
  }
}

export async function parseCompanyDetailFromAres(string: string) {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(string, "text/xml")
  if (xmlDoc.getElementsByTagName("D:E")[0]) throw new Error("Společnost nenalezena v ARESu")
  try {
    const companyDetailFromAres: CompanyDetailFromAres = {
      name: xmlDoc.getElementsByTagName("D:OF")[0].childNodes[0].nodeValue,
      location: {
        address: {
          address:
            xmlDoc.getElementsByTagName("D:NU")[0].childNodes[0].nodeValue +
            " " +
            xmlDoc.getElementsByTagName("D:CD")[0].childNodes[0].nodeValue +
            ", " +
            xmlDoc.getElementsByTagName("D:PSC")[0].childNodes[0].nodeValue +
            " " +
            xmlDoc.getElementsByTagName("D:N")[0].childNodes[0].nodeValue +
            ", " +
            xmlDoc.getElementsByTagName("D:NS")[0].childNodes[0].nodeValue,
          country: xmlDoc.getElementsByTagName("D:NS")[0].childNodes[0].nodeValue,
          city: xmlDoc.getElementsByTagName("D:N")[0].childNodes[0].nodeValue,
          zip: xmlDoc.getElementsByTagName("D:PSC")[0].childNodes[0].nodeValue,
          street: xmlDoc.getElementsByTagName("D:NU")[0].childNodes[0].nodeValue,
          streetNo: xmlDoc.getElementsByTagName("D:CD")[0].childNodes[0].nodeValue,
        },
      },
    }
    console.log("fetchCompanyDetailFromAres data:", companyDetailFromAres)
    return companyDetailFromAres
  } catch (error) {
    console.error("Error parseCompanyDetailFromAres:", error)
    throw error
  }
}
