import { DocObject } from "../DocObject"

export class ProductPrice extends DocObject {
  active?: boolean
  billingScheme?: string
  currency?: string
  description?: string
  interval?: string
  intervalCount?: number
  taxBehavior?: string
  tiers?: string
  tiersMode?: string
  transformQuantity?: string
  trialPeriodDays?: string
  type?: string
  unitAmount?: number

  constructor(params?: Partial<ProductPrice>) {
    super(params)
    this.assign(params)
  }

  getPrice(): number {
    return (this.unitAmount || 30) / 100
  }
}
