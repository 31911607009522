import { DatePickerModal } from "react-native-paper-dates"
import React, { useState } from "react"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { TouchableOpacity } from "react-native"
import TextField from "./TextField"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  required?: boolean
  value?: Date
  onSave: (date: Date) => void
  label: string
  disabled?: boolean
}

export function DateInput(props: Props) {
  const { i18n } = useSettingsProvider()
  const stringToDate = (text: string) => {
    const date = new Date()
    date.setFullYear(parseInt(text.split(".")[2]), parseInt(text.split(".")[1]) - 1, parseInt(text.split(".")[0]))
    return date
  }

  const dateToString = (date: Date) => {
    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  }

  const theme: Theme = useTheme()
  const [text, setText] = useState(
    props.required ? dateToString(new Date()) : props.value ? dateToString(props.value) : ""
  )
  const [isDatePickerVisible, setDatePickerVisible] = useState(false)
  const transformer = (text: string) => {
    text = text.replace(/\//g, ".").replace(/-/g, ".")
    text = text.replace(/\.\.+/g, ".")
    return text.replace(/[^0-9.]/g, "")
  }

  return (
    <>
      <TextField
        disabled={props.disabled}
        value={text}
        label={props.label}
        errorText={dateToString(stringToDate(text)) == text ? "" : i18n.t("invalid_date")}
        onChange={(t) => setText(transformer(t))}
        onSave={() => {
          if (dateToString(stringToDate(text)) == text) {
            props.onSave(stringToDate(text))
          }
        }}
      >
        <TouchableOpacity
          style={{
            flexGrow: 1,
            height: "100%",
            paddingHorizontal: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => setDatePickerVisible(true)}
        >
          <MaterialCommunityIcons name="calendar" size={theme.iconSize.regular} color={theme.colors.white} />
        </TouchableOpacity>
      </TextField>
      <DatePickerModal
        mode={"single"}
        locale={"cs"}
        moreLabel={i18n.t("more")}
        saveLabel={i18n.t("save")}
        date={props.required ? new Date() : props.value}
        label={i18n.t("select_date")}
        visible={isDatePickerVisible}
        onConfirm={(date) => {
          if (date == undefined || date.date == undefined) return
          const newDate = new Date(date.date.valueOf())
          setText(dateToString(newDate))
          setDatePickerVisible(false)
          props.onSave(newDate)
        }}
        onDismiss={() => setDatePickerVisible(false)}
      />
    </>
  )
}
