import React, { useState } from "react"
import { Project } from "../../../shared/entity/Project"
import { showMessage } from "react-native-flash-message"
import { useProjectProvider } from "../../../providers/ProjectProvider"
import { ExpandableCard } from "../../container/ExpandableCard"
import { FontAwesome } from "@expo/vector-icons"
import { Theme } from "../../../../types"
import { useTheme } from "react-native-paper"
import { ActionElement } from "../../container/ActionElement"
import { useCompanyProvider } from "../../../providers/CompanyProvider"
import { ProjectUser } from "./ProjectUser"
import { AddProjectUser } from "../AddProjectUser"
import { useUserProvider } from "../../../providers/UserProvider"
import { UserMessage } from "../../../shared/entity/UserMessage"
import { ROUTE } from "../../../navigation/routes"
import { PARTICIPANT_TYPE } from "../../../shared/entity/Participant"
import { useSettingsProvider } from "../../../providers/SettingsProvider"

export function ProjectUsers({ startOpen }: { startOpen?: boolean }) {
  const theme: Theme = useTheme()
  const { sendMessage } = useUserProvider()
  const { selectedProject, updateProject } = useProjectProvider()
  const { companyUsers } = useCompanyProvider()
  const [project, setProject] = useState(new Project(selectedProject))
  const [visible, setVisible] = useState(false)
  const { i18n } = useSettingsProvider()

  const actions = (
    <ActionElement
      actionList={[
        {
          label: i18n.t("add"),
          iconName: "plus",
          action: () => {
            setVisible(true)
          },
        },
      ]}
    />
  )
  if (!selectedProject) return <></>
  return (
    <>
      <ExpandableCard
        noPadding
        offset
        startOpen={startOpen}
        name={i18n.t("participants")}
        icon={<FontAwesome color={theme.colors.white} name={"user"} size={theme.iconSize.regular} />}
        actionElement={actions}
      >
        {project.getAuthorities()?.map((p) => (
          <ProjectUser
            user={companyUsers.find((u) => p == u.id)}
            type={project.participants[p] as unknown as keyof typeof PARTICIPANT_TYPE}
            removeUser={(userId) => {
              project.forbid(userId, "OWNER")
              setProject(new Project(project))
              updateProject(project).then(() => {
                showMessage({ type: "success", message: i18n.t("user_deleted") })
              })
            }}
          />
        ))}
      </ExpandableCard>
      <AddProjectUser
        visible={visible}
        onUserAdded={(user, type) => {
          setVisible(false)
          if (!user || !user.id || !type) return
          project.authorize(user.id, "OWNER")
          setProject(new Project(project))
          project.participants[user.id] = type
          updateProject(project).then(() => {
            sendMessage(
              new UserMessage({
                userId: user.id,
                title: `${i18n.t("you_have_been_added_to_the_project")} ${project.title} ${i18n.t("as")} ${
                  PARTICIPANT_TYPE[type as unknown as keyof typeof PARTICIPANT_TYPE]
                }`,
                link: ROUTE.PROJECT_DETAIL,
                payload: { projectId: project.id },
              })
            )
            showMessage({ type: "success", message: i18n.t("user_added") })
          })
        }}
      />
    </>
  )
}
