import React from "react"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { View } from "react-native"
import InputBase from "./InputBase"
import AppText from "../general/AppText"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function Cell({ label, value }: { label: string; value: string }) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  return (
    <InputBase noPadding={true} label={""}>
      <View style={{ alignItems: "center", flexDirection: "row", flexGrow: 1 }}>
        <View
          style={{
            height: "100%",
            backgroundColor: theme.colors.primary,
            justifyContent: "center",
            padding: 10,
            width: "30%",
          }}
        >
          <AppText noPadding white>
            {label}
          </AppText>
        </View>
        <AppText style={{ marginLeft: 10 }} noPadding>
          {value}
        </AppText>
      </View>
    </InputBase>
  )
}
