import {
  addDoc,
  collection,
  collectionGroup,
  doc,
  getCountFromServer,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore"
import { firestore } from "./FirebaseConfig"
import { DOC_AUTH_PREFIX, DOC_PERMISSION_CAN_READ, DOC_PERMISSION_CAN_WRITE } from "../shared/entity/DocObject"
import { User } from "../shared/entity/User"
import { QueryConstraint } from "@firebase/firestore"
import { Company, CompanyStatus } from "../shared/entity/Company"
import { Project } from "../shared/entity/Project"
import { Document } from "../shared/entity/Document"
import { Diary } from "../shared/entity/Diary"
import { Settings } from "../shared/entity/Settings"
import { Attendance, ATTENDANCE_STATE } from "../shared/entity/Attendance"
import { UserInvitation } from "../shared/entity/UserInvitation"
import { Issue } from "../shared/entity/Issue"
import { Transaction } from "../shared/entity/payment/Transaction"
import { Payment } from "@stripe/firestore-stripe-payments"
import { Product } from "../shared/entity/payment/Product"
import { ProductPrice } from "../shared/entity/payment/ProductPrice"
import { Subscription } from "../shared/entity/payment/Subscription"
import { UserMessage } from "../shared/entity/UserMessage"
import { Checkout } from "../shared/entity/payment/Checkout"
import { News } from "../shared/common/News"
import { Memo } from "../shared/entity/Memo"
import { MemoRoom } from "../shared/entity/MemoRoom"
import { Task } from "../shared/entity/Task"

// https://firebase.google.com/docs/firestore/query-data/order-limit-data
// https://firebase.google.com/docs/firestore/query-data/queries

export async function collectionUpdates<T>(
  pathSegment: string,
  queryConstraints: QueryConstraint[],
  constructorFn: (data: any) => T,
  callback: (objects: Array<T>) => void
) {
  console.debug("collectionUpdates pathSegment: ", pathSegment)
  const q = query(collection(firestore, pathSegment), ...queryConstraints)
  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      const objects: T[] = []
      querySnapshot.forEach((doc) => {
        const data = doc.data()
        objects.push(constructorFn({ id: doc.id, ...data }))
      })
      console.log("collectionUpdates: ", pathSegment, queryConstraints.length, objects)
      callback(objects)
    },
    (error) => {
      console.error("Error onSnapshot: ", error)
      throw error
    }
  )
  return unsubscribe
}

// company
export async function onCompanyList(setter: (objects: Array<Company>) => void) {
  return collectionUpdates<Company>("Company", [], (data: any) => new Company(data), setter)
}

export async function onCompanyListWhereUserIdIsAuthorized(userId: string, setter: (objects: Array<Company>) => void) {
  return collectionUpdates<Company>(
    "Company",
    [where(`${DOC_AUTH_PREFIX}.${userId}`, "!=", null)],
    //TODO: [where(`${DOC_AUTH_PREFIX}.${userId}`, "!=", null), where("status", "==", CompanyStatus.ACTIVE)],
    (data: any) => new Company(data),
    setter
  )
}

export async function onCompanyFirstWhereUserIdIsAuthorized(userId: string, setter: (object: Company) => void) {
  const getFirst: (objects: Array<Company>) => void = (objects) => {
    setter(objects.filter((value, index) => index == 0)[0])
  }
  return onCompanyListWhereUserIdIsAuthorized(userId, getFirst)
}

// issue
export async function onIssueListWhereUserIdIsAuthorized(userId: string, setter: (objects: Array<Issue>) => void) {
  return collectionUpdates<Issue>(
    "Issue",
    [where(`${DOC_AUTH_PREFIX}.${userId}`, "!=", null)],
    (data: any) => new Issue(data),
    setter
  )
}

export async function onIssueListWhereUserIdIsOwner(userId: string, setter: (object: Array<Issue>) => void) {
  return collectionUpdates<Issue>(
    "Issue",
    [where(`${DOC_AUTH_PREFIX}.${userId}`, "array-contains-any", DOC_PERMISSION_CAN_READ)],
    (data: any) => new Issue(data),
    setter
  )
}

export async function onIssueListWhereUserIdIsSuperAdmin(
  permission: () => false | boolean | undefined,
  setter: (objects: Array<Issue>) => void
) {
  if (permission())
    return collectionUpdates<Issue>("Issue", [where("id", "!=", null)], (data: any) => new Issue(data), setter)
  if (!permission()) return console.log("onIssueListWhereUserIdIsSuperAdmin: User does not have permission")
}

export const getIssuesCount = async () => {
  const coll = collection(firestore, "Issue")
  return (await getCountFromServer(coll)).data().count
}

export async function onUserInvitationListWhereCompanyId(
  companyId: string,
  setter: (object: UserInvitation[]) => void
) {
  return collectionUpdates<UserInvitation>(
    "UserInvitation",
    [where("invitedIntoCompanyId", "==", companyId)],
    (data: any) => new UserInvitation(data),
    setter
  )
}

export async function onNews(setter: (news: News[]) => void) {
  return collectionUpdates<News>("News", [], (data: any) => new News(data), setter)
}

// stripe

export async function onTransactionList(userId: string, setter: (objects: Array<Transaction>) => void) {
  return collectionUpdates<Transaction>(
    "Customer/" + userId + "/" + "payments",
    [],
    (data: Payment) => new Transaction(data),
    setter
  )
}

export async function onProductList(setter: (objects: Array<Product>) => void) {
  return collectionUpdates<Product>("Product", [where("active", "==", true)], (data: any) => new Product(data), setter)
}

export async function onProductPrices(productId: string, setter: (objects: Array<ProductPrice>) => void) {
  return collectionUpdates<ProductPrice>(
    "Product/" + productId + "/" + "prices",
    [],
    (data: any) =>
      new ProductPrice({
        id: data.id,
        interval: data?.interval,
        intervalCount: data?.interval_count,
        tiersMode: data?.tiers_mode,
        taxBehavior: data?.tax_behavior,
        currency: data?.currency,
        billingScheme: data?.billing_scheme,
        active: data?.active,
        unitAmount: data?.unit_amount,
        description: data?.description,
        tiers: data?.tiers,
        transformQuantity: data?.transform_quantity,
        trialPeriodDays: data?.trialPeriodDays,
        type: data?.type,
      }),
    setter
  )
}

export async function onCreateCheckoutSession(
  userId: string,
  priceId: string,
  successUrl: string,
  cancelUrl: string,
  onReady: (url: string) => void,
  mode?: string,
  amount?: number
) {
  const data = {
    price: priceId,
    success_url: successUrl,
    cancel_url: cancelUrl,
  }
  let mergedData = { ...data }
  if (mode) {
    mergedData = { mode: mode, ...mergedData }
  }
  if (amount) {
    mergedData = { amount: amount, ...mergedData }
  }
  addDoc(collection(firestore, "Customer/" + userId + "/checkout_sessions"), mergedData as any).then((obj) => {
    onSnapshot(doc(firestore, obj.path), (snapshot) => {
      const { error, url } = snapshot.data() as any
      if (error) {
        console.error("CreateSubscription", error)
      }
      if (url) {
        onReady(url)
      }
    })
  })
}

export async function onCreateCheckoutSessionOnMobile(
  userId: string,
  mode: string,
  amount: number,
  client: string,
  currency: string,
  onReady: (value: Checkout) => void
) {
  addDoc(collection(firestore, "Customer/" + userId + "/checkout_sessions"), {
    mode: mode,
    client: client,
    amount: amount,
    currency: currency,
  } as any).then((obj) => {
    onSnapshot(doc(firestore, obj.path), (snapshot) => {
      const { error, customer, ephemeralKeySecret, paymentIntentClientSecret } = snapshot.data() as any
      if (error) {
        console.error("CreateOneTimePaymentOnMobile", error)
      }
      if (customer && ephemeralKeySecret && paymentIntentClientSecret) {
        onReady(
          new Checkout({
            customer: customer,
            ephemeralKey: ephemeralKeySecret,
            paymentIntent: paymentIntentClientSecret,
          })
        )
      }
    })
  })
}

export async function onCustomerSubscriptions(
  userId: string | undefined,
  onlyActive: boolean,
  setter: (objects: Subscription[]) => void
) {
  console.debug("onCustomerSubscriptions")
  if (!userId) console.log("Missing user")
  return collectionUpdates<Subscription>(
    "Customer/" + userId + "/subscriptions",
    onlyActive ? [where("status", "==", "active")] : [],
    (data: any) =>
      new Subscription({
        id: data?.id,
        priceId: data?.items[0].price.id,
        product: data?.plan?.product,
        status: data?.status,
        role: data?.role,
        currentPeriodStart: data?.current_period_start,
        currentPeriodEnd: data?.current_period_end,
      }),
    setter
  )
}

// project
export async function onProjectListWhereUserIdIsOwner(userId: string, setter: (objects: Array<Project>) => void) {
  return collectionUpdates<Project>(
    "Project",
    [where(`${DOC_AUTH_PREFIX}.${userId}`, "array-contains-any", DOC_PERMISSION_CAN_READ)],
    (data: any) => new Project(data),
    setter
  )
}

export async function onDocumentListOrderByDate(projectId: string, setter: (objects: Array<Document>) => void) {
  return collectionUpdates<Document>(
    "Document",
    [where("projectId", "==", projectId), orderBy("date", "desc")],
    (data: any) => new Document(data),
    setter
  )
}

export async function onDiaryListOrderByDate(projectId: string, setter: (objects: Array<Diary>) => void) {
  return collectionUpdates<Diary>(
    "Project/" + projectId + "/" + "Diary",
    [orderBy("date", "desc")],
    (data: any) => new Diary(data),
    setter
  )
}

export async function onUpdatedProjects(setter: (objects: Array<Diary>) => void) {
  const today = new Date(new Date().setHours(0, 0, 0, 0))
  const todayString =
    today.getFullYear() +
    "-" +
    (today.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    today.getDate().toString().padStart(2, "0")
  const q = query(collectionGroup(firestore, "Diary"), where("date", ">=", todayString))
  return onSnapshot(q, (snapshot) => {
    setter(snapshot.docs.map((d) => new Diary(d.data())))
  })
}

//tasks
export default function onTaskListWhereUserIdIsOwner(userId: string, setter: (objects: Array<Task>) => void) {
  return collectionUpdates<Task>(
    "Task",
    [where(`${DOC_AUTH_PREFIX}.${userId}`, "array-contains-any", DOC_PERMISSION_CAN_WRITE)],
    (data: any) => new Task(data),
    setter
  )
}

export async function onTaskListManager(companyId: string, userId: string, setter: (objects: Array<Task>) => void) {
  return collectionUpdates<Task>("Task", [where("companyId", "==", companyId)], (data: any) => new Task(data), setter)
}

export async function onTasks(setter: (objects: Array<Task>) => void) {
  return collectionUpdates<Task>("Task", [orderBy("title", "desc")], (data: any) => new Task(data), setter)
}

// attendance
export async function onAttendanceOwnerOrderByDate(
  companyId: string,
  userId: string,
  setter: (objects: Array<Attendance>) => void
) {
  return collectionUpdates<Attendance>(
    "Attendance",
    [where("companyId", "==", companyId), where("userId", "==", userId), orderBy("start", "desc")],
    (data: any) => new Attendance(data),
    setter
  )
}

export async function onAttendanceManagerOrderByDate(
  companyId: string,
  userId: string,
  setter: (objects: Array<Attendance>) => void
) {
  return collectionUpdates<Attendance>(
    "Attendance",
    [where("companyId", "==", companyId), orderBy("start", "desc")],
    (data: any) => new Attendance(data),
    setter
  )
}

export async function onAttendanceFirstOpenOrderByDate(
  companyId: string,
  userId: string,
  setter: (object: Attendance) => void
) {
  const getFirst: (objects: Array<Attendance>) => void = (objects) => {
    setter(objects[0])
  }

  return collectionUpdates<Attendance>(
    "Attendance",
    [
      where("companyId", "==", companyId),
      where("userId", "==", userId),
      where("state", "==", ATTENDANCE_STATE.STARTED),
      orderBy("start", "desc"),
    ],
    (data: any) => new Attendance(data),
    getFirst
  )
}

// notifications

export async function onUserMessage(userId: string, setter: (object: UserMessage[]) => void) {
  return collectionUpdates<UserMessage>(
    "UserMessage",
    [where("userId", "==", userId)],
    (data: any) => new UserMessage(data),
    setter
  )
}

// messages

export async function getRecentMemosInRoom(count: number, roomId: string, setter: (objects: Array<Memo>) => void) {
  const q = query(
    collection(firestore, "Memo"),
    where("roomId", "==", roomId),
    orderBy("timestamp", "desc"),
    limit(count)
  )
  const docs = await getDocs(q)
  const recent: Memo[] = []
  docs.forEach((doc) => recent.push(new Memo(doc.data())))
  setter(recent)
}

export async function getMemoRooms(setter: (objects: Array<MemoRoom>) => void) {
  const q = query(collection(firestore, "MemoRoom"))
  return onSnapshot(q, (snapshot) => {
    setter(snapshot.docs.map((d) => new MemoRoom(d.data())))
  })
}

export async function getLatestMessages(roomId: string, setter: (objects: Array<Memo>) => void) {
  const q = query(collection(firestore, "Memo"), where("roomId", "==", roomId), orderBy("timestamp", "desc"), limit(10))
  return onSnapshot(q, (snapshot) => {
    setter(snapshot.docs.map((d) => new Memo(d.data())))
  })
}

// singletons updates for existing singleton entity
export async function singletonUpdates<T>(
  pathSegment: string,
  docId: string,
  constructorFn: (data: any) => T,
  callback: (object: T | undefined) => void
) {
  console.debug("singletonUpdates pathSegment: ", pathSegment, " with docId:", docId)
  const q = query(collection(firestore, pathSegment), where("id", "==", docId))
  const unsubscribe = onSnapshot(
    q,
    (querySnapshot) => {
      // already existing
      if (querySnapshot.size == 1) {
        const doc = querySnapshot.docs[0]
        const data = doc.data()
        const obj = constructorFn({ ...data })
        console.log("singletonUpdates: ", obj)
        callback(obj)
      }
      // already need to create new
      if (querySnapshot.size == 0) {
        console.warn("singletonUpdates NotFound object on pathSegment: " + pathSegment)
        callback(undefined)
      }
      if (querySnapshot.size > 1) throw Error("Error singletonUpdates MoreThanOneInstance pathSegment: " + pathSegment)
    },
    (error) => {
      console.error("Error singletonUpdates: ", error)
      throw error
    }
  )
  return unsubscribe
}

export async function onSettings(
  userId: string,
  setter: (value: ((prevState: Settings) => Settings) | Settings) => void
) {
  return singletonUpdates<Settings>("User", userId, (data: any) => new Settings(data["settings"]), setter)
}

export async function onCompany(companyId: string, setter: (object: Company | undefined) => void) {
  return singletonUpdates<Company>("Company", companyId, (data: any) => new Company(data), setter)
}

export async function onUser(userId: string, setter: (object: User | undefined) => void) {
  return singletonUpdates<User>("User", userId, (data: any) => new User(data), setter)
}

export async function onMyInvitationList(userEmail: string, setter: (object: UserInvitation[] | undefined) => void) {
  return collectionUpdates<UserInvitation>(
    "UserInvitation",
    [where("invitedUserEmail", "==", userEmail)],
    (data: any) => new UserInvitation(data),
    setter
  )
}
