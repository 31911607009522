import AsyncStorage from "@react-native-async-storage/async-storage"

const DISABLE_TUTORIAL = "DISABLE_TUTORIAL"

export async function isTutorialDisabled() {
  try {
    const result = await AsyncStorage.getItem(DISABLE_TUTORIAL)
    const value = result !== "true"
    console.debug("isTutorialDisabled: result: ", result)
    return value
  } catch (error) {
    console.error("Error isTutorialDisabled: ", error)
    return false
  }
}

export function setDisableTutorial(disable = true) {
  console.log("setDisableTutorial: ")
  try {
    AsyncStorage.setItem(DISABLE_TUTORIAL, disable.toString())
  } catch (error) {
    console.error("setDisableTutorial: ", error)
  }
}

// const saveFile = async (fileUri: string) => {
//   const { status } = await Permissions.askAsync(Permissions.CAMERA_ROLL);
//   if (status === "granted") {
//       const asset = await MediaLibrary.createAssetAsync(fileUri)
//       await MediaLibrary.createAlbumAsync("Download", asset, false)
//   }
// }

// export async function downloadFile(uri: string){

//   try {
//     const downloadedFile: FileSystem.FileSystemDownloadResult = await FileSystem.downloadAsync(uri, fileUri);

// if (downloadedFile.status != 200) {
//   handleError();
// }
//   } catch (error) {
//     console.error(error)
//   }
// }
