import { WrapRow } from "../container/WrapRow"
import { handleNextPress, TextInput } from "../input/TextInput"
import { NumberInput } from "../input/NumberInput"
import React, { useRef, useState } from "react"
import { WorkerEntryData } from "../../shared/entry/WorkerEntryData"
import { TimeInput } from "../input/TimeInput"
import { EntryProps } from "../../shared/entry/Entry"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function WorkerEntry(props: EntryProps<WorkerEntryData>) {
  const { i18n } = useSettingsProvider()
  const nameInputRef = useRef<TextInput>(null)
  const supplierInputRef = useRef<TextInput>(null)
  const [data, setData] = useState(props.entry || new WorkerEntryData())

  console.log("WorkerEntry", props.entry)

  const handleConfirm = (date: { hours: number; minutes: number }) => {
    const newData = new WorkerEntryData({ ...data, hours: date.hours, minutes: date.minutes })
    setData(newData)
    if (props.onSave) props.onSave(newData)
  }

  const onChange = <K extends keyof WorkerEntryData>(prop: K, value: WorkerEntryData[K]) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return
    }
    setData(new WorkerEntryData({ ...data, [prop]: value }))
  }

  const onSave = () => {
    if (props.onSave) props.onSave(data)
  }

  return (
    <>
      <WrapRow>
        <TextInput
          value={data.name || ""}
          prop="name"
          onChangeText={(value: string) => onChange("name", value)}
          onBlur={onSave}
          onSubmitEditing={() => handleNextPress(nameInputRef)}
          label={i18n.t("firstname_and_lastname")}
        />
        <TextInput
          value={data.job || ""}
          prop="job"
          onChangeText={(value: string) => onChange("job", value)}
          onBlur={onSave}
          onSubmitEditing={() => handleNextPress(supplierInputRef)}
          label={i18n.t("profession")}
        />
      </WrapRow>
      <WrapRow minima={[300, 200, 200]} basis={[2, 1, 1]}>
        <TextInput
          value={data.supplier}
          prop="supplier"
          onChangeText={(value: string) => onChange("supplier", value)}
          onBlur={onSave}
          onSubmitEditing={onSave}
          returnKeyType={"done"}
          label={i18n.t("supplier")}
        />
        <NumberInput
          value={data.workerCount || 0}
          key={data.name}
          prop="workerCount"
          label={i18n.t("number_of_persons") + ":"}
          onChange={(prop, value) => {
            if (!prop) return
            const newData = new WorkerEntryData({ ...data, [prop]: value })
            if (props.onSave) props.onSave(newData)
            setData(newData)
          }}
        />
        <TimeInput time={data} onSave={handleConfirm} label={i18n.t("duration_of_work")} />
      </WrapRow>
    </>
  )
}
