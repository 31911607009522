import React, { useEffect, useState } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { useMemoProvider } from "../../providers/MemoProvider"
import MaxWidth from "../../components/container/MaxWidth"
import { FlatList, KeyboardAvoidingView, View } from "react-native"
import TextField from "../../components/input/TextField"
import { ActionElement } from "../../components/container/ActionElement"
import Screen from "../../components/general/Screen"
import { Memo } from "../../shared/entity/Memo"
import DialogWindow from "../../components/general/DialogWindow"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { useUserProvider } from "../../providers/UserProvider"
import AppText from "../../components/general/AppText"
import { PADDING } from "../../constants/style"
import Separator from "../../components/flatlist/Separator"
import { MemoElement } from "./MemoElement"
import { MemoAction } from "./MemoAction"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.MEMO_MESSAGES>

export function MemoMessages({ navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const { createMemo, memoList, fetchMemos, currentRoom } = useMemoProvider()
  const { currentUser } = useUserProvider()
  const [text, setText] = useState("")
  const [length, setLength] = useState(20)
  const [selected, setSelected] = useState<Memo>()
  const [replying, setReplying] = useState<Memo>()
  const sortedMemoList = memoList.sort((a, b) => b.timestamp - a.timestamp)

  function messageOptions(memo: Memo) {
    console.log("selected", memo.short())
    setSelected(memo)
  }

  useEffect(() => {
    setLength(20)
    navigation.setOptions({ title: currentRoom?.name })
  }, [currentRoom])

  const actionList = []
  if (replying) {
    actionList.push({
      iconName: "reply",
      action: () => {
        setText("")
        createMemo(text, replying?.id)
        setReplying(undefined)
      },
    })
    actionList.push({
      iconName: "close",
      action: () => {
        setText("")
        setReplying(undefined)
      },
    })
  } else {
    actionList.push({
      iconName: "send",
      action: () => {
        setText("")
        createMemo(text)
      },
    })
  }

  return (
    <Screen>
      <MaxWidth height={"100%"}>
        <KeyboardAvoidingView behavior={"padding"} style={{ height: "100%", flexGrow: 0, flexShrink: 1 }}>
          <AppText type={"heading"}>{currentRoom?.name}</AppText>
          <View style={{ flexShrink: 1, flexGrow: 1 }}>
            <FlatList
              style={{ height: "100%", padding: PADDING }}
              inverted
              onEndReached={() => {
                console.log("onEndReached")
                fetchMemos(length + 5)
                setLength(length + 5)
              }}
              data={sortedMemoList}
              renderItem={({ item }) => (
                <MemoElement
                  memo={item}
                  key={item.id}
                  onPress={(m) => {
                    messageOptions(m)
                  }}
                />
              )}
            />
          </View>
          <View style={{ flexShrink: 0 }}>
            <TextField
              value={text}
              onChange={setText}
              label={replying ? i18n.t("responding_to") + ": " + replying.short() : undefined}
            >
              <ActionElement actionList={actionList} />
            </TextField>
          </View>
        </KeyboardAvoidingView>
      </MaxWidth>
      <DialogWindow
        title={i18n.t("select_action")}
        visible={!!selected}
        hideConfirmButton
        disableDismissButton
        onDismiss={() => {
          setSelected(undefined)
        }}
      >
        <View>
          <MemoAction
            iconName={"reply"}
            title={i18n.t("reply")}
            onPress={() => {
              setReplying(selected)
              setSelected(undefined)
            }}
          />
          <Separator />
          <MemoAction
            iconName={"check"}
            title={i18n.t("check")}
            onPress={async () => {
              setSelected(undefined)
              if (selected && currentUser?.id) {
                selected.react(currentUser?.id, "check")
                await FirebaseObject.update(new Memo(selected))
              }
            }}
          />
          <MemoAction
            iconName={"close"}
            title={i18n.t("close")}
            onPress={async () => {
              setSelected(undefined)
              if (selected && currentUser?.id) {
                selected.react(currentUser?.id, "close")
                await FirebaseObject.update(new Memo(selected))
              }
            }}
          />
        </View>
      </DialogWindow>
    </Screen>
  )
}
