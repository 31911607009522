import { DocObject } from "./DocObject"

export class User extends DocObject {
  getPathSegment() {
    return "User"
  }

  name?: string
  email?: string
  phoneNumber?: string
  password?: string
  imageUri?: string
  pushToken?: string

  constructor(params?: any) {
    super(params)
    this.assign(params)
  }

  assign(data: any) {
    super.assign(data)
  }

  getDisplayName() {
    return this.name || this.email
  }
}

// CurrentUser - related to auth
export class CurrentUser extends User {
  persistenceFilter: any = { persistenceFilter: true, id: true, emailVerified: true }

  constructor(params?: any) {
    super(params)
    this.assign(params)
  }

  assign(data: any) {
    Object.assign(this, data)
  }

  getPathSegment() {
    return "User"
  }

  emailVerified?: Boolean
  anonym?: Boolean
}

// CompanyUser - related to company

export class CompanyUser extends User {
  status?: UserStatus
  roles?: string[]

  constructor(params?: any) {
    super(params)
  }

  getPathSegment(): string {
    return super.getPathSegment()
  }

  getLabel(): string {
    return this.name || super.getLabel()
  }

  get statusName(): string {
    return this.status?.toString() || ""
  }

  get isActive() {
    return this.status === UserStatus.ACTIVE
  }

  toggleStatus(value: boolean) {
    if (value) {
      this.status = UserStatus.ACTIVE
    } else {
      this.status = UserStatus.DISABLED
    }
  }
}

export enum UserStatus {
  INVITED = "invited",
  ACTIVE = "active",
  DISABLED = "disabled",
  DELETED = "deleted",
}
