import React, { forwardRef, ReactNode, RefObject, useState } from "react"
import { HelperText, TextInput as PaperTextInput, TextInputProps, useTheme } from "react-native-paper"
import { StyleSheet, View } from "react-native"
import { MARGIN } from "../../constants/style"
import { Theme } from "../../../types"
import { CancelIcon, CheckIcon } from "../svg"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = TextInputProps & {
  customIconAction?: () => void
  customLeftIconAction?: () => void
  errorText?: string
  hintText?: string
  isValid?: () => boolean
  withHelperText?: boolean
  onBlur?: () => void
  prop?: string
  ref: RefObject<PaperTextInput>
  required?: boolean
  rightSideIcon?: ReactNode
  leftSideIcon?: ReactNode
  showValidation?: boolean
  grow?: boolean
  shrink?: boolean
}

export const TextInput = forwardRef<PaperTextInput, Props>((props, ref) => {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  //DEFAULT VALUES
  const {
    value,
    required = false,
    autoComplete = "off",
    hintText,
    withHelperText = true,
    errorText,
    keyboardType = "default",
    error = false,
    rightSideIcon = undefined,
    leftSideIcon = undefined,
    customIconAction,
    customLeftIconAction,
    autoCapitalize = "none",
    showValidation,
    secureTextEntry = false,
    isValid = () => true,
    onBlur = () => {},
    ...rest
  } = props

  const [isFocused, setIsFocused] = useState(false)
  const styles = getStylesTextInput(error || (required && !value), theme, props.grow, props.shrink)

  function handleOnBlur() {
    setIsFocused(false)
    onBlur()
  }

  const [passwordVisibility, setPasswordVisibility] = useState(true)
  const [rightIcon, setRightIcon] = useState("eye")

  function useTogglePasswordVisibility() {
    if (rightIcon === "eye") {
      setRightIcon("eye-off")
      setPasswordVisibility(!passwordVisibility)
    } else if (rightIcon === "eye-off") {
      setRightIcon("eye")
      setPasswordVisibility(!passwordVisibility)
    }
  }

  return (
    <View style={styles.container}>
      <PaperTextInput
        {...rest}
        placeholderTextColor={theme.colors.placeholder}
        returnKeyType="next"
        onBlur={handleOnBlur}
        autoComplete={autoComplete}
        keyboardType={keyboardType}
        autoCapitalize={autoCapitalize}
        label={required ? `${props.label}*` : props.label}
        error={false}
        mode="outlined"
        secureTextEntry={secureTextEntry && passwordVisibility}
        outlineStyle={theme.inputOutline}
        blurOnSubmit={false}
        ref={ref}
        clearButtonMode={"never"}
        value={value}
        style={[styles.input, props.style]}
        contentStyle={{ color: theme.colors.text }}
        left={
          leftSideIcon && (
            <PaperTextInput.Icon focusable={false} onPress={customLeftIconAction} icon={() => leftSideIcon} />
          )
        }
        right={
          secureTextEntry ? (
            <PaperTextInput.Icon focusable={false} onPress={useTogglePasswordVisibility} icon={rightIcon} />
          ) : rightSideIcon && customIconAction ? (
            <PaperTextInput.Icon focusable={false} onPress={customIconAction} icon={() => rightSideIcon} />
          ) : !secureTextEntry && isValid() && showValidation ? (
            <PaperTextInput.Icon pointerEvents={"none"} focusable={false} icon={() => <CheckIcon />} />
          ) : !secureTextEntry && !isValid() && showValidation ? (
            <PaperTextInput.Icon pointerEvents={"none"} focusable={false} icon={() => <CancelIcon />} />
          ) : (
            ""
          )
        }
      />
      {withHelperText && (errorText || hintText || required) ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            flexShrink: 0,
            position: "absolute",
            top: 6,
            right: MARGIN,
            height: 2,
            alignItems: "center",
            backgroundColor: theme.input.backgroundColor,
            overflow: "visible",
            paddingHorizontal: 3,
          }}
          pointerEvents={"none"}
        >
          <HelperText
            padding={"none"}
            style={{
              height: 22,
              bottom: 4,
              position: "relative",
              color: error ? theme.colors.danger : theme.colors.text,
              fontSize: 12,
            }}
            type={error ? "error" : "info"}
          >
            {!value && required ? "* " + i18n.t("required") : error ? errorText : hintText}
          </HelperText>
        </View>
      ) : (
        <></>
      )}
    </View>
  )
})

export const handleNextPress = (inputRef: React.RefObject<TextInput>) => {
  if (inputRef.current) {
    inputRef.current.focus()
  }
}

export function getStylesTextInput(error: boolean, theme: Theme, grow?: boolean, shrink?: boolean) {
  return StyleSheet.create({
    input: {
      ...theme.input,
      height: 70,
      marginBottom: MARGIN,
      overflow: "hidden",
      width: "100%",
    },
    container: {
      justifyContent: "center",
      alignItems: "center",
      marginHorizontal: MARGIN,
      flexGrow: grow ? 1 : 0,
      flexShrink: shrink ? 1 : 0,
    },
    validatorWrapper: { position: "absolute", bottom: 15, right: 10, alignSelf: "flex-end" },
    validatorText: {
      color: error ? "red" /*: isFocused ? theme.colors.primary */ : theme.colors.placeholder,
      fontSize: 12,
    }, //TODO put proper colors
  })
}
