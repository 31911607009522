import { Divider, useTheme } from "react-native-paper"
import React from "react"
import { Theme } from "../../../types"
import { StyleProp, ViewStyle } from "react-native"

type Props = {
  style?: StyleProp<ViewStyle>
}

export default function VerticalDivider({ style }: Props) {
  const theme: Theme = useTheme()

  return (
    <Divider
      style={[{ width: 1, height: "100%", backgroundColor: theme.colors.borderPrimary, flexShrink: 0 }, style]}
    />
  )
}
