import WorldProvider from "./WorldProvider"
import Navigation from "../navigation"
import { Platform } from "react-native"
import FlashMessage from "react-native-flash-message"
import { StatusBar } from "expo-status-bar"
import { Provider as PaperProvider } from "react-native-paper"
import React from "react"
import { Theme } from "../../types"
import { useColorSchemeProvider } from "../hooks/ColorScheme"

export default function ApplicationProvider() {
  const colorSchemeProvider: Theme = useColorSchemeProvider()
  return (
    <PaperProvider theme={colorSchemeProvider}>
      <WorldProvider>
        <Navigation theme={colorSchemeProvider} />
        {Platform.OS === "web" ? (
          <FlashMessage style={{ position: "absolute", left: 0, bottom: 0 }} position="center" />
        ) : (
          <FlashMessage position="bottom" />
        )}
      </WorldProvider>
      <StatusBar hidden={true} />
    </PaperProvider>
  )
}
