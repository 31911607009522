export class Location {
  latitude?: number
  longitude?: number

  constructor(params?: Partial<Location>) {
    Object.assign(this, params)
  }

  toString() {
    return "Zeměpisná šířka:" + this.latitude + " : " + "Zeměpisná délka:" + this.longitude
  }
}
