import React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import { HelperText, useTheme } from "react-native-paper"

import { Theme } from "../../../types"
import { MARGIN } from "../../constants/style"
import AppText from "../general/AppText"

type Props = {
  children: React.ReactNode
  errorText?: string
  hintText?: string
  label?: string
  noPadding?: boolean
  grow?: boolean
  overflow?: boolean
  style?: StyleProp<ViewStyle>
}

export default function InputBase({
  children,
  label = "",
  style = {},
  hintText,
  errorText = "",
  noPadding = false,
  grow = false,
  overflow = false,
}: Props) {
  const theme: Theme = useTheme()
  return (
    <View
      style={[{ position: "relative", marginHorizontal: MARGIN, flexGrow: grow ? 1 : 0, flexShrink: grow ? 1 : 0 }]}
    >
      <View
        style={[
          theme.input,
          {
            borderColor: theme.colors.primary,
            borderWidth: 2,
            borderRadius: 10,
            paddingVertical: noPadding ? 0 : 2,
            marginTop: 6,
            marginBottom: 10,
            position: "relative",
            overflow: overflow ? "visible" : "hidden",
          },
          style,
        ]}
      >
        {children}
      </View>
      {errorText || hintText ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-end",
            flexShrink: 0,
            position: "absolute",
            top: 6,
            right: MARGIN,
            height: 2,
            alignItems: "center",
            backgroundColor: theme.colors.primary,
            overflow: "visible",
            paddingHorizontal: 3,
          }}
          pointerEvents={"none"}
        >
          <HelperText
            padding={"none"}
            style={{
              height: 22,
              bottom: 4,
              position: "relative",
              fontSize: 12,
            }}
            type={errorText ? "error" : "info"}
          >
            {errorText ? errorText : hintText}
          </HelperText>
        </View>
      ) : undefined}
      {label.length > 0 && (
        <View
          style={{
            position: "absolute",
            top: 6,
            left: 7,
            height: 2,
            flexDirection: "row",
            alignItems: "center",
            overflow: "visible",
            paddingVertical: 0,
            backgroundColor: theme.input.backgroundColor,
            paddingHorizontal: 5,
          }}
          pointerEvents={"none"}
        >
          <AppText
            noPadding
            maxLines={1}
            style={{
              overflow: "visible",
              fontSize: 12,
              marginTop: 6,
            }}
          >
            {label}
          </AppText>
        </View>
      )}
    </View>
  )
}
