import React, { Ref } from "react"
import { Chip, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import AppText from "../general/AppText"
import { StyleProp, View, ViewStyle } from "react-native"
import { IconSource } from "react-native-paper/lib/typescript/src/components/Icon"
import { MARGIN, RADIUS } from "../../constants/style"

type Props = {
  ref?: Ref<View>
  label: string
  icon?: IconSource
  onPress?: (label: string) => void
  style?: StyleProp<ViewStyle>
  isSelected?: boolean
}

//https://pictogrammers.com/library/mdi/
export default function ChipSelector({
  onPress = () => console.log("Chip"),
  ref,
  icon,
  style,
  label,
  isSelected,
}: Props) {
  const theme: Theme = useTheme()

  return (
    <View style={{ flexDirection: "row", marginHorizontal: MARGIN / 2, marginVertical: 1, alignItems: "center" }}>
      <Chip
        ref={ref}
        icon={icon}
        style={[
          {
            backgroundColor: isSelected ? theme.colors.darkBlue : theme.colors.lightBlue,
            borderWidth: 2,
            borderRadius: RADIUS,
          },
          style,
        ]}
        onPress={() => onPress(label)}
        selected={isSelected}
      >
        <AppText>{label}</AppText>
      </Chip>
    </View>
  )
}
