import { CustomEntry } from "../entry/Entry"

export class Participant extends CustomEntry {
  userId?: string
  type?: PARTICIPANT_TYPE

  constructor(params?: any) {
    super(params)
    Object.assign(this, params)
  }

  get title() {
    return this.type?.toString()
  }
}

export enum PARTICIPANT_TYPE {
  CONTRACTOR = "Dodavatel",
  CUSTOMER = "Zákazník",
  WORKER = "Dělník",
  CONTRACTOR_CONSTRUCTION = "Konstrukční dodavatel",
  CONTRACTOR_TECHNOLOGICAL = "Technologický dodavatel",
  CONTRACTOR_GENERAL = "Generální dodavatel",
  SAFETY_COORDINATOR = "Koordinátor bezpečnosti",
  INVESTOR_TECHNICAL_SUPERVISION = "Technický dozor investora",
  INVESTOR_REPRESENTATIVE = "Zástupce investora",
  INVESTOR_PROJECT_MANAGER = "Projektový manažer pro investory",
  INVESTOR_SAFETY_COORDINATOR = "Koordinátor bezpečnosti investorů",
  INVESTOR_AUTHOR_SUPERVISION = "Autorský dozor investora",
  INVESTOR_RESPONSIBLE_DESIGNER = "Investor odpovědný projektant",
  INVESTOR_GENERAL_DESIGNER = "Investor Generální projektant",
  INVESTOR_TECHNICAL_SUPERVISION_ESI = "Technický dozor investora ESI",
  INVESTOR_TECHNICAL_SUPERVISION_TZB = "Investor technický dozor TZB",
}
