import React, { StyleSheet, View } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { CompanyUser, User } from "../../shared/entity/User"
import { PersonIcon } from "../svg"
import { useUserProvider } from "../../providers/UserProvider"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { ExpandableCard } from "../container/ExpandableCard"
import Cell from "../input/Cell"
import { WrapRow } from "../container/WrapRow"
import ChipPicker from "../input/ChipPicker"
import { OPERATION, ROLES } from "../../shared/entity/Role"
import { useProjectProvider } from "../../providers/ProjectProvider"
import AppText from "../general/AppText"
import { PARTICIPANT_TYPE } from "../../shared/entity/Participant"
import { translateEnums } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function CompanyUserList() {
  const { currentUser, createUser, updateUser, isOperationAuthorized } = useUserProvider()
  const { projects } = useProjectProvider()
  const { companyUsers, company, updateUserRoles } = useCompanyProvider()
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const styles = getStyles(theme)

  async function updateCompanyUser(companyUser: CompanyUser) {
    console.log("updateCompanyUser:", companyUser)
    await updateUser(new User({ ...companyUser }))
    if (companyUser.roles && companyUser.id) {
      console.log("updateUserRoles roles:", companyUser.roles)
      await updateUserRoles(companyUser.id, companyUser.roles)
    }
  }

  return (
    <View style={{ flexDirection: "column" }}>
      <ExpandableCard icon={<PersonIcon />} name={i18n.t("users")} noPadding offset>
        {companyUsers.map((user) => (
          <ExpandableCard key={user.id} noMargin noRadius name={user.name}>
            <WrapRow>
              <Cell label={i18n.t("name")} value={user.name ?? ""} />
              <Cell label={i18n.t("email")} value={user.email ?? ""} />
              <Cell label={i18n.t("phone")} value={user.phoneNumber ?? ""} />
            </WrapRow>
            {projects
              .filter((project) => project.participants[user.id ?? ""] != undefined)
              .map((p) => (
                <AppText> {p.title + ": " + PARTICIPANT_TYPE[p.participants[user.id ?? ""]]} </AppText>
              ))}
            <ChipPicker
              label={i18n.t("role")}
              data={translateEnums(ROLES, i18n)}
              values={user.roles}
              editable={isOperationAuthorized(OPERATION.COMPANY_MANAGE)}
              onChange={(prop, values) => {
                const newUserData = new CompanyUser({ ...user })
                newUserData.roles = values
                updateCompanyUser(newUserData)
              }}
            />
          </ExpandableCard>
        ))}
      </ExpandableCard>
    </View>
  )
}

function getStyles(theme: Theme) {
  return StyleSheet.create({
    dataTableHeader: { backgroundColor: theme.colors.primary, alignItems: "center" },
    dataTableTitle: {
      color: theme.colors.white,
      flex: 1,
      justifyContent: "center",
    },
  })
}
