import { FieldChangeHandler, Item, ItemProps } from "./Item"
import { View } from "react-native"
import React from "react"
import { Transaction } from "../../shared/entity/payment/Transaction"
import { TextInput } from "../input/TextInput"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function ItemTransactionReadonlyUser(props: ItemProps<Transaction>): JSX.Element {
  return Item<Transaction>(props, ItemTransactionReadonlyUserRenderer)
}

function ItemTransactionReadonlyUserRenderer(item: Transaction, fieldChangeHandler: FieldChangeHandler) {
  const cena = item.payment.amount / 100 + " " + item.payment.currency.toUpperCase()
  const { i18n } = useSettingsProvider()

  return (
    <View style={{ flexDirection: "column" }}>
      <>
        <TextInput disabled={true} label={i18n.t("status")} value={i18n.t(item.getStatus())} />
        <TextInput disabled={true} label={i18n.t("transaction_id")} value={item.payment.id} />
        <TextInput disabled={true} label={i18n.t("price")} value={cena} />
        {/*{item.payment.description && <AppText>{{i18n.t("additional_information")}+": " + item.payment.description}</AppText>}*/}
      </>
    </View>
  )
}
