import { FontAwesome, MaterialCommunityIcons } from "@expo/vector-icons"
import { NavigationProp } from "@react-navigation/native"
import React, { FC } from "react"
import { SectionList, StyleSheet, TouchableOpacity } from "react-native"
import { useTheme } from "react-native-paper"
import AppText from "../../components/general/AppText"
import Screen from "../../components/general/Screen"
import { RootStackParamList } from "../../navigation/types"
import { Theme } from "../../../types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type ItemType = {
  title: string
  navigateTo: string
  Icon: FC
}

const Item = ({
  item,
  nav,
  styles,
}: {
  item: ItemType
  nav: NavigationProp<RootStackParamList, ROUTE.GENERAL_SETTINGS>
  styles: StyleType
}) => (
  <TouchableOpacity style={styles.card} onPress={() => nav.navigate(item.navigateTo)}>
    <>
      {item.Icon}
      <AppText style={{ paddingBottom: 0, marginLeft: 5 }}>{item.title}</AppText>
    </>
  </TouchableOpacity>
)

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_SETTINGS>

export function SettingsScreen({ navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const styles: StyleType = getStyles(theme)
  const { isOperationAuthorized } = useUserProvider()
  const DATA = [
    {
      title: i18n.t("my_account"),
      data: [
        {
          title: i18n.t("account_detail"),
          navigateTo: ROUTE.USER_PROFILE,
          Icon: <MaterialCommunityIcons name="account-settings" size={24} color={theme.body?.textColor} />,
        },
        {
          title: i18n.t("password_change"),
          navigateTo: ROUTE.USER_PASSWORD_CHANGE,
          Icon: <MaterialCommunityIcons name="form-textbox-password" size={24} color={theme.body?.textColor} />,
        },
        {
          title: i18n.t("delete_account"),
          navigateTo: ROUTE.USER_DELETE,
          Icon: <MaterialCommunityIcons name="delete-alert" size={24} color={theme.body?.textColor} />,
        },
      ],
    },
    {
      title: i18n.t("my_company"),
      data: [
        {
          title: i18n.t("company"),
          navigateTo: ROUTE.COMPANY,
          Icon: <MaterialCommunityIcons name="home-edit" size={24} color={theme.body?.textColor} />,
        },
      ],
    },
    //TODO: remake custom modules
    //...(isOperationAuthorized(OPERATION.COMPANY_MANAGE)
    ...(isOperationAuthorized(OPERATION.SUPERADMIN)
      ? [
          {
            title: i18n.t("administration"),
            data: [
              {
                title: i18n.t("modules"),
                navigateTo: ROUTE.ADMIN_MODULES,
                Icon: <MaterialCommunityIcons name="view-module" size={24} color={theme.body?.textColor} />,
              },
            ],
          },
        ]
      : []),
    ...(isOperationAuthorized(OPERATION.SUPERADMIN)
      ? [
          {
            title: i18n.t("system_admin"),
            data: [
              {
                title: i18n.t("release"),
                navigateTo: ROUTE.SUPERADMIN_RELEASE,
                Icon: <MaterialCommunityIcons name="vanish" size={24} color={theme.body?.textColor} />,
              },
            ],
          },
        ]
      : []),
    {
      title: i18n.t("application"),
      data: [
        {
          title: i18n.t("application_settings"),
          navigateTo: ROUTE.APPLICATION_SETTINGS,
          Icon: <FontAwesome name="gear" size={24} color={theme.body?.textColor} />,
        },
      ],
    },
  ]

  return (
    <Screen>
      <SectionList
        sections={DATA}
        keyExtractor={(item, index) => item.title + index}
        renderItem={({ item }: { item: ItemType }) => <Item item={item} nav={navigation} styles={styles} />}
        renderSectionHeader={({ section: { title } }) => <AppText style={styles.header}>{title}</AppText>}
      />
    </Screen>
  )
}

type StyleType = {
  header: {}
  card: {}
}

function getStyles(theme: Theme): StyleType {
  return StyleSheet.create({
    header: {
      padding: theme.padding,
      fontSize: theme.fontSize?.medium,
      marginTop: theme.margin,
    },
    card: {
      ...theme.card,
      height: 80,
      alignItems: "center",
      width: "100%",
      paddingLeft: 15,
      flexDirection: "row",
      color: theme.body?.textColor,
      ...theme.borderTop,
    },
  })
}
