// @ts-nocheck
import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" fill="none" height={24} viewBox="0 96 960 960" width={24} {...props}>
    <Path
      fill="#f39719"
      d="M440 1014v-80q-125-14-214.5-103.5T122 616H42v-80h80q14-125 103.5-214.5T440 218v-80h80v80q125 14 214.5 103.5T838 536h80v80h-80q-14 125-103.5 214.5T520 934v80h-80Zm40-158q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-120q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47Zm0-80q33 0 56.5-23.5T560 576q0-33-23.5-56.5T480 496q-33 0-56.5 23.5T400 576q0 33 23.5 56.5T480 656Zm0-80Z"
    />
  </Svg>
)
export default SvgComponent
