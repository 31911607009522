import React from "react"
import Screen from "../../components/general/Screen"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import MaxWidth from "../../components/container/MaxWidth"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import { ModuleComponent } from "../../components/company/ModuleComponent"
import { CUSTOM_MODULES } from "../../shared/common/CustomInputDefinition"
import { ScrollView } from "react-native"
import { useSettingsProvider } from "../../providers/SettingsProvider"

//type Props = NativeStackScreenProps<RootStackParamList, ROUTE.ADMIN_MODULES>

export function ModulesScreen() {
  const { company } = useCompanyProvider()
  const { i18n } = useSettingsProvider()
  return (
    <Screen>
      <ScrollView>
        <MaxWidth>
          <ExpandableCard
            name={i18n.t("company_module_settings") + " " + company?.name}
            closable={false}
            startOpen
            noPadding
          >
            <ModuleComponent name={i18n.t(CUSTOM_MODULES.ATTENDANCE)} />
            <ModuleComponent name={i18n.t(CUSTOM_MODULES.MECHANIZATION)} />
            <ModuleComponent name={i18n.t(CUSTOM_MODULES.MATERIAL)} />
            <ModuleComponent name={i18n.t(CUSTOM_MODULES.WORK)} />
          </ExpandableCard>
        </MaxWidth>
      </ScrollView>
    </Screen>
  )
}
