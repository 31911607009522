import { ImageStyle, PermissionsAndroid, Platform, StyleProp, TouchableOpacity, View, ViewStyle } from "react-native"
import React, { useState } from "react"
import * as FileSystem from "expo-file-system"
import { useTheme } from "react-native-paper"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { Theme } from "../../../types"
import DialogWindow from "./DialogWindow"
import AppText from "./AppText"
import axios from "axios"
import { YYYYMMDD } from "../../shared/utils/date"
import { showMessage } from "react-native-flash-message"
import FullWidthImage from "./FullWidthImage"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type ImageProps = {
  style?: StyleProp<ViewStyle>
  imageStyle?: StyleProp<ImageStyle>
  uri: string | undefined
  prop: string
  disabled?: boolean
  label?: string
}

export default function LabeledImage({ style, imageStyle, uri, prop, disabled = false, label = "" }: ImageProps) {
  const theme: Theme = useTheme()
  const [visible, setVisible] = useState(false)
  const filename = uri?.split("=media&token=")[1] || "picture"
  const { i18n } = useSettingsProvider()
  const image = { uri: uri }
  const folderName = "Download"
  const checkPermission = async () => {
    if (uri) {
      switch (Platform.OS) {
        case "ios":
          await downloadImage(uri, folderName, filename)
          break
        case "web":
          try {
            const response = await axios.get(uri, { responseType: "blob" })
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement("a")
            link.href = url
            link.setAttribute("download", `${filename || image}-${YYYYMMDD(new Date())}.jpg`)
            document.body.appendChild(link)
            showMessage({ message: i18n.t("file_download_in_progress") + "...", type: "success" })
            link.click()
          } catch (error) {
            console.log(error)
            showMessage({ message: i18n.t("something_went_wrong"), type: "danger" })
          }
          break
        default:
          try {
            const granted = await PermissionsAndroid.request(PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE, {
              buttonNegative: i18n.t("disable"),
              buttonNeutral: i18n.t("later"),
              buttonPositive: i18n.t("allow"),
              title: i18n.t("required_storage_permission"),
              message: i18n.t("application_need_storage_permission"),
            })
            if (granted === PermissionsAndroid.RESULTS.GRANTED) {
              console.log("Storage Permission Granted.")
              await downloadImage(uri, folderName, filename)
            }
          } catch (err) {
            console.warn(err)
          }
      }
    }
  }

  const downloadImage = async (url: string, folderName: string, fileName: string) => {
    try {
      // Create the folder if it doesn't exist
      const folderInfo = await FileSystem.getInfoAsync(`${FileSystem.documentDirectory}${folderName}`)
      if (!folderInfo.exists) {
        await FileSystem.makeDirectoryAsync(`${FileSystem.documentDirectory}${folderName}`)
      }

      // Download the image
      const downloadResumable = FileSystem.createDownloadResumable(
        url,
        `${FileSystem.documentDirectory}${folderName}/${fileName}`
      )
      const { uri } = await downloadResumable.downloadAsync()

      console.log(`Image downloaded to ${uri}`)
      showMessage({ message: i18n.t("file_download_in_progress") + "...", type: "success" })
    } catch (error) {
      console.error("Error downloading image", error)
      showMessage({ message: i18n.t("something_went_wrong"), type: "danger" })
    }
  }

  const showImage = () => {
    setVisible(true)
  }

  const hideImage = () => {
    setVisible(false)
  }

  return (
    <TouchableOpacity
      onPress={async () => {
        showImage()
      }}
      style={[
        {
          flexGrow: 1,
          borderRadius: RADIUS,
          maxHeight: 500,
          borderWidth: 2,
          borderColor: theme.colors.primary,
          margin: MARGIN,
          padding: PADDING,
        },
        style,
      ]}
    >
      {uri && <FullWidthImage uri={uri} onLoadEnd={() => {}} />}
      {uri && visible && (
        <DialogWindow
          visible={visible}
          onDismiss={hideImage}
          title={label}
          confirmButtonTitle={"Stáhnout"}
          onPress={checkPermission}
          disableDismissButton
        >
          <View style={{ justifyContent: "center" }}>
            <View style={{ width: "100%", height: "100%" }}>
              <AppText type={"regular"}>{"Název: " + filename}</AppText>
              <FullWidthImage uri={uri} onLoadEnd={() => {}} />
              {/*<ReactImage
                alt={filename}
                resizeMode="contain"
                style={[
                  {
                    margin: MARGIN,
                    alignSelf: "center",
                    flex: 1,
                  },
                  imageStyle,
                ]}
                source={image}
              />*/}
            </View>
          </View>
        </DialogWindow>
      )}
    </TouchableOpacity>
  )
}
