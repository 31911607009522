import { WrapRow } from "../container/WrapRow"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { Task, TASK_STATUS } from "../../shared/entity/Task"
import { PADDING } from "../../constants/style"
import { Platform, View } from "react-native"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import StateInput from "../input/StateInput"
import { useTaskProvider } from "../../providers/TaskProvider"
import { TextInput } from "../input/TextInput"
import AppText from "../general/AppText"

interface Props {
  task: Task
  disabled?: boolean
}

export default function TaskListItem({ task, disabled = false }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { updateTask } = useTaskProvider()

  return (
    <WrapRow basis={[2, 2, 2, 1]} minima={[100, 100, 100, 50]}>
      <View style={{ flexGrow: 1, justifyContent: "center", padding: PADDING }}>
        {Platform.OS === "web" ? (
          <TextInput
            disabled={disabled}
            label={i18n.t("title")}
            value={task?.title}
            onChangeText={(value) => updateTask(new Task({ ...task, title: value }))}
          />
        ) : (
          <AppText>
            {i18n.t("title")}: {task.title}
          </AppText>
        )}
      </View>
      <View
        style={{ backgroundColor: theme.colors.background, flexGrow: 1, justifyContent: "center", padding: PADDING }}
      >
        {Platform.OS === "web" ? (
          <StateInput
            disabled={disabled}
            value={task.status || TASK_STATUS.OPEN}
            data={TASK_STATUS}
            label={i18n.t("status")}
            onChange={(value) => updateTask(new Task({ ...task, status: value }))}
            translation={i18n}
          />
        ) : (
          <AppText>
            {i18n.t("status")}: {task.status && i18n.t(task.status)}
          </AppText>
        )}
      </View>
    </WrapRow>
  )
}
