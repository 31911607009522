import React, { createContext, useContext, useEffect, useState } from "react"
import { Company, CompanyStatus } from "../shared/entity/Company"
import { CompanyUser, UserStatus } from "../shared/entity/User"
import { STATUS, UserInvitation } from "../shared/entity/UserInvitation"
import { FirebaseObject } from "../persistence/FirebaseObject"
import { collection, getDocs, query, where } from "firebase/firestore"
import { firestore } from "../persistence/FirebaseConfig"
import { fetchCompanyDetailFromAres } from "../api/ares"
import {
  onCompanyListWhereUserIdIsAuthorized,
  onUserInvitationListWhereCompanyId,
} from "../persistence/FirebaseCollection"
import { UserContext } from "./UserProvider"
import { sendUserInvitation } from "../api/user"
import { DEFAULT_SYSTEM_ROLES, SystemRole } from "../shared/entity/Role"
import { ModuleDefinition } from "../shared/common/CustomInputDefinition"

const initialVal: {
  company: Company | undefined
  setCompany: (company: Company) => void
  companyList: Company[] | undefined
  setCompanyList: (company: Company[]) => void
  companyUsers: CompanyUser[]
  setCompanyUsers: (users: CompanyUser[]) => void
  companyInvitationList: UserInvitation[]
  setCompanyInvitationList: (userInvitations: UserInvitation[]) => void
} = {
  company: undefined,
  setCompany: (company: Company) => {},
  companyList: [],
  setCompanyList: (company: Company[]) => {},
  companyUsers: [],
  setCompanyUsers: (companyUsers: CompanyUser[]) => {},
  companyInvitationList: [],
  setCompanyInvitationList: (userInvitations: UserInvitation[]) => {},
}

export const CompanyContext = createContext(initialVal)

export default function CompanyProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const [company, setCompany] = useState<Company>()
  const [companyList, setCompanyList] = useState<Company[]>()
  const [companyUsers, setCompanyUsers] = useState<CompanyUser[]>([])
  const [companyInvitationList, setCompanyInvitationList] = useState<UserInvitation[]>([])

  useEffect(() => {
    console.log("CompanyProvider useEffect onCompany based on currentUser", currentUser)
    if (currentUser && currentUser.id) {
      onCompanyListWhereUserIdIsAuthorized(currentUser.id, setCompanyList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  useEffect(() => {
    console.log("CompanyProvider useEffect onCompany based on companyList", companyList)
    if (companyList && companyList.length > 0 && company == undefined) {
      console.log("setting default company", companyList)
      setCompany(companyList[0])
    }
  }, [companyList])

  function enrichCompanyInvitationList(userInvitationList: UserInvitation[]) {
    userInvitationList.map((userInvitation) => {
      const status = companyUsers.some((companyUser) => userInvitation.invitedUserEmail === companyUser.email)
        ? UserStatus.ACTIVE
        : UserStatus.INVITED
      return new UserInvitation({ ...userInvitation, status })
    })
    console.debug("setCompanyInvitationList", userInvitationList)
    setCompanyInvitationList(userInvitationList)
  }

  useEffect(() => {
    console.log("useCompanyProvider useEffect onUserInvitationListWhereCompanyId based on company", company)
    if (company && company.id) {
      console.log("useCompanyProvider useEffect onUserInvitationListWhereCompanyId")
      onUserInvitationListWhereCompanyId(company.id, enrichCompanyInvitationList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [company])

  return (
    <CompanyContext.Provider
      value={{
        company,
        setCompany,
        companyList,
        setCompanyList,
        companyUsers,
        setCompanyUsers,
        companyInvitationList,
        setCompanyInvitationList,
      }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

export const useCompanyProvider = () => {
  const { currentUser, sendMessage } = useContext(UserContext)
  const { companyList, company, setCompany, companyUsers, setCompanyUsers, companyInvitationList } =
    useContext(CompanyContext)

  useEffect(() => {
    console.log("useCompanyProvider useEffect onCompanyUsers based on company")
    if (company && company?.getAuthorities()) {
      console.log("useCompanyProvider useEffect ", company.getAuthorities())
      Promise.all(
        company.getAuthorities().map(async (userId) => {
          const user = new CompanyUser({ id: userId })
          await FirebaseObject.read(user)
          user.roles = company.getPermissions(userId)
          console.log("getAuthorities", user)
          return user
        })
      ).then((users) => {
        console.log("setCompanyUsers:", users)
        setCompanyUsers(users)
      })
    }
  }, [company, companyList])

  function selectCompany(companyId: string) {
    if (companyList) {
      const foundCompany = companyList.find((company) => company.id === companyId)
      if (foundCompany) setCompany(foundCompany)
    }
  }

  function readCompany(companyId: string): Company {
    if (companyList) {
      const foundCompany = companyList.find((company) => company.id === companyId)
      if (foundCompany) return foundCompany
    }
    throw Error("readCompany: company was not found with companyId:" + companyId)
  }

  async function createCompany() {
    if (!currentUser?.id) throw Error("createCompany currentUser?.id have to be defined")
    const newCompany = new Company()
    newCompany.systemRoles = DEFAULT_SYSTEM_ROLES
    newCompany.authorizeOwner(currentUser?.id)
    newCompany.authorize(currentUser.id, "COMPANY_MANAGER")
    await FirebaseObject.create(newCompany)
    setCompany(newCompany)
    if (!newCompany.id) throw Error("useCompanyProvider createCompany cannot get companyId")
    return newCompany
  }

  async function updateCompany(mergeCompany: Company): Promise<boolean> {
    try {
      console.log("updateCompany", company)
      await FirebaseObject.update(new Company({ ...company, ...mergeCompany }))
      return true
    } catch (error) {
      console.error("Error updateCompany", error)
      throw error
    }
  }

  async function updateDefinitions(definition: ModuleDefinition): Promise<boolean> {
    try {
      console.log("updateDefinitions", company)
      if (company)
        await FirebaseObject.merge(company, "companyModules", {
          ...company.companyModules,
          [definition.name]: definition,
        })
      return true
    } catch (error) {
      console.error("Error updateCompany", error)
      throw error
    }
  }

  async function deleteCompany(company: Company): Promise<void> {
    const newCompany = company
    newCompany.status = CompanyStatus.DISABLED
    await FirebaseObject.merge<Company>(company, "status", newCompany)
  }

  async function hardDeleteCompany(company: Company): Promise<void> {
    await FirebaseObject.delete(company)
  }

  async function existsCompany(ico: string): Promise<boolean> {
    const company = await findCompany(ico)
    return !!company
  }

  async function findCompany(ico: string): Promise<Company | null> {
    const q = query(collection(firestore, "Company"), where("ico", "==", ico))
    const docs = await getDocs(q)
    if (docs.size > 0) {
      docs.forEach((doc) => {
        return new Company({ ...doc.data() })
      })
    }
    return null
  }

  async function fetchCompanyData(ico: string) {
    try {
      if (ico) {
        const companyDetailFromAres = await fetchCompanyDetailFromAres(ico)
        console.debug("fetchCompanyDetailFromAres data: ", companyDetailFromAres)
        return companyDetailFromAres
      }
    } catch (error) {
      console.error("Error fetchCompanyData", error)
      throw error
    }
  }

  const createInvitation = async (invitation?: UserInvitation): Promise<UserInvitation> => {
    if (!company?.id) throw Error("createInvitation company?.id have to be defined")
    if (!currentUser?.id) throw Error("createInvitation currentUser?.id have to be defined")
    console.debug("createInvitation invitation:", invitation)
    if (!invitation) invitation = new UserInvitation()
    invitation.invitedIntoCompanyId = company.id
    invitation.invitedIntoCompanyName = company.name
    invitation.fromUserId = currentUser?.id
    invitation.fromUserName = currentUser?.name
    invitation.fromUserEmail = currentUser?.email
    invitation.status = STATUS.DRAFT
    invitation.authorizeOwner(currentUser?.id)
    await FirebaseObject.create(invitation)
    return invitation
  }

  async function sendInvitation(userInvitation: UserInvitation) {
    console.debug("sendInvitation userInvitation:", userInvitation)
    if (userInvitation.id) {
      const result = await sendUserInvitation(userInvitation.id)
      console.debug("sendInvitation result:", result)
      return result
    }
  }

  const updateInvitation = async (invitation: UserInvitation) => {
    console.debug("updateInvitation", invitation)
    if (!invitation) throw Error("No invitation is selected")
    await FirebaseObject.update(new UserInvitation({ ...invitation, id: invitation.id }))
  }

  async function deleteInvitation(userInvitation: UserInvitation): Promise<boolean> {
    if (!userInvitation) throw Error("deleteInvitation userInvitation have to be defined")
    if (!userInvitation.id) throw Error("deleteInvitation userInvitation.id have to be defined")
    try {
      console.debug("deleteInvitation userInvitation", userInvitation)
      if (currentUser?.id) {
        userInvitation.isAuthorizedOwner(currentUser?.id)
        await FirebaseObject.delete(userInvitation)
        return true
      }
    } catch (error) {
      console.error("Error deleteInvitation:", error)
      throw error
    }
    return false
  }

  async function updateSystemRoles(data: any) {
    if (!company?.id) throw Error("updateSystemRoles company?.id have to be defined")
    await FirebaseObject.merge<Company>(company, "systemRoles", data)
  }

  async function createSystemRole(systemRole: SystemRole) {
    console.log("createSystemRole")
    const newSystemRoles = company?.systemRoles || []
    newSystemRoles.push(systemRole)
    await updateSystemRoles(newSystemRoles)
  }

  async function updateUserRoles(authId: string, roles: string[]) {
    if (company) {
      company?.authorizeOnlyRoles(authId, roles)
      await FirebaseObject.update(company)
    }
  }

  async function getMyInvitationList() {
    if (currentUser && currentUser.email) {
      return await FirebaseObject.findAll<UserInvitation>("UserInvitation", (data) => new UserInvitation(data), [
        where("invitedUserEmail", "==", currentUser.email),
      ])
    }
  }

  return {
    selectCompany,
    createCompany,
    readCompany,
    deleteCompany,
    existsCompany,
    findCompany,
    updateCompany,
    updateSystemRoles,
    createSystemRole,
    updateUserRoles,
    fetchCompanyData,
    createInvitation,
    updateInvitation,
    deleteInvitation,
    sendInvitation,
    updateDefinitions,
    getMyInvitationList,
    companyList,
    company,
    companyUsers,
    companyInvitationList,
    hardDeleteCompany,
  }
}
