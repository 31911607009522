import React, { useState } from "react"
import { StyleProp, Text, TextStyle } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"

type Props = {
  style?: StyleProp<TextStyle>
  ellipsizeMode?: "head" | "middle" | "tail" | "clip"
  children: any
  type?: "regular" | "heading" | "small"
  error?: boolean
  noPadding?: boolean
  white?: boolean
  link?: boolean
  maxLines?: number
}

export default function AppText({
  ellipsizeMode,
  style,
  children,
  type = "regular",
  error = false,
  noPadding = false,
  white = false,
  link = false,
  maxLines = 0,
}: Props) {
  const theme: Theme = useTheme()
  const fontSize =
    type === "heading" ? theme.fontSize?.big : type === "small" ? theme.fontSize?.small : theme.fontSize?.regular
  const paddingBottom = noPadding ? 0 : theme.padding
  const height = maxLines != 0 ? maxLines * fontSize * 1.2 + paddingBottom : undefined
  const [currentFont, setCurrentFont] = useState(fontSize)

  return (
    <Text
      ellipsizeMode={ellipsizeMode}
      adjustsFontSizeToFit={!!maxLines}
      numberOfLines={maxLines}
      allowFontScaling={true}
      onTextLayout={(e) => {
        const { lines } = e.nativeEvent
        if (lines.length > maxLines) {
          setCurrentFont(currentFont - 1)
        }
      }}
      style={[
        {
          height,
          fontSize,
          color: error ? theme.colors?.danger : white ? theme.colors?.white : theme.body?.textColor,
          paddingBottom,
          textDecorationLine: link ? "underline" : "none",
        },
        style,
      ]}
    >
      {children}
    </Text>
  )
}

export class Container {}

export class AppLink {}
