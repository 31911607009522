import { useContext, useEffect, useState } from "react"
import { Theme } from "../../types"
import DarkTheme from "../theme/DarkTheme"
import DefaultTheme from "../theme/DefaultTheme"
import * as SplashScreen from "expo-splash-screen"
import * as Font from "expo-font"
import { FontAwesome } from "@expo/vector-icons"
import { SettingsContext } from "../providers/SettingsProvider"
import { Appearance } from "react-native"

export const useColorSchemeProvider = (): Theme => {
  const [theme, setTheme] = useState<Theme>(DefaultTheme)
  const { settings } = useContext(SettingsContext)
  const colorScheme = Appearance.getColorScheme()

  useEffect(() => {
    console.log("useColorSchemeProvider useEffect")
    switch (settings?.appearance?.theme) {
      case "dark":
        setTheme(DarkTheme)
        break
      case "light":
        setTheme(DefaultTheme)
        break
      case "system_appearance":
        if (colorScheme === "dark") {
          setTheme(DarkTheme)
        } else setTheme(DefaultTheme)
        break
    }
  }, [colorScheme, settings])

  return theme
}

export const useCachedResourcesProvider = () => {
  const [isLoadingComplete, setLoadingComplete] = useState(false)

  // Load any resources or data that we need prior to rendering the app
  useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        await SplashScreen.preventAutoHideAsync()

        // Load fonts
        await Font.loadAsync({
          ...FontAwesome.font,
          "space-mono": require("../assets/fonts/SpaceMono-Regular.ttf"),
        })
      } catch (e) {
        // We might want to provide this error information to an error reporting service
        console.warn(e)
      } finally {
        setLoadingComplete(true)
        await SplashScreen.hideAsync()
      }
    }

    loadResourcesAndDataAsync()
  }, [])

  return isLoadingComplete
}
