import React, { useState } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import PaymentComponentStripe from "../../components/payment/PaymentComponentStripe"
import { usePaymentProvider } from "../../providers/PaymentProvider"
import { ExpandableItemListCard } from "../../components/container/ExpandableItemListCard"
import { Transaction } from "../../shared/entity/payment/Transaction"
import { MaterialIcons } from "@expo/vector-icons"
import { ItemTransactionReadonlyUser } from "../../components/flatlist/ItemTransaction"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_PAYMENT>

export enum StateNames {
  SUBSCRIPTION = "subscription",
  ONE_TIME_PAYMENT = "onetime_payments",
  TRANSACTION = "transaction",
}

export function PaymentScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const { transactionList, getInvoice } = usePaymentProvider()
  const [showMode, setShowMode] = useState(StateNames.SUBSCRIPTION)

  const transactionListComponent = () => {
    return (
      <>
        {transactionList.length > 0 && (
          <ExpandableItemListCard<Transaction>
            itemList={transactionList
              .sort((a, b) => b.getCreatedDateTime().getTime() - a.getCreatedDateTime().getTime())
              .filter((transaction: Transaction) => transaction.getCreatedDateTime())}
            startOpen={showMode === StateNames.TRANSACTION || transactionList.length <= 3}
            icon={<MaterialIcons color={theme.colors.white} name="payments" size={theme.iconSize.regular} />}
            childrenIcon={<MaterialIcons color={theme.colors.white} name="payment" size={theme.iconSize.regular} />}
            name={i18n.t("list_of_transactions")}
            template={ItemTransactionReadonlyUser}
            onSaveItem={(item) => getInvoice(item.payment.charges.data[0].invoice)}
          />
        )}
      </>
    )
  }
  const paymentRenderComponent = () => {
    return <PaymentComponentStripe navigation={navigation} route={route} transactionList={transactionListComponent()} />
  }

  return <>{paymentRenderComponent()}</>
}
