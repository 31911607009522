import React from "react"
import { FlatList, StyleSheet } from "react-native"
import { FAB, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import Separator from "../../components/flatlist/Separator"
import UserItem from "../../components/flatlist/UserItem"
import Screen from "../../components/general/Screen"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { User } from "../../shared/entity/User"
import { useCompanyProvider } from "../../providers/CompanyProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_LIST>

export function UserListScreen({ route, navigation }: Props) {
  const theme: Theme = useTheme()
  const { users } = useCompanyProvider()
  console.log("users", users)

  return (
    <Screen>
      <FlatList
        ItemSeparatorComponent={Separator}
        data={users}
        renderItem={({ item }: { item: User }) => (
          <UserItem
            user={item}
            onPress={() => {
              if (item.id) navigation.navigate(ROUTE.USER_DETAIL, { userId: item.id })
            }}
          />
        )}
      />

      <FAB
        visible
        style={theme.fab}
        color={theme.colors.green}
        icon="plus"
        onPress={() => navigation.navigate(ROUTE.USER_NEW)}
      />
    </Screen>
  )
}

const styles = StyleSheet.create({})
