export enum OPERATION {
  COMPANY_MANAGE = "COMPANY_MANAGE",
  PROJECT_MANAGE = "PROJECT_MANAGE",
  ATTENDANCE_MANAGE = "ATTENDANCE_MANAGE",
  TASK_MANAGE = "TASK_MANAGE",
  ISSUE_MANAGE = "ISSUE_MANAGE",
  SUPERADMIN = "SUPERADMIN",
}

export class SystemRole {
  name?: string
  operationList?: string[]

  constructor(data?: any) {
    Object.assign(this, data)
  }
}

export const DEFAULT_SYSTEM_ROLES: SystemRole[] = [
  {
    name: "COMPANY_MANAGER",
    operationList: [OPERATION.COMPANY_MANAGE],
  },
  {
    name: "PROJECT_MANAGER",
    operationList: [OPERATION.PROJECT_MANAGE],
  },
  {
    name: "ATTENDANCE_MANAGER",
    operationList: [OPERATION.ATTENDANCE_MANAGE],
  },
  {
    name: "TASK_MANAGER",
    operationList: [OPERATION.TASK_MANAGE],
  },
]

export enum CONSTRUCTION_ROLES {
  "BUILDING_OWNER" = "building_owner",
  "BUILDING_INVESTOR" = "building_investor",
  "CONSTRUCTION_LEAD" = "construction_lead",
  "CONSTRUCTION_MANAGER" = "construction_manager",
  "CONSTRUCTION_STAFF" = "construction_staff",
}

export enum SYSTEM_ROLES {
  "SYSTEM_ADMIN" = "system_admin",
  "COMPANY_MANAGER" = "company_manager",
  "ATTENDANCE_MANAGER" = "attendance_manager",
  "PROJECT_MANAGER" = "project_manager",
  "TASK_MANAGER" = "task_manager",
}

const mergedRoles = {
  ...SYSTEM_ROLES,
}

export const ROLES: Record<string, any> = mergedRoles
