import React, { useEffect } from "react"
import {
  CustomInputDefinition,
  INPUT_DEFINITION,
  isInputDefinition,
  isRendered,
} from "../../shared/common/CustomInputDefinition"
import { TextInput } from "../input/TextInput"
import Dropdown from "../input/Dropdown"
import { WrapRow } from "../container/WrapRow"
import BoolInput from "../input/BoolInput"
import { CameraInput } from "../input/CameraInput"
import { TimeInput } from "../input/TimeInput"
import { NumberInput } from "../input/NumberInput"
import Button from "../button/Button"
import { isUserOperationAuthorized, useUserProvider } from "../../providers/UserProvider"
import Cell from "../input/Cell"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { UserMessage } from "../../shared/entity/UserMessage"
import { useMemoProvider } from "../../providers/MemoProvider"
import { Memo } from "../../shared/entity/Memo"
import { DOC_PERMISSION } from "../../shared/entity/DocObject"
import { MemoRoom } from "../../shared/entity/MemoRoom"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export interface ItemWithCustomFieldsProps {
  onSave?: (customFields: Record<string, any>, ready?: boolean, quicksave?: boolean) => void
  definition: CustomInputDefinition[]
  customFields: Record<string, any>
  noRequireCheck?: boolean
  conditionRender?: boolean
  alwaysRender?: boolean
  navigate?: { link: string; payload: any }
}

export function ItemWithCustomFields(props: ItemWithCustomFieldsProps) {
  console.log("ItemWithCustomFields definitions:", props.definition)
  const { companyUsers, company } = useCompanyProvider()
  const { i18n } = useSettingsProvider()
  const { sendMemo, createRoom } = useMemoProvider()
  const { currentUser, isOperationAuthorized, sendMessage } = useUserProvider()
  useEffect(() => {
    if (props.noRequireCheck) return
    if (props.onSave) {
      let ready = true
      const customFields: Record<string, any> = { ...props.customFields }
      for (const definition of props.definition) {
        if (definition.type == "CONFIRMATION" && customFields[definition.label] == undefined) continue
        if (customFields[definition.label] == undefined) customFields[definition.label] = definition.defaultValue
        if (definition.required && definition.defaultValue == undefined) {
          ready = false
          break
        }
      }
      props.onSave(customFields, ready)
    }
  }, [props.definition])

  const onChange = (prop: string | undefined, value: any, quicksave = false) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return
    }
    if (props.onSave) {
      const newValues = { ...props.customFields, [prop]: value }
      let ready = true
      if (!props.noRequireCheck) {
        for (const definition of props.definition) {
          if (!isRendered(definition, props.definition, props.customFields)) continue
          if (definition.required && newValues[definition.label] == undefined) {
            ready = false
            break
          }
        }
      }
      console.log("newValues:", newValues)
      props.onSave(newValues, ready, quicksave)
    }
  }

  return (
    <>
      <WrapRow>
        {props &&
          props.definition &&
          props.definition
            .filter((d) => d && d.type && d.type != "TAG")
            .map((definition) => {
              if (
                definition &&
                definition.type &&
                isInputDefinition(definition.type) &&
                (props.alwaysRender ||
                  props.conditionRender ||
                  isRendered(definition, props.definition, props.customFields))
              ) {
                switch (INPUT_DEFINITION[definition.type]) {
                  case INPUT_DEFINITION.TEXT:
                    return (
                      <TextInput
                        key={definition.label}
                        value={(props.customFields[definition.label] as string) ?? definition.defaultValue}
                        prop={definition.label}
                        onChangeText={(value: string) => onChange(definition.label, value)}
                        label={definition.label}
                        disabled={props.onSave == undefined}
                        required={definition.required}
                      />
                    )
                  case INPUT_DEFINITION.TOGGLE:
                    return (
                      <BoolInput
                        key={definition.label}
                        value={(props.customFields[definition.label] as boolean) ?? definition.defaultValue}
                        onChange={(value) => {
                          onChange(definition.label, value)
                        }}
                        disabled={props.onSave == undefined}
                        label={definition.label}
                      />
                    )
                  case INPUT_DEFINITION.SELECT:
                    return (
                      <Dropdown
                        key={definition.label}
                        label={definition.label}
                        value={props.customFields[definition.label] ?? definition.defaultValue}
                        onChange={(v) => {
                          onChange(definition.label, v)
                        }}
                        withSearchBar={false}
                        data={definition.values.map((v) => ({ label: v, value: v }))}
                        required={definition.required}
                      />
                    )
                  case INPUT_DEFINITION.MULTISELECT:
                    return (
                      <Dropdown
                        key={definition.label}
                        label={definition.label}
                        value={props.customFields[definition.label] ?? definition.defaultValue}
                        onChange={(v) => {
                          onChange(definition.label, v)
                        }}
                        withSearchBar={false}
                        isMultipleSelect={true}
                        data={definition.values.map((v) => ({ label: v, value: v }))}
                        required={definition.required}
                      />
                    )
                  case INPUT_DEFINITION.IMAGE:
                    return (
                      <CameraInput
                        key={definition.label}
                        imageUri={props.customFields[definition.label]}
                        onSave={(v) => onChange(definition.label, v)}
                      />
                    )
                  case INPUT_DEFINITION.TIME:
                    return (
                      <TimeInput
                        label={definition.label}
                        key={definition.label}
                        time={props.customFields[definition.label] ?? definition.defaultValue}
                        required={definition.required}
                        onSave={(v) => onChange(definition.label, v)}
                      />
                    )
                  case INPUT_DEFINITION.NUMBER:
                    return (
                      <NumberInput
                        label={definition.label}
                        minLimit={0}
                        maxLimit={Number.MAX_SAFE_INTEGER}
                        key={definition.label}
                        value={props.customFields[definition.label] ?? definition.defaultValue}
                        required={definition.required}
                        onChange={(_, v) => onChange(definition.label, v)}
                      />
                    )
                  case INPUT_DEFINITION.CONFIRMATION:
                    if (props.conditionRender) {
                      return (
                        <BoolInput
                          label={i18n.t("request_completed")}
                          value={props.customFields[definition.label]}
                          onChange={(v) => onChange(definition.label, v)}
                        />
                      )
                    } else {
                      if (
                        props.customFields[definition.label] == undefined &&
                        !isOperationAuthorized(definition.defaultValue)
                      ) {
                        return (
                          <Button
                            key={definition.label}
                            title={i18n.t("request_confirmation")}
                            onPress={() => {
                              if (currentUser && currentUser.id) {
                                const memo = new Memo()
                                const room = new MemoRoom({ hidden: true })
                                companyUsers
                                  .filter((u) => isUserOperationAuthorized(definition.defaultValue, u, company))
                                  .forEach((u) => room.authorizeRole(u.id, [DOC_PERMISSION.WRITE]))
                                room.authorizeOwner(currentUser?.id)
                                memo.text = currentUser?.name + "" + i18n.t("requests") + " " + definition.label
                                room.name = currentUser?.name + "" + i18n.t("requests") + " " + definition.label
                                memo.extra = {
                                  link: props.navigate?.link,
                                  payload: props.navigate?.payload,
                                }
                                memo.userId = currentUser.id
                                memo.renderer = "NavigateRenderer"
                                memo.timestamp = Date.now()
                                createRoom(room).then((r) => {
                                  if (r.id) {
                                    memo.roomId = r.id
                                    sendMemo(memo)
                                  }
                                })
                                onChange(definition.label, { requestBy: currentUser?.id }, true)
                              }
                            }}
                          />
                        )
                      } else {
                        if (props.customFields[definition.label]?.confirmedBy) {
                          return (
                            <Cell
                              label={definition.label}
                              value={
                                i18n.t("confirmed") +
                                " " +
                                companyUsers.find((u) => u.id == props.customFields[definition.label].confirmedBy)?.name
                              }
                            />
                          )
                        }
                        return (
                          <Button
                            key={definition.label}
                            title={i18n.t("waiting_for_confirmation")}
                            disabled={!isOperationAuthorized(definition.defaultValue)}
                            onPress={() => {
                              onChange(definition.label, { confirmedBy: currentUser?.id }, true)
                              sendMessage(
                                new UserMessage({
                                  userId: props.customFields[definition.label].requestBy,
                                  title: currentUser?.name + " " + i18n.t("has_confirmed") + " " + definition.label,
                                  link: props.navigate?.link,
                                  payload: props.navigate?.payload,
                                })
                              )
                            }}
                          />
                        )
                      }
                    }
                }
              }
            })}
      </WrapRow>
    </>
  )
}
