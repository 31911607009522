const NUMBER_OF_PASSWORD_CHARS = 6
export const REGEX_EMAIL =
  // eslint-disable-next-line
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
// minimal 6 characters for password
export const REGEX_PASSWORD = new RegExp(`(?=.{${NUMBER_OF_PASSWORD_CHARS},})`)

export const shouldWarningEmail = (email: string): boolean => {
  if (!email) return true
  return !!email
}

export const isEmailValid = (email: string | undefined): boolean => {
  if (!email) return false
  return REGEX_EMAIL.test(email)
}

/**
 * Check password with regex and if it is not empty
 *
 * @param {string} password
 * @returns {boolean}
 */
export function isPasswordValid(password: string | undefined): boolean {
  if (!password) return false
  return REGEX_PASSWORD.test(password)
}

/**
 * Check if value is empty
 *
 * @param {(string|number)} value
 * @returns {boolean}
 */
export function shouldWarningInput(value: any): boolean {
  if (!value) return true
  return !!value
}

/**
 * Check if value is valid ICO
 *
 * @param {(string|number)} value
 * @returns {boolean}
 */
export function isValidICO(value: any): boolean {
  if (!value) return false
  return /^\d{8}$/.test(value)
}

export function isNumberPositive(number: number) {
  return !(!+number || Math.sign(number) === -1)
}

export function isCoordinate(value?: string): boolean {
  if (!value) return false
  return /^(-?\d+(\.\d+)?).\s*(-?\d+(\.\d+)?)$/.test(value)
}

export function isNumeric(num: string): boolean {
  return !isNaN(parseInt(num, 10)) && isFinite(parseInt(num))
}

export function isZipCode(value = ""): boolean {
  return /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(value)
}

export function isPhoneNum(num: string | undefined): boolean {
  return !!(num && num.match(/([+]?\d{1,3}[. \s]?)?(\d{9}?)/g))
}

export function isRequired(str = ""): boolean {
  if (str !== null) return !(str.trim().length === 0)
  return false
}
