import { toDateString } from "../utils/date"

interface Selectable {
  getLabel(): string

  getId(): string
}

export enum DOC_STATE {
  ENABLED,
  DISABLED,
}

//A read rule can be broken into get and list, while a write rule can be broken into create, update, and delete:

export enum DOC_PERMISSION {
  READ = "READ",
  READ_GET = "READ_GET",
  READ_LIST = "READ_LIST",
  WRITE = "WRITE",
  WRITE_CREATE = "WRITE_CREATE",
  WRITE_DELETE = "WRITE_DELETE",
  OWNER = "OWNER",
}

export enum DOC_OPERATION {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  READ = "READ",
  DELETE = "DELETE",
}

export const DOC_PERMISSION_CAN_READ = [DOC_PERMISSION.READ, DOC_PERMISSION.OWNER]
export const DOC_PERMISSION_CAN_WRITE = [DOC_PERMISSION.WRITE, DOC_PERMISSION.READ, DOC_PERMISSION.OWNER]
export const DOC_PERMISSION_IS_OWNER = [DOC_PERMISSION.OWNER]
export const DOC_AUTH_PREFIX = "_doc_auth"

export class DocObject implements Selectable {
  persistenceFilter: any = {
    persistenceFilter: true,
    id: true,
    createdAt: true,
    updatedAt: true,
    readAt: true,
    deletedAt: true,
  }
  id?: string
  createdAt?: Date
  updatedAt?: Date
  readAt?: Date
  deletedAt?: Date
  private _doc_state?: DOC_STATE = DOC_STATE.ENABLED
  private _doc_auth?: { [authId: string]: string[] }
  private _doc_op?: { [op: string]: Date[] }

  constructor(params?: any) {
    this.assign(params)
  }

  getPathSegment() {
    return this.constructor.name
  }

  assign(data: any) {
    Object.assign(this, data)
    for (const key in data) {
      if (key == "createdAt") {
        this.createdAt = extractDateFromTimestamp(data["createdAt"])
      }
      if (key == "updatedAt") {
        this.updatedAt = extractDateFromTimestamp(data["updatedAt"])
      }
      if (key == "readAt") {
        this.readAt = extractDateFromTimestamp(data["readAt"])
      }
      if (key == "deletedAt") {
        this.deletedAt = extractDateFromTimestamp(data["deletedAt"])
      }
    }
  }

  softDelete() {
    this.deletedAt = new Date()
  }

  getRaw() {
    const raw: any = {}
    for (const key in this) {
      if (!(key in this.persistenceFilter)) raw[key] = this[key as keyof typeof this]
    }
    console.log("raw", raw)
    const pureObj = JSON.parse(JSON.stringify(raw))
    console.log("pureObj", pureObj)
    return pureObj
  }

  authorize(authId: string, permission: string) {
    console.log("authorize authId:", authId, "permission:", permission)

    if (authId) {
      if (!this._doc_auth) this._doc_auth = {}
      if (!this._doc_auth[authId]) this._doc_auth[authId] = new Array<string>()
      this._doc_auth[authId]?.push(permission)
      const uniqueArray = this._doc_auth[authId]?.filter((value, index, array) => {
        return array.indexOf(value) === index
      })
      this._doc_auth[authId] = uniqueArray
    }
    console.log("authorize final:", this._doc_auth)
  }

  forbid(authId: string, permission: string) {
    console.log("forbid authId:", authId, "forbid:", permission)

    if (authId && this._doc_auth && this._doc_auth[authId]) {
      const index = this._doc_auth[authId].findIndex((p) => permission === p)
      if (index != -1) this._doc_auth[authId].splice(index, 1)
      if (this._doc_auth[authId].length == 0) delete this._doc_auth[authId]
    }

    console.log("authorize final:", this._doc_auth)
  }

  authorizeRole(authId: string, roles: string[] | undefined) {
    console.log("authorizeRole authId:", authId, "roles:", roles)
    if (roles) Object.values(roles).forEach((role) => this.authorize(authId, role))
  }

  authorizeOnlyRoles(authId: string, roles: string[] | undefined) {
    console.log("authorizeOnlyRoles authId:", authId, "roles:", roles)
    if (!this._doc_auth) this._doc_auth = {}
    if (this._doc_auth) this._doc_auth[authId] = new Array<string>()
    if (roles) Object.values(roles).forEach((role) => this.authorize(authId, role))
  }

  authorizeOwner(authId: string) {
    this.authorize(authId, DOC_PERMISSION.OWNER)
  }

  isAuthorizedRead(authId: string): boolean {
    return DOC_PERMISSION_CAN_READ.some((value) => this.isAuthorized(authId, value))
  }

  isAuthorizedWrite(authId: string): boolean {
    return DOC_PERMISSION_CAN_WRITE.some((value) => this.isAuthorized(authId, value))
  }

  isAuthorizedOwner(authId: string): boolean {
    return this.isAuthorized(authId, DOC_PERMISSION.OWNER)
  }

  isAuthorized(authId: string, permission: DOC_PERMISSION | string): boolean {
    if (this._doc_auth && this._doc_auth[authId]) {
      const authorized = this._doc_auth[authId].includes(permission)
      console.log("isAuthorized", authorized)
      if (authorized) return true
    }
    console.log("notAuthorized")
    return false
  }

  getAuthorities(): string[] {
    if (this._doc_auth) return Object.keys(this._doc_auth)
    return []
  }

  getPermissions(authId: string): string[] {
    if (this._doc_auth) return this._doc_auth[authId]
    return []
  }

  getId(): string {
    return this.id || "undefined"
  }

  getLabel(): string {
    return this.getPathSegment() + "/" + this.getId()
  }

  createdLately() {
    if (this.createdAt) {
      return new Date().getTime() - 10000 < new Date(this.createdAt).getTime()
    }
    return false
  }

  getCreatedAtAsString() {
    return toDateString(this.createdAt || new Date())
  }
}

export function extractDateFromTimestamp(ts: any) {
  if (typeof ts == "object" && "getDay" in ts) return ts as Date
  if (ts == undefined || null) return undefined
  if (typeof ts == "string") return new Date(Date.parse(ts))
  if (typeof ts == "number") ts = { seconds: ts }
  let date = 0
  for (const key in ts) {
    if (key == "seconds") {
      date += ts[key] * 1000
    }
    if (key == "nanoseconds") {
      date += ts[key] / 1000000
    }
  }
  return new Date(date)
}

export function dateFromDB(text: string) {
  const ts = Date.parse(text)
  if (isNaN(ts)) return undefined
  return new Date(ts)
}
