import { useSettingsProvider } from "../../providers/SettingsProvider"
import { Feather, FontAwesome } from "@expo/vector-icons"
import React from "react"
import { ExpandableCard } from "../container/ExpandableCard"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { Project } from "../../shared/entity/Project"
import TaskListItem from "./TaskListItem"
import { ActionElement } from "../container/ActionElement"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { useTaskProvider } from "../../providers/TaskProvider"
import { Task, TASK_STATUS } from "../../shared/entity/Task"
import { OPERATION } from "../../shared/entity/Role"
import { useUserProvider } from "../../providers/UserProvider"

export default function ProjectTasks({ project, startOpen }: { project: Project; startOpen: boolean }) {
  const { navigate } = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { isOperationAuthorized } = useUserProvider()
  const { taskList, selectedTask, selectTask, updateTask } = useTaskProvider()
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()

  if (!project) return <></>

  const actions = (
    <ActionElement
      actionList={[
        {
          iconName: "plus",
          label: i18n.t("new"),
          action: () => {
            selectTask(new Task({ title: i18n.t("task"), projectId: project.id }))
            navigate(ROUTE.TASK_DETAIL, { task: new Task({ title: i18n.t("task"), projectId: project.id }) })
          },
        },
        {
          label: i18n.t("manage"),
          iconName: "cog",
          action: () => {
            project.id && navigate(ROUTE.GENERAL_TASKS, { selectedProject: project })
          },
        },
      ]}
    />
  )
  return (
    <ExpandableCard
      noPadding
      offset
      startOpen={startOpen}
      name={i18n.t("tasks")}
      actionElement={actions}
      info={taskList.filter((task) => task.projectId == project.id).length}
      icon={<FontAwesome color={theme.colors.white} name={"tasks"} size={theme.iconSize.regular} />}
    >
      {taskList
        .filter((task) => task.projectId == project.id)
        .map((task) => (
          <ExpandableCard
            noPadding
            noRadius
            noMargin
            icon={
              task.status === TASK_STATUS.FINISHED ? (
                <Feather color={theme.colors.white} name={"check-square"} size={theme.iconSize.regular} />
              ) : task.status === TASK_STATUS.CLOSED ? (
                <Feather color={theme.colors.white} name={"x-square"} size={theme.iconSize.regular} />
              ) : (
                <Feather color={theme.colors.white} name={"square"} size={theme.iconSize.regular} />
              )
            }
            name={task.title}
            actionElement={
              <ActionElement
                actionList={[
                  {
                    iconName: "info",
                    label: i18n.t("detail"),
                    action: () => {
                      selectTask(task)
                      navigate(ROUTE.TASK_DETAIL, { task: task })
                    },
                  },
                ]}
              />
            }
          >
            <TaskListItem
              disabled={isOperationAuthorized(
                OPERATION.PROJECT_MANAGE ||
                  OPERATION.ATTENDANCE_MANAGE ||
                  OPERATION.COMPANY_MANAGE ||
                  OPERATION.SUPERADMIN ||
                  OPERATION.TASK_MANAGE
              )}
              task={task}
            />
          </ExpandableCard>
        ))}
    </ExpandableCard>
  )
}
