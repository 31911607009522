import React from "react"
import { FlatList } from "react-native"
import Separator from "../../components/flatlist/Separator"
import InfoItem from "../../components/flatlist/InfoItem"
import Screen from "../../components/general/Screen"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { useSettingsProvider } from "../../providers/SettingsProvider"

const Screens = [
  {
    id: "1",
    label: "about_us",
    route: ROUTE.GENERAL_ABOUT,
  },
  {
    id: "2",
    label: "terms_and_conditions",
    route: ROUTE.GENERAL_VOP,
  },
  {
    id: "3",
    label: "support",
    route: ROUTE.GENERAL_SUPPORT,
  },
]

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_INFO>

export function InfoScreen({ navigation }: Props) {
  const { i18n } = useSettingsProvider()
  return (
    <Screen>
      <FlatList
        data={Screens}
        renderItem={({ item }) => (
          <InfoItem key={item.label} text={i18n.t(item.label)} onPress={() => navigation.navigate(item.route)} />
        )}
        refreshing
        ItemSeparatorComponent={Separator}
      />
    </Screen>
  )
}
