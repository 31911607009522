import React from "react"
import { TouchableOpacity, View } from "react-native"
import { useTheme } from "react-native-paper"

import { Theme } from "../../../types"
import AppText from "../general/AppText"

export default function InfoItem({ text, onPress }: { text: string; onPress: () => void }) {
  const theme: Theme = useTheme()

  return (
    <TouchableOpacity onPress={onPress}>
      <View style={[theme.flatListItem]}>
        <AppText>{text}</AppText>
      </View>
    </TouchableOpacity>
  )
}
