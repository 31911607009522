import React from "react"
import { KeyboardAvoidingView, Platform, StyleSheet, ViewStyle } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"

export default function Screen({ children, style }: { children?: JSX.Element | JSX.Element[]; style?: any }) {
  const theme: Theme = useTheme()
  const styles: Styles = getStyles(theme)

  return (
    <KeyboardAvoidingView
      style={[Platform.OS !== "web" ? theme.screenContainer : styles.containerWeb, style]}
      behavior={Platform.OS === "android" ? undefined : "padding"}
      collapsable
    >
      {children}
    </KeyboardAvoidingView>
  )
}

interface Styles {
  containerWeb: ViewStyle
}

function getStyles(theme: Theme): Styles {
  return StyleSheet.create<Styles>({
    containerWeb: {
      position: "absolute",
      height: "100%",
      overflow: "auto",
      width: "100%",
      backgroundColor: theme.body?.backgroundColor,
    },
  } as Styles)
}
