import { View, ViewStyle } from "react-native"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { PieChart } from "react-native-chart-kit"
import { Task } from "../../shared/entity/Task"
import { Attendance, TimeUnit } from "../../shared/entity/Attendance"
import { User } from "../../shared/entity/User"
import { useUserProvider } from "../../providers/UserProvider"

export interface TaskChartProps {
  data: Attendance[]
  chartType?: ChartType
  style?: Partial<ViewStyle>
  roundedValues?: number
  timeUnit?: TimeUnit
  percent?: boolean
  task: Task
}

export enum ChartType {
  PIE = "pie",
  LINE = "line",
  BAR = "bar",
  PROGRESS = "progress",
}

export default function TaskChartView({
  data,
  chartType = ChartType.PIE,
  timeUnit = TimeUnit.HOURS,
  style,
  percent = false,
  task,
  roundedValues = 2,
}: TaskChartProps) {
  const viewRef = useRef<View>(null)
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { readUser } = useUserProvider()
  const [users, setUsers] = useState<User[]>(new Array<User>())
  const [viewDimensions, setViewDimensions] = useState({ width: 0, height: 0 })
  const duration = data.reduce(
    (accumulator, currentValue) => accumulator + currentValue.deltaOfTimes(timeUnit, roundedValues),
    0
  )

  const mergedData: { [name: string]: number } = data.reduce(
    (accumulator: { [name: string]: number }, currentTimer: Attendance) => {
      const duration = currentTimer.deltaOfTimes(timeUnit, roundedValues)
      if (currentTimer.userId) {
        accumulator[currentTimer.userId] = (accumulator[currentTimer.userId] || 0) + duration
      }
      return accumulator
    },
    {}
  )

  useEffect(() => {
    const newUsers: User[] = new Array<User>()
    data.forEach((att) => {
      att.taskId === task.id && att.userId && readUser(att.userId).then((user) => newUsers.push(new User(user)))
    })
    setUsers(newUsers)
  }, [task])

  useLayoutEffect(() => {
    if (viewRef.current) {
      viewRef.current.measure((x, y, width, height) => {
        setViewDimensions({ width, height })
        console.log(viewDimensions)
      })
    }
  }, [])

  function generateRandomColor(): string {
    const letters = "0123456789ABCDEF"
    let color = "#"
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)]
    }
    return color
  }

  return (
    <View style={{ width: "100%", height: 300 }} ref={viewRef}>
      {mergedData && chartType === ChartType.PIE && (
        <PieChart
          accessor={"worked"}
          fromZero
          backgroundColor={theme.colors.background}
          paddingLeft={"15"}
          style={style}
          absolute
          avoidFalseZero
          hasLegend={data.length < 10}
          data={Object.entries(mergedData).map(([label, value]) => ({
            name:
              percent && duration !== 0
                ? " %  " + users.find((user) => label === user.id)?.name || i18n.t("user")
                : " h  " + users.find((user) => label === user.id)?.name || i18n.t("user"),
            worked: percent && duration !== 0 ? (value / duration) * 100 : value,
            color: generateRandomColor(),
            legendFontColor: theme.colors.text,
            legendFontSize: 15,
          }))}
          height={viewDimensions.height}
          width={viewDimensions.width}
          chartConfig={{
            backgroundGradientFrom: "#1E2923",
            backgroundGradientFromOpacity: 0,
            backgroundGradientTo: "#08130D",
            backgroundGradientToOpacity: 0.5,
            color: (opacity = 1) => `rgba(26, 255, 146, ${opacity})`,
            barPercentage: 0.5,
          }}
        />
      )}
    </View>
  )
}
