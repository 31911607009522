import { StyleProp, View, ViewStyle } from "react-native"
import GooglePlacesInput from "./GooglePlacesInput"
import { fetchGoogleMapCoords, fetchGoogleMapPlaces } from "../../api/googleApi"
import MapImage from "./MapImage"
import React, { useEffect, useRef, useState } from "react"
import { PlaceLocation } from "../../shared/common/PlaceLocation"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { handleNextPress, TextInput } from "../input/TextInput"
import DialogWindow from "../general/DialogWindow"
import AppText from "../general/AppText"
import EmptyScreenPlaceholder from "../general/EmptyScreenPlaceholder"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  value: PlaceLocation | undefined
  label: string
  autocomplete?: boolean
  hintText?: string
  showInfo?: boolean
  showCurrentLocation?: boolean
  currentLocationLabel?: string
  onSave: (val: PlaceLocation | undefined) => void
  style?: StyleProp<ViewStyle>
}
export default function PlaceLocationComponent({
  hintText,
  label,
  value,
  onSave,
  currentLocationLabel,
  showCurrentLocation,
  showInfo = false,
  autocomplete,
  style,
}: Props) {
  const { i18n } = useSettingsProvider()
  const [expand, setExpand] = useState(false)
  const [open, setOpen] = useState(false)
  const [address, setAddress] = useState<string>()

  const countryInputRef = useRef<TextInput>(null)
  const cityInputRef = useRef<TextInput>(null)
  const zipInputRef = useRef<TextInput>(null)
  const streetInputRef = useRef<TextInput>(null)
  const streetNoInputRef = useRef<TextInput>(null)

  useEffect(() => {
    console.debug("PlaceLocationComponent useEffect: ", value)
    setAddress(value?.address?.address)
  }, [value])

  return (
    <View
      style={[
        {
          justifyContent: "flex-start",
          flexDirection: "column",
          flexWrap: "wrap",
          marginBottom: 150,
          flexGrow: 1,
          flexShrink: 1,
        },
        style,
      ]}
    >
      <View style={{ flexGrow: 1, flexShrink: 1, width: "100%" }}>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            zIndex: 99,
            flexGrow: 1,
            flexShrink: 1,
          }}
        >
          <GooglePlacesInput
            style={{ flexGrow: 1, flexShrink: 1 }}
            hintText={hintText}
            value={value}
            label={label}
            autocomplete={autocomplete}
            showCurrentLocation={showCurrentLocation}
            currentLocationLabel={currentLocationLabel || ""}
            onAddressSelected={async (searchAddress) => {
              console.log("Project location places: ", searchAddress)
              await onSave(await fetchGoogleMapCoords(searchAddress))
            }}
          >
            {/*{address ? (
              <IconButton
                size={theme.iconSize.big}
                style={theme.iconButton}
                onPress={() => {
                  if (Platform.OS !== "web") {
                    setOpen(!open)
                  } else {
                    setExpand(!expand)
                  }
                }}
                icon={<MapIcon />}
              />
            ) : (
              <></>
            )}*/}
          </GooglePlacesInput>
        </View>
      </View>
      {expand ? (
        <View>
          {showInfo ? (
            <View>
              <TextInput
                ref={countryInputRef}
                prop={"country"}
                value={value?.address?.country || ""}
                onChangeText={(val) => onSave({ address: { country: val } })}
                label={i18n.t("country")}
                onSubmitEditing={() => handleNextPress(cityInputRef)}
              />
              <TextInput
                ref={cityInputRef}
                prop={"city"}
                value={value?.address?.city || ""}
                onChangeText={(value: string) => onSave({ address: { city: value } })}
                label={i18n.t("city")}
                onSubmitEditing={() => handleNextPress(zipInputRef)}
              />
              <TextInput
                ref={zipInputRef}
                prop={"zip"}
                value={value?.address?.zip || ""}
                onChangeText={(value: string) => onSave({ address: { zip: value } })}
                label={i18n.t("zip")}
                onSubmitEditing={() => handleNextPress(streetInputRef)}
              />
              <TextInput
                ref={streetInputRef}
                prop={"street"}
                value={value?.address?.street || ""}
                onChangeText={(value: string) => onSave({ address: { street: value } })}
                label={i18n.t("street")}
                onSubmitEditing={() => handleNextPress(streetNoInputRef)}
              />
              <TextInput
                ref={streetNoInputRef}
                prop={"streetNo"}
                value={value?.address?.streetNo || ""}
                onChangeText={(value: string) => onSave({ address: { streetNo: value } })}
                label={i18n.t("street_number")}
                returnKeyType={"done"}
              />
            </View>
          ) : (
            <></>
          )}
          <View style={{ paddingHorizontal: 10, flexGrow: 1, padding: 5 }}>
            {value?.coordinates ? (
              <MapImage
                style={{ minWidth: 300, minHeight: 300 }}
                location={value}
                onDrag={async (coords) => {
                  console.log("Project location coords: ", coords)
                  await onSave(await fetchGoogleMapPlaces(coords))
                }}
              />
            ) : (
              <EmptyScreenPlaceholder text={i18n.t("missing_location_data")} />
            )}
          </View>
        </View>
      ) : (
        <></>
      )}
      <DialogWindow
        visible={open}
        onDismiss={() => setOpen(!open)}
        title={i18n.t("map")}
        confirmButtonTitle={i18n.t("close")}
        onPress={() => setOpen(!open)}
        disableDismissButton={true}
      >
        <View style={{ paddingHorizontal: 10, flexGrow: 1 }}>
          <MapImage
            style={{ minWidth: 300, minHeight: 300 }}
            location={value}
            onDrag={async (coords) => {
              console.log("Project location coords: ", coords)
              const place: PlaceLocation = await fetchGoogleMapPlaces(coords)
              setAddress(place?.address?.address)
              await onSave(place)
            }}
          />
          <AppText type={"regular"}>{i18n.t("address") + ": " + (address ?? value?.address?.address ?? "")}</AppText>
        </View>
      </DialogWindow>
    </View>
  )
}
