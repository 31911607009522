import axios from "axios"
import { GOOGLE_API_KEY, LANGUAGE } from "../../environments"
import { PlaceLocation } from "../shared/common/PlaceLocation"

const BASE_URL = "https://maps.googleapis.com/maps/api/geocode/json?"
const API_KEY = GOOGLE_API_KEY

/**
 * A function that returns the location name from latitude and longitude or vice versa from the Google Maps API
 * @param location {PlaceLocation} - Location e.g {address: "Prague, Czechia", coordinates: { latitude: 50.0755381, longitude: 14.4378005 }}
 * @param language - e.g "cs" | "en"
 * @return {PlaceLocation} - Returns the latitude and longitude of the address name from the address name and or vice versa.
 */
export async function fetchGoogleMapInformation(location: PlaceLocation, language?: string) {
  const lang = language || LANGUAGE
  let URL = ""
  if (!location.coordinates?.latitude && !location.coordinates?.longitude) {
    console.log("fetchGoogleMapInformation: I calculate the location using the address.", location.address?.address)
    URL =
      BASE_URL +
      `address=${location?.address?.address || location?.address?.toString()}&language=${lang}&key=` +
      API_KEY
  } else if (!location.address?.address) {
    console.log(
      "fetchGoogleMapInformation: I calculate the location using the coordinates.",
      location.coordinates.latitude,
      "&",
      location.coordinates.longitude
    )
    URL =
      BASE_URL +
      `latlng=${location.coordinates?.latitude},${location.coordinates?.longitude}&language=${lang}&key=` +
      API_KEY
  } else if (!location.address || !location.coordinates) {
    console.error("Incorrectly entered location!", location)
  } else {
    console.error("Incorrectly entered location!", location)
  }
  try {
    const response = await axios.get(URL)
    console.log("fetchGoogleMapInformation response: ", response)
    if (response.data.results != 0) {
      const placeLocation = new PlaceLocation({
        address: {
          address: response.data.results[0].formatted_address.toString() || location.address?.address,
          zip: location.address?.zip || "",
          street: location.address?.street || "",
          streetNo: location.address?.streetNo || "",
          country: location.address?.country || "",
          city: location.address?.city || "",
        },
        coordinates: {
          latitude: response.data.results[0].geometry.location.lat || location.coordinates?.latitude,
          longitude: response.data.results[0].geometry.location.lng || location.coordinates?.longitude,
        },
      })
      console.log("fetchGoogleMapInformation data: ", placeLocation)
      return placeLocation
    }
  } catch (e) {
    console.error("fetchGoogleMapInformation: ", e)
  }
}

export async function fetchGoogleMapCoords(location: PlaceLocation, language?: string) {
  const lang = language || LANGUAGE
  const URL =
    BASE_URL + `address=${location?.address?.address || location?.address?.toString()}&language=${lang}&key=` + API_KEY
  if (!location.address?.address) {
    console.error("fetchGoogleMapCoords: Missing place!")
  } else {
    try {
      const response = await axios.get(URL)
      console.log("fetchGoogleMapCoords response: ", response)
      if (response.data.results != 0) {
        const placeLocation = new PlaceLocation({
          address: {
            address: location.address?.address,
            zip: location.address?.zip || "",
            street: location.address?.street || "",
            streetNo: location.address?.streetNo || "",
            country: location.address?.country || "",
            city: location.address?.city || "",
          },
          coordinates: {
            latitude: response.data.results[0].geometry.location.lat,
            longitude: response.data.results[0].geometry.location.lng,
          },
        })
        console.log("fetchGoogleMapCoords data: ", placeLocation)
        return placeLocation
      }
    } catch (e) {
      console.error("fetchGoogleMapCoords: ", e)
    }
  }
}

export async function fetchGoogleMapPlaces(location: PlaceLocation, language?: string) {
  const lang = language || LANGUAGE
  const URL =
    BASE_URL +
    `latlng=${location.coordinates?.latitude},${location.coordinates?.longitude}&language=${lang}&key=` +
    API_KEY
  if (!location.coordinates?.latitude && !location.coordinates?.longitude) {
    console.error("fetchGoogleMapPlaces: Missing coordination!")
  } else {
    try {
      const response = await axios.get(URL)
      console.log("fetchGoogleMapPlaces response: ", response)
      if (response.data.results != 0) {
        const placeLocation = new PlaceLocation({
          address: {
            address: response.data.results[0].formatted_address.toString(),
            zip: location.address?.zip || "",
            street: location.address?.street || "",
            streetNo: location.address?.streetNo || "",
            country: location.address?.country || "",
            city: location.address?.city || "",
          },
          coordinates: {
            latitude: response.data.results[0].geometry.location.lat || location.coordinates?.latitude,
            longitude: response.data.results[0].geometry.location.lng || location.coordinates?.longitude,
          },
        })
        console.log("fetchGoogleMapPlaces data: ", placeLocation)
        return placeLocation
      }
    } catch (e) {
      console.error("fetchGoogleMapPlaces: ", e)
    }
  }
}
