import { Button, useTheme } from "react-native-paper"
import React from "react"
import { StyleProp, View, ViewStyle } from "react-native"
import AppText from "../general/AppText"
import { Theme } from "../../../types"

type Props = {
  heading: string
  text?: string
  button?: () => void
  data?: boolean
  buttonText?: string
  buttonStyle?: StyleProp<ViewStyle>
  buttonIcon?: string
}

export default function ActionCardBody({
  button,
  buttonText = "",
  buttonIcon = "plus",
  text = "",
  heading,
  buttonStyle,
}: Props) {
  const theme: Theme = useTheme()

  return (
    <View
      style={{
        alignItems: "flex-end",
        justifyContent: "space-between",
      }}
    >
      <View style={{ width: "100%", flexGrow: 0 }}>
        <AppText maxLines={1} type={"heading"}>
          {heading}
        </AppText>
        <AppText>{text}</AppText>
      </View>
      {button && (
        <View style={{ flexDirection: "row", alignItems: "center", marginHorizontal: 5 }}>
          <Button
            style={{
              ...theme.shadows,
              backgroundColor: theme.colors.primary,
              buttonStyle,
            }}
            mode={"contained"}
            icon={buttonIcon}
            onPress={() => button && button()}
          >
            {buttonText}
          </Button>
        </View>
      )}
    </View>
  )
}
