import React, { useEffect, useLayoutEffect, useState } from "react"
import { View } from "react-native"
import AppText from "../../components/general/AppText"
import Screen from "../../components/general/Screen"
import HeaderMenu from "../../components/user/HeaderMenu"
import UserComponent, { FIELD_MODE } from "../../components/user/UserComponent"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { User } from "../../shared/entity/User"
import MaxWidth from "../../components/container/MaxWidth"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import { PersonIcon } from "../../components/svg"
import { useUserProvider } from "../../providers/UserProvider"
import Button from "../../components/button/Button"
import { MARGIN } from "../../constants/style"
import { OPERATION } from "../../shared/entity/Role"
import MyInvitationList from "../../components/company/MyInvitationList"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props =
  | NativeStackScreenProps<RootStackParamList, ROUTE.USER_DETAIL>
  | NativeStackScreenProps<RootStackParamList, ROUTE.USER_PROFILE>
  | NativeStackScreenProps<RootStackParamList, ROUTE.USER_NEW>

export function UserDetailScreen({ route, navigation }: Props) {
  const { readUser, currentUser, updateUser, isOperationAuthorized } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const isEdit: boolean = [ROUTE.USER_DETAIL, ROUTE.USER_PROFILE].includes(route.name)
  const [user, setUser] = useState<User>()

  useEffect(() => {
    if (route.name === ROUTE.USER_PROFILE && currentUser) {
      console.log("UserDetailScreen with current user")
      setUser(currentUser)
    }
    if (route.name === ROUTE.USER_DETAIL) {
      console.log("UserDetailScreen with userId")
      if (route.params.userId) {
        console.log("UserDetailScreen route.params.userId", route.params.userId)
        readUser(route.params.userId).then((user) => {
          console.log("UserDetailScreen user", user)
          setUser(user)
        })
      }
    }
  }, [currentUser])

  useLayoutEffect(() => {
    console.log("UserDetailScreen useLayoutEffect")
    if (user && isEdit) {
      if (route.name === ROUTE.USER_DETAIL) {
        navigation.setOptions({
          headerRight: () => <HeaderMenu userId={user.id} />,
          title: user?.name,
        })
      }
      if (route.name === ROUTE.USER_PROFILE) {
        navigation.setOptions({
          // headerRight: () => <HeaderMenu uid={userData.uid} />,
          title: i18n.t("profile"),
        })
      }
    }
  }, [navigation])

  const handleSubmit = async (user: User) => {
    console.log("handleSubmit:", user)
    setUser(user)
    await updateUser(user)
  }

  function canEditRole() {
    return currentUser?.id === user?.id || isOperationAuthorized(OPERATION.COMPANY_MANAGE)
  }

  return (
    <Screen>
      <MaxWidth>
        <ExpandableCard closable={false} startOpen icon={<PersonIcon />} name={i18n.t("user")}>
          {user === undefined ? (
            <AppText>{i18n.t("user_does_not_exist")}</AppText>
          ) : (
            <>
              <UserComponent
                fieldModeRoles={canEditRole() ? FIELD_MODE.EDIT : FIELD_MODE.READONLY}
                fieldModePassword={FIELD_MODE.HIDDEN}
                initialUser={user}
                handleSubmit={handleSubmit}
              />
              <View style={{ zIndex: 1, flexDirection: "column", paddingBottom: 15 }}>
                <Button
                  style={{ marginBottom: MARGIN }}
                  title={i18n.t("password_change")}
                  onPress={() => navigation.navigate(ROUTE.USER_PASSWORD_CHANGE)}
                />
              </View>
            </>
          )}
        </ExpandableCard>
        <MyInvitationList />
      </MaxWidth>
    </Screen>
  )
}
