import React, { useEffect, useState } from "react"
import { Image, ImageStyle, StyleProp } from "react-native"

export default function FullWidthImageWeb({
  uri,
  width,
  onLoadEnd,
  style,
}: {
  uri: string
  width: number | string
  onLoadEnd: () => void
  style?: StyleProp<ImageStyle>
}) {
  const [ratio, setRatio] = useState(1)

  useEffect(() => {
    if (uri) {
      Image.getSize(uri, (width, height) => {
        setRatio(width / height)
      })
    }
  }, [uri])

  return (
    <Image
      style={[
        {
          width: width ?? "95%",
          height: "100%",
          aspectRatio: ratio,
          alignSelf: "center",
          marginVertical: 10,
          borderRadius: 5,
        },
        style,
      ]}
      resizeMode="contain"
      source={{ uri }}
      onLoadEnd={onLoadEnd}
    />
  )
}
