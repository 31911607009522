import React from "react"
import FitImage from "react-native-fit-image"
import { ImageStyle, StyleProp } from "react-native"
type Props = {
  uri: string
  onLoadEnd: () => void
  style?: StyleProp<ImageStyle>
}

export default function FullWidthImagePhone({ uri, onLoadEnd, style }: Props) {
  return (
    <FitImage
      // indicator={false}
      source={{ uri }}
      style={style}
    />
  )
}
