import { DocObject } from "./DocObject"

class Change {
  name?: string
  description?: string
}

export class Release extends DocObject {
  changelog: Change[] = []
  version?: string
  commitId?: string

  constructor(params?: Partial<Release>) {
    super(params)
    Object.assign(this, params)
  }

  getPathSegment() {
    return "Release"
  }
}
