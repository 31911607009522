import React from "react"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { TouchableOpacity, View } from "react-native"
import InputBase from "./InputBase"
import AppText from "../general/AppText"
import { MARGIN } from "../../constants/style"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function BoolInput({
  label,
  value,
  disabled = false,
  stateNames = { yes: "yes", no: "no" },
  onChange,
}: {
  label: string
  value: boolean
  disabled?: boolean
  stateNames?: { yes: string; no: string }
  onChange?: (newValue: boolean) => void
}) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  return (
    <InputBase noPadding={true} label={label}>
      <View style={{ alignItems: "center", flexDirection: "row", flexGrow: 1 }}>
        <TouchableOpacity style={{ flexGrow: 1 }} onPress={() => onChange && onChange(!value)} disabled={disabled}>
          <View
            style={{
              flexGrow: 1,
              margin: MARGIN,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: value ? "flex-end" : "flex-start",
              position: "relative",
            }}
          >
            <View
              style={{
                width: "50%",
                height: 50,
                backgroundColor: theme.colors.primary,
                borderRadius: 10,
                justifyContent: "center",
                alignItems: "center",
              }}
            ></View>
            <View
              style={{
                left: 0,
                width: "100%",
                position: "absolute",
                flexDirection: "row",
                justifyContent: "space-around",
              }}
            >
              <AppText noPadding>{i18n.t(stateNames.no)}</AppText>
              <AppText noPadding>{i18n.t(stateNames.yes)}</AppText>
            </View>
          </View>
        </TouchableOpacity>
      </View>
    </InputBase>
  )
}
