import React, { useContext } from "react"
import { createNativeStackNavigator } from "@react-navigation/native-stack"
import { ModalScreen } from "../screens/ModalScreen"
import { NotFoundScreen } from "../screens/NotFoundScreen"
import DrawerNavigator from "./DrawerNavigator"
import { RootStackParamList } from "./types"
import { ROUTE } from "./routes"
import { UserLoginScreen } from "../screens/user/UserLoginScreen"
import { UserRegisterScreen } from "../screens/user/UserRegisterScreen"
import { UserPasswordForgotScreen } from "../screens/user/UserPasswordForgotScreen"
import { UserPasswordChangeScreen } from "../screens/user/UserPasswordChangeScreen"
import { UserDeleteScreen } from "../screens/user/UserDeleteScreen"
import { UserListScreen } from "../screens/user/UserListScreen"
import { UserDetailScreen } from "../screens/user/UserDetailScreen"
import { UserPickerScreen } from "../screens/user/UserPickerScreen"
import { ProjectListScreen } from "../screens/project/ProjectListScreen"
import { AboutScreen } from "../screens/general/AboutScreen"
import { ReportScreen } from "../screens/general/ReportScreen"
import { TutorialScreen } from "../screens/general/TutorialScreen"
import { PreviewScreen } from "../screens/general/PreviewScreen"
import { ProjectDeleteScreen } from "../screens/project/ProjectDeleteScreen"
import { DiaryDetailScreen } from "../screens/diary/DiaryDetailScreen"
import { DiarySignatureScreen } from "../screens/diary/DiarySignatureScreen"
import { VopScreen } from "../screens/general/VopScreen"
import { ProjectDetailScreen } from "../screens/project/ProjectDetailScreen"
import { useTheme } from "react-native-paper"
import { Theme } from "../../types"
import { AttendanceScreen } from "../screens/attendance/AttendanceScreen"
import { ApplicationSettingsScreen } from "../screens/general/ApplicationSettingsScreen"
import { AttendanceList } from "../screens/attendance/AttendanceList"
import { UserContext } from "../providers/UserProvider"
import { ModulesScreen } from "../screens/company/ModulesScreen"
import CompanyScreen from "../screens/company/CompanyScreen"
import { SupportScreen } from "../screens/general/SupportScreen"
import { ReleaseScreen } from "../screens/admin/ReleaseScreen"
import { PaymentScreen } from "../screens/payment/PaymentScreen"
import { PaymentProductScreen } from "../screens/payment/PaymentProductScreen"
import { DevScreen } from "../screens/DevScreen"
import { MemoMessages } from "../screens/memo/MemoScreen"
import { useSettingsProvider } from "../providers/SettingsProvider"
import TaskDetailScreen from "../screens/task/TaskDetailScreen"
import ArticleDetailScreen from "../screens/article/ArticleDetailScreen"

const Stack = createNativeStackNavigator<RootStackParamList>()

export default function StackNavigator() {
  const { i18n } = useSettingsProvider()
  const { currentUser } = useContext(UserContext)
  const theme: Theme = useTheme()

  // useLayoutEffect(() => {
  //   if (showTutorial && !currentUser) {
  //     console.log("navigation to TUTORIAL_WIZARD")
  //     // navigation.navigate(ROUTE.GENERAL_TUTORIAL)
  //   }
  // }, [showTutorial])

  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: theme.colors.primary,
      }}
    >
      <>
        {!currentUser ? (
          <Stack.Group>
            <Stack.Screen
              name={ROUTE.USER_LOGIN}
              component={UserLoginScreen}
              options={{ title: i18n.t("login"), headerShown: false, headerLeft: () => null }}
            />
            <Stack.Screen
              name={ROUTE.USER_REGISTER}
              component={UserRegisterScreen}
              options={{ title: i18n.t("register"), headerShown: false }}
            />
            <Stack.Screen
              name={ROUTE.USER_PASSWORD_FORGOT}
              component={UserPasswordForgotScreen}
              options={{ title: i18n.t("forgot_password"), headerShown: false }}
            />
          </Stack.Group>
        ) : (
          <Stack.Group>
            <Stack.Screen name={ROUTE.DRAWER} component={DrawerNavigator} options={{ headerShown: false }} />
            <Stack.Screen
              name={ROUTE.USER_PASSWORD_CHANGE}
              component={UserPasswordChangeScreen}
              options={{ title: i18n.t("password_change") }}
            />
            <Stack.Screen
              name={ROUTE.USER_DELETE}
              component={UserDeleteScreen}
              options={{ title: i18n.t("delete_account") }}
            />
            <Stack.Screen name={ROUTE.USER_LIST} component={UserListScreen} options={{ title: i18n.t("users") }} />
            <Stack.Screen
              name={ROUTE.USER_PROFILE}
              component={UserDetailScreen}
              options={{ title: i18n.t("profile") }}
            />
            <Stack.Screen name={ROUTE.USER_NEW} component={UserDetailScreen} options={{ title: i18n.t("new_user") }} />
            <Stack.Screen
              name={ROUTE.USER_DETAIL}
              component={UserDetailScreen}
              options={{ title: i18n.t("user_detail") }}
            />
            <Stack.Screen name={ROUTE.USER_PICKER} component={UserPickerScreen} options={{ title: "Vybrat" }} />
            <Stack.Screen
              name={ROUTE.PROJECT_LIST}
              component={ProjectListScreen}
              options={{ title: i18n.t("projects"), headerShown: false }}
            />
            <Stack.Screen
              name={ROUTE.DEV_SCREEN}
              component={DevScreen}
              options={{ title: "Dev screen", headerShown: false }}
            />
            <Stack.Screen
              name={ROUTE.PROJECT_DELETE}
              component={ProjectDeleteScreen}
              options={{ title: i18n.t("delete_project") }}
            />
            <Stack.Screen
              name={ROUTE.PROJECT_DETAIL}
              component={ProjectDetailScreen}
              options={{ title: i18n.t("project_detail") }}
            />
            <Stack.Screen
              name={ROUTE.TASK_DETAIL}
              component={TaskDetailScreen}
              options={{ title: i18n.t("task_detail") }}
            />
            <Stack.Screen
              name={ROUTE.ARTICLE_DETAIL}
              component={ArticleDetailScreen}
              options={{ title: i18n.t("article") }}
            />
            <Stack.Screen
              name={ROUTE.PAYMENT_PRODUCT}
              component={PaymentProductScreen}
              options={{ title: i18n.t("product_detail") }}
            />
            <Stack.Screen
              name={ROUTE.GENERAL_PAYMENT}
              component={PaymentScreen}
              options={{ title: i18n.t("payments") }}
            />
            <Stack.Screen
              name={ROUTE.DIARY_NEW}
              component={DiaryDetailScreen}
              options={{ title: i18n.t("daily_records") }}
            />
            <Stack.Screen
              name={ROUTE.DIARY_DETAIL}
              component={DiaryDetailScreen}
              options={{ title: i18n.t("diary_detail") }}
            />
            <Stack.Screen
              name={ROUTE.DIARY_SIGNATURE}
              component={DiarySignatureScreen}
              options={{ title: i18n.t("signature") }}
            />
            <Stack.Screen name={ROUTE.GENERAL_ABOUT} component={AboutScreen} options={{ title: i18n.t("about_us") }} />
            <Stack.Screen
              name={ROUTE.GENERAL_VOP}
              component={VopScreen}
              options={{ title: i18n.t("terms_and_conditions") }}
            />
            <Stack.Screen
              name={ROUTE.GENERAL_SUPPORT}
              component={SupportScreen}
              options={{ title: i18n.t("support") }}
            />
            <Stack.Screen
              name={ROUTE.GENERAL_REPORT}
              component={ReportScreen}
              options={{ title: i18n.t("report_issue") }}
            />
            <Stack.Screen
              name={ROUTE.COMPANY}
              component={CompanyScreen}
              options={{ title: i18n.t("company_detail") }}
            />
            <Stack.Screen
              name={ROUTE.ATTENDANCE}
              component={AttendanceScreen}
              options={{ title: i18n.t("attendance") }}
            />
            <Stack.Screen name={ROUTE.ADMIN_MODULES} component={ModulesScreen} options={{ title: i18n.t("modules") }} />
            <Stack.Screen
              name={ROUTE.ATTENDANCE_LIST}
              component={AttendanceList}
              options={{ title: i18n.t("attendance_records") }}
            />
            <Stack.Screen
              name={ROUTE.APPLICATION_SETTINGS}
              component={ApplicationSettingsScreen}
              options={{ title: i18n.t("application_settings") }}
            />
            <Stack.Screen name={ROUTE.MEMO_MESSAGES} component={MemoMessages} options={{ title: "" }} />
            {
              <Stack.Screen
                name={ROUTE.SUPERADMIN_RELEASE}
                component={ReleaseScreen}
                options={{ title: i18n.t("release") }}
              />
            }
          </Stack.Group>
        )}
        <Stack.Group screenOptions={{ presentation: "modal" }}>
          <Stack.Screen
            name={ROUTE.GENERAL_TUTORIAL}
            component={TutorialScreen}
            options={{ headerShown: false, headerLeft: () => null }}
          />
          <Stack.Screen name={ROUTE.GENERAL_PREVIEW} component={PreviewScreen} options={{ title: i18n.t("preview") }} />
          <Stack.Screen
            name={ROUTE.MODAL}
            component={ModalScreen}
            options={{ headerShown: false, headerLeft: () => null }}
          />
          <Stack.Screen
            name={ROUTE.NOTFOUND}
            component={NotFoundScreen}
            options={{ title: i18n.t("page_not_found") }}
          />
          <Stack.Screen
            name={ROUTE.LOADING}
            component={ModalScreen}
            options={{ headerShown: false, headerLeft: () => null }}
          />
        </Stack.Group>
      </>
    </Stack.Navigator>
  )
}
