import React, { useEffect, useState } from "react"
import { Attendance, ATTENDANCE_STATE } from "../../shared/entity/Attendance"
import { ExpandableCard } from "../container/ExpandableCard"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { getTimeWithSeconds, toCurrentDate } from "../../shared/utils/date"
import { WrapRow } from "../container/WrapRow"
import { ItemWithCustomFields } from "../flatlist/ItemWithCustomFields"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { TimeInput } from "../input/TimeInput"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { ActionElement } from "../container/ActionElement"
import { FontAwesome } from "@expo/vector-icons"
import { showMessage } from "react-native-flash-message"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { ROUTE } from "../../navigation/routes"
import Dropdown from "../input/Dropdown"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { useTaskProvider } from "../../providers/TaskProvider"
import { TASK_STATUS } from "../../shared/entity/Task"

interface Props {
  attendance: Attendance
}

export function AttendanceEntry({ attendance }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { companyUsers } = useCompanyProvider()
  const { updateAttendance, deleteAttendance } = useAttendanceProvider()
  const { isOperationAuthorized } = useUserProvider()
  const [time, setTime] = useState(new Date())
  const [newAttendance, setNewAttendance] = useState(attendance)
  const { projects } = useProjectProvider()
  const { taskList } = useTaskProvider()
  const timeSpent = new Date(
    (newAttendance.end?.valueOf() ?? new Date().valueOf()) - (newAttendance.start?.valueOf() ?? new Date().valueOf())
  )
  const disabled =
    !isOperationAuthorized(OPERATION.ATTENDANCE_MANAGE) &&
    attendance.start &&
    new Date().valueOf() - attendance.start.valueOf() > 24 * 60 * 60 * 1000
  console.log("Attandance manager: ", !disabled)
  useEffect(() => {
    if (attendance.state != ATTENDANCE_STATE.FINISHED) setTimeout(() => setTime(new Date()), 1000)
  }, [time])

  useEffect(() => {
    setNewAttendance(attendance)
  }, [attendance])

  const saveChanges = (
    <ActionElement
      actionList={[
        {
          label: i18n.t("save"),
          iconName: "save",
          action: () => {
            updateAttendance(newAttendance).then(() => {
              showMessage({ message: i18n.t("changes_saved"), type: "success" })
            })
          },
          disabled: disabled,
        },
        {
          label: i18n.t("delete"),
          iconName: "trash-o",
          action: () => {
            deleteAttendance(attendance).then(() => {
              showMessage({ message: i18n.t("entry_removed"), type: "success" })
            })
          },
          disabled: disabled,
        },
      ]}
    />
  )

  return (
    <ExpandableCard
      icon={
        newAttendance.state == ATTENDANCE_STATE.STARTED ? (
          <FontAwesome name={"play"} size={theme.iconSize.regular} color={theme.colors.white} />
        ) : (
          <></>
        )
      }
      info={getTimeWithSeconds(timeSpent)}
      name={
        (companyUsers?.find((u) => u.id == attendance.userId)?.getDisplayName() ?? "") +
        " - " +
        toCurrentDate(newAttendance.start)
      }
      noRadius
      noMargin
      actionElement={saveChanges}
    >
      <Dropdown
        label={i18n.t("project")}
        value={attendance.projectId}
        onChange={(selected) => {
          setNewAttendance(new Attendance({ ...attendance, projectId: selected }))
        }}
        data={projects.map((p) => ({ value: p.id, label: p.title }))}
      />
      {newAttendance.projectId && (
        <Dropdown
          label={i18n.t("task")}
          value={newAttendance.taskId || attendance.taskId}
          withEmptyValue
          data={taskList
            .filter(
              (task) =>
                task.projectId === newAttendance.projectId &&
                task.status !== (TASK_STATUS.CLOSED || TASK_STATUS.FINISHED)
            )
            .map((t) => ({ value: t.id, label: t.title }))}
          onChange={(selected) => {
            setNewAttendance(new Attendance({ ...attendance, taskId: selected }))
          }}
        />
      )}
      <WrapRow>
        <TimeInput
          label={i18n.t("duration_of_work")}
          time={{ hours: timeSpent.getUTCHours(), minutes: timeSpent.getUTCMinutes() }}
          onSave={(time) => {
            const updatedAttendance = new Attendance(newAttendance)
            updatedAttendance.end = new Date(
              (newAttendance.start?.valueOf() ?? new Date().valueOf()) + (time.hours * 60 + time.minutes) * 60 * 1000
            )
            setNewAttendance(updatedAttendance)
          }}
          disabled={!!disabled}
        />
        <TimeInput
          label={i18n.t("start")}
          time={{ hours: newAttendance.start?.getHours(), minutes: newAttendance.start?.getMinutes() }}
          onSave={(time) => {
            const updatedAttendance = new Attendance(newAttendance)
            updatedAttendance.start = new Date(
              newAttendance.start?.setHours(time.hours, time.minutes) ?? new Date().setHours(time.hours, time.minutes)
            )
            setNewAttendance(updatedAttendance)
          }}
          disabled={!!disabled}
        />
        <TimeInput
          label={i18n.t("finish")}
          time={{ hours: newAttendance.end?.getHours(), minutes: newAttendance.end?.getMinutes() }}
          onSave={(time) => {
            const updatedAttendance = new Attendance(newAttendance)
            updatedAttendance.end = new Date(
              attendance.end?.setHours(time.hours, time.minutes) ?? new Date().setHours(time.hours, time.minutes)
            )
            setNewAttendance(updatedAttendance)
          }}
          disabled={!!disabled}
        />
      </WrapRow>
      {
        <ItemWithCustomFields
          definition={newAttendance.definitions}
          customFields={newAttendance.customFields}
          onSave={(customFields, ready, quicksave) => {
            if (disabled) return
            const updatedAttendance = new Attendance(newAttendance)
            updatedAttendance.customFields = customFields
            setNewAttendance(updatedAttendance)

            if (quicksave) {
              updateAttendance(updatedAttendance).then(() => {
                showMessage({ message: i18n.t("changes_saved"), type: "success" })
              })
            }
          }}
          navigate={{ link: ROUTE.ATTENDANCE, payload: { userId: attendance.userId, date: attendance.start } }}
        />
      }
    </ExpandableCard>
  )
}
