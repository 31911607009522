import { FontAwesome, FontAwesome5 } from "@expo/vector-icons"
import React, { useState } from "react"
import { Platform } from "react-native"
import { openURL } from "expo-linking"
import { exportDiary } from "../../api/project"
import DialogWindow from "../general/DialogWindow"
import AppText from "../general/AppText"
import { Project } from "../../shared/entity/Project"
import { errorCodes } from "../../constants/ErrorMessages"
import OptionsMenu from "./OptionsMenu"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  project?: Project
}

export default function ProjectOptions({ project }: Props) {
  const [visibleDialog, setVisibleDialog] = useState(false)
  const { i18n } = useSettingsProvider()
  const { setStatusUndetermined, setStatusSuccess, setStatusError } = useStatusProvider()
  const { isOperationAuthorized } = useUserProvider()
  const { selectedProject, deleteProject, selectProject } = useProjectProvider()

  const handleExport = async () => {
    console.log("handleExport")
    setStatusUndetermined(i18n.t("export_in_progress") + "...")
    try {
      if (selectedProject && selectedProject.id) {
        const result = await exportDiary(selectedProject.id)
        console.log("exportDiary", result)
        if (Platform.OS === "web") {
          window.open(result, "_blank")
        } else {
          await openURL(result)
        }
      }
      setStatusSuccess(i18n.t("export_completed") + "!")
    } catch (error) {
      console.error("handleExport err", error)
      setStatusError(i18n.t("export_error"))
    }
  }

  const handleDelete = async () => {
    try {
      if (selectedProject) {
        setVisibleDialog(false)
        setStatusUndetermined(i18n.t("delete") + " " + i18n.t("project").toLowerCase())
        await deleteProject(new Project(selectedProject))
        setStatusSuccess(i18n.t("project") + " " + i18n.t("deleted"))
      }
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }

  return (
    <>
      <OptionsMenu
        onPress={() => {
          if (project) selectProject(project)
        }}
        title={i18n.t("select_action")}
        options={[
          {
            action: handleExport,
            name: i18n.t("export_project"),
            icon: <FontAwesome5 color={"white"} size={24} name={"file-export"} />,
          },
          {
            action: () => setVisibleDialog(true),
            name: i18n.t("delete_project"),
            icon: <FontAwesome name={"trash-o"} color={"white"} size={24} />,
            disabled: !isOperationAuthorized(OPERATION.PROJECT_MANAGE),
          },
        ]}
      ></OptionsMenu>
      <DialogWindow
        visible={visibleDialog}
        onDismiss={() => setVisibleDialog(false)}
        onPress={handleDelete}
        title={`${i18n.t("delete_project")} ${selectedProject?.title}`}
      >
        <AppText>{i18n.t("do_you_want_this_project_removed") + "?"}</AppText>
      </DialogWindow>
    </>
  )
}
