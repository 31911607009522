import { useTheme } from "react-native-paper"
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View } from "react-native"
import AppText from "../general/AppText"
import React from "react"
import { Theme } from "../../../types"
import VerticalDivider from "../general/VerticalDivider"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import { User } from "../../shared/entity/User"
import AvatarImage from "../file/AvatarImage"
import { MARGIN } from "../../constants/style"
import { useUserProvider } from "../../providers/UserProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  navigation: NativeStackNavigationProp<RootStackParamList>
  user?: User
  visible?: boolean
  style?: StyleProp<TextStyle>
}

export default function UserMenuComponent({ navigation, visible, style, user }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { unreadMessages } = useUserProvider()

  return (
    <View style={[{ flexDirection: "row" }, style]}>
      <TouchableOpacity
        onPress={() => {
          if (user && user.id) navigation.navigate(ROUTE.USER_PROFILE, { userId: user.id })
        }}
        style={{
          shadowColor: theme.colors.primary,
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <View style={styles.imageWrapper}>
          <AvatarImage name={user?.name} size={46} imageUri={user?.imageUri} />
          {unreadMessages && (
            <View
              style={{
                backgroundColor: theme.colors.primary,
                position: "absolute",
                bottom: 0,
                right: 0,
                width: 15,
                height: 15,
                borderRadius: 15,
              }}
            />
          )}
        </View>
        <View style={{ flexDirection: "column", marginLeft: MARGIN, marginRight: 15 }}>
          <AppText maxLines={1} style={{ fontWeight: "bold" }}>
            {user?.name || user?.email || i18n.t("username")}
          </AppText>
        </View>
      </TouchableOpacity>
      <VerticalDivider />
    </View>
  )
}

const styles = StyleSheet.create({
  imageWrapper: {
    height: "50%",
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
})
