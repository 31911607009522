import { DocObject } from "../DocObject"
import { Payment } from "./Payment"
import { getDateTime } from "../../utils/date"

export class Transaction extends DocObject {
  payment: Payment

  getLabel(): string {
    const utcSeconds = Number(this.payment.created)
    const date = new Date(0)
    date.setUTCSeconds(utcSeconds)
    return getDateTime({ date: date, format: "MONTHASNUMBER" }) + " " + date.getFullYear()
  }

  getStatus(): string {
    return this.payment.status
  }

  getCreatedDateTime(): Date {
    const utcSeconds = Number(this.payment.created)
    const date = new Date(0)
    date.setUTCSeconds(utcSeconds)
    return date
  }

  constructor(payment: Payment) {
    super()
    this.payment = payment
  }
}
