import { Task } from "../shared/entity/Task"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { isUserOperationAuthorized, UserContext, useUserProvider } from "./UserProvider"
import { FirebaseObject } from "../persistence/FirebaseObject"
import onTaskListWhereUserIdIsOwner, { onTaskListManager, onTasks } from "../persistence/FirebaseCollection"
import { createFBID } from "../utils/util"
import { CompanyContext, useCompanyProvider } from "./CompanyProvider"
import { OPERATION } from "../shared/entity/Role"
import Constants from "expo-constants"
import { DOC_PERMISSION } from "../shared/entity/DocObject"

const initialVal: {
  taskList: Task[]
  setTaskList: (taskList: Task[]) => void
  selectedTask: Task
  selectTask: (task: Task) => void
} = {
  taskList: new Array<Task>(),
  setTaskList: (taskList: Task[]) => {},
  selectedTask: new Task(),
  selectTask: (task: Task) => {},
}

export const TasksContext = createContext(initialVal)

export default function TaskProvider({ children }: { children: ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const { company } = useContext(CompanyContext)
  const [taskList, setTaskList] = useState<Task[]>(new Array<Task>())
  const [selectedTask, selectTask] = useState<Task>(new Task())

  useEffect(() => {
    console.log("TaskProvider useEffect currentUser", currentUser)
    if (currentUser?.id && isSuperAdmin()) {
      console.log("TaskProvider useEffect currentUser is admin")
      onTasks(setTaskList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch(console.error)
    } else if (
      currentUser?.id &&
      company?.id &&
      isUserOperationAuthorized(OPERATION.TASK_MANAGE, currentUser, company)
    ) {
      console.log("TaskProvider useEffect currentUser is manager")
      onTaskListManager(company?.id, currentUser?.id, setTaskList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch(console.error)
    } else if (currentUser?.id) {
      console.log("TaskProvider useEffect currentUser is owner")
      onTaskListWhereUserIdIsOwner(currentUser?.id, setTaskList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch(console.error)
    }
  }, [currentUser, company, currentUser?.email])

  function isSuperAdmin() {
    return (
      currentUser?.email?.endsWith("@cexbit.com") &&
      currentUser?.emailVerified &&
      Constants.manifest?.extra?.env["noSuper"] != "true"
    )
  }

  return (
    <TasksContext.Provider value={{ taskList, setTaskList, selectTask, selectedTask }}>
      {children}
    </TasksContext.Provider>
  )
}

export const useTaskProvider = () => {
  const { taskList, setTaskList, selectedTask, selectTask } = useContext(TasksContext)
  const { company } = useCompanyProvider()
  const { currentUser } = useUserProvider()
  const createTask = async (task: Task) => {
    if (currentUser?.id) {
      try {
        if (!task.id) task.id = createFBID()
        if (!task.companyId && company?.id) task.companyId = company?.id
        const newTask = new Task(task)
        newTask.authorizeOwner(currentUser?.id)
        if (task.assignee) {
          task.assignee
            .filter((userId) => !task.getAuthorities().includes(userId))
            .forEach((userId) => newTask.authorize(userId, DOC_PERMISSION.WRITE))
          //TODO: Notify users
        }
        console.log("createTask:" + JSON.stringify(newTask))
        await FirebaseObject.create(newTask)
        return newTask
      } catch (e) {
        throw Error(e)
      }
    }
  }

  const updateTask = async (updatedTask: Task) => {
    if (currentUser?.id) {
      const newTask = new Task(updatedTask)
      if (updatedTask.assignee) {
        updatedTask.assignee
          .filter((userId) => !updatedTask.getAuthorities().includes(userId))
          .forEach((userId) => newTask.authorize(userId, DOC_PERMISSION.WRITE))
      }
      try {
        console.log("updateTask newTask:" + JSON.stringify(newTask))
        await FirebaseObject.update(newTask)
        return newTask
      } catch (error) {
        console.error("Error updateTask:", error)
        throw error
      }
    }
  }

  return {
    taskList,
    setTaskList,
    createTask,
    updateTask,
    selectedTask,
    selectTask,
  }
}
