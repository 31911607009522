import { callFunction } from "./general"
import { API } from "../shared/apis"

export async function sendUserInvitation(invitationId: string): Promise<boolean> {
  if (!invitationId) throw Error("invitationId must be defined")
  console.debug("sendUserInvitation with params invitationId:", invitationId)
  try {
    const response = await callFunction(API.sendInvitation, {
      invitationId,
    })
    console.debug("sendUserInvitation response:", response)
    const result = response.data
    console.debug("sendUserInvitation result:", result)
    return result
  } catch (error) {
    console.error("Error sendUserInvitation:", error)
    throw error
  }
}
