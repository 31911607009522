import React from "react"
import { createDrawerNavigator, DrawerContentScrollView, DrawerItemList } from "@react-navigation/drawer"
import { StyleSheet, View } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../types"
import { FontAwesome, FontAwesome5, Ionicons } from "@expo/vector-icons"
import { AppVersionComponentAsDrawerItem } from "../components/main/AppVersionComponent"
import { RootDrawerParamList } from "./types"
import { ROUTE } from "./routes"
import { ProjectListScreen } from "../screens/project/ProjectListScreen"
import { SettingsScreen } from "../screens/general/SettingsScreen"
import { InfoScreen } from "../screens/general/InfoScreen"
import { AttendanceScreen } from "../screens/attendance/AttendanceScreen"
import CompanyScreen from "../screens/company/CompanyScreen"
import { SupportScreen } from "../screens/general/SupportScreen"
import { PaymentScreen } from "../screens/payment/PaymentScreen"
import { ArticleScreen } from "../screens/article/ArticleScreen"
import Constants from "expo-constants"
import { useSettingsProvider } from "../providers/SettingsProvider"
import TaskManagerScreen from "../screens/task/TaskManagerScreen"
import { DevScreen } from "../screens/DevScreen"

export default function DrawerNavigator() {
  const Drawer = createDrawerNavigator<RootDrawerParamList>()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()

  return (
    <>
      <Drawer.Navigator
        initialRouteName={
          Constants.manifest?.extra?.env["route"] ? Constants.manifest?.extra?.env["route"] : ROUTE.PROJECT_LIST
        }
        screenOptions={{ drawerStyle: { flex: 1 }, drawerActiveTintColor: theme.colors.primary }}
        drawerContent={(props) => <CustomDrawerContent {...props} />}
      >
        <Drawer.Screen
          name={ROUTE.PROJECT_LIST}
          component={ProjectListScreen}
          options={{
            title: i18n.t("projects"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome5
                  name="drafting-compass"
                  size={theme.iconSize.small}
                  color={focused ? color : theme.colors.primary}
                />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.COMPANY}
          component={CompanyScreen}
          options={{
            title: i18n.t("company"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome
                  name="building"
                  size={theme.iconSize.small}
                  color={focused ? color : theme.colors.primary}
                />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.GENERAL_INFO}
          component={InfoScreen}
          options={{
            title: i18n.t("information"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome5 name="info" size={theme.iconSize.small} color={focused ? color : theme.colors.primary} />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.ARTICLE_LIST}
          component={ArticleScreen}
          options={{
            title: i18n.t("news"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome5
                  name="newspaper"
                  size={theme.iconSize.small}
                  color={focused ? color : theme.colors.primary}
                />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.ATTENDANCE}
          component={AttendanceScreen}
          options={{
            title: i18n.t("attendance"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome5
                  name="clipboard"
                  size={theme.iconSize.small}
                  color={focused ? color : theme.colors.primary}
                />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.GENERAL_TASKS}
          component={TaskManagerScreen}
          options={{
            title: i18n.t("task_manager"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome5 name="tasks" size={theme.iconSize.small} color={focused ? color : theme.colors.primary} />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.GENERAL_PAYMENT}
          component={PaymentScreen}
          options={{
            title: i18n.t("payments"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome5
                  name="credit-card"
                  size={theme.iconSize.small}
                  color={focused ? color : theme.colors.primary}
                />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.GENERAL_SUPPORT}
          component={SupportScreen}
          options={{
            title: i18n.t("support"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome
                  name="support"
                  size={theme.iconSize.small}
                  color={focused ? color : theme.colors.primary}
                />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.GENERAL_SETTINGS}
          component={SettingsScreen}
          options={{
            title: i18n.t("settings"),
            drawerIcon: ({ focused, color }) => (
              <View style={{ width: 30, alignItems: "center" }}>
                <FontAwesome name="gear" size={theme.iconSize.small} color={focused ? color : theme.colors.primary} />
              </View>
            ),
          }}
        />
        <Drawer.Screen
          name={ROUTE.DEV_SCREEN}
          component={DevScreen}
          options={{ drawerItemStyle: { display: "none" } }}
        />
      </Drawer.Navigator>
    </>
  )
}

function CustomDrawerContent(props: any) {
  const { i18n } = useSettingsProvider()
  console.log("CustomDrawerContent")
  const styles = getStyles()
  const items = [
    {
      title: i18n.t("projects"),
      path: "ProjectList",
      Icon: (
        <View style={{ width: 20 }}>
          <FontAwesome5 name="project-diagram" size={18} color="black" />
        </View>
      ),
    },
    {
      title: i18n.t("diary_overview"),
      path: "DiaryOverview",
      Icon: (
        <View style={{ width: 20 }}>
          <Ionicons name="build" size={18} color="black" />
        </View>
      ),
    },
  ]

  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView {...props} style={{ flex: 1 }}>
        <View style={{ flex: 1 }}>
          {/* <List.Accordion
            style={styles.listAccordion}
            titleStyle={[styles.listAccordionTitle, { paddingLeft: 5 }]}
            title={i18n.t("projects")}
          >
            {companyItems.map((item, i) => {
              return (
                <DrawerItem
                  key={i}
                  style={{ marginLeft: 10 }}
                  icon={() => item.Icon}
                  label={item.title}
                  onPress={() => props.navigation.navigate(item.path)}
                />
              )
            })}
          </List.Accordion> */}
          <DrawerItemList {...props} />
        </View>
      </DrawerContentScrollView>

      <AppVersionComponentAsDrawerItem />
    </View>
  )
}

const getStyles = () => {
  return StyleSheet.create({
    listAccIconWrapper: {
      paddingLeft: 10,
      width: 40,
      justifyContent: "center",
    },
    listAccordion: {
      paddingLeft: 5,
      // backgroundColor: DefaultTheme.colors.white,
    },
    listAccordionTitle: {
      paddingLeft: 10,
    },
  })
}
