import React from "react"
import { Theme } from "../../../types"
import { TextInput, useTheme } from "react-native-paper"
import InputBase from "./InputBase"
import { View } from "react-native"
import { MARGIN } from "../../constants/style"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function TextField({
  label,
  value,
  onChange,
  onSave,
  children,
  disabled,
  required = false,
  hintText = undefined,
  errorText = "",
}: {
  label?: string
  value: string
  onChange?: (text: string) => void
  onSave?: () => void
  children?: React.ReactNode
  disabled?: boolean
  required?: boolean
  hintText?: string
  errorText?: string
}) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  return (
    <InputBase
      errorText={errorText}
      noPadding={true}
      label={label}
      hintText={(required ? "* " + i18n.t("required") : "") + (hintText ?? "")}
    >
      <View style={{ flexDirection: "row" }}>
        <TextInput
          onChangeText={onChange}
          onBlur={() => onSave && onSave()}
          style={[{ flexShrink: 1 }, theme.input]}
          contentStyle={{ color: theme.colors.text }}
          mode={"flat"}
          value={value}
          disabled={disabled}
        />
        {children ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              backgroundColor: theme.colors.primary,
              flexShrink: 0,
              flexGrow: 1,
              marginBottom: MARGIN,
            }}
          >
            {children}
          </View>
        ) : undefined}
      </View>
    </InputBase>
  )
}
