import React from "react"
import { View } from "react-native"
import { useTheme } from "react-native-paper"

import { Theme } from "../../../types"

export default function Separator() {
  const theme: Theme = useTheme()

  return (
    <View
      style={{
        width: "100%",
        height: 1,
        backgroundColor: theme.colors.borderPrimary,
      }}
    />
  )
}
