import { Provider } from "react-native-maps"
import React, { View } from "react-native"
import { Iframe } from "@bounceapp/iframe"
import { GOOGLE_API_KEY } from "../../../environments"
import { PlaceLocation } from "../../shared/common/PlaceLocation"

type Props = {
  provider?: Provider
  style?: any
  location?: PlaceLocation
  onDrag: (location: PlaceLocation) => void
}

export default function MapImage({ provider, style, location, onDrag }: Props) {
  const latitude = -33.8569
  const longitude = 151.2152
  const language = "cs"

  //TODO - https://developers.google.com/maps/documentation/javascript/examples/event-click-latlng

  return (
    <View style={[{ flex: 1 }, style]}>
      <Iframe
        style={{ minHeight: 520 }}
        uri={`https://www.google.com/maps/embed/v1/place?q=${location?.coordinates?.latitude || latitude},${
          location?.coordinates?.longitude || longitude
        }&key=${GOOGLE_API_KEY}&language=${language}&zoom=15`}
      />
    </View>
  )
}
