import axios from "axios"

import { getFunctionUrl, getHeadersWithoutToken } from "./general"
import { API } from "../shared/apis"
import { AppInfo } from "../shared/entity/AppInfo"

export async function fetchAppInfo() {
  try {
    const headers = await getHeadersWithoutToken()
    const response = await axios.post(getFunctionUrl(API.appInfo), {}, { headers })
    console.debug("fetchApp:" + JSON.stringify(response))
    return new AppInfo(response.data)
  } catch (error) {
    console.error("Error fetchCompanyDetailFromAres:", error)
    throw error
  }
}
