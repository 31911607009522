// TODO all codes here https://firebase.google.com/docs/auth/admin/errors
export const errorCodes = new Map([
  ["auth/user-disabled", "auth_user_disabled"],
  ["auth/email-already-in-use", "auth_email_already_in_use"],
  ["auth/invalid-email", "auth_invalid-email"],
  ["auth/operation-not-allowed", "auth_operation_not_allowed"],
  ["auth/weak-password", "auth_weak_password"],
  ["auth/user-not-found", "auth_user_not_found"],
  ["auth/wrong-password", "auth_wrong_password"],
])
