import React, { createContext, useContext, useEffect, useState } from "react"
import { CurrentUser, User, UserStatus } from "../shared/entity/User"
import {
  browserLocalPersistence,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  EmailAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  updatePassword,
} from "firebase/auth"
import { auth, firestore } from "../persistence/FirebaseConfig"
import { FirebaseObject } from "../persistence/FirebaseObject"
import { errorCodes } from "../constants/ErrorMessages"
import {
  setPersistence as firebaseSetPersistence,
  signInAnonymously,
  signOut as firebaseSignOut,
  User as AuthUser,
} from "@firebase/auth"
import { ChangePasswordFormType } from "../shared/common/Types"
import { collection, getDocs, query, where } from "firebase/firestore"
import { useStatusProvider } from "./StatusProvider"
import { onUser, onUserMessage } from "../persistence/FirebaseCollection"
import { OPERATION } from "../shared/entity/Role"
import { CompanyContext } from "./CompanyProvider"
import { isEmailValid, isPasswordValid } from "../shared/utils/validations"
import Constants from "expo-constants"
import { Company } from "../shared/entity/Company"
import { UserMessage } from "../shared/entity/UserMessage"
import { useSettingsProvider } from "./SettingsProvider"

const initialVal: {
  currentUser: CurrentUser | undefined
  userMessages: UserMessage[]
  setUserMessages: (messages: UserMessage[]) => void
  unreadMessages: boolean
  setUnreadMessages: (anyUnread: boolean) => void
  sendMessage: (message: UserMessage) => void
} = {
  currentUser: undefined,
  userMessages: [],
  setUserMessages: () => {},
  unreadMessages: false,
  setUnreadMessages: (anyUnread: boolean) => {},
  sendMessage: () => {},
}

export const UserContext = createContext(initialVal)

export default function UserProvider({ children }: { children: React.ReactNode }) {
  const [currentUser, setCurrentUser] = useState<CurrentUser | undefined>()
  const [userMessages, setUserMessages] = useState<UserMessage[]>([])
  const [unreadMessages, setUnreadMessages] = useState(false)

  useEffect(() => {
    console.debug("currentUser: ", currentUser)
    //isTutorialDisabled().then((result) => setShowTutorial(result))

    const unsubscribe = onAuthStateChanged(
      auth,
      async (authUser) => {
        console.debug("onAuthStateChanged: authUser:" + JSON.stringify(authUser))
        console.info("onAuthStateChanged: authUser: " + (authUser ? "isSigned" : "isNotSigned"))
        if (authUser) {
          onUser(authUser.uid, (user) => userSetter(user, authUser))
            .then((unsubscribe) => {
              return () => unsubscribe()
            })
            .catch((e) => console.error(e))
        } else {
          setCurrentUser(undefined)
        }
      },
      (err) => console.error("Error onAuthStateChanged:", err),
      () => console.log("Complete onAuthStateChanged:")
    )
    return () => {
      unsubscribe()
    }
  }, [])

  function userSetter(user: User | undefined, authUser: AuthUser) {
    console.log(`userSetter user: ${JSON.stringify(user)}, auth: ${JSON.stringify(authUser)}`)
    // TODO create user if authUser but not exists in db
    if (authUser) {
      let newCurrentUser = new CurrentUser()
      if (user) {
        newCurrentUser = new CurrentUser({ ...user })
      }
      newCurrentUser.id = authUser.uid
      newCurrentUser.emailVerified = authUser.emailVerified
      newCurrentUser.anonym = authUser.isAnonymous
      setCurrentUser(newCurrentUser)
      console.log("userSetter mergeCompanyFromDb", newCurrentUser)
    } else {
      setCurrentUser(undefined)
    }
  }

  useEffect(() => {
    if (currentUser && currentUser.id) {
      console.log("useUserProvider useEffect onUserMessage")
      onUserMessage(currentUser.id, (messages) => {
        setUnreadMessages(messages.some((m) => !m.isRead))
        console.log("onUserMessage", messages)

        setUserMessages(messages)
      })
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  function sendMessage(message: UserMessage) {
    message?.authorizeOwner(message.userId)
    FirebaseObject.create(message)
  }

  return (
    <UserContext.Provider
      value={{
        currentUser,
        setUserMessages,
        userMessages,
        setUnreadMessages,
        unreadMessages,
        sendMessage,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUserProvider = () => {
  const { i18n } = useSettingsProvider()
  const { setStatusError, setStatusSuccess, setStatusUndetermined } = useStatusProvider()
  const { currentUser, userMessages, unreadMessages, sendMessage } = useContext(UserContext)
  const { company } = useContext(CompanyContext)

  /**
   * Register new user
   *
   * @param email
   * @param password
   * @param name
   * @param phoneNumber
   */
  async function registerUser(email: string, password: string, name?: string, phoneNumber?: string): Promise<boolean> {
    if (!email || !isEmailValid(email)) throw Error("registerUser: email není validní")
    if (!password || !isPasswordValid(password)) throw Error("registerUser: password není validní")
    console.debug("registerUser with email: ", email, " name:", name, "phoneNumber:", phoneNumber)
    await setPersistence()
    try {
      setStatusUndetermined("Vytvářím uživatele s emailovou adresou " + email)
      const userCredential = await createUserWithEmailAndPassword(auth, email, password)
      const newUser: User = new User({
        id: userCredential.user.uid,
        email: userCredential.user.email || email,
        phoneNumber: phoneNumber,
        name: name,
        status: UserStatus.ACTIVE,
      })
      if (currentUser?.id) newUser.authorizeOwner(currentUser.id)
      await FirebaseObject.create(newUser)
      // await sendEmailVerification(userCredential.user)
      setStatusSuccess("Uživatel vytvořen")
      return true
    } catch (error) {
      console.error("Error registerUser:", error)
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
      return false
    }
  }

  /**
   * SignIn via firebase auth and update currentUser state
   *
   * @param {string} email - email
   * @param {string} password - password
   *
   * @exception Error Codes:
   * <ul>
   * <li>auth/email-already-in-use</li>
   *        Thrown if there already exists an account with the given email address.
   * <li>auth/invalid-email</li>
   *        Thrown if the email address is not valid.
   * <li>auth/operation-not-allowed</li>
   *        Thrown if email/password accounts are not enabled. Enable email/password
   *        accounts in the Firebase Console, under the Auth tab.
   * <li>auth/weak-password</li>
   *        Thrown if the password is not strong enough.
   * </ul>
   */
  async function signIn(email: string, password: string): Promise<boolean> {
    try {
      console.debug("signIn: setPersistence:")
      await setPersistence()
      const userCredential = await signInWithEmailAndPassword(auth, email, password)
      console.debug("signIn: userCredential: ", JSON.stringify(userCredential))
      return true
    } catch (error) {
      console.error("signIn: error: ", error)
      throw error
    }
  }

  async function signInAnonym(): Promise<boolean> {
    try {
      console.debug("signInAnonym")
      const userCredential = await signInAnonymously(auth)
      console.debug("signInAnonym: ", JSON.stringify(userCredential))
      return true
    } catch (error) {
      console.error("signIn: error: ", error)
      throw error
    }
  }

  async function signOut(): Promise<void> {
    try {
      await firebaseSignOut(auth)
      console.log("signOut")
    } catch (error) {
      console.error("signOut err", error)
    }
  }

  /**
   * Send verification email
   *
   * @returns {object}
   * @throws {Error}
   */
  async function resendVerificationEmail() {
    try {
      if (auth.currentUser) {
        await sendEmailVerification(auth.currentUser)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async function sendResetLink(email: string): Promise<void> {
    // const actionCodeSettings = {
    //   url: "https://stavx.cz/resetpassword?email=" + email,
    //   iOS: {
    //     bundleId: "com.cexbit.stavx",
    //   },
    //   android: {
    //     packageName: "com.cexbit.stavx",
    //     installApp: true,
    //     minimumVersion: "12",
    //   },
    //   handleCodeInApp: true,
    //   dynamicLinkDomain: "https://stavx.cz",
    // }
    await sendPasswordResetEmail(auth, email)
    //await confirmPasswordReset('user@example.com', code);
  }

  async function confirmResetPassword(email: string, code: string, password: string): Promise<void> {
    await confirmPasswordReset(auth, code, password)
  }

  async function changePassword(passwordForm: ChangePasswordFormType) {
    try {
      if (
        !passwordForm.password ||
        !passwordForm.passwordAgain ||
        passwordForm.password !== passwordForm.passwordAgain
      ) {
        throw new Error("Hesla nejsou stejná")
      }
      setStatusUndetermined("Měním heslo pro email: " + auth.currentUser?.email)

      if (auth.currentUser && auth.currentUser.email) {
        const result = await reauthenticateWithCredential(
          auth.currentUser,
          EmailAuthProvider.credential(auth.currentUser.email, passwordForm.oldPassword)
        )
        console.info("changePassword: reauthenticateWithCredential: " + JSON.stringify(result))
        if (result.user != null) {
          await updatePassword(auth.currentUser, passwordForm.password)
          setStatusSuccess("Heslo změněno")
        } else {
          setStatusError("Heslo nezměněno")
        }
      }
    } catch (error) {
      console.error("changePassword:" + error.message)
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }

  async function createUser(user: User): Promise<void> {
    console.debug("createUser:", user)
    if (!user.email || !isEmailValid(user.email)) throw Error("registerUser: email není validní")
    if (!user.password || !isPasswordValid(user.password)) throw Error("registerUser: password není validní")
    console.debug("registerUser with email: ", user.email, " name:", user.name, "phoneNumber:", user.phoneNumber)
    try {
      const userCredential = await createUserWithEmailAndPassword(auth, user.email, user.password)
      const newUser = new User({
        id: userCredential.user.uid,
        status: UserStatus.ACTIVE,
        email: userCredential.user.email,
        phoneNumber: user.phoneNumber,
        name: user.name,
      })
      if (currentUser?.id) newUser.authorizeOwner(currentUser?.id)
      await FirebaseObject.create(newUser)
    } catch (error) {
      console.error("Error createUser:", error)
      throw error
    }
  }

  async function readUser(userId: string): Promise<User> {
    return await FirebaseObject.readById<User>(userId, "User", (data) => new User({ ...data }))
  }

  async function updateUser(user: User): Promise<void> {
    await FirebaseObject.update(user)
  }

  // soft delete keep in auth as disabled
  async function deleteUser(user: User): Promise<void> {
    await FirebaseObject.softDelete(user)
  }

  async function disableUser(email: string, password: string) {
    const credential = EmailAuthProvider.credential(email, password)
    console.log("credential ", credential)
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    await reauthenticateWithCredential(userCredential.user, credential)
    if (auth.currentUser) {
      await FirebaseObject.merge<User>(new User({ id: auth.currentUser.uid }), "status", UserStatus.DISABLED)
      await signOut()
    }
  }

  /**
   * Find user in database via email
   *
   * @param email string
   */
  async function isUserWithEmail(email: string): Promise<boolean> {
    console.debug("isUserWithEmail:", email)
    try {
      const q = query(collection(firestore, "User"), where("email", "==", email))
      const querySnapshot = await getDocs(q)
      return querySnapshot.size > 0
    } catch (error) {
      console.error("Error isUserWithEmail:", error)
      throw error
    }
  }

  async function setPersistence(): Promise<void> {
    console.debug("setPersistence:")
    try {
      await firebaseSetPersistence(auth, browserLocalPersistence)
    } catch (error) {
      console.error("Error setPersistence:", error)
    }
  }

  function isSuperAdmin() {
    return (
      currentUser?.email?.endsWith("@cexbit.com") &&
      currentUser?.emailVerified &&
      Constants.manifest?.extra?.env["noSuper"] != "true"
    )
  }

  function isOperationAuthorized(operation: OPERATION) {
    console.log("isOperationAuthorized", operation)
    if (isSuperAdmin()) return true
    if (company?.systemRoles && currentUser?.id && company.getPermissions(currentUser?.id)) {
      const systemRoleNamesWithOperation = company?.systemRoles
        ?.filter((systemRole) => systemRole.operationList?.includes(operation))
        .map((systemRole) => systemRole.name)
      if (systemRoleNamesWithOperation) {
        return company
          .getPermissions(currentUser?.id)
          ?.some((userRole) => systemRoleNamesWithOperation.includes(userRole))
      }
    }
    return false
  }

  return {
    currentUser,
    createUser,
    registerUser,
    signIn,
    signInAnonym,
    sendResetLink,
    updateUser,
    signOut,
    deleteUser,
    readUser,
    disableUser,
    resendVerificationEmail,
    changePassword,
    isUserWithEmail,
    isSuperAdmin,
    isOperationAuthorized,
    userMessages,
    unreadMessages,
    sendMessage,
  }
}

export function isUserSuperAdmin(user: User) {
  return user?.email?.endsWith("@cexbit.com") && Constants.manifest?.extra?.env["noSuper"] != "true"
}

export function isUserOperationAuthorized(operation: OPERATION, user: User, company: Company) {
  console.log("isOperationAuthorized", operation)
  if (isUserSuperAdmin(user)) return true
  if (company?.systemRoles && user?.id && company.getPermissions(user?.id)) {
    const systemRoleNamesWithOperation = company?.systemRoles
      ?.filter((systemRole) => systemRole.operationList?.includes(operation))
      .map((systemRole) => systemRole.name)
    if (systemRoleNamesWithOperation) {
      return company.getPermissions(user?.id)?.some((userRole) => systemRoleNamesWithOperation.includes(userRole))
    }
  }
  return false
}
