import React from "react"
import { View } from "react-native"
import { ActivityIndicator } from "react-native-paper"

export default function LoadingComponent() {
  return (
    <View>
      <ActivityIndicator animating />
    </View>
  )
}
