import React, { createContext, useContext, useEffect, useState } from "react"
import { UserContext } from "./UserProvider"
import { Device } from "../shared/entity/Device"
import { Camera } from "expo-camera"
import { Platform } from "react-native"

const initialVal: {
  device: Device
  setDevice: (device: Device) => void
  cameraAvailable: boolean
  checkCamera: () => Promise<void>
} = {
  device: new Device(),
  setDevice: (device: Device) => {},
  cameraAvailable: false,
  checkCamera: () => new Promise(null),
}

export const DeviceContext = createContext(initialVal)

export default function DeviceProvider({ children }: { children: React.ReactNode }) {
  const { currentUser, setCurrentUser } = useContext(UserContext)
  const [device, setDevice] = useState<Device>(new Device())
  const [cameraAvailable, setCameraAvailable] = useState(true)
  const [permission, requestPermission] = Camera.useCameraPermissions()

  useEffect(() => {}, [])

  const checkCamera = async () => {
    if (!permission) await requestPermission()
    if (Platform.OS === "web") Camera.getAvailableCameraTypesAsync().then((type) => setCameraAvailable(type.length > 0))
  }

  return (
    <DeviceContext.Provider
      value={{
        device,
        setDevice,
        cameraAvailable,
        checkCamera,
      }}
    >
      {children}
    </DeviceContext.Provider>
  )
}

export const useDeviceProvider = () => {
  const { device, cameraAvailable, checkCamera } = useContext(DeviceContext)
  const { currentUser } = useContext(UserContext)

  return { device, cameraAvailable, checkCamera }
}
