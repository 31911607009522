import React from "react"
import { StyleProp, useWindowDimensions, View, ViewStyle } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"

type Props = {
  children?: Element | Element[] | any
  style?: StyleProp<ViewStyle>
  row?: boolean
}

export default function Container({ children, style, row }: Props) {
  const theme: Theme = useTheme()
  const { width } = useWindowDimensions()

  return (
    <View style={[{ padding: theme.padding }, { alignItems: "center" }, style]}>
      <View
        style={[
          width > 800 ? { width: 500 } : { width: "100%" },
          row && { flexDirection: "row", justifyContent: "space-between" },
        ]}
      >
        {children}
      </View>
    </View>
  )
}
