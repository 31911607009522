import { MARGIN, RADIUS } from "../../constants/style"
import AppText from "../general/AppText"
import { useTheme } from "react-native-paper"
import { StyleProp, View, ViewStyle } from "react-native"
import React from "react"
import { Theme } from "../../../types"
import ProductDescriptionCard from "./ProductDescriptionCard"
import { Product } from "../../shared/entity/payment/Product"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type ProductCardProps = {
  disablePayButton?: boolean
  product: Product
  action: (id: string, amount: number) => void
  actionButtonTitle?: string
  style?: StyleProp<ViewStyle>
  withInterval?: boolean
  withQuantity?: boolean
}

export default function ProductCard({
  product,
  withInterval,
  style,
  withQuantity,
  action,
  disablePayButton,
  actionButtonTitle,
}: ProductCardProps) {
  const theme: Theme = useTheme()

  return (
    <View
      style={[
        {
          flexDirection: "column",
          borderRadius: RADIUS,
          borderColor: theme.colors.primary,
          borderWidth: 2,
          margin: MARGIN,
        },
        style,
      ]}
      key={product.getLabel()}
    >
      <View
        style={{
          backgroundColor: theme.colors.primary,
          flexGrow: 1,
          flexShrink: 1,
          padding: MARGIN,
          borderTopLeftRadius: RADIUS / 2,
          borderTopRightRadius: RADIUS / 2,
        }}
      >
        <AppText style={{ color: theme.colors.white }} key={product.name} type={"regular"}>
          {product.name}
        </AppText>
      </View>
      <View style={{ margin: MARGIN }}>
        {product.prices && (
          <ProductDescriptionCard
            key={product.prices[0].getLabel()}
            disablePayButton={disablePayButton}
            entry={product.prices[0]}
            withInterval={withInterval}
            withQuantity={withQuantity}
            action={action}
            actionButtonTitle={actionButtonTitle}
          />
        )}
      </View>
    </View>
  )
}
