// @ts-nocheck
import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg width={81} height={73} viewBox="0 0 81 73" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.7951 8.88948H13.4802C10.5559 8.88948 8.18538 11.26 8.18538 14.1843V59.7102C8.18538 62.6345 10.5559 65.0051 13.4802 65.0051H38.7951C41.7193 65.0051 44.0899 62.6345 44.0899 59.7102V50.6025H52.0321V59.7102C52.0321 67.0208 46.1057 72.9473 38.7951 72.9473H13.4802C6.16958 72.9473 0.243164 67.0208 0.243164 59.7102V14.1843C0.243164 6.87369 6.16958 0.947266 13.4802 0.947266H38.7951C46.1057 0.947266 52.0321 6.87369 52.0321 14.1843V23.2921H44.0899V14.1843C44.0899 11.26 41.7193 8.88948 38.7951 8.88948ZM79.1592 34.3172L66.9782 22.0576C65.5336 20.6032 63.1914 20.6032 61.7467 22.0576C60.3021 23.5119 60.3021 25.8699 61.7467 27.3243L67.6128 33.2264L23.5215 33.2269C21.4785 33.2269 19.8223 34.8943 19.8223 36.951C19.8223 39.0078 21.4785 40.6752 23.5215 40.6752L67.6128 40.6747L61.7467 46.5803C60.3021 48.0346 60.3021 50.3926 61.7467 51.847C63.1914 53.3014 65.5336 53.3014 66.9782 51.847L79.1592 39.5839C80.6038 38.1295 80.6038 35.7715 79.1592 34.3172Z"
      fill="#F39719"
    />
  </Svg>
)
export default SvgComponent
