import React from "react"
import { NavigationProp } from "@react-navigation/native"
import { TouchableOpacity } from "react-native"
import AppText from "./AppText"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"

type Props = { to: ROUTE; title: string; navigation: NavigationProp<RootStackParamList> }

export default function AppLink({ to, title, navigation }: Props) {
  return (
    <TouchableOpacity onPress={() => navigation.navigate(to)}>
      <AppText noPadding link>
        {title}
      </AppText>
    </TouchableOpacity>
  )
}
