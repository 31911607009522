import { DocObject } from "./DocObject"

export class MemoRoom extends DocObject {
  name = ""
  hidden = false
  constructor(data?: Partial<MemoRoom>) {
    super()
    this.assign(data)
  }
}
