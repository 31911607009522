import Screen from "../../components/general/Screen"
import { ScrollView } from "react-native"
import MaxWidth from "../../components/container/MaxWidth"
import { OPERATION } from "../../shared/entity/Role"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import { News } from "../../shared/common/News"
import { MemoRoom } from "../../shared/entity/MemoRoom"
import { Memo } from "../../shared/entity/Memo"
import TextField from "../../components/input/TextField"
import { TextInput } from "../../components/input/TextInput"
import React, { useEffect, useState } from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { useUserProvider } from "../../providers/UserProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { useWorldProvider } from "../../providers/WorldProvider"
import { useMemoProvider } from "../../providers/MemoProvider"
import Button from "../../components/button/Button"
import { showMessage } from "react-native-flash-message"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.ARTICLE_DETAIL>

export default function ArticleDetailScreen({ navigation, route }: Props) {
  const { isOperationAuthorized } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const [article, setArticle] = useState(new News())
  const { publishArticle, updateArticle } = useWorldProvider()
  const { createRoom, sendMemo } = useMemoProvider()

  useEffect(() => {
    if (route.params?.article) setArticle(route.params.article)
  }, [route, navigation])

  return (
    <Screen>
      <ScrollView>
        <MaxWidth>
          {isOperationAuthorized(OPERATION.SUPERADMIN) && (
            <ExpandableCard closable={false} startOpen name={i18n.t("create_article")}>
              <TextField
                value={article.title}
                onChange={(v) => {
                  setArticle(new News({ ...article, title: v }))
                }}
                label={i18n.t("headline")}
              />
              <TextField
                onChange={(v) => {
                  setArticle(new News({ ...article, publishNote: v }))
                }}
                value={article.publishNote}
                label={i18n.t("publishing_note")}
              />
              <TextInput
                onChangeText={(v) => {
                  setArticle(new News({ ...article, description: v }))
                }}
                multiline={true}
                style={{ height: 100 }}
                value={article.description}
                label={i18n.t("description")}
              />
              <TextInput
                onChangeText={(v) => {
                  setArticle(new News({ ...article, text: v }))
                }}
                multiline={true}
                style={{ height: 200 }}
                value={article.text}
                label={i18n.t("article")}
              />
              {!article.id ? (
                <Button
                  title={i18n.t("publish")}
                  onPress={() => {
                    article.link = "https://www.stavx.cz/#" + article.id
                    publishArticle(
                      new News({
                        ...article,
                        content: `<div id="${article.id}"><h3>${article.title}</h3><i>${
                          article.publishNote
                        }</i><p>${article.text.replace("\n", "<br>")}</p></div>`,
                      })
                    )
                    createRoom(new MemoRoom({ id: article.id, name: article.title }))
                      .then((room) => {
                        sendMemo(
                          new Memo({
                            id: article.id,
                            renderer: "ArticleRenderer",
                            roomId: room.id,
                            extra: article.id,
                            text: article.description,
                            timestamp: Date.now(),
                          })
                        )
                      })
                      .then(() => navigation.navigate(ROUTE.ARTICLE_LIST))
                  }}
                />
              ) : (
                <Button
                  title={i18n.t("update")}
                  onPress={async () => {
                    await updateArticle(article)
                    showMessage({ message: i18n.t("changes_saved"), type: "success" })
                  }}
                />
              )}
            </ExpandableCard>
          )}
        </MaxWidth>
      </ScrollView>
    </Screen>
  )
}
