import { dateFromDB, DocObject } from "./DocObject"

export class Task extends DocObject {
  title = "task"
  status?: TASK_STATUS = TASK_STATUS.OPEN
  participants?: string[]
  assignee?: string[]
  description?: string
  end?: Date
  start?: Date
  duration?: number
  expectedEnd?: Date
  projectId?: string
  companyId?: string

  constructor(params?: Partial<Task>) {
    super(params)
    this.assign(params)
  }

  assign(data: any) {
    super.assign(data)
    for (const key in data) {
      if (key == "start") this.start = dateFromDB(data["start"])
      if (key == "end") this.end = dateFromDB(data["end"])
      if (key == "expectedEnd") this.expectedEnd = dateFromDB(data["expectedEnd"]) || new Date()
    }
  }

  getLabel(): string {
    return this.title
  }
}

export enum TASK_STATUS {
  OPEN = "open",
  IN_PROGRESS = "in_progress",
  FINISHED = "finished",
  CLOSED = "closed",
}
