import { CustomEntry } from "../../entry/Entry"

export class LanguageSettings extends CustomEntry {
  language?: string

  constructor(params?: Partial<LanguageSettings>) {
    super(params)
    Object.assign(this, params)
  }
}

export enum LANGUAGE {
  cs = "czech",
  en = "english",
}
