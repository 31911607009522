import React from "react"
import { TouchableOpacity } from "react-native"
import { Checkbox } from "react-native-paper"
import AppText from "../general/AppText"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function AppCheckbox({
  checked,
  onPress,
  disabled = false,
  label,
}: {
  checked: boolean
  onPress: () => void
  disabled?: boolean
  label: string
}) {
  const { i18n } = useSettingsProvider()

  return (
    <TouchableOpacity onPress={onPress} disabled={disabled} style={{ flexDirection: "row", alignItems: "center" }}>
      <Checkbox status={checked ? "checked" : "unchecked"} disabled={disabled} />
      <AppText style={{ paddingBottom: 0, marginLeft: 5 }}>{label}</AppText>
    </TouchableOpacity>
  )
}
