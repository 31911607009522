import { SafeAreaView } from "react-native"
import AppText from "../general/AppText"
import React from "react"
import Constants from "expo-constants"
import { DrawerItem } from "@react-navigation/drawer"

export default function AppVersionComponent() {
  return (
    <SafeAreaView style={{ justifyContent: "center", alignItems: "center" }}>
      <AppText> {getInfoText()} </AppText>
    </SafeAreaView>
  )
}

export function AppVersionComponentAsDrawerItem() {
  return <DrawerItem onPress={() => {}} label={getInfoText()} />
}

function getInfoText(): string {
  let infoText =
    `${Constants.manifest?.extra?.environment}` +
    `[${Constants.manifest?.extra?.projectId}] - ` +
    `v${Constants.manifest?.version}`
  const commitId: string = Constants.manifest?.extra?.commitId
  if (commitId) infoText += `[${commitId}]`
  const buildDate: string = Constants.manifest?.extra?.buildDate
  if (buildDate) infoText += `[${buildDate}]`
  return infoText
}
