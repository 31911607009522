import { IExporter, ITableExportable } from "../export/export"
import { CustomEntry } from "./Entry"
import { stringFrom } from "../common/CustomInputDefinition"

export class MaterialEntryData extends CustomEntry implements ITableExportable {
  constructor(data?: any) {
    super(data)
    Object.assign(this, data)
  }

  fieldNames(): string[] {
    return this.definitions.map((d) => d.label)
  }

  exportTableData(exporter: IExporter, heads: string[]) {
    for (const head of heads) {
      const definition = this.definitions.find((d) => d.label == head)
      if (!definition) continue
      exporter.addCellText(stringFrom(this.customFields[head], definition))
    }
  }
}
