import React from "react"
import Screen from "../../components/general/Screen"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import MaxWidth from "../../components/container/MaxWidth"
import { ScrollView } from "react-native"
import { ShowAttendance } from "../../components/attendance/ShowAttendance"
import { LogAttendance } from "../../components/attendance/LogAttendance"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.ATTENDANCE_LIST>

//this is unused, consider removing
export function AttendanceList({ route, navigation }: Props) {
  return (
    <Screen>
      <ScrollView style={{ flexShrink: 0 }}>
        <MaxWidth>
          <LogAttendance />
          <ShowAttendance />
        </MaxWidth>
      </ScrollView>
    </Screen>
  )
}
