import { TouchableOpacity, View } from "react-native"
import React from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "./types"
import AppText from "../components/general/AppText"
import { FontAwesome5 } from "@expo/vector-icons"

type Props = Omit<NativeStackScreenProps<RootStackParamList>, "route"> & { path: Array<[string, () => void]> }

export function pathRenderer(params: Props) {
  params.navigation.setOptions({
    headerShown: true,
    headerTitle: () => (
      <View
        style={{
          flexDirection: "row",
          flexWrap: "wrap",
          overflow: "hidden",
          alignItems: "center",
          flexGrow: 0,
          flexShrink: 1,
          marginRight: 50,
          marginLeft: -20,
          maxWidth: "90%",
        }}
      >
        {params.path.slice(-2).map((p, i) => (
          <TouchableOpacity key={i} style={{ flexDirection: "row", alignItems: "center" }} onPress={p[1]}>
            {i != 0 && (
              <FontAwesome5 color={"#F39719"} style={{ opacity: 0.5, marginHorizontal: 5 }} name={"angle-right"} />
            )}
            <AppText style={{ flexShrink: 1 }} ellipsizeMode={"middle"} noPadding>
              {p[0]}
            </AppText>
          </TouchableOpacity>
        ))}
      </View>
    ),
  })
}
