import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { IconSource } from "react-native-paper/lib/typescript/src/components/Icon"
import { StyleProp, View, ViewStyle } from "react-native"
import React, { useEffect, useRef, useState } from "react"
import ChipSelector from "./ChipSelector"
import ModalList from "../flatlist/ModalList"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type ChipPickerProps = {
  prop?: string | undefined
  values: string[] | undefined
  data: [] | Record<string, any>
  label?: string
  icons?: IconSource[]
  editable?: boolean
  style?: StyleProp<ViewStyle>
  withModal?: boolean
  isMultipleSelect?: boolean
  onChange?: (prop: string | undefined, values: any) => void
}
//https://pictogrammers.com/library/mdi/
export default function ChipPicker({
  onChange,
  isMultipleSelect,
  withModal = false,
  editable = true,
  icons,
  style,
  label,
  values = [],
  data,
  prop,
}: ChipPickerProps) {
  const [showModal, setShowModal] = useState(false)
  const [itemIcons, setItemIcons] = useState<IconSource[]>()
  const [isHovered, setIsHovered] = useState(false)
  const newArray = new Array<string>(...values)
  const chipRef = useRef<View>(null)
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  let dataArray: { label: string; value: any }[]

  if (data instanceof Array) {
    dataArray = data
  } else {
    dataArray = Object.entries(data).map(([val, lab]) => ({
      label: lab,
      value: val,
    }))
  }
  useEffect(() => {
    if (icons && icons.length == 1 && icons.length < data.length) {
      const array: IconSource[] = []
      for (array.length; array.length == data.length; ) {
        array.push(icons[0])
      }
      setItemIcons(array)
    } else if (icons && icons.length == data.length) {
      setItemIcons(icons)
    }
  }, [icons])

  const openModal = () => {
    setShowModal(true)
  }
  const closeModal = () => {
    setShowModal(false)
  }

  const handleMouseEnter = () => setIsHovered(true)
  const handleMouseLeave = () => setIsHovered(false)

  const onPress = (value: string) => {
    if (newArray.includes(value)) {
      const updatedArray: string[] = newArray.filter((v) => value !== v)
      console.log("updatedArray", updatedArray)
      if (onChange) onChange(prop, updatedArray)
    } else {
      newArray.push(value)
      console.log("updatedArray", newArray)
      if (onChange) onChange(prop, newArray)
    }
  }

  const isSelected = (value: string): boolean => {
    return newArray.includes(value)
  }

  return (
    <>
      <View style={{ flexDirection: "row", flexWrap: "wrap", flexGrow: 1, flexShrink: 1 }}>
        {withModal ? (
          <>
            {values &&
              values.map((item, index) => (
                <ChipSelector
                  ref={chipRef}
                  key={item + index}
                  icon={isHovered ? "close-thick" : itemIcons ? itemIcons[index] : "circle"}
                  style={[
                    {
                      backgroundColor: theme.colors.darkBlue,
                    },
                    style,
                  ]}
                  onPress={() => editable && onPress(item)}
                  label={item}
                />
              ))}
          </>
        ) : (
          <>
            {dataArray &&
              dataArray
                .filter((item) =>
                  !editable ? newArray.some((filterItem) => item.value.includes(filterItem)) : dataArray
                )
                .map((item, index) => (
                  <ChipSelector
                    ref={chipRef}
                    key={item.label + index}
                    icon={isHovered ? "close-thick" : itemIcons ? itemIcons[index] : "circle"}
                    style={[
                      {
                        backgroundColor: isSelected(item.value) ? theme.colors.darkBlue : theme.colors.lightBlue,
                        opacity: isSelected(item.value) ? 1 : 0.7,
                        borderColor: theme.colors.darkBlue,
                      },
                      style,
                    ]}
                    onPress={() => editable && onPress(item.value)}
                    label={item.label}
                  />
                ))}
          </>
        )}
        {withModal && editable && values.length != dataArray.length && (
          <ChipSelector
            style={[
              {
                backgroundColor: theme.colors.green,
              },
              style,
            ]}
            key={"newItem"}
            icon={"plus-thick"}
            onPress={openModal}
            label={i18n.t("add")}
          />
        )}
      </View>
      <ModalList
        isMultipleSelect
        visible={showModal}
        label={label}
        values={values}
        data={dataArray}
        onClose={closeModal}
        selected={(val: string) => {
          if (!isMultipleSelect) {
            console.log(val)
            closeModal()
          }
        }}
      />
    </>
  )
}
