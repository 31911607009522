import { getApps, initializeApp, setLogLevel } from "firebase/app"
import { connectFirestoreEmulator, Firestore, getFirestore } from "firebase/firestore"
import { Auth, connectAuthEmulator, getAuth } from "firebase/auth"
import { connectFunctionsEmulator, Functions, getFunctions } from "firebase/functions"
import { connectStorageEmulator, FirebaseStorage, getStorage } from "firebase/storage"

import Constants from "expo-constants"
import { ENVIRONMENT } from "../shared/constants/enum"
import firebase from "firebase/compat/app"

/* eslint-disable */
let firestore: Firestore
let auth: Auth
let functions: Functions
let storage: FirebaseStorage

function configure() {
  console.debug("Constants:" + JSON.stringify(Constants))
  const environment = Constants.expoConfig?.extra?.environment
  console.debug(`configure env: __${environment}__`)

  const app = initializeApp(Constants.manifest?.extra?.FIREBASE_CONFIG)
  firestore = getFirestore(app)
  auth = getAuth(app)
  functions = getFunctions(app)
  storage = getStorage(app)

  if (environment === ENVIRONMENT.EMU) {
    console.log(`__${environment}__: initializing connection to firebase emulator`)
    connectFirestoreEmulator(firestore, "localhost", 8181)
    connectAuthEmulator(auth, "http://localhost:9099")
    connectFunctionsEmulator(functions, "localhost", 5001)
    connectStorageEmulator(storage, "localhost", 9199)
  } else {
    console.log(`__${environment}__ - initializing connection to live firestore`)
  }

  console.log("firebase.getApps: " + JSON.stringify(getApps()))
  //  auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  setLogLevel("info")
  // TODO all log level needs to be bind to variable and environment

  return true
}

export { firebase, firestore, auth, storage, functions, configure }
