import React, { Image, Linking, Platform, StyleSheet, TouchableOpacity, View } from "react-native"
import { useTheme } from "react-native-paper"
import AppText from "../../components/general/AppText"
import Screen from "../../components/general/Screen"
import { Theme } from "../../../types"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import MaxWidth from "../../components/container/MaxWidth"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function AboutScreen() {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()

  const handleOpenUrl = async (url: string) => {
    if (Platform.OS === "web") {
      window.open(url, "_blank")
    } else {
      await Linking.openURL(url)
    }
  }

  return (
    <Screen>
      <MaxWidth>
        <View style={[styles.companyWrapper, theme.shadows, { backgroundColor: theme.colors.background }]}>
          <View style={[styles.innerWrapper, { alignSelf: "center" }]}>
            <AppText type="heading">{i18n.t("cexbit")}</AppText>
          </View>
          <View style={styles.innerWrapper}>
            <AppText type="regular" noPadding>
              {i18n.t("email")}
            </AppText>
            <View style={[styles.secondCol]}>
              <TouchableOpacity onPress={() => handleOpenUrl("mailto:info@cexbit.com")}>
                <AppText noPadding>info@cexbit.com</AppText>
              </TouchableOpacity>
            </View>
          </View>
          <View style={styles.innerWrapper}>
            <AppText type="regular" noPadding style={{}}>
              {i18n.t("billing_information")}
            </AppText>
            <AppText type={"small"} style={[styles.secondCol]} noPadding>
              {i18n.t("registration_number") + ": 26967405"}
              {"\n"}
              {i18n.t("tax_id") + ": CZ26967405"}
            </AppText>
          </View>
          <View style={[styles.innerWrapper, { alignItems: "flex-start", justifyContent: "center" }]}>
            <AppText type="regular" noPadding style={{}}>
              {i18n.t("phone")}
            </AppText>
            <View style={[styles.secondCol, { alignSelf: "center" }]}>
              <TouchableOpacity onPress={() => handleOpenUrl("tel:+420734723693")}>
                <AppText noPadding>+420 734 723 693</AppText>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[styles.innerWrapper, { alignItems: "flex-start" }]}>
            <AppText type="regular" noPadding>
              {i18n.t("address")}
            </AppText>
            <View style={[{ flexDirection: "column" }, styles.secondCol]}>
              <TouchableOpacity
                onPress={() =>
                  handleOpenUrl(
                    // eslint-disable-next-line max-len
                    "https://www.google.com/maps/dir/50.2104064,15.8203904/2a,+n%C3%A1m.+Svobody+450,+500+02+Hradec+Kr%C3%A1lov%C3%A9/@50.2107796,15.8193444,16z/data=!3m1!4b1!4m9!4m8!1m1!4e1!1m5!1m1!1s0x470c2b2e30e3e60b:0x1f977201f31bd50f!2m2!1d15.8269633!2d50.2106346"
                  )
                }
              >
                <AppText
                  noPadding
                  style={[
                    {
                      fontSize: theme.fontSize.regular,
                      color: theme.body.textColor,
                      textDecorationLine: "underline",
                      alignItems: "center",
                    },
                  ]}
                >
                  náměstí Svobody 450/2a{"\n"}Hradec Králové 500 02
                </AppText>
              </TouchableOpacity>
            </View>
          </View>
          <View style={[styles.innerWrapper, { width: "60%", alignSelf: "center", justifyContent: "center" }]}>
            <Image source={require("../../assets/images/office_hk.jpeg")} style={styles.image} />
          </View>
        </View>
      </MaxWidth>
    </Screen>
  )
}

const styles = StyleSheet.create({
  innerWrapper: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignSelf: "center",
    paddingBottom: 10,
  },
  bubbleStyles: {
    position: "absolute",
    bottom: 55,
    right: 5,
  },
  companyWrapper: {
    flexGrow: 1,
    flexShrink: 1,
    flexDirection: "column",
    borderRadius: RADIUS,
    padding: PADDING,
    margin: MARGIN,
  },
  header: {
    alignSelf: "flex-start",
    paddingBottom: 10,
    fontWeight: "bold",
    paddingLeft: 20,
  },
  text: {
    flex: 1.4,
    lineHeight: 20,
  },
  title: {
    textTransform: "uppercase",
    lineHeight: 20,
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    minWidth: 200,
  },
  icon: {
    marginRight: 10,
  },
  iconWrapper: {
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    resizeMode: "center",
    height: "80%",
    width: "80%",
    aspectRatio: "1 / 1",
    marginVertical: 10,
  },
  secondCol: {
    marginLeft: 20,
    flexGrow: 1,
  },
})
