import React, { useState } from "react"
import { View } from "react-native"
import Signature from "../general/Signature"
import { Diary } from "../../shared/entity/Diary"
import { SignatureEntryData } from "../../shared/entry/SignatureEntryData"
import { WrapRow } from "../container/WrapRow"
import { getDateSimpleString } from "../../shared/utils/date"
import Cell from "../input/Cell"
import { useUserProvider } from "../../providers/UserProvider"
import { EntryProps } from "../../shared/entry/Entry"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { PARTICIPANT_TYPE } from "../../shared/entity/Participant"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function SignatureEntry(props: EntryProps<SignatureEntryData>) {
  const { i18n } = useSettingsProvider()
  const { currentUser } = useUserProvider()
  const { selectedProject } = useProjectProvider()

  const [data, setData] = useState({
    user: currentUser?.name,
    ...new SignatureEntryData(props.entry),
  } as SignatureEntryData)
  const onSave = (newData: SignatureEntryData) => {
    if (props.onSave) props.onSave(newData)
  }
  if (currentUser == undefined || currentUser.id == undefined || selectedProject == undefined) return <></>
  return (
    <View>
      <WrapRow>
        <Cell
          label={i18n.t("signed_by")}
          value={(data?.user ?? "") + ", " + PARTICIPANT_TYPE[selectedProject?.participants[currentUser.id]] ?? ""}
        />
        <Cell
          label={i18n.t("on_as_day")}
          value={data.date ? getDateSimpleString(new Date(data.date)) : getDateSimpleString(new Date())}
        />
      </WrapRow>

      <Signature
        title={i18n.t("signature")}
        onSave={(uri) => {
          const newData = new SignatureEntryData(data)
          newData.uri = uri
          newData.user = currentUser.name
          newData.date = new Date()
          newData.role = selectedProject.participants[currentUser.id]
          setData(newData)
          onSave(newData)
        }}
        style={{ maxWidth: 300, width: "100%", alignSelf: "center" }}
        uri={data.uri}
        path={(props.parentData as Diary).getPathSegmentWithId()}
      />
    </View>
  )
}
