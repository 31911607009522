import React, { useState } from "react"
import Screen from "../../components/general/Screen"
import { ROUTE } from "../../navigation/routes"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import TextField from "../../components/input/TextField"
import { Release } from "../../shared/entity/Release"
import MaxWidth from "../../components/container/MaxWidth"
import { View } from "react-native"
import { ActionElement } from "../../components/container/ActionElement"
import { TextInput } from "../../components/input/TextInput"
import Separator from "../../components/flatlist/Separator"
import Button from "../../components/button/Button"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { showMessage } from "react-native-flash-message"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.SUPERADMIN_RELEASE>

export function ReleaseScreen() {
  const { isOperationAuthorized } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const [release, setRelease] = useState(new Release())
  if (!isOperationAuthorized(OPERATION.SUPERADMIN)) return <></>
  return (
    <Screen>
      <MaxWidth>
        <ExpandableCard
          startOpen
          closable={false}
          name={i18n.t("release")}
          actionElement={
            <ActionElement
              actionList={[
                {
                  action: () => {
                    setRelease(new Release())
                  },
                  iconName: "remove",
                  label: "Clear",
                },
              ]}
            />
          }
        >
          <TextField
            label={i18n.t("version")}
            value={release.version ?? ""}
            onChange={(v) => setRelease(new Release({ ...release, version: v, id: v }))}
          />
          <TextField
            label={i18n.t("commit_id")}
            value={release.commitId ?? ""}
            onChange={(v) => setRelease(new Release({ ...release, commitId: v }))}
          />
          <ExpandableCard
            name={i18n.t("changelog")}
            startOpen
            closable={false}
            actionElement={
              <ActionElement
                actionList={[
                  {
                    action: () => {
                      const newRelease = new Release(release)
                      newRelease.changelog.push({ name: "", description: "" })
                      setRelease(newRelease)
                    },
                    iconName: "plus",
                    label: i18n.t("add"),
                  },
                ]}
              />
            }
          >
            {release.changelog?.map((change, i) => (
              <View>
                <TextField
                  label={i18n.t("header_html")}
                  value={release.changelog[i].name ?? ""}
                  onChange={(v) => {
                    const newRelease = new Release(release)
                    newRelease.changelog[i].name = v
                    setRelease(newRelease)
                  }}
                >
                  <ActionElement
                    actionList={[
                      {
                        action: () => {
                          const newRelease = new Release(release)
                          newRelease.changelog.splice(i, 1)
                          setRelease(newRelease)
                        },
                        iconName: "remove",
                        label: "",
                      },
                    ]}
                  />
                </TextField>
                <TextInput
                  multiline={true}
                  style={{ height: 100 }}
                  label={i18n.t("description_html")}
                  value={release.changelog[i].description ?? ""}
                  onChangeText={(v) => {
                    const newRelease = new Release(release)
                    newRelease.changelog[i].description = v
                    setRelease(newRelease)
                  }}
                />
                <Separator />
              </View>
            ))}
          </ExpandableCard>
          <Button
            title={i18n.t("confirm_changes")}
            onPress={() => {
              FirebaseObject.create(release)
                .then(() => {
                  showMessage({ message: i18n.t("success"), type: "success" })
                })
                .catch((reason) => {
                  showMessage({ message: i18n.t("error_message"), type: "danger" })
                  console.error(reason)
                })
            }}
          />
        </ExpandableCard>
      </MaxWidth>
    </Screen>
  )
}
