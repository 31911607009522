// @ts-nocheck
import * as React from "react"
import Svg, { Path, SvgProps } from "react-native-svg"

const SvgComponent = (props: SvgProps) => (
  <Svg xmlns="http://www.w3.org/2000/svg" height={24} viewBox="0 96 960 960" width={24} {...props}>
    <Path
      fill="#fff"
      d="M680 456h80v-80h-80v80Zm0 160h80v-80h-80v80Zm0 160h80v-80h-80v80Zm0 160v-80h160V296H480v56l-80-58v-78h520v720H680Zm-640 0V536l280-200 280 200v400H360V736h-80v200H40Zm80-80h80V656h240v200h80V576L320 434 120 576v280Zm560-360ZM440 856V656H200v200-200h240v200Z"
    />
  </Svg>
)
export default SvgComponent
