import { Platform, useWindowDimensions, View } from "react-native"
import React, { useContext, useState } from "react"
import * as Device from "expo-device"
import Constants from "expo-constants"
import Screen from "../../components/general/Screen"
import Button from "../../components/button/Button"
import { UserContext } from "../../providers/UserProvider"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import { Issue, ISSUE_TYPE } from "../../shared/entity/Issue"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useStorageProvider } from "../../providers/StorageProvider"
import { TextInput } from "../../components/input/TextInput"
import Dropdown from "../../components/input/Dropdown"
import MaxWidth from "../../components/container/MaxWidth"
import { useIssueProvider } from "../../providers/IssueProvider"
import LabeledImage from "../../components/general/LabeledImage"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_REPORT>

export function ReportScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const [issueData, setIssueData] = useState<Issue>(new Issue())
  const width = useWindowDimensions().width
  const height = useWindowDimensions().height
  const { currentUser } = useContext(UserContext)
  const { setStatusError, setStatusUndetermined, setStatusSuccess } = useStatusProvider()
  const { uploadFileStringOnFirebase, uploadFileOnFirebase, getBlobFromFile } = useStorageProvider()
  const [visible, setVisible] = useState(false)
  const { createIssue } = useIssueProvider()
  const theme: Theme = useTheme()

  console.log("route.params", route.params)

  function getRoutes() {
    const routeList = navigation.getState().routes || []
    let routes = ""
    routeList.forEach(
      (
        route: {
          key: string
          name: string
          path?: string | undefined
        },
        index: number
      ) => {
        if (index < routeList.length - 1) {
          routes += `${route.name} ${index < routeList.length - 2 ? "=>" : ""} `
        }
      }
    )
    return routes
  }

  async function handleReportIssue() {
    setStatusUndetermined(i18n.t("sending_report"))

    try {
      let uri
      if (Platform.OS === "web") {
        uri = await uploadFileStringOnFirebase(route.params.uri, "Issue")
      } else {
        /*const imgPath = await getBlobFromFile(route.params.uri)
        //TODO: Odesílám report
        console.log("handleReportIssue")
        uri = await uploadFileOnFirebase(imgPath, "Issue", { contentType: "image/png" })*/
      }

      await createIssue(
        new Issue({
          createdBy: currentUser?.id as string,
          uri: uri,
          description: issueData?.description || "not filled",
          type: issueData?.type,
          deviceInfo: {
            brand: Device.brand || "undefined",
            manufacturer: Device.manufacturer || "undefined",
            device: Device.modelName || "undefined",
            osVersion: Device.osVersion || "undefined",
          },
          windowDimensions: { width, height },
          platform: Platform.constants || {},
          routes: getRoutes(),
          commitId: Constants?.manifest?.extra?.commitId,
        })
      )
      setStatusSuccess(i18n.t("report_sent"))
    } catch (error) {
      console.error(JSON.stringify(error))
      setStatusError(error.message)
    }
  }

  const handleChange = (prop: string, val: string | number): void => {
    setIssueData(new Issue({ ...issueData, [prop]: val }))
  }

  return (
    <Screen>
      <MaxWidth>
        <View
          style={{
            justifyContent: "center",
            flexGrow: 1,
            flexShrink: 1,
            flexDirection: "column",
            backgroundColor: theme.colors.background,
            borderRadius: RADIUS,
            padding: PADDING,
            margin: MARGIN,
            ...theme.shadows,
          }}
        >
          <TextInput
            required
            multiline
            label={i18n.t("complete_problem_description")}
            errorText={i18n.t("required_issue_description")}
            error={!issueData.description}
            onChangeText={(value: string) => handleChange("description", value)}
            value={issueData?.description || ""}
            prop="description"
            style={{ height: 100 }}
          />
          <Dropdown
            value={issueData.type}
            onChange={(value) => handleChange("type", value)}
            data={ISSUE_TYPE}
            label={i18n.t("issue_type")}
          />
          <Button onPress={() => setVisible(!visible)} title={visible ? i18n.t("hide_image") : i18n.t("show_image")} />
          {visible && <LabeledImage uri={route?.params?.uri} prop={"uri"} />}
        </View>
      </MaxWidth>
      <Button
        standalone
        onPress={handleReportIssue}
        title={i18n.t("send")}
        disabled={!issueData.type || !issueData.description}
      />
    </Screen>
  )
}
