import React, { FlatList, Pressable } from "react-native"
import { Portal } from "react-native-paper"
import AppText from "../general/AppText"
import { TextInput } from "../input/TextInput"
import { useState } from "react"

interface ModalListProps {
  isMultipleSelect?: boolean
  visible?: boolean
  label?: string
  values: string[]
  data: { label: string; value: any }[]
  selected: (value: string) => void
  onClose?: () => void
}

export default function ModalList({
  isMultipleSelect = false,
  selected,
  values,
  visible,
  label,
  data,
  onClose,
}: ModalListProps) {
  const [searchText, setSearchText] = useState<string>("")
  const filteredData = data.filter((item) => JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase()))

  const handleSearch = (text: string) => {
    setSearchText(text)
  }
  const renderItem = ({ item }) => (
    <Pressable key={item.key} onPress={() => console.log(item.value)}>
      <AppText>{item.value}</AppText>
    </Pressable>
  )

  const renderSearchBar = () => {
    return <TextInput value={searchText} onChangeText={handleSearch} withHelperText={false} />
  }

  return (
    <>
      {visible && (
        <Portal>
          {/*@ts-ignore*/}
          <Pressable style={{ width: "100%", height: "100%", cursor: "default" }} onPress={onClose}>
            <FlatList
              data={data}
              renderItem={renderItem}
              scrollEnabled
              refreshing
              StickyHeaderComponent={renderSearchBar}
            />
          </Pressable>
        </Portal>
      )}
    </>
  )
}
