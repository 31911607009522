import React, { useEffect, useState } from "react"
import { FlatList, View } from "react-native"
import Button from "../../components/button/Button"
import Separator from "../../components/flatlist/Separator"
import UserItem from "../../components/flatlist/UserItem"
import Screen from "../../components/general/Screen"
import EmptyScreenPlaceholder from "../../components/general/EmptyScreenPlaceholder"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_PICKER>

export function UserPickerScreen({ route, navigation }: Props) {
  const users = route.params?.users || []
  const { i18n } = useSettingsProvider()
  const [choosedUids, setChoosedUids] = useState([])

  console.debug("CHOOSED USERS", setChoosedUids)

  useEffect(() => {
    setChoosedUids(route.params?.choosedUsers || [])
  }, [])

  const handleSelect = (user) => {
    if (choosedUids.some((uid) => uid === user.uid)) {
      setChoosedUids(choosedUids.filter((uid) => uid !== user.uid))
    } else {
      setChoosedUids([...choosedUids, user.uid])
    }
  }

  return (
    <Screen>
      {/* TEMPORARY REPORTING*/}
      <View style={{ flex: 1 }}>
        {users.length > 0 ? (
          <FlatList
            data={users}
            renderItem={({ item }) => (
              <UserItem
                user={item}
                onPress={() => handleSelect(item)}
                viewType="picker"
                selected={choosedUids.some((uid) => uid === item.uid)}
              />
            )}
            keyExtractor={(item) => item}
            ItemSeparatorComponent={Separator}
          />
        ) : (
          <EmptyScreenPlaceholder
            text={users?.length > 0 ? i18n.t("no_user_selected") : i18n.t("project_users_have_no_roles")}
          />
        )}
      </View>
      {/* {pickerUsers?.length > 0 && (
        <AppPicker
          value={picked}
          style={appPickerStyles}
          placeholder={{ label: "--- i18n.t("Vyberte uživatele") ---", value: "" }}
          onValueChange={(uid) => handleValueChange(uid)}
          //   onClose={() => addUser(picked)}
          items={pickerUsers.map((u) => ({ label: getUserName(getUserFromList(users, u.uid)), value: u.uid }))}
        />
      )} */}
      <Button
        onPress={() => {
          route.params.addUsers(choosedUids)
          navigation.goBack()
        }}
        title={i18n.t("ok")}
      />
    </Screen>
  )
}
