import React, { useRef, useState } from "react"
import { ScrollView } from "react-native"
import Button from "../../components/button/Button"
import AppText from "../../components/general/AppText"
import Container from "../../components/general/Container"
import Screen from "../../components/general/Screen"
import { TextInput } from "../../components/input/TextInput"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import { useUserProvider } from "../../providers/UserProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_DELETE>

export function UserDeleteScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const { disableUser, currentUser } = useUserProvider()
  const [password, setPassword] = useState<string>("")
  const passwordInputRef = useRef<TextInput>(null)

  const handleChangePassword = (val: string) => {
    setPassword(val)
  }

  const handleDeleteAccount = () => {
    if (currentUser?.email) {
      disableUser(currentUser?.email, password).then((result) => {
        console.log("Account deleted:" + result)
      })
    }
  }

  return (
    <Screen style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <AppText type="heading" style={{ paddingHorizontal: 10 }}>
            {i18n.t("do_you_want_this_account_removed")}
          </AppText>
          <TextInput
            ref={passwordInputRef}
            value={password}
            prop="password"
            onChangeText={(value: string) => handleChangePassword(value)}
            label={i18n.t("verification_password")}
            secureTextEntry
            returnKeyType={"done"}
            onSubmitEditing={() => {
              !password ? console.log("Missing password!") : handleDeleteAccount
            }}
            autoCapitalize="none"
            required
          />
          <Button title={i18n.t("delete")} onPress={handleDeleteAccount} />
        </Container>
      </ScrollView>
    </Screen>
  )
}
