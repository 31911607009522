import React, { useLayoutEffect } from "react"
import { Theme } from "../../../types"
import { ScrollView, View } from "react-native"
import HeaderMenu from "../../components/project/HeaderMenu"
import Screen from "../../components/general/Screen"
import AppText from "../../components/general/AppText"
import { errorCodes } from "../../constants/ErrorMessages"
import { Button, useTheme } from "react-native-paper"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.PROJECT_DELETE>

export function ProjectDeleteScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const { setStatusError, setStatusUndetermined, setStatusSuccess } = useStatusProvider()
  const { deleteProject, selectedProject } = useProjectProvider()
  const theme: Theme = useTheme()

  useLayoutEffect(() => {
    if (selectedProject) {
      navigation.setOptions({
        headerRight: () => <HeaderMenu id={selectedProject.id ?? ""} />,
      })
    }
  }, [navigation])

  const handleDelete = async () => {
    try {
      if (selectedProject) {
        setStatusUndetermined(i18n.t("deleting_project"))
        await deleteProject(selectedProject)
        setStatusSuccess(i18n.t("project_deleted"))
        navigation.navigate(ROUTE.PROJECT_LIST, { projectId: selectedProject.parentProjectId ?? "" })
      }
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }

  return (
    <Screen>
      <ScrollView>
        <View
          style={{
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5%",
          }}
        >
          <AppText>{i18n.t("deleting_project_message")}</AppText>
        </View>
      </ScrollView>
      <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", height: "10%" }}>
        <Button color={theme.colors?.primary} onPress={handleDelete}>
          {i18n.t("yes")}
        </Button>
        <Button color={theme.colors?.primary} onPress={() => navigation.navigate(ROUTE.PROJECT_LIST)}>
          {i18n.t("no")}
        </Button>
      </View>
    </Screen>
  )
}
