import { DocObject } from "./DocObject"
import { PlaceLocation } from "../common/PlaceLocation"
import { SystemRole } from "./Role"
import { User } from "./User"
import { CUSTOM_MODULES, ModuleDefinition } from "../common/CustomInputDefinition"

export class Company extends DocObject {
  getPathSegment() {
    return "Company"
  }

  persistenceFilter: any = { persistenceFilter: true, id: true, companyUsers: true, companyInvitations: true }
  name = ""
  ico?: string
  dic?: string
  location?: PlaceLocation
  logoUri?: string
  status?: CompanyStatus = CompanyStatus.ACTIVE

  companyModules: Record<string, ModuleDefinition> = {}

  systemRoles?: Array<SystemRole>
  companyUsers?: User[]

  constructor(params?: Partial<Company>) {
    super(params)
    for (const companyModule of Object.keys(CUSTOM_MODULES) as (keyof typeof CUSTOM_MODULES)[]) {
      const mod = CUSTOM_MODULES[companyModule]
      this.companyModules[mod] = new ModuleDefinition({ name: mod })
    }
    this.assign(params)
  }

  public setLocation(location: PlaceLocation) {
    this.location = location
  }

  public setName(name: string) {
    this.name = name
  }

  public setIco(ico: string) {
    this.ico = ico
  }

  assign(data: any) {
    if (data) {
      if (data.companyModules != undefined) {
        for (const companyModule in data.companyModules) {
          data.companyModules[companyModule] = new ModuleDefinition(data.companyModules[companyModule])
        }
      }
      super.assign(data)
    }
  }

  getLabel(): string {
    return this.name || "undefinedCompany"
  }
}

export enum CompanyStatus {
  ACTIVE = "active",
  DISABLED = "disabled",
}
