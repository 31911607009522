import { Entypo, FontAwesome } from "@expo/vector-icons"
import React from "react"
import { View } from "react-native"
import { TouchableOpacity } from "react-native-gesture-handler"
import { Menu, useTheme } from "react-native-paper"
import { errorCodes } from "../../constants/ErrorMessages"
import { Theme } from "../../../types"
import { RADIUS } from "../../constants/style"
import { useUserProvider } from "../../providers/UserProvider"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function HeaderMenu({ userId }: { userId: string | undefined }) {
  const { deleteUser } = useUserProvider()
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const [visible, setVisible] = React.useState(false)
  const { setStatusError, setStatusSuccess, setStatusUndetermined } = useStatusProvider()

  const openMenu = () => setVisible(true)

  const closeMenu = () => setVisible(false)

  const handleClick = async () => {
    console.log("handleClick")
    closeMenu()
    try {
      setStatusUndetermined(i18n.t("deleting_user"))
      if (!userId) throw { message: i18n.t("user_does_not_exist") }
      await deleteUser(userId)
      setStatusSuccess(i18n.t("user_deleted"))
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }

  return (
    // <Provider>
    <View>
      <Menu
        style={{ borderRadius: RADIUS }}
        contentStyle={theme.menu}
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <TouchableOpacity onPress={openMenu}>
            <Entypo name="dots-three-vertical" size={24} color={theme.colors.primary} />
          </TouchableOpacity>
        }
      >
        <Menu.Item
          titleStyle={{ color: theme.body.textColor }}
          onPress={handleClick}
          title="Odstranit"
          leadingIcon={({ size }) => <FontAwesome name="trash-o" size={size} color={theme.colors.primary} />}
        />
      </Menu>
    </View>
    // </Provider>
  )
}
