import React from "react"
import { Button, Dialog, Portal, useTheme } from "react-native-paper"
import { StyleProp, StyleSheet, TextStyle, View } from "react-native"
import { Theme } from "../../../types"
import AppText from "./AppText"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  style?: StyleProp<TextStyle>
  children?: React.ReactNode
  visible: boolean
  access?: boolean
  onDismiss: () => void
  onPress?: () => void
  title: string
  disableDismissButton?: boolean
  moreButtons?: JSX.Element | JSX.Element[]
  confirmButtonTitle?: string
  dismissButtonTitle?: string
  customDismissOnPress?: () => void
  disabledConfirmButton?: boolean
  hideConfirmButton?: boolean
}
export default function DialogWindow({
  onPress,
  style,
  children,
  visible,
  onDismiss,
  title,
  confirmButtonTitle,
  dismissButtonTitle,
  customDismissOnPress,
  disableDismissButton = false,
  disabledConfirmButton = false,
  hideConfirmButton = false,
  moreButtons,
}: Props) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const styles = getStyles(theme)
  return (
    <View>
      <Portal>
        <Dialog style={[style, styles.dialog]} visible={visible} onDismiss={onDismiss}>
          <Dialog.Title>
            <AppText type={"heading"} style={{ color: theme.colors.primary }}>
              {title}
            </AppText>
          </Dialog.Title>
          <Dialog.Content style={theme.modal}>
            <View style={{ flex: 1, marginBottom: 10 }}>{children}</View>
          </Dialog.Content>
          <Dialog.Actions style={{ flexDirection: "row", justifyContent: "space-between" }}>
            {moreButtons}
            {!disableDismissButton ? (
              <Button
                contentStyle={styles.button}
                compact
                mode={"contained"}
                onPress={customDismissOnPress ? customDismissOnPress : onDismiss}
              >
                {dismissButtonTitle || i18n.t("no")}
              </Button>
            ) : (
              <View></View>
            )}
            {!hideConfirmButton && (
              <Button
                disabled={disabledConfirmButton}
                contentStyle={styles.button}
                compact
                mode={"contained"}
                onPress={() => {
                  if (onPress) {
                    onPress()
                  }
                  onDismiss()
                }}
              >
                {confirmButtonTitle || i18n.t("yes")}
              </Button>
            )}
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  )
}

function getStyles(theme: Theme) {
  return StyleSheet.create({
    dialog: {
      ...theme.modal,
      alignSelf: "center",
      justifyContent: "center",
      maxWidth: 500,
      minWidth: 300,
      marginHorizontal: 10,
      position: "absolute",
      flexGrow: 1,
    },
    button: {
      marginHorizontal: 5,
    },
  })
}
