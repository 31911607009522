import { FontAwesome } from "@expo/vector-icons"
import React from "react"
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"

export default function DeleteButton({
  onPress,
  absolute = true,
  disabled = false,
  style,
}: {
  onPress: () => void
  absolute?: boolean
  disabled?: boolean
  style?: StyleProp<TextStyle>
}) {
  const theme: Theme = useTheme()
  const styles = getStyles(theme)
  return (
    <TouchableOpacity
      disabled={disabled}
      style={[
        disabled ? styles.buttonDisabled : styles.button,
        { width: 40, height: 40 },
        { backgroundColor: disabled ? theme.colors.disabled : theme.colors.danger },
        absolute && { position: "absolute", top: 5, right: 0 },
        style,
      ]}
      onPress={onPress}
    >
      <FontAwesome name="times" size={18} color={theme.colors.white} />
    </TouchableOpacity>
  )
}

function getStyles(theme: Theme) {
  return StyleSheet.create({
    button: {
      ...theme.shadows,
      backgroundColor: theme.colors.primary,
      marginVertical: 5,
      borderRadius: 20,
      alignItems: "center",
      justifyContent: "center",
    },
    buttonDisabled: {
      backgroundColor: theme.colors.disabled,
      marginVertical: 5,
      borderRadius: 20,
      alignItems: "center",
      justifyContent: "center",
    },
    buttonTitle: {
      color: theme.colors.white,
      fontSize: theme.fontSize.regular,
      fontWeight: "bold",
      paddingHorizontal: 10,
    },
  })
}
