import React from "react"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { StyleProp, TouchableOpacity, View, ViewStyle } from "react-native"
import InputBase from "./InputBase"
import AppText from "../general/AppText"
import { MARGIN, PADDING } from "../../constants/style"
import { I18n } from "i18n-js"

export default function StateInput<T>({
  label = "",
  value,
  disabled = false,
  data,
  style,
  onChange,
  translation,
}: {
  label?: string
  value: string
  disabled?: boolean
  data: Record<string, T> | { [s: number]: string }
  onChange?: (newValue: T) => void
  style?: StyleProp<ViewStyle>
  translation: I18n
}) {
  const theme: Theme = useTheme()

  return (
    <InputBase noPadding={true} label={label}>
      <View style={[{ alignItems: "center", flexDirection: "row", padding: PADDING, flexGrow: 1 }, style]}>
        {Object.entries(data).map(([key, label]) => (
          <TouchableOpacity
            key={key}
            style={{
              flexGrow: 1,
              height: "100%",
              borderRadius: 10,
              margin: MARGIN,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: label == value ? theme.colors.primary : undefined,
            }}
            onPress={() => onChange && onChange(label)}
            disabled={disabled}
          >
            <AppText style={{ color: label == value ? theme.colors.white : theme.colors.text }} noPadding>
              {translation.t(label)}
            </AppText>
          </TouchableOpacity>
        ))}
      </View>
    </InputBase>
  )
}
