import React from "react"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { User } from "../../shared/entity/User"
import { ExpandableCard } from "../container/ExpandableCard"
import { TouchableOpacity, View } from "react-native"
import Separator from "../flatlist/Separator"
import { PADDING } from "../../constants/style"
import AppText from "../general/AppText"
import { WrapRow } from "../container/WrapRow"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  users: User[]
  selectedUser: User
  onUserSelected: (user: User) => void
}

export function AttendanceUsers({ selectedUser, onUserSelected, users }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  return (
    <ExpandableCard startOpen closable={false} noPadding name={i18n.t("users")}>
      {users.map((user) => (
        <View>
          <TouchableOpacity style={{ flexDirection: "row" }} onPress={() => onUserSelected(user)}>
            <View
              style={{
                padding: PADDING,
                height: "100%",
                backgroundColor: user.id == selectedUser.id ? theme.colors.primary : theme.colors.background,
              }}
            />
            <WrapRow style={{ flexGrow: 1 }}>
              <View style={{ padding: PADDING, flexGrow: 1, alignItems: "center" }}>
                <AppText noPadding>{user.name}</AppText>
              </View>
              <View style={{ padding: PADDING, flexGrow: 1, alignItems: "center" }}>
                <AppText noPadding>{user.email}</AppText>
              </View>
              <View style={{ padding: PADDING, flexGrow: 1, alignItems: "center" }}>
                <AppText noPadding>{user.phoneNumber}</AppText>
              </View>
            </WrapRow>
          </TouchableOpacity>
          <Separator />
        </View>
      ))}
    </ExpandableCard>
  )
}
