import React from "react"
import { ImageStyle, Platform, StyleProp } from "react-native"
import FullWidthImagePhone from "./FullWidthImagePhone"
import FullWidthImageWeb from "./FullWidthImageWeb"

export default function FullWidthImage({
  uri,
  width,
  onLoadEnd,
  style,
}: {
  uri: string
  width?: number | string
  onLoadEnd: () => void
  style?: StyleProp<ImageStyle>
}) {
  return Platform.OS === "web" ? (
    <FullWidthImageWeb uri={uri} width={width as number | string} onLoadEnd={onLoadEnd} style={style} />
  ) : (
    <FullWidthImagePhone uri={uri} style={style} onLoadEnd={onLoadEnd} />
  )
}
