import React from "react"
import Screen from "../../components/general/Screen"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import MaxWidth from "../../components/container/MaxWidth"
import AppText from "../../components/general/AppText"
import { View } from "react-native"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_VOP>

export function VopScreen({ route, navigation }: Props) {
  const theme: Theme = useTheme()

  return (
    <Screen style={{ flex: 1 }}>
      <MaxWidth>
        <View
          style={[
            {
              backgroundColor: theme.colors.background,
              padding: PADDING,
              margin: MARGIN,
              borderRadius: RADIUS,
            },
            theme.shadows,
          ]}
        >
          <View>
            <View>
              <AppText>
                <AppText> </AppText>
              </AppText>
            </View>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText style={{ fontWeight: "bold" }}>Obchodní podmínky pro internetové aplikace</AppText>
            </AppText>
            <AppText>
              <AppText style={{ fontWeight: "bold" }}> </AppText>
            </AppText>
            <AppText>
              <AppText style={{ fontWeight: "bold" }}>
                Vzájemná práva a povinnosti Uživatele a Provozovatele pramenící z užívání Aplikace
              </AppText>
            </AppText>
            <AppText>
              <AppText style={{ fontWeight: "bold" }}>se řídí těmito obchodními podmínkami:</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>1 Definice</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1 V těchto obchodních podmínkách:</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.1 </AppText>
              <AppText>„Provozovatel“</AppText>
              <AppText>
                je Společnost CEXBit s. r. o., IČO: 26967405, se sídlem náměstí Svobody 450/2a Hradec Králové, 500 02
                Česká republika.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.2 </AppText>
              <AppText>„Aplikace“ </AppText>
              <AppText>
                znamená internetovou aplikaci s názvem Stavx, mezi jejíž hlavní funkcionality patří provozování
                stavebního deníku a která je dostupná z internetové adresy www.stavx.cz
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.3 </AppText>
              <AppText>„VOP“</AppText>
              <AppText> znamená tyto Všeobecné obchodní podmínky.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.4 </AppText>
              <AppText>„Smlouva“</AppText>
              <AppText>
                znamená smlouvu uzavřenou mezi Uživatelem a Provozovatelem, jejímž předmětem je poskytnutí Licence k
                užívání Aplikace Uživateli za podmínek uvedených v těchto VOP.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.5 </AppText>
              <AppText>„Licence“</AppText>
              <AppText>znamená nevýhradní licenci k užívání Aplikace za podmínek uvedených v těchto VOP.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.6 </AppText>
              <AppText>„Uživatel“</AppText>
              <AppText>
                znamená jakoukoliv právnickou nebo fyzickou osobu odlišnou od Provozovatele, která užívá Aplikaci.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.7 </AppText>
              <AppText>„Uživatelský účet“</AppText>
              <AppText>
                znamená neveřejnou část Aplikace, která je uživateli přístupná po zadání Přihlašovacích údajů.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>1.1.8 </AppText>
              <AppText>„Přihlašovací údaje“</AppText>
              <AppText>
                znamenají unikátní kombinaci přihlašovacího jména Uživatele a hesla zvoleného Uživatelem, které Uživatel
                uloží do databáze Aplikace při zřizování Uživatelského účtu prostřednictvím Aplikace a/nebo Uživateli
                automaticky vygenerovaného Aplikací.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>2 Proces uzavření smlouvy o užívání Aplikace</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                2.1 Zobrazení tlačítka s názvem „Registrace“ v uživatelském prostředí Aplikace je návrhem na uzavření
                Smlouvy ze strany Provozovatele.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                2.2 Kliknutí Uživatele na tlačítko „Registrace“ je bezvýhradným přijetím návrhu na uzavření Smlouvy ze
                strany Uživatele a tímto úkonem je uzavřena Smlouva.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                2.3 Kliknutím na pole (tzv. checkbox), které obsahuje popis ve znění „Souhlas s obchodními podmínkami“ a
                následným kliknutím na tlačítko ve smyslu 2.2 Uživatel vyjadřuje souhlas s těmito VOP.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>2.4 Uzavřením Smlouvy vstupují v účinnost následující ustanovení těchto VOP.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>3 Užívání Aplikace</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.1 Provozovatel uděluje Uživateli Licenci v rozsahu a za podmínek uvedených v uživatelském prostředí
                Aplikace, zejména uvedených u daného typu Uživatelského účtu.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.2 Uživatel se za udělení Licence zavazuje Provozovateli zaplatit odměnu ve výši uvedené v uživatelském
                prostředí Aplikace.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.3 Provozovatel má právo poskytnout plnění Smlouvy (např. zpřístupnit danou část nebo funkcionalitu
                Aplikace) až v momentě, kdy dojde k zaplacení odměny za poskytnutí Licence, a nezaplatí-li Uživatel za
                Licenci odměnu v plné výši v Provozovatelem stanovené lhůtě, jinak ihned, ruší se Smlouva bez dalšího od
                počátku.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.4 Provozovatel je po uplynutí doby Licence oprávněn plně a/nebo částečně znepřístupnit Uživatelský
                účet Uživateli.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>3.5 Uživatel nesmí zřídit více než jeden Uživatelský účet.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>3.6 Uživatel nemá právo udělit třetí osobě podlicenci k užívání Aplikace.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.7 Veškerá finanční plnění realizovaná prostřednictví Aplikace nebo platební brány propojené s Aplikací
                se považují za zaplacené připsáním celé příslušné částky na příslušný bankovní účet Provozovatele.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.8 Provozovatel má právo Aplikaci nebo její část po předchozím upozornění Uživatele odstavit, změnit
                a/nebo ji znepřístupnit z příslušné internetové adresy, a to z jakéhokoliv důvodu.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.9 Uživatel má právo na užívání Aplikace za podmínek Smlouvy v aktuální verzi dostupné z příslušné
                internetové adresy.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                3.10 Uživatel se zavazuje při užívání Aplikace jednat tak, aby jemu ani Provozovateli užíváním Aplikace
                nevznikla žádná škoda.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText style={{ fontWeight: "bold" }}>4 Databáze</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                4.1 Uživatel nemá právo prostřednictvím Aplikace strojově (zejm. pomocí tzv. softwarových robotů)
                vytěžovat databázi spojenou s Aplikací.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                4.2 Uživatel a Provozovatel tímto sjednávají, že jakékoliv údaje vložené Uživatelem do databáze Aplikace
                jsou části databáze shromážděné Provozovatelem a stávají se součástí databáze Provozovatele, aniž by
                Uživateli jeho jednáním popsaným v tomto odstavci vzniklo jakékoliv právo k databázi Aplikace.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                4.3 Smluvní strany jsou si vědomy, shodně prohlašují a činí nesporným, že Aplikace splňuje podmínky ust.
                § 562 odst. 2 občanského zákoníku, tedy že záznamy údajů v Aplikaci a její databázi jakožto
                elektronickém systému jsou spolehlivé a jsou prováděny systematicky a posloupně a jsou chráněny proti
                změnám.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>5 Odpovědnost za škodu</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>5.1 Ustanovení toho článku VOP se nevztahují na spotřebitele.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                5.2 Uživatel se tímto výslovně vzdává práva na náhradu škody od Provozovatele neúmyslně nebo nikoli z
                hrubé nedbalosti způsobené Provozovatelem porušením jakékoliv povinnosti Provozovatele uvedené ve
                Smlouvě v souvislosti s plněním Smlouvy nebo v těchto VOP.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>6 Změna VOP</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                6.1 Uživatel bere na vědomí, že Provozovatel uzavírá Smlouvu v běžném obchodním styku s větším počtem
                osob a Smlouvy jsou podle svého charakteru zavazující dlouhodobě k opětovným plněním stejného druhu s
                odkazem na tyto VOP. Smluvní strany činí nesporným, že z povahy závazků Provozovatele podle těchto VOP
                vyplývá a již před uzavřením Smlouvy vyplývala rozumná potřeba pozdější změny těchto VOP.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                6.2 Smluvní strany tímto ujednávají, že Provozovatel může tyto VOP v přiměřeném rozsahu změnit, a
                zároveň ujednávají, že změna VOP bude Uživateli, jako druhé straně, oznámena prostřednictvím e-mailové
                zprávy odeslané na e-mailovou adresu zadanou Uživatelem do Aplikace. Uživatel má v takovém případě právo
                změny VOP odmítnout a předmětný závazek mezi ním a Provozovatelem z tohoto důvodu vypovědět ve výpovědní
                době jeden (1) kalendářní měsíc, která začne běžet den následující po odeslání oznámení, o které Smluvní
                strany tímto shodně prohlašují, že je dostatečná k obstarání obdobných plnění od jiného subjektu.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                6.3 V případě, že dojde k uzavření nové Smlouvy k již existujícímu Uživatelskému účtu (tj. k obnovení
                nebo prodloužení Uživatelského účtu), řídí se taková Smlouva VOP ve znění ke dni uzavření takové
                Smlouvy.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>7 Ochrana osobních údajů</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>7.1 Provozovatel je ze zákona povinen chránit a zabezpečit poskytnuté osobní údaje.</AppText>
            </AppText>
            <AppText>
              <AppText>Provozovatel proto využívá různých efektivních zabezpečovacích technologií k tomu, aby</AppText>
            </AppText>
            <AppText>
              <AppText>byly osobní údaje ochráněny před neoprávněným zpřístupněním nebo užitím.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                7.2 Podrobnější informace o ochraně osobních údajů naleznete v Zásadách ochrany osobních údajů zde:
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>8 Povinné informace pro spotřebitele</AppText>
            </AppText>
            <AppText>
              <AppText>8.1 Tento článek VOP podmínek je účinný pouze vůči Uživateli, který je spotřebitelem.</AppText>
            </AppText>
            <AppText>
              <AppText>Ustanovení uvedená v tomto článku VOP se nevztahují zejména na podnikatele –</AppText>
            </AppText>
            <AppText>
              <AppText>živnostníky nebo obchodní společnosti.</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2 Provozovatel tímto informuje Uživatele, že:</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.1 Adresa pro doručování písemností Provozovateli je shodná s výše uvedenou adresou sídla
                Provozovatele;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2.2 Telefonický kontakt Provozovatele je +420 734 132 844;</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2.3 Adresa Provozovatele pro doručování elektronické pošty je info@stavx.cz;</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.4 Odměna Provozovatele za Licenci je stanovena včetně všech daní a poplatků a její výše a/nebo
                způsob výpočtu je stanoven v Aplikaci;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.5 Uživatel je povinen zaplatit Provozovateli odměnu za Licenci, není-li Licence poskytována
                bezúplatně, jedním ze způsobů, který je u příslušné možnosti uveden v uživatelském prostředí Aplikace;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2.6 Uživateli nevznikají náklady na dodání;</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2.7 K užívání Aplikace je třeba přístup k síti Internet a internetový prohlížeč;</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.8 Uživatel má právo od Smlouvy odstoupit bez uvedení důvodu a bez jakékoliv sankce do 14 dnů od
                převzetí plnění. Převzetím plnění se rozumí poskytnutí Licence;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.9 ve vztahu k Provozovateli nevznikají Uživateli žádné náklady za použití komunikačních prostředků
                na dálku;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.10 Údaje o uzavření Smlouvy vč. těchto obchodních podmínek jsou uloženy v databázi Aplikace a
                Uživatel k nim má přístup prostřednictvím Uživatelského účtu.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2.11 Uživatel má možnost zjistit chyby kontrolou v Uživatelském účtu;</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.12 Opravování chyb vzniklých při zadávání dat lze provádět rovněž prostřednictvím Aplikace a tam,
                kde to Aplikace neumožňuje, prostřednictvím technické podpory, jejíž kontakt je uveden v Aplikaci;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.13 Provozovatel dodržuje veškeré platné zákony České republiky; žádné další kodexy nejsou pro
                Provozovatele závazné;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>8.2.14 Provozovatel nevyužívá žádné prostředky mimosoudního řešení sporů;</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.15 Předmětem Smlouvy není dodání zboží, na Aplikace se tak nevztahují ustanovení zákona o reklamaci;
                odpovědnost za zjevné nebo skryté vady Aplikace, které měla Aplikace v době jejího zpřístupnění
                Uživateli, lze uplatnit u Provozovatele za podmínek stanovených zákonem, Smlouvou a těmito VOP;
              </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.16 Uživatel je povinen dodržovat tyto VOP, které jsou též součástí Smlouvy, a platné a účinné právní
                předpisy České republiky;
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                8.2.17 Uživatel se může obrátit se stížností na orgán dohledu nebo státního dozoru. Česká obchodní
                inspekce vyřizuje mimosoudní stížnosti spotřebitelů způsobem a za podmínek stanovených příslušnými
                právními předpisy.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>9 Rozhodné právo</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                9.1 Tyto VOP, jakož i Smlouva, se řídí právním řádem České republiky, zejména zák. č. 89/2012 Sb.,
                občanský zákoník, ve znění pozdějších předpisů.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                9.2 Jakékoliv spory vzniklé na základě Smlouvy a/nebo těchto VOP budou rozhodovat věcně a místně
                příslušné soudy České republiky.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>10 Elektronická evidence tržeb</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                10.1 Podle zákona o evidenci tržeb je prodávající povinen vystavit kupujícímu účtenku a zároveň je
                povinen zaevidovat přijatou tržbu u správce daně online; v případě technického výpadku pak nejpozději do
                48 hodin.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>
                10.2 Fakturu obsahující povinné údaje a účtenku podle zákona č. 112/2016 Sb., o evidenci tržeb, obdrží
                Uživatel formou odkazu ke stažení těchto dokumentů či jako přílohu e-mailu. Kupující s tímto souhlasí.
              </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
              <AppText style={{ fontWeight: "bold" }}>11 Účinnost</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
            <AppText>
              <AppText>11.1 Tyto VOP nabývají platnosti a účinnosti dne 1.1. 2022</AppText>
            </AppText>
            <AppText>
              <AppText> </AppText>
            </AppText>
          </View>
        </View>
      </MaxWidth>
    </Screen>
  )
}
