import React, { View } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { MaterialIcons } from "@expo/vector-icons"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { ExpandableItemListCard } from "../container/ExpandableItemListCard"
import { UserInvitation } from "../../shared/entity/UserInvitation"
import { ItemUserInvitation } from "../flatlist/ItemUserInvitation"
import { PersonIcon } from "../svg"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function CompanyInvitationList() {
  const { companyInvitationList, deleteInvitation, createInvitation, updateInvitation } = useCompanyProvider()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()

  return (
    <View style={{ flexDirection: "column" }}>
      <ExpandableItemListCard<UserInvitation>
        key={"CompanyInvitationList"}
        name={i18n.t("invites")}
        icon={<MaterialIcons color={theme.colors.white} name="list-alt" size={theme.iconSize.regular} />}
        itemList={companyInvitationList}
        childrenIcon={<PersonIcon />}
        onCreateItem={createInvitation}
        onDeleteItem={deleteInvitation}
        onSaveItem={updateInvitation}
        template={ItemUserInvitation}
      />
    </View>
  )
}
