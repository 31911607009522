import { DocObject } from "../entity/DocObject"

export class News extends DocObject {
  title = "article"
  description = ""
  content = ""
  text = ""
  publishNote = ""
  link?: string
  constructor(data?: Partial<News>) {
    super(data)
    Object.assign(this, data)
  }
}
