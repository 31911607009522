import { Image, StyleProp, TouchableOpacity, useWindowDimensions, View, ViewStyle } from "react-native"
import React from "react"
import AppText from "../general/AppText"
import VerticalDivider from "../general/VerticalDivider"
import { Weather, WEATHER_CONDITIONS } from "../../shared/common/Weather"
import { getWeatherIcon } from "../../constants/WeatherIcon"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  style?: StyleProp<ViewStyle>
  weather: Weather
}

export default function WeatherMenuComponent({ style, weather }: Props) {
  const { i18n } = useSettingsProvider()
  const { width } = useWindowDimensions()

  return (
    <View style={[{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }, style]}>
      {weather?.getCurrentHours() && (
        <>
          <VerticalDivider />
          <TouchableOpacity
            disabled={true}
            style={{ flexDirection: "row", alignItems: "center", marginHorizontal: 10, marginTop: 5 }}
          >
            {width > 800 ? (
              <>
                <Image source={getWeatherIcon(weather.getCurrentHours()?.icon)} style={{ height: 50, width: 50 }} />
                <View style={{ flexDirection: "column", marginHorizontal: 5, alignItems: "center" }}>
                  <AppText style={{ fontWeight: "bold" }}>
                    {
                      // @ts-ignore
                      i18n.t(WEATHER_CONDITIONS.get(weather.getCurrentHours()?.icon))
                    }
                  </AppText>
                  {weather.getCurrentHours()?.temp && <AppText>{weather.getCurrentHours()?.temp} °C </AppText>}
                </View>
              </>
            ) : (
              <>
                <Image source={getWeatherIcon(weather.getCurrentHours()?.icon)} style={{ height: 50, width: 50 }} />
              </>
            )}
          </TouchableOpacity>
        </>
      )}
    </View>
  )
}
