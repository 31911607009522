import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import Screen from "../../components/general/Screen"
import MaxWidth from "../../components/container/MaxWidth"
import { ExpandableItemListCard } from "../../components/container/ExpandableItemListCard"
import { useIssueProvider } from "../../providers/IssueProvider"
import { Issue, ISSUE_TYPE } from "../../shared/entity/Issue"
import { Platform } from "react-native"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import * as Device from "expo-device"
import Constants from "expo-constants"
import { showMessage } from "react-native-flash-message"
import React from "react"
import { ItemIssueAdmin, ItemIssueReadonlyUser } from "../../components/flatlist/ItemIssue"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_SUPPORT>

export function SupportScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const { issueList, deleteIssue, updateIssue, createIssue } = useIssueProvider()
  const { currentUser, isOperationAuthorized, isSuperAdmin } = useUserProvider()

  async function handleCreateIssue() {
    showMessage({ message: i18n.t("creating_issue"), type: "success" })
    try {
      await createIssue(
        new Issue({
          createdBy: currentUser?.id as string,
          description: "",
          type: ISSUE_TYPE.ISSUE,
          deviceInfo: {
            brand: Device.brand,
            manufacturer: Device.manufacturer,
            device: Device.modelName,
            osVersion: Device.osVersion,
          },
          platform: Platform.constants || {},
          commitId: Constants?.manifest?.extra?.commitId,
        })
      )
      showMessage({ message: i18n.t("issue_created"), type: "success" })
    } catch (error) {
      console.error(error)
      showMessage({ message: error.toString(), type: "warning" })
    }
  }

  function sortByStatus(a: any, b: any) {
    if (a.solved && !b.solved) {
      return 1
    } else if (!a.solved && b.solved) {
      return -1
    } else {
      return 0
    }
  }

  return (
    <Screen>
      <MaxWidth>
        {isOperationAuthorized(OPERATION.ISSUE_MANAGE) || isSuperAdmin() ? (
          <ExpandableItemListCard<Issue>
            itemList={issueList.sort(sortByStatus)}
            startOpen={issueList.length <= 3}
            name={i18n.t("list_of_issues")}
            onSaveItem={updateIssue}
            onDeleteItem={deleteIssue}
            onCreateItem={handleCreateIssue}
            template={ItemIssueAdmin}
          />
        ) : (
          <ExpandableItemListCard<Issue>
            itemList={issueList.sort(sortByStatus).filter((e) => !e.solved)}
            startOpen={issueList.length <= 3}
            name={i18n.t("list_of_issues")}
            onSaveItem={() => console.log("onSave")}
            template={ItemIssueReadonlyUser}
          />
        )}
      </MaxWidth>
    </Screen>
  )
}
