import React, { useEffect, useState } from "react"
import { AppearanceSettings } from "../../shared/common/settings/AppearanceSettings"
import { ExpandableCard } from "../container/ExpandableCard"
import { View } from "react-native"
import AppText from "../general/AppText"
import { THEME } from "../../shared/constants/enum"
import Dropdown from "../input/Dropdown"
import { translateEnums } from "../../utils/util"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  data: AppearanceSettings
  prop: string
  onSave: (prop: string, data: AppearanceSettings) => void
}

export default function AppearanceSettingsComponent(props: Props) {
  const [appearanceSettings, setAppearanceSettings] = useState(new AppearanceSettings(props.data))
  const { i18n } = useSettingsProvider()

  useEffect(() => {
    if (!appearanceSettings) {
      setAppearanceSettings(props.data)
    }
  }, [appearanceSettings])

  const onChange = (prop: string | undefined, value: any) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return appearanceSettings
    }
    const newAppearanceSettings = new AppearanceSettings({ ...appearanceSettings, [prop]: value })
    setAppearanceSettings(newAppearanceSettings)
    console.log(newAppearanceSettings)
    props.onSave(props.prop, newAppearanceSettings)
    return newAppearanceSettings
  }

  return (
    <ExpandableCard startOpen name={i18n.t("appearance")} closable={false}>
      <View>
        <AppText type={"heading"}>{i18n.t("select_appearance")}</AppText>
        <Dropdown
          value={props?.data?.theme || "light"}
          label={i18n.t("appearance")}
          data={translateEnums(THEME, i18n)}
          onChange={(selected) => {
            onChange("theme", selected)
            //AsyncStorage.setItem("theme", selected).catch(console.error)
          }}
        />
      </View>
    </ExpandableCard>
  )
}
