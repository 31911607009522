import { IExporter, ITableExportable } from "../export/export"
import { CustomEntry } from "./Entry"

export class WorkerEntryData extends CustomEntry implements ITableExportable {
  name?: string
  job?: string
  supplier?: string
  workerCount?: number
  hours?: number
  minutes?: number

  constructor(data?: any) {
    super(data)
    Object.assign(this, data)
  }

  fieldNames(): string[] {
    return ["Jméno", "Profese", "Dodavatel", "Počet osob", "Čas"]
  }

  exportTableData(exporter: IExporter) {
    exporter.addCellText(this.name ?? "")
    exporter.addCellText(this.job ?? "")
    exporter.addCellText(this.supplier ?? "")
    exporter.addCellText(this.workerCount?.toString() ?? "")
    if (this.hours != undefined) {
      exporter.addCellText(this.hours + ":" + this.minutes?.toString().padStart(2, "0") ?? "00")
    } else {
      exporter.addCellText("")
    }
  }
}
