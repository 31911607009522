import React, { createContext, useContext, useRef, useState } from "react"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "../navigation/types"
import { ROUTE } from "../navigation/routes"
import { auth } from "../persistence/FirebaseConfig"
import { UploadTaskSnapshot } from "@firebase/storage-types"

export enum STATUS {
  NONE,
  UNDETERMINED,
  DETERMINED,
  SUCCESS,
  ERROR,
}

export type Status = {
  value: STATUS
  message: string
}

const initialVal: {
  status: Status
  setStatus: (status: Status) => void
  percentUploaded: number
  setPercentUploaded: (val: number) => void
} = {
  status: { value: STATUS.NONE, message: "" },
  setStatus: (status: Status) => undefined,
  percentUploaded: 0,
  setPercentUploaded: (val: number) => undefined,
}

export const StatusContext = createContext(initialVal)

export default function StatusProvider({ children }: { children: React.ReactNode }) {
  const [status, setStatus] = useState<Status>({ value: STATUS.NONE, message: "" })
  const [percentUploaded, setPercentUploaded] = useState<number>(0)

  return (
    <StatusContext.Provider
      value={{
        status,
        setStatus,
        percentUploaded,
        setPercentUploaded,
      }}
    >
      {children}
    </StatusContext.Provider>
  )
}

export const useStatusProvider = () => {
  const { status, setStatus, percentUploaded, setPercentUploaded } = useContext(StatusContext)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const navigateToModal = (): void => {
    navigation.navigate(ROUTE.MODAL)
  }

  const setStatusUndetermined = (message: string): void => {
    navigateToModal()
    setStatus({ message, value: STATUS.UNDETERMINED })
  }

  const setStatusDetermined = (message: string): void => {
    navigateToModal()
    setStatus({ message, value: STATUS.DETERMINED })
  }

  const setStatusSuccess = (message: string): void => {
    navigateToModal()
    setStatus({ message, value: STATUS.SUCCESS })
  }

  const setStatusError = (message: string): void => {
    navigateToModal()
    setStatus({ message, value: STATUS.ERROR })
  }

  const clearStatus = (statusType: STATUS): void => {
    if (statusType === STATUS.ERROR) {
      navigation.goBack()
    } else if (navigation.canGoBack()) {
      navigation.pop(2)
    } else if (auth.currentUser) {
      navigation.navigate(ROUTE.DRAWER)
    } else {
      navigation.navigate(ROUTE.USER_LOGIN)
    }
    setStatus({ message: "", value: STATUS.NONE })
  }

  const updatePercentageRef = useRef<any>()

  updatePercentageRef.current = (snap: UploadTaskSnapshot) => {
    const p = Math.round((snap.bytesTransferred / snap.totalBytes) * 100)
    setPercentUploaded(p)
  }

  return {
    status,
    setStatusSuccess,
    setStatusDetermined,
    setStatusUndetermined,
    setStatusError,
    clearStatus,
    updatePercentageRef,
  }
}
