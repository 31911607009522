import React, { useEffect, useState } from "react"
import { ScrollView, View } from "react-native"
import { IconButton, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import Screen from "../../components/general/Screen"
import ProjectOptions from "../../components/header/ProjectOptions"
import { FontAwesome } from "@expo/vector-icons"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import { ProjectInformation } from "../../components/project/ProjectInformation"
import { pathRenderer } from "../../navigation/pathRenderer"
import MaxWidth from "../../components/container/MaxWidth"
import { Calendar } from "react-native-calendario"
import { Diary } from "../../shared/entity/Diary"
import { MarkedDays } from "react-native-month"
import { YYYYMMDD } from "../../shared/utils/date"
import { CalendarMonthIcon, HomeWorkIcon } from "../../components/svg"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { ProjectUsers } from "../../components/project/participants/ProjectUsers"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import ProjectTasks from "../../components/tasks/ProjectTasks"
import StateInput from "../../components/input/StateInput"
import { useTaskProvider } from "../../providers/TaskProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.PROJECT_DETAIL>

enum PageNames {
  DAILY_RECORDS = "daily_records",
  PARTICIPANTS = "participants",
  TASKS = "tasks",
}

enum PageNamesWithoutTasks {
  DAILY_RECORDS = PageNames.DAILY_RECORDS,
  PARTICIPANTS = PageNames.PARTICIPANTS,
}

export function ProjectDetailScreen({ route, navigation }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { readProject, selectedProject, selectProject, diaryList, createDiary } = useProjectProvider()
  const { taskList } = useTaskProvider()
  const [startingMonth, setStartingMonth] = useState(new Date(Date.now()))
  const [showMode, setShowMode] = useState<PageNames>(PageNames.DAILY_RECORDS)

  const newDailyRecord = (date: Date) =>
    createDiary(new Diary({ date: date })).then((diary) => {
      if (selectedProject) {
        if (selectedProject.id && diary.id) {
          navigation.navigate(ROUTE.DIARY_DETAIL, {
            projectId: selectedProject.id,
            diaryId: diary.id,
          })
        }
      }
    })

  useEffect(() => {
    if ("projectId" in route.params) {
      readProject(route.params.projectId).then((project) => {
        if (project) selectProject(project)
      })
    }

    if (selectedProject) {
      navigation.setOptions({
        headerRight: () => <ProjectOptions />,
        title: `${selectedProject.title}`,
      })
      pathRenderer({
        navigation,
        path: [
          [i18n.t("projects"), () => navigation.popToTop()],
          [selectedProject.title ?? i18n.t("project"), () => {}],
        ],
      })
    }
  }, [navigation, route])

  const markDays = () => {
    const marks: MarkedDays = {}
    for (const entry of diaryList) {
      marks[YYYYMMDD(entry.date)] = {
        theme: {
          dayContainerStyle: { backgroundColor: theme.colors.primary },
          dayTextStyle: { color: theme.colors.white },
          todayTextStyle: { color: theme.colors.white },
        },
      }
    }
    return marks
  }

  return (
    <Screen>
      <ScrollView>
        <MaxWidth>
          <ExpandableCard
            icon={<HomeWorkIcon />}
            name={i18n.t("general")}
            startOpen={selectedProject?.title == i18n.t("untitled_project") || !selectedProject?.startDate}
          >
            <ProjectInformation />
          </ExpandableCard>
          <StateInput
            data={
              taskList.filter((task) => task.projectId === selectedProject?.id).length > 0
                ? PageNames
                : PageNamesWithoutTasks
            }
            value={showMode}
            onChange={setShowMode}
            translation={i18n}
          />
          {showMode === PageNames.DAILY_RECORDS ? (
            <ExpandableCard icon={<CalendarMonthIcon fill={"#ffffff"} />} name={i18n.t("daily_records")} startOpen>
              <View style={{ position: "relative" }}>
                <Calendar
                  key={new Date().toString() + diaryList.length} //doesn't re-render normally for some reason
                  numberOfMonths={1}
                  minDate={
                    new Date(selectedProject?.startDate || new Date(new Date().getFullYear(), new Date().getMonth(), 1))
                  }
                  maxDate={new Date()}
                  firstDayMonday
                  startingMonth={YYYYMMDD(startingMonth)}
                  disableRange
                  markedDays={markDays()}
                  dayNames={[
                    i18n.t("week_mon"),
                    i18n.t("week_tue"),
                    i18n.t("week_wed"),
                    i18n.t("week_thu"),
                    i18n.t("week_fri"),
                    i18n.t("week_sat"),
                    i18n.t("week_sun"),
                  ]}
                  theme={{
                    monthTitleTextStyle: {
                      fontWeight: "bold",
                      fontSize: 24,
                      color: theme.colors.primary,
                    },
                    todayContainerStyle: {
                      borderColor: theme.colors.primary,
                      borderWidth: 2,
                    },
                    todayTextStyle: {
                      color: theme.colors.primary,
                      fontWeight: "bold",
                    },
                    dayContainerStyle: {
                      marginVertical: 2,
                      marginHorizontal: 2,
                      borderRadius: 5,
                      borderWidth: 1,
                      borderColor: theme.colors.borderPrimary,
                    },
                    weekColumnsContainerStyle: {
                      paddingVertical: 10,
                    },
                    weekColumnTextStyle: {
                      fontSize: 20,
                    },
                  }}
                  monthNames={[
                    i18n.t("january"),
                    i18n.t("february"),
                    i18n.t("march"),
                    i18n.t("april"),
                    i18n.t("may"),
                    i18n.t("june"),
                    i18n.t("july"),
                    i18n.t("august"),
                    i18n.t("september"),
                    i18n.t("october"),
                    i18n.t("november"),
                    i18n.t("december"),
                  ]}
                  onPress={(date) => {
                    console.log(diaryList)
                    const selected = diaryList.find((p) => YYYYMMDD(p.date) == YYYYMMDD(date))
                    if (selected) {
                      navigation.navigate(ROUTE.DIARY_DETAIL, {
                        projectId: selected.projectId,
                        diaryId: selected.id,
                      })
                    } else {
                      newDailyRecord(date)
                    }
                  }}
                  monthHeight={350}
                />
                <View
                  style={{
                    position: "absolute",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    top: 0,
                    width: "100%",
                  }}
                >
                  {["chevron-left", "chevron-right"].map((text, index) => (
                    <IconButton
                      key={text}
                      icon={() => <FontAwesome color={theme.colors.primary} name={text} />}
                      onPress={() => {
                        startingMonth.setMonth(startingMonth.getMonth() - 1 + 2 * index)
                        setStartingMonth(new Date(startingMonth))
                      }}
                    />
                  ))}
                </View>
              </View>
            </ExpandableCard>
          ) : showMode === PageNames.PARTICIPANTS ? (
            <ProjectUsers startOpen />
          ) : showMode === PageNames.TASKS && selectedProject ? (
            <ProjectTasks startOpen project={selectedProject} />
          ) : (
            <></>
          )}
        </MaxWidth>
      </ScrollView>
    </Screen>
  )
}
