import { CustomInputDefinition, INPUT_DEFINITION } from "../common/CustomInputDefinition"

export interface EntryProps<T> {
  onSave?: (entry: T) => void
  entry: T
  parentData?: any
}

export interface Entry {
  customFields: Record<string, any>
  definitions: CustomInputDefinition[]
}

export function isAggregatable(definition: CustomInputDefinition) {
  return [
    INPUT_DEFINITION.TOGGLE,
    INPUT_DEFINITION.TIME,
    INPUT_DEFINITION.NUMBER,
    INPUT_DEFINITION.SELECT,
    INPUT_DEFINITION.TAG,
  ].includes(INPUT_DEFINITION[definition.type])
}

export function aggregateField(definition: CustomInputDefinition, current: any, value: any) {
  switch (INPUT_DEFINITION[definition.type]) {
    case INPUT_DEFINITION.NUMBER:
      return (current ?? 0) + (value ?? 0)
    case INPUT_DEFINITION.SELECT:
      return (isNaN(parseFloat(current)) ? 0 : parseFloat(current)) + (value ?? 0)
    case INPUT_DEFINITION.TOGGLE:
      return (current ? 1 : 0) + (value ?? 0)
    case INPUT_DEFINITION.TAG:
      return 1 + (value ?? 0)
    case INPUT_DEFINITION.TIME:
      if (current == undefined) return value ?? 0
      return ((current as { hours: number }).hours + (current as { minutes: number }).minutes / 60 ?? 0) + (value ?? 0)
  }
}

export class CustomEntry implements Entry {
  constructor(params?: Partial<CustomEntry>) {
    this.customFields = params?.customFields || {}
    this.definitions = params?.definitions || []
  }

  customFields: Record<string, any>
  definitions: CustomInputDefinition[]
}
