import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { User } from "../../shared/entity/User"
import DialogWindow from "../general/DialogWindow"
import Dropdown from "../input/Dropdown"
import { useState } from "react"
import { PARTICIPANT_TYPE } from "../../shared/entity/Participant"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  visible: boolean
  onUserAdded: (user?: User, type?: PARTICIPANT_TYPE) => void
}

export function AddProjectUser({ onUserAdded, visible }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { companyUsers } = useCompanyProvider()
  const [selectedUser, setSelectedUser] = useState<string | undefined>(undefined)
  const [selectedRole, setSelectedRole] = useState<PARTICIPANT_TYPE | undefined>(undefined)

  return (
    <DialogWindow
      visible={visible}
      confirmButtonTitle={i18n.t("add")}
      dismissButtonTitle={i18n.t("cancel")}
      title={i18n.t("add_user")}
      onDismiss={onUserAdded}
      disabledConfirmButton={selectedUser == undefined}
      onPress={() => {
        onUserAdded(
          companyUsers.find((u) => u.id == selectedUser),
          selectedRole
        )
      }}
    >
      <Dropdown
        label={i18n.t("users")}
        value={selectedUser}
        data={companyUsers.map((u) => ({ label: u.name ?? "", value: u.id ?? "" }))}
        onChange={(userId: string) => setSelectedUser(userId)}
      />
      <Dropdown
        label={i18n.t("role")}
        value={selectedRole}
        data={PARTICIPANT_TYPE as Record<string, string>}
        onChange={(type: PARTICIPANT_TYPE) => setSelectedRole(type)}
      />
    </DialogWindow>
  )
}
