export const THEME: Record<string, string> = {
  system: "system_appearance",
  dark: "dark",
  light: "light",
}

export const ENVIRONMENT = Object.freeze({
  DEV: "dev",
  EMU: "emu",
})
