import React from "react"
import { TextInput } from "../input/TextInput"
import { STATUS, UserInvitation } from "../../shared/entity/UserInvitation"
import { View } from "react-native"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import Button from "../button/Button"
import ChipPicker from "../input/ChipPicker"
import { ROLES } from "../../shared/entity/Role"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function MyInvitationView({ item }: { item: UserInvitation }) {
  const { company, updateInvitation } = useCompanyProvider()
  const { i18n } = useSettingsProvider()

  async function acceptInvitation() {
    item.status = STATUS.ACCEPTED
    await updateInvitation(item)
  }

  return (
    <View style={{ flexDirection: "column" }}>
      <>
        <TextInput value={item?.status} prop="status" label={i18n.t("invitation_status")} editable={false} />
      </>
      <>
        <TextInput
          value={item?.invitedIntoCompanyName || ""}
          prop="invitedIntoCompanyName"
          label={i18n.t("invitation_to_company")}
          editable={false}
        />
      </>
      <>
        <TextInput
          value={item?.fromUserEmail || ""}
          prop="fromUserEmail"
          label={i18n.t("invitation_by")}
          editable={false}
        />
      </>
      <>
        <View>
          {item?.invitedUserRoles && (
            <ChipPicker editable={false} isMultipleSelect data={ROLES} values={item?.invitedUserRoles} />
          )}
        </View>
      </>
      <>
        {item.status === STATUS.INVITED && (
          <Button onPress={() => acceptInvitation()} title={i18n.t("accept_invitation")} />
        )}
      </>
    </View>
  )
}
