import { Address } from "./Address"
import { Location } from "./Location"
import { CustomEntry } from "../entry/Entry"

export class PlaceLocation extends CustomEntry {
  address?: Address
  coordinates?: Location

  constructor(params?: Partial<PlaceLocation>) {
    super(params)
    Object.assign(this, params)
  }

  toString() {
    return "Address: " + this.address?.toString() + ", GeoLocation: " + this.coordinates?.toString()
  }
}
