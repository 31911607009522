import { Theme } from "../../../../types"
import { useTheme } from "react-native-paper"
import { View } from "react-native"
import AppText from "../../general/AppText"
import { User } from "../../../shared/entity/User"
import Cell from "../../input/Cell"
import { ActionElement } from "../../container/ActionElement"
import { WrapRow } from "../../container/WrapRow"
import { PARTICIPANT_TYPE } from "../../../shared/entity/Participant"
import { PADDING } from "../../../constants/style"
import { useSettingsProvider } from "../../../providers/SettingsProvider"

interface Props {
  user: User | undefined
  type: keyof typeof PARTICIPANT_TYPE
  removeUser: (userId: string) => void
}

export function ProjectUser({ user, removeUser, type }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  if (user == undefined) return null
  const remove = (
    <ActionElement
      actionList={[
        {
          iconName: "minus",
          label: i18n.t("retrieve"),
          action: () => {
            if (user.id) removeUser(user.id)
          },
        },
      ]}
    />
  )
  return (
    <WrapRow basis={[2, 2, 2, 1]} minima={[100, 100, 100, 50]}>
      <View style={{ flexGrow: 1, justifyContent: "center", padding: PADDING }}>
        <AppText noPadding> {user.name}</AppText>
      </View>
      <View
        style={{ backgroundColor: theme.colors.background, flexGrow: 1, justifyContent: "center", padding: PADDING }}
      >
        <AppText noPadding> {user.email}</AppText>
      </View>
      <Cell label={i18n.t("role")} value={PARTICIPANT_TYPE[type]} />
      <View
        style={{ backgroundColor: theme.colors.primary, flexGrow: 1, alignItems: "center", justifyContent: "center" }}
      >
        {remove}
      </View>
    </WrapRow>
  )
}
