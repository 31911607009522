import { WrapRow } from "../container/WrapRow"
import React, { useEffect, useRef, useState } from "react"
import { UploadFile } from "../file/UploadFile"
import { Diary } from "../../shared/entity/Diary"
import { RecordEntryData } from "../../shared/entry/RecordEntryData"
import { handleNextPress, TextInput } from "../input/TextInput"
import { EntryProps } from "../../shared/entry/Entry"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function RecordEntry(props: EntryProps<RecordEntryData>) {
  const [data, setData] = useState(new RecordEntryData(props.entry))
  const descriptionInputRef = useRef<TextInput>(null)
  const typeInputRef = useRef<TextInput>(null)
  const { i18n } = useSettingsProvider()

  const onChange = <K extends keyof RecordEntryData>(prop: K, value: RecordEntryData[K]) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return
    }
    setData(new RecordEntryData({ ...data, [prop]: value }))
  }

  useEffect(() => {
    setData(new RecordEntryData(props.entry))
  }, [props.entry.uriList])

  const onSave = () => {
    if (props.onSave) props.onSave(data)
  }

  return (
    <>
      <WrapRow>
        <TextInput
          ref={descriptionInputRef}
          value={data.description ?? ""}
          prop="description"
          onChangeText={(value: string) => onChange("description", value)}
          onBlur={onSave}
          label={i18n.t("job_description")}
          onSubmitEditing={() => handleNextPress(typeInputRef)}
        />
        {
          // todo type ["TDI", "INV", "VCP", "MPC"]
        }
        <TextInput
          ref={typeInputRef}
          value={data.type ?? ""}
          prop="type"
          onChangeText={(value: string) => onChange("type", value)}
          onBlur={onSave}
          label={i18n.t("entry_type")}
          returnKeyType={"done"}
        />
      </WrapRow>
      <UploadFile
        uriList={data.uriList}
        path={(props.parentData as Diary).getPathSegmentWithId()}
        onChange={(uriList) => {
          const newData = new RecordEntryData()
          newData.uriList = uriList
          if (props.onSave) props.onSave(newData)
          setData(newData)
        }}
      />
    </>
  )
}
