import { DefaultTheme } from "react-native-paper"

const PADDING = 10
const MARGIN = 10
const RADIUS = 10
const colors = {
  black: "#2e2e2d",
  blue: "#ADD8E6",
  borderPrimary: "#D7D7D7",
  danger: "#ff453a",
  darkBlue: "#7EC0EE",
  darkGray: "#4F4F4F",
  darkerBlue: "#6CA6CD",
  darkerGray: "#3f3f3f",
  disabled: "#4c4b4b",
  gray: "#74787B",
  green: "rgba(0,198,80,0.8)",
  lime: "#32CD32",
  light: "#ECECEC",
  lightBlue: "#B0C4DE",
  lightGray: "#99A1A6",
  placeholder: "#74787B",
  primary: "#F39719",
  darkerOrange: "#e38e18",
  red: "#ff3b30",
  success: "#8FEC78",
  warning: "#ffcc00",
  white: "#ffffff",
  transparent: "rgba(0,0,0,0)",
}

export default {
  ...DefaultTheme,
  dark: false,
  roundness: 2,
  colors: {
    ...DefaultTheme.colors,
    accent: colors.primary,
    background: "#f2f2f2",
    black: colors.black,
    blue: colors.blue,
    border: colors.borderPrimary,
    borderPrimary: colors.light,
    card: colors.white,
    danger: colors.danger,
    darkBlue: colors.darkBlue,
    darkGray: colors.darkGray,
    darkerBlue: colors.darkerBlue,
    disabled: colors.disabled,
    elevation: {
      level0: "transparent",
      level1: "rgb(247, 243, 249)",
      level2: "rgb(243, 237, 246)",
      level3: "rgb(238, 232, 244)",
      level4: "rgb(236, 230, 243)",
      level5: "rgb(233, 227, 241)",
    },
    gray: colors.gray,
    green: colors.green,
    imageBackground: colors.lightGray,
    light: colors.light,
    lightBlue: colors.lightBlue,
    lightGray: colors.lightGray,
    lime: colors.lime,
    notification: colors.red,
    primary: colors.primary,
    placeholder: colors.primary,
    success: colors.success,
    text: colors.black,
    transparent: colors.transparent,
    warning: colors.warning,
    white: colors.white,
  },
  iconButton: {
    backgroundColor: colors.transparent,
  },
  button: {
    primary: {
      backgroundColor: colors.primary,
      borderColor: colors.darkerOrange,
    },
    disabled: {
      backgroundColor: colors.disabled,
    },
    secondary: {
      backgroundColor: colors.lime,
    },
  },
  body: {
    backgroundColor: colors.light,
    textColor: colors.black,
    borderColor: colors.lightGray,
  },
  borderBottom: {
    width: "100%",
    borderBottomWidth: 1,
    borderBottomColor: colors.borderPrimary,
  },
  borderTop: {
    width: "100%",
    borderTopWidth: 1,
    borderTopColor: colors.borderPrimary,
  },
  card: {
    backgroundColor: colors.white,
  },
  fab: {
    backgroundColor: colors.white,
    position: "absolute",
  },
  hint: {
    fontStyle: "italic",
    color: colors.placeholder,
    paddingLeft: PADDING,
  },
  menu: { backgroundColor: colors.white, color: colors.white },
  input: {
    backgroundColor: colors.white,
    color: colors.black,
    placeholderColor: colors.white,
    height: 70,
    marginBottom: MARGIN,
    overflow: "hidden",
    width: "100%",
  },
  inputOutline: {
    borderRadius: 10,
    borderWidth: 2,
    borderColor: colors.primary,
  },
  iconSize: {
    small: 18,
    regular: 24,
    big: 32,
  },
  fontSize: {
    small: 14,
    regular: 16,
    medium: 20,
    big: 32,
  },
  flatListItem: {
    width: "100%",
    backgroundColor: colors.white,
    color: colors.black,
    height: 60,
    padding: PADDING,
    borderLeftColor: colors.primary,
    borderLeftWidth: 5,
  },
  header: {
    backgroundColor: colors.white,
    color: colors.black,
  },
  headerItem: {
    color: colors.black,
    backgroundColor: colors.white,
  },
  padding: PADDING,
  margin: MARGIN,
  radius: RADIUS,
  textInputHint: {
    color: colors.primary,
    fontSize: 12,
  },
  textInputError: {
    color: colors.red,
    fontSize: 12,
  },
  screenContainer: {
    flex: 1,
    backgroundColor: colors.light,
  },
  modal: {
    backgroundColor: colors.white,
    color: colors.black,
  },
  shadows: {
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 3,
  },
}
