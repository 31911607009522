import React, { useState } from "react"
import { ScrollView, View } from "react-native"
import Screen from "../../components/general/Screen"
import CompanyDetail from "../../components/company/CompanyDetail"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import MaxWidth from "../../components/container/MaxWidth"
import { ApartmentIcon } from "../../components/svg"
import CompanyUserList from "../../components/company/CompanyUserList"
import Button from "../../components/button/Button"
import { useUserProvider } from "../../providers/UserProvider"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { OPERATION } from "../../shared/entity/Role"
import CompanyInvitationList from "../../components/company/CompanyInvitationList"
import { WrapRow } from "../../components/container/WrapRow"
import Cell from "../../components/input/Cell"
import IconButton from "../../components/button/IconButton"
import { FontAwesome5 } from "@expo/vector-icons"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { PADDING } from "../../constants/style"
import Separator from "../../components/flatlist/Separator"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { CompanyStatus } from "../../shared/entity/Company"

export default function CompanyScreen() {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const { createCompany, selectCompany, company, companyList } = useCompanyProvider()
  const { isOperationAuthorized } = useUserProvider()
  const [infoVisible, setInfoVisible] = useState(false)
  const [companyToDetail, setCompanyToDetail] = useState(company)
  const companyArray: { label: string; value: string }[] = []

  if (companyList) {
    companyList.forEach((value) => companyArray.push({ label: value.name || "", value: value.id || "" }))
  }
  console.log(isOperationAuthorized(OPERATION.COMPANY_MANAGE))
  return (
    <Screen>
      <MaxWidth>
        <ScrollView style={{ flexGrow: 1 }}>
          <ExpandableCard icon={<ApartmentIcon />} name={i18n.t("select_company")} startOpen={false}>
            <>
              {companyList &&
                companyList
                  .filter((com) => com.status === CompanyStatus.ACTIVE || undefined)
                  .map((c) => (
                    <View>
                      <WrapRow basis={[2, 2, 1]} minima={[200, 200, 200]}>
                        <Cell label={i18n.t("title")} value={c.name} />
                        <Cell label={i18n.t("registration_number")} value={c.ico} />
                        <View style={{ flexDirection: "row", paddingLeft: PADDING }}>
                          <View style={{ flexShrink: 1, flexGrow: 1 }}>
                            <Button
                              title={c.id == company.id ? i18n.t("active") : i18n.t("select")}
                              disabled={c.id == company.id}
                              onPress={() => {
                                selectCompany(c.id)
                                //TODO: implement lastSelectedCompany for faster select specific company
                                AsyncStorage.setItem("lastSelectedCompany", c.id).catch(console.error)
                              }}
                            />
                          </View>
                          <IconButton
                            onPress={() => {
                              console.log("editing", c)
                              setCompanyToDetail(c)
                              setInfoVisible(true)
                            }}
                            icon={
                              <FontAwesome5 color={theme.colors.white} size={theme.iconSize.regular} name={"edit"} />
                            }
                          />
                        </View>
                      </WrapRow>
                      <Separator />
                    </View>
                  ))}
              <Button
                title={i18n.t("create_company")}
                type={"submit"}
                standalone={false}
                onPress={() => {
                  createCompany().then((c) => {
                    setCompanyToDetail(c)
                    setInfoVisible(true)
                  })
                }}
              />
            </>
          </ExpandableCard>

          <CompanyUserList />
          {isOperationAuthorized(OPERATION.COMPANY_MANAGE) && <CompanyInvitationList />}
        </ScrollView>
      </MaxWidth>
      {companyToDetail ? (
        <CompanyDetail company={companyToDetail} visible={infoVisible} setVisible={setInfoVisible} />
      ) : (
        <></>
      )}
    </Screen>
  )
}
