import React from "react"
import { View } from "react-native"

type Props = {
  children?: React.ReactNode
  height?: number | string
}

export default function MaxWidth(props: Props) {
  return (
    <View
      style={{
        maxWidth: 1200,
        width: "100%",
        alignSelf: "center",
        height: props.height,
      }}
    >
      {props.children}
    </View>
  )
}
