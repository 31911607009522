import React, { useRef, useState } from "react"
import { View } from "react-native"
import { IconButton, TextInput as PaperTextInput, useTheme } from "react-native-paper"

import { Theme } from "../../../types"
import AppText from "../general/AppText"
import { PADDING } from "../../constants/style"

type Props = {
  value: number
  onChange(prop: string | undefined, val: number): void
  onBlur?: () => void
  label?: string
  prop?: string
  error?: any
  disabled?: boolean
  required?: boolean
  maxLimit?: number
  minLimit?: number
}

export function NumberInput({
  value = 0,
  onChange,
  onBlur = () => {},
  prop,
  label = "",
  error = false,
  disabled = false,
  required = false,
  maxLimit = 100,
  minLimit = 0,
}: Props) {
  const theme: Theme = useTheme()
  const inputRef = useRef(null)
  const [isFocused, setIsFocused] = useState(false)

  function handleOnBlur() {
    setIsFocused(false)
    onBlur()
  }

  function handleOnFocus() {
    setIsFocused(true)
    inputRef.current?.focus()
  }

  const buttonStyle = {
    width: 50,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.colors?.primary,
    borderRadius: 0,
    margin: 0,
    flex: 1,
    height: "100%",
  }

  return (
    <View
      style={[{ justifyContent: "center", alignItems: "center", flexDirection: "row", paddingHorizontal: PADDING }]}
    >
      {label?.length > 0 && (
        <View style={{ flexGrow: 1, justifyContent: "center", alignItems: "center", marginRight: 10 }}>
          <AppText noPadding style={{ width: "100%" }}>
            {label}
          </AppText>
        </View>
      )}
      <View style={{ height: "100%", paddingTop: 6, paddingBottom: 10 }}>
        <IconButton
          iconColor={theme.colors?.white}
          icon="minus"
          style={[buttonStyle, { borderBottomLeftRadius: 10, borderTopLeftRadius: 10 }]}
          onPress={() => {
            onChange(prop, value == minLimit ? minLimit : --value)
          }}
        ></IconButton>
      </View>
      <PaperTextInput
        ref={inputRef}
        outlineStyle={[theme.inputOutline, { borderRadius: 0 }]}
        style={[theme.input, { flexShrink: 1, width: 200 }]}
        error={error || (required && !value)}
        mode={"outlined"}
        onChangeText={(value: string) => {
          parseInt(value, 10) > maxLimit
            ? onChange(prop, maxLimit)
            : parseInt(value, 10) < minLimit
            ? onChange(prop, minLimit)
            : onChange(prop, isNaN(parseFloat(value)) ? 0 : parseFloat(value))
        }}
        value={String(value)}
        disabled={disabled}
        keyboardType={"numeric"}
        onBlur={handleOnBlur}
        onFocus={() => setIsFocused(true)}
      />
      <View style={{ height: "100%", paddingTop: 6, paddingBottom: 10 }}>
        <IconButton
          iconColor={theme.colors?.white}
          icon="plus"
          style={[buttonStyle, { borderBottomRightRadius: 10, borderTopRightRadius: 10 }]}
          onPress={() => {
            onChange(prop, value == maxLimit ? maxLimit : ++value)
          }}
        ></IconButton>
      </View>
    </View>
  )
}
