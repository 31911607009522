import React, { ElementType, FC } from "react"
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"

type Props = {
  type?: "primary" | "danger" | "warning"
  disabled?: boolean
  size?: number
  style?: StyleProp<TextStyle>
  onPress: () => void
  icon: FC | ElementType | any
}

export default function IconButton({ onPress, icon, size = 70, disabled = false, style, type = "primary" }: Props) {
  const theme: Theme = useTheme()
  const styles = getStyles(theme, type)
  return (
    <TouchableOpacity
      disabled={disabled}
      style={[disabled ? styles.buttonDisabled : styles.button, { width: size, height: size }, style]}
      onPress={onPress}
    >
      {icon}
    </TouchableOpacity>
  )
}

function getStyles(theme: Theme, type: "primary" | "danger" | "warning") {
  return StyleSheet.create({
    button: {
      backgroundColor:
        type === "primary" ? theme.colors.primary : type === "danger" ? theme.colors.danger : theme.colors.warning,
      marginVertical: 5,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
    },
    buttonDisabled: {
      backgroundColor: theme.colors.disabled,
      marginVertical: 5,
      borderRadius: 10,
      alignItems: "center",
      justifyContent: "center",
    },
  })
}
