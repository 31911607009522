import React from "react"
import { StyleProp, StyleSheet, TextStyle, TouchableOpacity, View, ViewStyle } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import AppText from "../general/AppText"
import { PADDING, RADIUS } from "../../constants/style"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  onPress(): void
  title?: string
  type?: "submit" | "next"
  style?: StyleProp<ViewStyle>
  disabled?: boolean
  standalone?: boolean
  noMargin?: boolean
}

export default function Button({
  onPress,
  title = "save",
  type = "submit",
  style,
  disabled = false,
  standalone = false,
  noMargin = false,
  ...rest
}: Props) {
  const theme: Theme = useTheme()
  const styles: Styles = getStyles()

  return (
    <View style={{ paddingHorizontal: PADDING, width: "100%" }}>
      <TouchableOpacity
        onPress={onPress}
        disabled={disabled}
        style={[
          styles.button,
          disabled ? theme.button?.disabled : theme.button?.primary,
          standalone,
          noMargin ? {} : { marginTop: 6 },
          style,
        ]}
        {...rest}
      >
        <AppText noPadding style={styles.buttonTitle}>
          {title}
        </AppText>
      </TouchableOpacity>
    </View>
  )
}

interface Styles {
  button: StyleProp<ViewStyle>
  buttonTitle: TextStyle
}

function getStyles(theme?: Theme): Styles {
  return StyleSheet.create({
    buttonTitle: {
      fontWeight: "bold",
      color: "white",
    },
    standalone: {
      borderRadius: 0,
    },
    button: {
      ...theme?.button,
      borderRadius: RADIUS,
      borderWidth: 2,
      borderColor: theme?.colors.primary,
      height: 70,
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
    },
  })
}
