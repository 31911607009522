import React from "react"
import { TouchableHighlight } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  onPress: () => void
  top?: number
  right?: number
  disabled?: boolean
}

export default function CloseButton({ onPress, top = 0, right = -10, disabled = false }: Props) {
  const theme: Theme = useTheme()
  return (
    <TouchableHighlight
      disabled={disabled}
      underlayColor={theme.colors.primary}
      onPress={onPress}
      style={{
        position: "absolute",
        top,
        right,
        backgroundColor: "white",
        width: 25,
        height: 25,
        justifyContent: "center",
        alignItems: "center",
        borderWidth: 1,
        borderColor: theme.colors.light,
        borderRadius: 15,
      }}
    >
      <MaterialCommunityIcons name="close" size={18} />
    </TouchableHighlight>
  )
}
