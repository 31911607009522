import { DocObject } from "./DocObject"

export class Document extends DocObject {
  projectId?: string
  name?: string
  uri?: string
  documentType?: DOCUMENT_TYPE
  updatedById?: string
  mimeType?: string

  constructor(params: Partial<Document>) {
    super(params)
    this.assign(params)
  }

  getPathSegment() {
    return "Document"
  }

  assign(data: any) {
    super.assign(data)
  }
}

export enum DOCUMENT_TYPE {
  DOCUMENT = "Dokument",
  CONTRACT = "Smlouva",
  RECEIPT = "Účetní Doklad",
}
