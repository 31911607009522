import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { Company } from "../shared/entity/Company"
import { UserContext } from "./UserProvider"
import { onCompanyList } from "../persistence/FirebaseCollection"

const initialVal: {
  companies: Company[]
  setCompanies: (company: Company[]) => void
} = {
  companies: [],
  setCompanies: (company: Company[]) => {},
}

export const AdminContext = createContext(initialVal)

export default function AdminProvider({ children }: { children: ReactNode }) {
  const [companies, setCompanies] = useState<Company[]>([])
  const { currentUser } = useContext(UserContext)

  // TODO ADMIN Permission
  useEffect(() => {
    console.log("useAdminProvider useEffect")
    onCompanyList(setCompanies)
      .then((unsubscribe) => {
        return () => unsubscribe()
      })
      .catch((e) => console.error(e))
  }, [currentUser])

  return (
    <AdminContext.Provider
      value={{
        companies,
        setCompanies,
      }}
    >
      {children}
    </AdminContext.Provider>
  )
}

export const useAdminProvider = () => {
  const { setCompanies, companies } = useContext(AdminContext)

  return { companies }
}
