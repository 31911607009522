import React, { useState } from "react"
import { Platform, ScrollView, View } from "react-native"
import Screen from "../../components/general/Screen"
import EmptyScreenPlaceholder from "../../components/general/EmptyScreenPlaceholder"
import FullWidthImagePhone from "../../components/general/FullWidthImagePhone"
import FullWidthImageWeb from "../../components/general/FullWidthImageWeb"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { FILE_TYPE } from "../../providers/StorageProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_PREVIEW>

export function PreviewScreen({ route }: Props) {
  const { i18n } = useSettingsProvider()
  const [isLoading, setIsLoading] = useState(true)
  const { uri, type } = route.params
  console.log("type", type)

  function Scan() {
    if (type === FILE_TYPE.PDF) {
      setIsLoading(false)
      return <View style={{ flex: 1 }}>{/*<PreviewPDF uri={uri} />*/}</View>
    } else if (type === FILE_TYPE.IMAGE) {
      if (Platform.OS !== "web") {
        return <FullWidthImagePhone uri={uri} onLoadEnd={() => setIsLoading(false)} />
      } else {
        return (
          <ScrollView contentContainerStyle={{ flex: 1 }}>
            <FullWidthImageWeb uri={uri} onLoadEnd={() => setIsLoading(false)} width="100%" />
          </ScrollView>
        )
      }
    } else {
      setIsLoading(false)
      return <EmptyScreenPlaceholder text={i18n.t("preview_not_available")} />
    }
  }

  return (
    <Screen>
      <>{isLoading && <EmptyScreenPlaceholder text={i18n.t("loading")} />}</>

      <View style={isLoading ? { transform: [{ scale: 0 }], flex: 0 } : { flex: 1 }}>
        <Scan />
      </View>
    </Screen>
  )
}
