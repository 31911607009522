import React from "react"
import { View } from "react-native"
import AppText from "./AppText"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function EmptyScreenPlaceholder({ text }: { text?: string }) {
  const { i18n } = useSettingsProvider()
  return (
    <View style={{ flex: 1, flexGrow: 1, justifyContent: "center", alignItems: "center" }}>
      <AppText>{text || i18n.t("no_data")}</AppText>
    </View>
  )
}
