import { TouchableOpacity, View } from "react-native"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import AppText from "../../components/general/AppText"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { FontAwesome } from "@expo/vector-icons"

interface Props {
  onPress: () => void
  iconName?: string
  title: string
}

export function MemoAction({ onPress, title, iconName }: Props) {
  const theme: Theme = useTheme()

  return (
    <TouchableOpacity
      style={{
        flexDirection: "row",
        alignItems: "center",
        backgroundColor: theme.colors.primary,
        padding: PADDING,
        margin: MARGIN,
        borderRadius: RADIUS,
      }}
      onPress={onPress}
    >
      <View style={{ width: 24, height: 24, marginRight: MARGIN }}>
        {
          // @ts-ignore
          <FontAwesome color={theme.colors.white} name={iconName} size={24} />
        }
      </View>
      <AppText noPadding>{title}</AppText>
    </TouchableOpacity>
  )
}
