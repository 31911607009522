import { CustomEntry } from "../../entry/Entry"

export class AppearanceSettings extends CustomEntry {
  theme?: string

  constructor(params?: Partial<AppearanceSettings>) {
    super(params)
    Object.assign(this, params)
  }
}
