// @ts-nocheck
import * as React from "react"
import Svg, { Defs, Path, Rect, SvgProps } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: style */
const SvgComponent = (props: SvgProps) => (
  <Svg id="Vrstva_1" data-name="Vrstva 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 440.85 511.98" {...props}>
    <Defs></Defs>
    <Rect
      className="cls-2"
      style={{
        fill: "#f39719",
      }}
      x={0}
      y={231.16}
      width={440.85}
      height={53.06}
      rx={8.01}
      ry={8.01}
    />
    <Path
      className="cls-2"
      style={{
        fill: "#f39719",
      }}
      d="M189.36,0h59.02C255.41,0,261.13,5.71,261.13,12.75V210.36h-84.52V12.75C176.61,5.71,182.32,0,189.36,0Z"
    />
    <Path
      className="cls-2"
      style={{
        fill: "#f39719",
      }}
      d="M156.12,210.36H28.91S42.53,78.44,156.01,29.76V210.36"
    />
    <Path
      className="cls-2"
      style={{
        fill: "#f39719",
      }}
      d="M282.82,210.36h127.21s-13.62-131.92-127.1-180.59V210.36"
    />
    <Path
      className="cls-1"
      style={{
        fill: "#4c4b4b",
      }}
      d="M350.11,305.67v111.4H89.35v-111.4H54.46v145.31c0,33.69,27.31,60.99,60.99,60.99h208.55c33.69,0,60.99-27.31,60.99-60.99v-145.31h-34.89Zm-129.46,189.44c-17.29,0-31.31-14.02-31.31-31.32s14.02-31.32,31.31-31.32,31.31,14.02,31.31,31.32-14.02,31.32-31.31,31.32Z"
    />
  </Svg>
)
export default SvgComponent
