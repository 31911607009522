//@ts-nocheck
import clearDayIcon from "./../assets/wheather-icons/clear-day.png"
import clearNightIcon from "./../assets/wheather-icons/clear-night.png"
import cloudyIcon from "./../assets/wheather-icons/cloudy.png"
import fogIcon from "./../assets/wheather-icons/fog.png"
import hailIcon from "./../assets/wheather-icons/hail.png"
import partlyCloudyDayIcon from "./../assets/wheather-icons/partly-cloudy-day.png"
import partlyCloudyNightIcon from "./../assets/wheather-icons/partly-cloudy-night.png"
import rainSnowShowersDayIcon from "./../assets/wheather-icons/rain-snow-showers-day.png"
import rainSnowShowersNightIcon from "./../assets/wheather-icons/rain-snow-showers-night.png"
import rainSnowIcon from "./../assets/wheather-icons/rain-snow.png"
import rainIcon from "./../assets/wheather-icons/rain.png"
import showersDayIcon from "./../assets/wheather-icons/showers-day.png"
import showersNightIcon from "./../assets/wheather-icons/showers-night.png"
import sleetIcon from "./../assets/wheather-icons/sleet.png"
import snowShowersDayIcon from "./../assets/wheather-icons/snow-showers-day.png"
import snowShowersNightIcon from "./../assets/wheather-icons/snow-showers-night.png"
import snowIcon from "./../assets/wheather-icons/snow.png"
import thunderRainIcon from "./../assets/wheather-icons/thunder-rain.png"
import thunderShowersDayIcon from "./../assets/wheather-icons/thunder-showers-day.png"
import thunderShowersNightIcon from "./../assets/wheather-icons/thunder-showers-night.png"
import thunderIcon from "./../assets/wheather-icons/thunder.png"
import windIcon from "./../assets/wheather-icons/wind.png"

/**
 * Function to load the weather icon
 * @param {string} name - load weather icon name
 */
export function getWeatherIcon(name?: string) {
  console.debug("getWeatherIcon name:", name)
  const result = PNGS[name]
  if (result) return result
  return clearDayIcon
}

enum PNGS {
  "clear-day" = clearDayIcon,
  "clear-night" = clearNightIcon,
  "cloudy" = cloudyIcon,
  "fog" = fogIcon,
  "hail" = hailIcon,
  "overcast" = cloudyIcon,
  "partly-cloudy-day" = partlyCloudyDayIcon,
  "partly-cloudy-night" = partlyCloudyNightIcon,
  "rain-snow-showers-day" = rainSnowShowersDayIcon,
  "rain-snow-showers-night" = rainSnowShowersNightIcon,
  "rain-snow" = rainSnowIcon,
  "rain" = rainIcon,
  "showers-day" = showersDayIcon,
  "showers-night" = showersNightIcon,
  "sleet" = sleetIcon,
  "snow-showers-day" = snowShowersDayIcon,
  "snow-showers-night" = snowShowersNightIcon,
  "snow" = snowIcon,
  "thunder-rain" = thunderRainIcon,
  "thunder-showers-day" = thunderShowersDayIcon,
  "thunder-showers-night" = thunderShowersNightIcon,
  "thunder" = thunderIcon,
  "wind" = windIcon,
}
