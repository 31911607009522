import AppText from "../general/AppText"
import { getDateTime, getTime } from "../../shared/utils/date"
import { StyleProp, TextStyle, TouchableOpacity, useWindowDimensions, View } from "react-native"
import React, { useEffect, useState } from "react"
import VerticalDivider from "../general/VerticalDivider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  style?: StyleProp<TextStyle>
}
export default function TimeHeaderMenuComponent({ style }: Props) {
  const { width } = useWindowDimensions()
  const { i18n } = useSettingsProvider()

  const [date, setDate] = useState(new Date())
  useEffect(() => {
    setInterval(() => setDate(new Date()), 1000)
  }, [])

  return (
    <View style={{ flexDirection: "row" }}>
      <VerticalDivider />
      <TouchableOpacity
        disabled={true}
        style={[
          {
            marginTop: 5,
            flexDirection: "column",
            alignItems: "center",
            borderStyle: "solid",
          },
          style,
        ]}
      >
        <AppText style={{ fontWeight: "bold" }}>
          {getDateTime({ i18n: i18n, date: date, format: width >= 400 ? "FULLMONTH" : "MONTHASNUMBER" })}
        </AppText>
        <AppText>{getTime(date)}</AppText>
      </TouchableOpacity>
      <VerticalDivider />
    </View>
  )
}
