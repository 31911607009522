import React, { useRef, useState } from "react"
import { StyleSheet, View, ViewStyle } from "react-native"
import { useTheme } from "react-native-paper"
import { isEmailValid } from "../../shared/utils/validations"
import Button from "../button/Button"
import AppText from "./../general/AppText"
import AppLink from "./../general/AppLink"
import { errorCodes } from "../../constants/ErrorMessages"
import { NavigationProp } from "@react-navigation/native"
import { RootStackParamList } from "../../navigation/types"
import { Theme } from "../../../types"
import { ROUTE } from "../../navigation/routes"
import { handleNextPress, TextInput } from "../input/TextInput"
import { useUserProvider } from "../../providers/UserProvider"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type FormData = {
  email: string
  password: string
}

type Props = {
  navigation: NavigationProp<RootStackParamList>
}

export default function LoginForm({ navigation }: Props) {
  const { signIn, signInAnonym } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const styles: Styles = getStyles(theme)
  const { setStatusError } = useStatusProvider()

  const [formData, setFormData] = useState<FormData>({ email: "", password: "" })

  // TODO remember me - accordingly: https://www.sohamkamani.com/blog/javascript-localstorage-with-ttl-expiry/

  async function login(): Promise<void> {
    try {
      await signIn(formData.email.toLowerCase(), formData.password.trim())
    } catch (error) {
      console.error("error login", error)
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") + i18n.t("please_contact_support"))
    }
  }

  async function tryLogin(): Promise<void> {
    try {
      await signInAnonym()
    } catch (error) {
      console.error("error login", error)
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") + i18n.t("please_contact_support"))
    }
  }

  const handleChange = (prop: string, val: string): void => {
    setFormData({ ...formData, [prop]: val })
  }

  const emailInputRef = useRef<TextInput>(null)
  const passwordInputRef = useRef<TextInput>(null)

  return (
    <View style={{ flex: 1 }}>
      <TextInput
        label={"E-mail"}
        error={!isEmailValid(formData.email) && !!formData.email}
        ref={emailInputRef}
        value={formData.email}
        autoComplete={"email"}
        autoFocus
        showValidation={true}
        isValid={() => isEmailValid(formData.email)}
        onChangeText={(value: string) => handleChange("email", value)}
        prop="email"
        hintText={i18n.t("enter_email")}
        errorText={i18n.t("email_is_not_valid")}
        onSubmitEditing={() => {
          formData.email && formData.password ? login() : handleNextPress(passwordInputRef)
        }}
      />
      <TextInput
        label={i18n.t("password")}
        ref={passwordInputRef}
        prop="password"
        autoComplete={"password"}
        value={formData.password}
        onChangeText={(value: string) => handleChange("password", value)}
        returnKeyType={"done"}
        secureTextEntry={true}
        hintText={i18n.t("enter_password")}
        onSubmitEditing={() => {
          formData.email && formData.password ? login() : handleNextPress(emailInputRef)
        }}
      />
      <Button
        disabled={!(isEmailValid(formData.email.toLowerCase()) && formData.password)}
        title={i18n.t("login")}
        onPress={login}
      />
      <Button title={i18n.t("try")} onPress={tryLogin} />
      <View style={styles.footerView}>
        <AppText>
          {i18n.t("forgot_password") + "?"}&nbsp;
          <AppLink to={ROUTE.USER_PASSWORD_FORGOT} title={i18n.t("reset_password")} navigation={navigation} />
        </AppText>

        <AppText>
          {i18n.t("dont_have_an_account") + "?"}&nbsp;
          <AppLink to={ROUTE.USER_REGISTER} title={i18n.t("create_account")} navigation={navigation} />
        </AppText>
      </View>
    </View>
  )
}

interface Styles {
  footerView: ViewStyle
}

function getStyles(theme: Theme): Styles {
  return StyleSheet.create({
    footerView: {
      alignItems: "center",
      marginVertical: 10,
    },
  })
}
