import React, { useEffect, useState } from "react"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { AttendanceEntry } from "./AttendanceEntry"
import { DateRangeInput } from "../input/DateRangeInput"
import { ExpandableCard } from "../container/ExpandableCard"
import { WrapRow } from "../container/WrapRow"
import { useProjectProvider } from "../../providers/ProjectProvider"
import BoolInput from "../input/BoolInput"
import Dropdown from "../input/Dropdown"
import { FontAwesome5 } from "@expo/vector-icons"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { AttendanceMonth } from "./AttendanceMonth"
import { AttendanceCalendar } from "./AttendanceCalendar"
import { ActionElement } from "../container/ActionElement"
import { YYYYMMDD } from "../../shared/utils/date"
import { Attendance, ATTENDANCE_STATE } from "../../shared/entity/Attendance"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { CUSTOM_MODULES } from "../../shared/common/CustomInputDefinition"
import StateInput from "../input/StateInput"
import { objectWithoutKey } from "../../utils/util"
import { AttendanceUsers } from "./AttendanceUsers"
import { useWorldProvider } from "../../providers/WorldProvider"
import { View } from "react-native"
import AppText from "../general/AppText"
import { PADDING } from "../../constants/style"
import { User } from "../../shared/entity/User"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { useTaskProvider } from "../../providers/TaskProvider"

enum SHOW_MODE {
  USERS = "users",
  MONTHS = "all",
  CALENDAR = "calendar",
  DAY = "records",
}

type Props = {
  props:
    | {
        userId: string
        date: string
      }
    | undefined
}

export function ShowAttendance({ props }: Readonly<Props>) {
  const theme: Theme = useTheme()
  const { attendances, createAttendance } = useAttendanceProvider()
  const { currentUser, isOperationAuthorized } = useUserProvider()
  const { projects } = useProjectProvider()
  const { taskList } = useTaskProvider()
  const { holidays, findHoliday } = useWorldProvider()
  const { company, companyUsers } = useCompanyProvider()
  const [dateFiler, setDateFilter] = useState(!!props)
  const [showMode, setShowMode] = useState(props ? SHOW_MODE.DAY : SHOW_MODE.MONTHS)
  const [filterByUser, setFilterByUser] = useState(true)
  const [selectedUser, setSelectedUser] = useState(props ? companyUsers.find((u) => u.id == props.userId) : currentUser)
  const { i18n } = useSettingsProvider()
  const [dateRange, setDateRange] = useState(
    props
      ? {
          from: new Date(new Date(Date.parse(props.date)).setHours(0, 0)),
          to: new Date(new Date(Date.parse(props.date)).setHours(23, 59)),
        }
      : {
          from: new Date(new Date().setHours(0, 0)),
          to: new Date(new Date().setHours(23, 59)),
        }
  )
  const [projectFilter, setProjectFilter] = useState(false)
  const [taskFilter, setTaskFilter] = useState(false)
  const [projectId, setProjectId] = useState()
  const [taskId, setTaskId] = useState()

  useEffect(() => {
    if (props && !selectedUser) setSelectedUser(companyUsers.find((u) => u.id == props.userId))
  }, [companyUsers])

  const dates = []
  if (showMode == SHOW_MODE.MONTHS) {
    const numMonths = 10 // Number of months to generate
    const today = new Date() // Today's date
    const startDate = new Date(today.getFullYear(), today.getMonth() - 6, 1) // Start date 6 months ago
    const endDate = new Date(today.getFullYear(), today.getMonth() + 3, 1) // End date 3 months in the future

    // Loop through each month and add the first day to the array
    for (let i = 0; i < numMonths; i++) {
      const date = new Date(startDate.getFullYear(), startDate.getMonth() + i, 1)
      if (date >= startDate && date <= endDate) {
        dates.unshift(date)
      }
    }
  }
  dateRange.from && findHoliday(YYYYMMDD(dateRange.from))
  const addAttendance = (
    <ActionElement
      actionList={[
        {
          label: i18n.t("add"),
          iconName: "plus",
          disabled:
            !isOperationAuthorized(OPERATION.ATTENDANCE_MANAGE) &&
            dateRange.from.valueOf() < new Date().valueOf() - 24 * 60 * 60 * 1000,
          action: () => {
            if (YYYYMMDD(dateRange.from) == YYYYMMDD(dateRange.to)) {
              console.log("creating attendance...")
              createAttendance(
                new Attendance({
                  start: dateRange.from,
                  end: dateRange.from,
                  state: ATTENDANCE_STATE.FINISHED,
                  userId: selectedUser?.id,
                  companyId: company?.id,
                  definitions: company?.companyModules[CUSTOM_MODULES.ATTENDANCE]?.inputDefinitions || [],
                })
              )
            }
          },
        },
      ]}
    />
  )
  const stateNames = isOperationAuthorized(OPERATION.ATTENDANCE_MANAGE)
    ? SHOW_MODE
    : objectWithoutKey(SHOW_MODE, "USERS")
  if (selectedUser)
    return (
      <>
        <StateInput<string>
          label={i18n.t("view")}
          data={stateNames}
          value={showMode}
          onChange={setShowMode}
          translation={i18n}
        />
        {showMode == SHOW_MODE.DAY && (
          <ExpandableCard
            startOpen
            name={i18n.t("attendance_history")}
            actionElement={addAttendance}
            noPadding
            icon={<FontAwesome5 size={theme.iconSize.small} color={theme.colors.white} name={"clipboard-check"} />}
          >
            {dateFiler &&
              YYYYMMDD(dateRange.from) == YYYYMMDD(dateRange.to) &&
              holidays[YYYYMMDD(dateRange.from)]?.isHoliday && (
                <View style={{ backgroundColor: theme.colors.green, padding: PADDING, alignItems: "center" }}>
                  <AppText white noPadding>
                    {i18n.t("holiday") + ": " + holidays[YYYYMMDD(dateRange.from)].holidayName}
                  </AppText>
                </View>
              )}
            <ExpandableCard noMargin noRadius name={i18n.t("filter_attendance")}>
              <WrapRow minima={[100, 150]} basis={[1, 4]} style={{ alignItems: "center" }}>
                <BoolInput label={i18n.t("filter_by_date")} value={dateFiler} onChange={setDateFilter} />
                <DateRangeInput
                  dateFrom={dateRange.from}
                  dateTo={dateRange.to}
                  onSave={(from, to) => setDateRange({ from, to })}
                  label={i18n.t("date")}
                />
              </WrapRow>
              <WrapRow minima={[100, 150]} basis={[1, 4]} style={{ alignItems: "center" }}>
                <BoolInput label={i18n.t("filter_by_project")} value={projectFilter} onChange={setProjectFilter} />
                <Dropdown
                  label={i18n.t("project")}
                  value={projectId}
                  onChange={(selected) => {
                    setProjectId(selected)
                  }}
                  data={projects.map((p) => ({ value: p.id, label: p.title }))}
                />
              </WrapRow>
              {projectFilter && taskList.find((t) => t.projectId === projectId) && (
                <WrapRow minima={[100, 150]} basis={[1, 4]} style={{ alignItems: "center" }}>
                  <BoolInput label={i18n.t("filter_by_task")} value={taskFilter} onChange={setTaskFilter} />
                  <Dropdown
                    label={i18n.t("task")}
                    value={taskId}
                    onChange={(selected) => {
                      setTaskId(selected)
                    }}
                    data={taskList
                      .filter((t) => projectFilter && t.projectId === projectId)
                      .map((t) => ({
                        value: t.id,
                        label: t.title,
                      }))}
                  />
                </WrapRow>
              )}
              {isOperationAuthorized(OPERATION.ATTENDANCE_MANAGE) && (
                <WrapRow minima={[100, 150]} basis={[1, 4]} style={{ alignItems: "center" }}>
                  <BoolInput label={i18n.t("filter_by_user")} value={filterByUser} onChange={setFilterByUser} />
                  <Dropdown
                    label={i18n.t("user")}
                    value={selectedUser}
                    onChange={(selected) => {
                      setSelectedUser(selected)
                    }}
                    data={companyUsers.map((u) => ({ value: u, label: u.name }))}
                  />
                </WrapRow>
              )}
            </ExpandableCard>
            {attendances
              .filter(
                (a) =>
                  a.state != 0 &&
                  a.start &&
                  ((a.start >= dateRange.from && a.start <= dateRange.to) || !dateFiler) &&
                  (a.projectId == projectId || !projectFilter) &&
                  (a.taskId == taskId || !taskFilter) &&
                  (a.userId == selectedUser?.id || !filterByUser)
              )
              .map((a) => (
                <AttendanceEntry key={a.id} attendance={a} />
              ))}
          </ExpandableCard>
        )}

        {showMode == SHOW_MODE.MONTHS && (
          <ExpandableCard closable={false} noPadding startOpen name={i18n.t("all")}>
            {dates.map((date) => (
              <AttendanceMonth
                month={date}
                user={selectedUser as User}
                onPress={() => {
                  setDateRange({
                    from: new Date(date.setDate(1)),
                    to: new Date(new Date(date.setMonth(date.getMonth() + 1)).setDate(-1)),
                  })
                  setShowMode(SHOW_MODE.CALENDAR)
                }}
              />
            ))}
          </ExpandableCard>
        )}

        {showMode == SHOW_MODE.CALENDAR && selectedUser && (
          <AttendanceCalendar
            user={selectedUser}
            month={dateRange.from}
            onPress={(date) => {
              setDateRange({ from: new Date(date.setHours(0, 0)), to: new Date(date.setHours(23, 59)) })
              setDateFilter(true)
              setShowMode(SHOW_MODE.DAY)
            }}
          />
        )}

        {showMode == SHOW_MODE.USERS && selectedUser && (
          <AttendanceUsers
            selectedUser={selectedUser}
            users={companyUsers}
            onUserSelected={(user) => {
              setSelectedUser(user)
              setShowMode(SHOW_MODE.MONTHS)
            }}
          />
        )}
      </>
    )
}
