import { NavigationContainer } from "@react-navigation/native"
import * as React from "react"
import AppHeader from "../components/main/AppHeader"
import StackNavigator from "./StackNavigator"
import LinkingConfiguration from "./LinkingConfiguration"
import AppText from "../components/general/AppText"
import { Theme } from "../../types"
import DeepLinkRedirect from "./DeepLinkRedirect"

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */

export default function Navigation({ theme }: { theme: Theme }) {
  return (
    <NavigationContainer linking={LinkingConfiguration} fallback={<AppText>Loading...</AppText>} theme={theme}>
      <DeepLinkRedirect>
        <AppHeader />
        {/* <AppStatusBar></AppStatusBar> */}
        <StackNavigator />
      </DeepLinkRedirect>
    </NavigationContainer>
  )
}
