import React from "react"
import { ItemWithCustomFields } from "../flatlist/ItemWithCustomFields"
import { ExpandableCard } from "../container/ExpandableCard"
import { CustomInputDefinition } from "../../shared/common/CustomInputDefinition"
import { ActionElement } from "../container/ActionElement"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  customFields: Record<string, any>
  definitions: CustomInputDefinition[]
  label: string
  onSave: (fields?: Record<string, any>) => void
}

export function GenericEntry(props: Props) {
  const { i18n } = useSettingsProvider()
  const actions = (
    <ActionElement
      actionList={[
        {
          label: i18n.t("delete"),
          iconName: "trash-o",
          action: () => {
            props.onSave(undefined)
          },
        },
      ]}
    />
  )

  console.log("customFields::", props.customFields)

  return (
    <ExpandableCard noMargin noRadius actionElement={actions} name={props.label}>
      <ItemWithCustomFields
        definition={props.definitions ?? []}
        customFields={props.customFields}
        onSave={props.onSave}
      />
    </ExpandableCard>
  )
}
