import Screen from "../../components/general/Screen"
import MaxWidth from "../../components/container/MaxWidth"
import { Task, TASK_STATUS } from "../../shared/entity/Task"
import React, { useEffect, useState } from "react"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import { FontAwesome } from "@expo/vector-icons"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { TextInput } from "../../components/input/TextInput"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { useTaskProvider } from "../../providers/TaskProvider"
import StateInput from "../../components/input/StateInput"
import { Platform, ScrollView, View } from "react-native"
import Dropdown from "../../components/input/Dropdown"
import { DateInput } from "../../components/input/DateInput"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { MARGIN, PADDING } from "../../constants/style"
import AppText from "../../components/general/AppText"
import { getDateTime } from "../../shared/utils/date"
import { TimeUnit } from "../../shared/entity/Attendance"
import TaskChartView, { ChartType } from "../../components/tasks/TaskChartView"
import Button from "../../components/button/Button"
import { showMessage } from "react-native-flash-message"
import { User } from "../../shared/entity/User"
import { useCompanyProvider } from "../../providers/CompanyProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.TASK_DETAIL>

export default function TaskDetailScreen({ route, navigation }: Props) {
  const theme: Theme = useTheme()
  const { projects, selectProject, selectedProject } = useProjectProvider()
  const { i18n } = useSettingsProvider()
  const { updateTask, selectedTask, selectTask, createTask } = useTaskProvider()
  const [task, setTask] = useState<Task>()
  const { attendances } = useAttendanceProvider()
  const [users, setUsers] = useState<User[]>(new Array<User>())
  const { companyUsers } = useCompanyProvider()
  const { isOperationAuthorized, isSuperAdmin, readUser } = useUserProvider()
  const disabled = !(
    isSuperAdmin() ||
    isOperationAuthorized(
      OPERATION.TASK_MANAGE || OPERATION.ATTENDANCE_MANAGE || OPERATION.COMPANY_MANAGE || OPERATION.PROJECT_MANAGE
    )
  )
  const onChange = async <K extends keyof Task>(prop: K, value: Task[K]) => {
    const newTask = new Task({ ...selectedTask })
    newTask[prop] = value
    selectTask(newTask)
    return selectedTask
  }

  useEffect(() => {
    const newUsers: User[] = new Array<User>()
    attendances.forEach((att) => {
      att.taskId === selectedTask.id && att.userId && readUser(att.userId).then((user) => newUsers.push(new User(user)))
    })
    setUsers(newUsers)
  }, [selectedTask])

  const create = (task: Task) => {
    createTask(task)
      .then(() => {
        setTask(new Task({ title: i18n.t("task") }))
      })
      .catch((e) => {
        showMessage({ message: i18n.t("error_message") + ": " + e, type: "danger" })
        console.error(e)
      })
  }

  useEffect(() => {
    if (route.params.task.id) selectTask(route.params.task)
    const newProject = projects.find((p) => p.id === selectedTask.projectId)
    if (newProject) {
      selectProject(newProject)
    }
  }, [route, navigation])

  function nextDay() {
    const currentDate = selectedTask.start ? selectedTask.start : new Date()
    const nextDay = new Date(currentDate)
    nextDay.setDate(currentDate.getDate() + 1)
    return nextDay
  }

  return (
    <Screen>
      <ScrollView>
        <MaxWidth>
          <ExpandableCard
            startOpen
            name={task?.getLabel() || selectedTask.getLabel()}
            icon={<FontAwesome color={theme.colors.white} name={"tasks"} size={theme.iconSize.regular} />}
          >
            <StateInput
              disabled={disabled}
              value={task?.status || selectedTask.status || TASK_STATUS.OPEN}
              translation={i18n}
              data={TASK_STATUS}
              label={i18n.t("status")}
              onChange={(value) => onChange("status", value)}
            />
            <TextInput
              disabled={disabled}
              label={i18n.t("title")}
              value={task?.title || selectedTask.title}
              onChangeText={(value) => onChange("title", value)}
            />
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <View style={{ width: Platform.OS === "web" ? "50%" : "100%" }}>
                <DateInput
                  disabled={disabled}
                  value={task?.start || selectedTask.start || new Date()}
                  label={i18n.t("start")}
                  onSave={(value) => onChange("start", value)}
                />
              </View>
              <View style={{ width: Platform.OS === "web" ? "50%" : "100%" }}>
                {selectedTask.end && selectedTask.status === (TASK_STATUS.FINISHED || TASK_STATUS.CLOSED) ? (
                  <DateInput
                    disabled
                    value={task?.end || selectedTask.end}
                    label={i18n.t("finish")}
                    onSave={(value) => onChange("end", value)}
                  />
                ) : (
                  <DateInput
                    disabled={disabled}
                    value={task?.expectedEnd || selectedTask.expectedEnd || nextDay()}
                    label={i18n.t("expected_finish_date")}
                    onSave={(value) => onChange("expectedEnd", value)}
                  />
                )}
              </View>
            </View>
            <TextInput
              disabled={disabled}
              label={i18n.t("description")}
              multiline={true}
              style={{ height: 200 }}
              value={task?.description || selectedTask.description}
              onChangeText={(value) => onChange("description", value)}
            />
            <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
              <View style={{ width: "50%" }}>
                <Dropdown
                  disabled={disabled}
                  value={selectedProject?.id}
                  label={i18n.t("project")}
                  data={projects.map((p) => ({ label: p.title, value: p.id }))}
                  onChange={(selected) => onChange("projectId", selected)}
                />
              </View>
              <View style={{ width: "50%" }}>
                <Dropdown
                  disabled={disabled}
                  isMultipleSelect
                  value={task?.assignee || selectedTask.assignee}
                  label={i18n.t("assignee")}
                  data={companyUsers.map((user) => ({ label: user.getLabel(), value: user.id }))}
                  onChange={(selected) => onChange("assignee", selected)}
                />
              </View>
            </View>
            <Button
              onPress={async () => (!selectedTask.id ? create(selectedTask) : await updateTask(selectedTask))}
              title={!selectedTask.id ? i18n.t("create_task") : i18n.t("update")}
            />
          </ExpandableCard>
          {attendances.length > 0 && (
            <ExpandableCard name={i18n.t("records")} noPadding>
              <TaskChartView
                roundedValues={1}
                chartType={ChartType.PIE}
                timeUnit={TimeUnit.HOURS}
                task={selectedTask}
                data={attendances.filter((attendance) => attendance.taskId === selectedTask.id)}
              />
              <ExpandableCard
                info={attendances.filter((attendance) => attendance.taskId === selectedTask.id).length}
                noPadding
                noMargin
                noRadius
                name={i18n.t("records")}
              >
                <ScrollView pagingEnabled style={{ height: 500 }}>
                  {attendances
                    .filter((attendance) => attendance.taskId === selectedTask.id)
                    .map((slot) => (
                      <>
                        <View
                          style={{
                            flexDirection: "row",
                            borderBottomWidth: 2,
                            borderColor: theme.colors.borderPrimary,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: theme.colors.background,
                              padding: PADDING,
                              height: "100%",
                              marginRight: MARGIN,
                            }}
                          />
                          <View
                            style={{
                              backgroundColor: theme.colors.background,
                              flexDirection: "row",
                              justifyContent: "space-between",
                              borderColor: theme.colors.borderPrimary,
                              flexGrow: 1,
                              padding: PADDING,
                            }}
                          >
                            <View>
                              <AppText>
                                {users.find((user) => user.id === slot.userId)?.name || slot.userId || i18n.t("user")}
                              </AppText>
                              <AppText>{getDateTime({ i18n: i18n, date: slot.end, format: "MONTHANDYEAR" })}</AppText>
                            </View>
                            <View>
                              <AppText>{i18n.t("worked") + ": " + slot.deltaOfTimes(TimeUnit.HOURS, 1) + "h"}</AppText>
                            </View>
                          </View>
                        </View>
                      </>
                    ))}
                </ScrollView>
              </ExpandableCard>
            </ExpandableCard>
          )}
        </MaxWidth>
      </ScrollView>
    </Screen>
  )
}
