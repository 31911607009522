import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { Platform, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native"
import VerticalDivider from "../general/VerticalDivider"
import AppText from "../general/AppText"
import React from "react"
import { captureScreen } from "react-native-view-shot"
import { MaterialIcons } from "@expo/vector-icons"
import { ROUTE } from "../../navigation/routes"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = { visible?: boolean; style?: any }

export default function ReportIssueComponent({ visible, style }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { width } = useWindowDimensions()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  async function handleReportIssue() {
    let screenshot
    if (Platform.OS === "web") {
      const html2canvas = require("html2canvas")
      const canvas = await html2canvas(document.body)
      screenshot = canvas.toDataURL()
    } else {
      screenshot = await captureScreen({
        result: "tmpfile",
        quality: 1,
        format: "png",
      })
    }
    navigation.navigate(ROUTE.GENERAL_REPORT, { uri: screenshot })
  }

  return (
    <View style={{ flexDirection: "row" }}>
      <VerticalDivider />
      <TouchableOpacity
        style={[{ alignItems: "center", justifyContent: "center", flexDirection: "row" }, style]}
        onPress={handleReportIssue}
      >
        <View style={styles.imageWrapper}>
          {/*TODO: svg for reportissue*/}
          <MaterialIcons name="report-problem" size={50} color={theme.colors?.primary} />
        </View>
        {width > 800 ? <AppText>{i18n.t("report")}</AppText> : <></>}
      </TouchableOpacity>
      <VerticalDivider />
    </View>
  )
}

const styles = StyleSheet.create({
  imageWrapper: {
    height: "100%",
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
})
