import { DatePickerModal } from "react-native-paper-dates"
import React, { useState } from "react"
import { TextInput, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import InputBase from "./InputBase"
import { View } from "react-native"
import AppText from "../general/AppText"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  dateFrom: Date
  dateTo: Date
  onSave: (from: Date, to: Date) => void
  label: string
}

export function DateRangeInput(props: Props) {
  const { i18n } = useSettingsProvider()
  const stringToDate = (text: string) => {
    const date = new Date(0)
    date.setFullYear(parseInt(text.split(".")[2]), parseInt(text.split(".")[1]) - 1, parseInt(text.split(".")[0]))
    return date
  }

  const dateToString = (date: Date) => {
    return date.getDate() + "." + (date.getMonth() + 1) + "." + date.getFullYear()
  }

  const theme: Theme = useTheme()
  const [textFrom, setTextFrom] = useState(props.dateFrom ? dateToString(props.dateFrom) : "")
  const [textTo, setTextTo] = useState(props.dateTo ? dateToString(props.dateTo) : "")
  const [isDatePickerVisible, setDatePickerVisible] = useState(false)
  const transformer = (text: string) => {
    text = text.replace(/\//g, ".").replace(/-/g, ".")
    text = text.replace(/\.\.+/g, ".")
    return text.replace(/[^0-9.]/g, "")
  }

  const onBlur = () => {
    if (dateToString(stringToDate(textTo)) == textTo && dateToString(stringToDate(textFrom)) == textFrom) {
      props.onSave(new Date(stringToDate(textFrom).setHours(0, 0)), new Date(stringToDate(textTo).setHours(23, 59)))
    }
  }

  //() => setDatePickerVisible(true)
  return (
    <>
      <InputBase
        label={props.label}
        errorText={
          dateToString(stringToDate(textFrom)) == textFrom || dateToString(stringToDate(textTo)) == textTo
            ? ""
            : i18n.t("invalid_date")
        }
      >
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TextInput
            value={textFrom}
            style={[theme.input, { flexShrink: 1 }]}
            onChangeText={(t) => setTextFrom(transformer(t))}
            onBlur={onBlur}
          />
          <AppText type={"heading"} style={{ color: theme.colors.primary }}>
            /
          </AppText>
          <TextInput
            value={textTo}
            style={[theme.input, { flexShrink: 1 }]}
            onChangeText={(t) => setTextTo(transformer(t))}
            onBlur={onBlur}
            right={
              <TextInput.Icon
                icon={"calendar"}
                iconColor={theme.colors.primary}
                onPress={() => setDatePickerVisible(true)}
              />
            }
          />
        </View>
      </InputBase>
      <DatePickerModal
        mode={"range"}
        locale={"cs"}
        startDate={props.dateFrom}
        endDate={props.dateTo}
        label={i18n.t("select_date")}
        visible={isDatePickerVisible}
        onConfirm={(range) => {
          if (range == undefined || range.endDate == undefined || range.startDate == undefined) return
          const newStartDate = new Date(range.startDate.valueOf())
          const newEndDate = new Date(range.endDate.valueOf())
          setTextFrom(dateToString(newStartDate))
          setTextTo(dateToString(newEndDate))
          setDatePickerVisible(false)
          props.onSave(newStartDate, newEndDate)
        }}
        onDismiss={() => setDatePickerVisible(false)}
      />
    </>
  )
}
