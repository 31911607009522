import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from "react"
import { Location } from "../shared/common/Location"
import { Weather } from "../shared/common/Weather"
import { LocationContext } from "./LocationProvider"
import { UserContext } from "./UserProvider"
import { fetchWeatherBackend } from "../api/weather"
import { YYYYMMDD } from "../shared/utils/date"
import { News } from "../shared/common/News"
import { onNews } from "../persistence/FirebaseCollection"
import { FirebaseObject } from "../persistence/FirebaseObject"
import * as Notifications from "expo-notifications"
import { Platform } from "react-native"
import { User } from "../shared/entity/User"
import { createFBID } from "../utils/util"

type HolidayRecord = Record<string, { isHoliday: boolean | undefined; holidayName?: string }>

const initialVal: {
  holidays: HolidayRecord
  findHoliday: (yyyymmdd: string) => void
  weather: Weather
  setWeather: (weather: Weather) => void
  news: News[]
  setNews: (news: News[]) => void
  publishArticle: (news: News) => void
  getArticle: (articleId: string) => Promise<News>
} = {
  holidays: {},
  findHoliday: () => {},
  weather: new Weather(),
  setWeather: (weather: Weather) => new Weather(weather),
  news: [],
  setNews: (news: News[]) => {},
  publishArticle: (news: News) => {},
  getArticle: async (articleId: string) => {
    return new News()
  },
}

export const WorldContext = createContext(initialVal)

export default function WorldProvider({ children }: { children: ReactNode }) {
  const [holidays, setHolidays] = useState<HolidayRecord>({})
  const [weather, setWeather] = useState<Weather>(new Weather())
  const [news, setNews] = useState<News[]>([])
  const { location } = useContext(LocationContext)
  const { currentUser } = useContext(UserContext)
  const getWeather = () => {
    if (location) {
      fetchWeather(location, new Date())
        .then((value) => {
          setWeather(value)
        })
        .catch((e) => console.error(e))
    }
  }

  useEffect(() => {
    if (currentUser) {
      console.log("useWeatherProvider useEffect")
      getWeather()
    }
  }, [location, currentUser])

  useEffect(() => {
    console.log("News useEffect")
    onNews(setNews)
      .then((unsubscribe) => {
        return () => unsubscribe()
      })
      .catch((e) => console.error(e))
  }, [])

  const findHoliday = (yyyymmdd: string) => {
    yyyymmdd = yyyymmdd.substring(0, 4) + "-01-01"
    if (holidays[yyyymmdd] != undefined) return
    const newHolidays = { ...holidays }
    console.log("fetching holiday for ", yyyymmdd)
    fetch("https://svatkyapi.cz/api/day/" + yyyymmdd + "/interval/365").then((r) => {
      r.json().then((data) => {
        data.map((day: any) => {
          newHolidays[day.date] = {
            isHoliday: day.isHoliday,
            holidayName: day.isHoliday ? day.holidayName : null,
          }
        })
        setHolidays(newHolidays)
      })
    })
  }

  useEffect(() => {
    getWeather()
    findHoliday(YYYYMMDD(new Date()))
    const timer = setInterval(getWeather, 600000)
    return () => {
      clearInterval(timer)
    }
  }, [])

  const publishArticle = (news: News) => {
    news.id = createFBID()
    FirebaseObject.create(news)
  }

  const getArticle = async (articleId: string) => {
    const article = new News({ id: articleId })
    await FirebaseObject.read(article)
    return article
  }

  return (
    <WorldContext.Provider
      value={{
        holidays,
        findHoliday,
        weather,
        setWeather,
        setNews,
        news,
        publishArticle,
        getArticle,
      }}
    >
      {children}
    </WorldContext.Provider>
  )
}

export const useWorldProvider = () => {
  const { holidays, findHoliday, weather, setWeather, news, publishArticle, getArticle } = useContext(WorldContext)
  const { currentUser } = useContext(UserContext)
  const [expoPushToken, setExpoPushToken] = useState<string>()
  const [notification, setNotification] = useState<Notifications.Notification>()
  const notificationListener = useRef<Notifications.Subscription>()
  const responseListener = useRef<Notifications.Subscription>()

  const updateArticle = async (article: News) => {
    await FirebaseObject.update(article)
  }

  async function registerForPushNotificationsAsync() {
    let token: string | undefined

    if (Platform.OS !== "web") {
      const { status: existingStatus } = await Notifications.getPermissionsAsync()
      let finalStatus = existingStatus

      if (existingStatus !== "granted") {
        const { status } = await Notifications.requestPermissionsAsync()
        finalStatus = status
      }

      if (finalStatus !== "granted") {
        console.error("Failed to get push token for push notifications")
        return
      }

      token = (await Notifications.getExpoPushTokenAsync()).data
      console.debug(token)
    } else {
      console.error("You have to use physical device for push notifications")
    }

    if (Platform.OS === "android") {
      await Notifications.setNotificationChannelAsync("default", {
        name: "default",
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: "#FF231F7C",
      })
    }

    return token
  }

  async function setupNotifications(token: string | undefined) {
    try {
      // Replace 'FirebaseObject.update' with your actual Firebase object update method
      await FirebaseObject.update(new User({ ...currentUser, token: token }))
      Notifications.setNotificationHandler({
        handleNotification: async () => ({
          shouldShowAlert: true,
          shouldPlaySound: false,
          shouldSetBadge: false,
        }),
      })
      console.log("setting up the listener")
      notificationListener.current = Notifications.addNotificationReceivedListener((notification) => {
        setNotification(notification)
      })
      console.log(notificationListener.current)
      responseListener.current = Notifications.addNotificationResponseReceivedListener((response) => {
        console.debug("Received Notification Response", response)
        const data = response.notification.request.content.data
        console.debug("Notification data: ", data)
      })
      console.debug("user push token is uploaded")
    } catch (err) {
      console.error("error push token is not uploaded")
    }
  }

  useEffect(() => {
    if (Platform.OS !== "web") {
      registerForPushNotificationsAsync().then((token) => {
        setExpoPushToken(token)
        setupNotifications(token)
      })

      return () => {
        notificationListener.current && Notifications.removeNotificationSubscription(notificationListener.current)
        responseListener.current && Notifications.removeNotificationSubscription(responseListener.current)
      }
    }
  }, [])

  return { holidays, findHoliday, weather, setWeather, news, publishArticle, getArticle, updateArticle }
}

export async function fetchWeather(geoLocation: Location, date: Date): Promise<Weather> {
  console.debug("fetchWeather")
  try {
    return await fetchWeatherBackend(geoLocation.latitude, geoLocation.longitude, date)
  } catch (e) {
    console.error("fetchWeather from backend error: " + e)
  }
  return new Weather()
}
