import { useTheme } from "react-native-paper"
import React from "react"
import { Image, StyleProp, TextStyle, TouchableOpacity, View } from "react-native"
import { NavigationProp } from "@react-navigation/native"
import { RootStackParamList } from "../../navigation/types"
import { Theme } from "../../../types"

type Props = {
  style?: StyleProp<TextStyle>
  imageSource?: any
  children?: React.ReactNode
  onPress?: () => void
  actions?: React.ReactNode
  navigation?: NavigationProp<RootStackParamList>
}

export default function ActionCard({ actions, imageSource, children, onPress, style }: Props) {
  const theme: Theme = useTheme()

  return (
    <TouchableOpacity
      style={[
        {
          ...theme.shadows,
          width: 400,
          maxWidth: "100%",
          height: 280,
          backgroundColor: theme.card.backgroundColor,
          borderRadius: 20,
          margin: 10,
        },
        style,
      ]}
      onPress={() => onPress && onPress()}
    >
      <View
        style={{
          height: 150,
          position: "relative",
        }}
      >
        <Image
          source={imageSource}
          style={{
            flexGrow: 1,
            width: "100%",
            height: "100%",
            backgroundColor: theme.colors.imageBackground,
            resizeMode: "cover",
            position: "absolute",
            borderTopRightRadius: 20,
            borderTopLeftRadius: 20,
          }}
        />
        {actions && (
          <View
            style={{
              ...theme.fab,
              ...theme.shadows,
              aspectRatio: 1,
              borderRadius: 8,
              flexDirection: "row-reverse",
              marginHorizontal: 5,
              marginTop: 5,
              position: "absolute",
              right: 5,
            }}
          >
            {actions}
          </View>
        )}
      </View>
      <View style={{ padding: 5 }}>{children}</View>
    </TouchableOpacity>
  )
}
