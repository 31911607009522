// @ts-nocheck
import * as React from "react"
import Svg, { Path } from "react-native-svg"

// generated via https://react-svgr.com/playground/?native=true
const SvgComponent = (props: any) => (
  <Svg
    viewBox="0 0 512 129.36"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    {...props}
  >
    <Path
      style={{
        fill: "#f39719",
      }}
      d="M6.7 58.44h97.96c3.68 0 6.7 3.01 6.7 6.7 0 3.68-3.01 6.7-6.7 6.7H6.7c-3.68 0-6.7-3.01-6.7-6.7 0-3.77 3.01-6.7 6.7-6.7zM55.26 0c5.86 0 10.72 4.77 10.72 10.72v42.45H44.63V10.72C44.63 4.78 49.4 0 55.26 0zM39.44 53.17H7.28s3.44-33.33 32.07-45.63l.08 45.63M71.42 53.17h32.15S100.14 19.85 71.5 7.54l-.08 45.63"
    />
    <Path
      style={{
        fill: "#4c4b4b",
      }}
      d="M88.42 77.2v28.13H22.61V77.2h-8.88v36.67c0 8.54 6.87 15.41 15.41 15.41h52.67c8.54 0 15.41-6.87 15.41-15.41V77.2h-8.79zm-32.65 47.89c-4.35 0-7.95-3.52-7.95-7.87s3.52-7.87 7.95-7.87 7.87 3.52 7.87 7.87-3.52 7.87-7.87 7.87z"
    />
    <Path
      style={{
        fill: "white",
      }}
      d="M55.76 109.35c-4.44 0-7.95 3.52-7.95 7.87s3.6 7.87 7.95 7.87 7.87-3.52 7.87-7.87-3.43-7.87-7.87-7.87z"
    />
    <Path
      style={{
        fill: "#4c4b4b",
      }}
      d="M164.35 129.36c-8.71 0-17.21-.96-25.29-2.98-8.18-2.02-14.88-4.57-20.08-7.76l11.26-25.61c4.99 2.87 10.52 5.21 16.58 6.91 6.16 1.7 12.01 2.66 17.85 2.66 3.4 0 6.06-.21 7.97-.64s3.29-1.06 4.25-1.91c.85-.85 1.38-1.91 1.38-3.08 0-1.91-1.06-3.4-3.08-4.46-2.13-1.06-4.89-2.02-8.29-2.76s-7.23-1.49-11.26-2.34-8.18-1.91-12.33-3.4c-4.14-1.38-7.86-3.19-11.26-5.53-3.4-2.34-6.16-5.31-8.29-9.03-2.13-3.72-3.08-8.39-3.08-13.92 0-6.48 1.81-12.33 5.42-17.64 3.61-5.31 9.03-9.56 16.26-12.75s16.15-4.78 26.88-4.78c7.01 0 14.03.74 20.83 2.23 6.8 1.49 12.96 3.72 18.49 6.91l-10.52 25.5c-5.21-2.55-10.2-4.46-14.98-5.84-4.78-1.28-9.46-1.91-14.03-1.91-3.4 0-6.06.32-8.08.85-2.02.64-3.4 1.38-4.25 2.34-.85.96-1.28 2.13-1.28 3.29 0 1.81 1.06 3.19 3.08 4.25s4.89 1.91 8.29 2.55c3.4.74 7.23 1.49 11.26 2.23 4.14.85 8.18 1.91 12.33 3.29 4.04 1.38 7.86 3.19 11.26 5.53 3.4 2.34 6.16 5.21 8.29 8.93s3.08 8.29 3.08 13.71c0 6.38-1.81 12.22-5.42 17.53-3.61 5.31-9.03 9.56-16.15 12.86-7.33 3.19-16.26 4.78-27.09 4.78z"
    />
    <Path
      style={{
        fill: "#4c4b4b",
      }}
      d="M264.54 100.78c-2.13 1.49-4.68 2.23-7.54 2.23-2.02 0-3.61-.74-4.99-2.13-1.38-1.38-2.02-3.4-2.02-5.84V74.53h16.58V49.99h-16.58V27.15h-33.68v22.84h-11.16v24.54h11.16v20.83c0 11.26 3.08 19.55 9.35 24.97 6.27 5.42 14.98 8.08 26.24 8.08 3.93 0 7.76-.32 11.37-.96 3.61-.74 6.8-1.81 9.35-3.29l-8.08-23.38z"
    />
    <Path
      style={{
        fill: "#4c4b4b",
      }}
      d="M340.51 53.39c-7.65-6.27-18.49-9.35-32.51-9.35-6.38 0-12.86.74-19.55 2.34-6.59 1.59-12.33 3.83-17.11 6.8l10.73 22.21c2.66-1.91 5.95-3.51 9.67-4.68 3.72-1.17 7.44-1.7 11.16-1.7 5.42 0 9.35.96 11.79 3.08 1.86 1.62 2.97 3.79 3.41 6.48h-13.4c-8.39 0-15.41.96-20.93 2.87-5.53 1.81-9.67 4.57-12.33 8.29-2.66 3.61-4.04 8.18-4.04 13.39 0 4.68 1.17 8.93 3.4 12.75s5.63 6.91 10.09 9.14c4.46 2.23 9.99 3.4 16.68 3.4s12.64-1.49 17-4.36c2.55-1.9 4.58-4.32 6.06-7.32v10.19h31.45V83.14c0-13.6-3.93-23.48-11.58-29.75zm-26.35 52.91c-2.02 1.28-4.14 1.91-6.38 1.91-2.44 0-4.36-.64-5.84-1.91-1.38-1.28-2.13-2.76-2.13-4.68 0-2.23.85-3.93 2.44-5.1 1.7-1.17 4.25-1.7 7.86-1.7h8.18v5.9c-.96 2.51-2.3 4.35-4.14 5.58z"
    />
    <Path
      style={{
        fill: "#4c4b4b",
      }}
      d="M414.25 45.42L396.69 89.11 380.25 45.42 345.72 45.42 378.44 126.92 413.4 126.92 446.12 45.42 414.25 45.42z"
    />
    <Path
      style={{
        fill: "#f39719",
      }}
      d="M512 126.92L481.43 85.18 511.26 45.53 475.98 45.53 463.79 62.76 452.18 45.42 414.25 45.42 444.12 86.55 413.29 126.92 449.74 126.81 462.18 109.49 473.43 126.92 512 126.92z"
    />
    <Path
      style={{
        fill: "white",
      }}
      d="M22.62 77.2h65.8v28.13h-65.8V77.2z"
    />
  </Svg>
)
export default SvgComponent
