// @ts-nocheck
// import { useNavigation } from "@react-navigation/native"
import React from "react"
import EmptyScreenPlaceholder from "./EmptyScreenPlaceholder"

// import { Button } from "../button"
// import EmptyScreenPlaceholder from "./EmptyScreenPlaceholder"
// import { navigate } from "./../../navigation/RootNavigation"

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error })

    console.log(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBoundaryFunctionalComponent error={this.state.error} />
    }
    return this.props.children
  }
}

//todo not able to click on screen
export function ErrorBoundaryFunctionalComponent({ error }: { error: Error }) {
  // const { setStatusError } = useStatusProvider()
  // const navigation = useNavigation()

  // useEffect(() => {
  //   setStatusError(error.message)
  // }, [error])

  return (
    <>
      <EmptyScreenPlaceholder text={error.message} />
      {/* <Button title="Ok" onPress={() => navigate("Root")} /> */}
    </>
  )
}
