import { UserMessage } from "../../shared/entity/UserMessage"
import { TouchableOpacity, View } from "react-native"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import AppText from "../general/AppText"
import { FontAwesome } from "@expo/vector-icons"
import { PADDING } from "../../constants/style"
import { useNavigation } from "@react-navigation/native"
import { ROUTE } from "../../navigation/routes"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function UserMessageElement({ userMessage }: { userMessage: UserMessage }) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { navigate } = useNavigation()
  const { selectProject, projects } = useProjectProvider()

  function navigateToLink() {
    userMessage.read()
    FirebaseObject.update(userMessage)
    if (userMessage.link == ROUTE.PROJECT_DETAIL) {
      const projectToSelect = projects.find((p) => p.id == userMessage.payload.projectId)
      if (projectToSelect) selectProject(projectToSelect)
    }
    //@ts-ignore
    navigate(userMessage.link, userMessage.payload)
  }

  return (
    <View>
      <View style={{ flexDirection: "row", gap: PADDING, padding: PADDING }}>
        <View style={{ flexDirection: "row", alignItems: "center", gap: PADDING, flexGrow: 1, flexShrink: 1 }}>
          <AppText style={{ backgroundColor: theme.colors.primary, padding: PADDING, borderRadius: 10 }} white>
            {userMessage.source}
          </AppText>
          <AppText style={{ padding: PADDING }}>{userMessage.title}</AppText>
        </View>
        {userMessage.link && (
          <TouchableOpacity
            style={{
              backgroundColor: theme.colors.primary,
              height: theme.input.height,
              aspectRatio: 1,
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 10,
            }}
            onPress={navigateToLink}
          >
            <FontAwesome name={"mail-forward"} size={theme.iconSize.regular} color={theme.colors.white} />
          </TouchableOpacity>
        )}
        <TouchableOpacity
          style={{
            backgroundColor: theme.colors.primary,
            height: theme.input.height,
            aspectRatio: 1,
            alignItems: "center",
            justifyContent: "center",
            borderRadius: 10,
          }}
          onPress={() => {
            FirebaseObject.delete(userMessage)
          }}
        >
          <FontAwesome name={"trash-o"} size={theme.iconSize.regular} color={theme.colors.white} />
        </TouchableOpacity>
      </View>
      <View style={{ backgroundColor: theme.colors.background, height: 2 }} />
    </View>
  )
}
