import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import Screen from "../../components/general/Screen"
import React from "react"
import MaxWidth from "../../components/container/MaxWidth"
import { ScrollView } from "react-native"
import { Settings } from "../../shared/entity/Settings"
import ProjectSettingsComponent from "../../components/settings/ProjectSettingsComponent"
import { ProjectSettings } from "../../shared/common/settings/ProjectSettings"
import { AppearanceSettings } from "../../shared/common/settings/AppearanceSettings"
import AppearanceSettingsComponent from "../../components/settings/AppearanceSettingsComponent"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { Entry } from "../../shared/entry/Entry"
import { LanguageSettings } from "../../shared/common/settings/LanguageSettings"
import LanguageSettingsComponent from "../../components/settings/LanguageSettingsComponent"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.APPLICATION_SETTINGS>

export function ApplicationSettingsScreen({ route, navigation }: Props) {
  const { settings, updateSettings } = useSettingsProvider()
  console.log("ApplicationSettingsScreen data: ", settings)

  async function updateSettingsData(sectionName: string, data: Entry) {
    const newSettings = new Settings({ ...settings, [sectionName]: data })
    console.debug("updateSettingsData", newSettings)
    await updateSettings(newSettings)
  }

  return (
    <Screen style={{ flex: 1 }}>
      <MaxWidth>
        <ScrollView style={{ flexShrink: 0 }}>
          <AppearanceSettingsComponent
            prop={"appearance"}
            onSave={(prop: string, data: AppearanceSettings) => updateSettingsData(prop, data)}
            data={settings?.appearance || new AppearanceSettings()}
          />
          <LanguageSettingsComponent
            prop={"language"}
            onSave={(prop: string, data: LanguageSettings) => updateSettingsData(prop, data)}
            data={settings?.language || new LanguageSettings()}
          />
          <ProjectSettingsComponent
            prop={"project"}
            onSave={(prop: string, data: ProjectSettings) => updateSettingsData(prop, data)}
            data={settings?.project || new ProjectSettings()}
          />
        </ScrollView>
      </MaxWidth>
    </Screen>
  )
}
