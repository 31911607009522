import { DocObject, extractDateFromTimestamp } from "../DocObject"

export class Subscription extends DocObject {
  product?: string
  priceId?: string
  status?: string
  role?: string
  currentPeriodStart?: Date
  currentPeriodEnd?: Date

  constructor(params?: Partial<Subscription>) {
    super(params)
    console.log("Subs: ", params)
    this.assign(params)
  }

  assign(data: any) {
    for (const key in data) {
      switch (key) {
        case "currentPeriodStart":
          this.currentPeriodStart = extractDateFromTimestamp(data["currentPeriodStart"])
          break
        case "currentPeriodEnd":
          this.currentPeriodEnd = extractDateFromTimestamp(data["currentPeriodEnd"])
          break
        case "priceId":
          this.priceId = data["priceId"]
          break
        case "status":
          this.status = data["status"]
          break
        case "role":
          this.role = data["role"]
          break
        case "product":
          this.product = data["product"]
          break
      }
    }
  }
}
