import React, { useState } from "react"
import { SafeAreaView, StyleSheet, TouchableOpacity, useWindowDimensions, View } from "react-native"
import { Divider, Menu, useTheme } from "react-native-paper"
import WeatherMenuComponent from "./WeatherMenuComponent"
import TimeMenuComponent from "./TimeMenuComponent"
import UserMenuComponent from "./UserMenuComponent"
import ReportIssueComponent from "./ReportIssueComponent"
import LogoutMenuComponent from "./LogoutMenuComponent"
import AppText from "../general/AppText"
import { ROUTE } from "../../navigation/routes"
import { CommonActions, useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import DialogWindow from "../general/DialogWindow"
import { Theme } from "../../../types"
import { AppIconLogo, AppLogo, ExpandMoreIcon, LogoutIcon } from "../svg"
import { RADIUS } from "../../constants/style"
import { useUserProvider } from "../../providers/UserProvider"
import AvatarImage from "../file/AvatarImage"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { useWorldProvider } from "../../providers/WorldProvider"

export default function AppHeader() {
  const { currentUser, signOut } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { weather } = useWorldProvider()

  const handleLogout = async (): Promise<void> => {
    await signOut()
  }
  const [visibleMenu, setVisibleMenu] = useState(false)
  const openMenu = () => setVisibleMenu(true)
  const closeMenu = () => setVisibleMenu(false)
  const [visibleLogout, setVisibleLogout] = useState(false)
  const showDialog = () => setVisibleLogout(true)
  const hideDialog = () => setVisibleLogout(false)

  const { width } = useWindowDimensions()

  const goHome = () =>
    navigation.dispatch((state) =>
      CommonActions.reset({
        ...state,
        index: 0,
      })
    )

  return (
    <SafeAreaView style={{ width: "100%" }}>
      {currentUser && (
        <>
          <View
            style={[
              {
                paddingVertical: 2,
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                backgroundColor: theme?.header?.backgroundColor,
                borderBottomColor: theme.colors?.borderPrimary,
                borderBottomWidth: 1,
                maxHeight: 58,
                width: "100%",
              },
              theme.shadows,
            ]}
          >
            {width > 465 ? (
              <TouchableOpacity style={[styles.container, { width: "30%", maxWidth: 200 }]} onPress={() => goHome()}>
                <AppLogo style={[{ height: 60, marginHorizontal: 5 }, styles.container]} />
              </TouchableOpacity>
            ) : (
              <TouchableOpacity style={[styles.container, { width: "20%", maxWidth: 200 }]} onPress={() => goHome()}>
                <AppIconLogo style={[{ height: 60, marginRight: 2 }, styles.container]}></AppIconLogo>
              </TouchableOpacity>
            )}
            <View style={{ flexDirection: "row", flexShrink: 1 }}>
              {weather && weather.hours && <WeatherMenuComponent weather={weather} style={[styles.container]} />}
              <TimeMenuComponent style={[styles.container]} />
            </View>
            <View style={{ flexDirection: "row", justifyContent: "space-between", flexShrink: 1 }}>
              <ReportIssueComponent style={[styles.container]} />
              {width > 1400 ? (
                <>
                  {/* <CompanyMenuComponent style={[styles.container]} navigation={navigation} /> */}
                  <UserMenuComponent user={currentUser} style={[styles.container]} navigation={navigation} />
                  <LogoutMenuComponent style={[styles.container]} />
                </>
              ) : (
                <View
                  style={[
                    {
                      flexDirection: "column",
                      justifyContent: "center",
                    },
                    styles.container,
                  ]}
                >
                  <Menu
                    style={{ borderRadius: RADIUS }}
                    contentStyle={theme.menu}
                    visible={visibleMenu}
                    onDismiss={closeMenu}
                    anchor={
                      <TouchableOpacity style={{ flexDirection: "row", alignItems: "center" }} onPress={openMenu}>
                        <AvatarImage name={currentUser.name} size={46} imageUri={currentUser.imageUri} />
                        <ExpandMoreIcon />
                      </TouchableOpacity>
                    }
                  >
                    <Menu.Item
                      titleStyle={{ color: theme.body.textColor }}
                      onPress={() => {
                        if (currentUser.id) navigation.navigate(ROUTE.USER_PROFILE, { userId: currentUser.id })
                      }}
                      title={
                        <View style={{ flexDirection: "row" }}>
                          <AppText
                            maxLines={1}
                            style={{ fontWeight: "bold", justifyContent: "flex-end", color: theme.body.textColor }}
                          >
                            {currentUser.name || currentUser.email || " "}
                          </AppText>
                        </View>
                      }
                    />
                    <Divider style={{ backgroundColor: theme.colors.borderPrimary }} />
                    <Menu.Item
                      titleStyle={{ color: theme.body.textColor }}
                      onPress={showDialog}
                      title={i18n.t("logout")}
                      leadingIcon={({ size }) => <LogoutIcon width={size} height={size} color={theme.colors.primary} />}
                    />
                  </Menu>
                </View>
              )}
            </View>
          </View>
          <DialogWindow visible={visibleLogout} onDismiss={hideDialog} onPress={handleLogout} title={i18n.t("logout")}>
            <AppText>{i18n.t("do_you_want_to_be_logged_out") + "?"}</AppText>
          </DialogWindow>
        </>
      )}
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 0.1,
    flexBasis: "auto",
    marginHorizontal: 5,
  },
})
