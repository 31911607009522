import React from "react"
import { TouchableOpacity, View } from "react-native"
import { useTheme } from "react-native-paper"
import { FontAwesome5 } from "@expo/vector-icons"
import { Theme } from "../../../types"
import Container from "../general/Container"
import AppText from "../general/AppText"
import { User } from "../../shared/entity/User"

type Props = {
  user: User
  onPress: () => void
  selected?: boolean
  viewType?: "normal" | "picker"
}

export default function UserItem({ user, onPress, selected = false, viewType = "normal" }: Props) {
  const theme: Theme = useTheme()

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[theme.flatListItem, { flexDirection: "row", justifyContent: "space-between" }]}
    >
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <FontAwesome5 name="user-alt" size={theme.iconSize.small} color={theme.colors.primary} />
        <AppText noPadding style={{ paddingLeft: 10 }}>
          {user.name} - {user.email}
        </AppText>
      </View>

      {viewType === "picker" && (
        <Container>
          <FontAwesome5
            name="check-circle"
            size={theme.iconSize.small}
            color={selected ? theme.colors.primary : theme.colors.disabled}
          />
        </Container>
      )}
    </TouchableOpacity>
  )
}
