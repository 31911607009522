import { Button, Divider, Portal, useTheme } from "react-native-paper"
import React, {
  FlatList,
  Image,
  Modal,
  Pressable,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native"
import { Key, ReactNode, useState } from "react"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { Theme } from "../../../types"
import AppText from "./AppText"
import { TextInput } from "../input/TextInput"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export type ListItem = { label: string; value: string | number | undefined }

type Props = {
  label?: string
  visible?: boolean
  withSearchBar?: boolean
  listValues?: Array<string>
  withFooter?: boolean
  onClose?: () => void
  isMultipleSelect?: boolean
  listData?: ListItem[]
  position?: "center" | "relative"
  selectedListItem: (value: Array<any>) => void
  containerStyle?: StyleProp<ViewStyle>
  listStyle?: StyleProp<ViewStyle>
  containerListStyle?: StyleProp<ViewStyle>
  headerItems?: ReactNode[]
  listIcons?: [string]
  footerItems?: ReactNode[]
  fullscreen?: boolean
}

export default function ModalPicker({
  visible = false,
  onClose,
  withSearchBar,
  listData,
  listStyle,
  fullscreen = false,
  headerItems,
  isMultipleSelect = false,
  footerItems,
  containerListStyle,
  selectedListItem,
  withFooter = true,
  listValues,
  listIcons,
  position = "relative",
  containerStyle,
  label = "",
}: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const styles = getStyles(theme)

  return (
    <>
      {!fullscreen ? (
        <Portal>
          {/*@ts-ignore*/}
          <Pressable style={{ width: "100%", height: "100%", cursor: "default" }} onPress={onClose}>
            <View style={[styles.container, fullscreen ? { width: "100%" } : containerStyle]}>
              {label && <View style={[styles.header]}>{[headerItems]}</View>}
              {listData && listData?.length > 0 && (
                <>
                  {(label || headerItems) && <Divider />}
                  <View style={[styles.body, containerListStyle]}>
                    <ScrollList
                      fullscreen={fullscreen}
                      isMultipleSelect={isMultipleSelect}
                      data={listData}
                      icons={listIcons}
                      values={listValues}
                      onClose={onClose}
                      selectedListItem={selectedListItem}
                      withSearchBar={withSearchBar}
                      rowStyle={listStyle}
                    />
                  </View>
                </>
              )}
              {withFooter && (
                <>
                  {(listData || label || headerItems) && <Divider />}
                  <View style={[styles.footer]}>
                    <Button onPress={onClose}>{i18n.t("close")}</Button>
                  </View>
                </>
              )}
            </View>
          </Pressable>
        </Portal>
      ) : (
        <Modal transparent={false} visible={visible} style={{ borderRadius: 0 }}>
          <View style={[styles.container, { width: "100%", height: "100%", justifyContent: "space-between" }]}>
            {label && <View style={[styles.header]}>{[headerItems]}</View>}
            {listData && listData?.length > 0 && (
              <>
                {(label || headerItems) && <Divider />}
                <View style={containerListStyle}>
                  <ScrollList
                    fullscreen={fullscreen}
                    isMultipleSelect={isMultipleSelect}
                    data={listData}
                    icons={listIcons}
                    values={listValues}
                    selectedListItem={selectedListItem}
                    withSearchBar={withSearchBar}
                    rowStyle={listStyle}
                    containerStyle={{ flexGrow: 1, flexShrink: 1 }}
                    listStyle={{ flexGrow: 1, flexShrink: 1 }}
                  />
                </View>
              </>
            )}
            {withFooter && (
              <View style={{ flexShrink: 1 }}>
                {(listData || label || headerItems) && <Divider />}
                <View style={styles.footer}>
                  <Button onPress={onClose}>{i18n.t("close")}</Button>
                </View>
              </View>
            )}
          </View>
        </Modal>
      )}
    </>
  )
}

function ItemRow({
  data,
  currentValue,
  style,
  key,
  onPress,
  icon,
}: {
  data: ListItem
  key?: Key
  currentValue?: boolean
  icon?: string
  style?: StyleProp<ViewStyle>
  onPress?: () => void
}) {
  const theme: Theme = useTheme()

  return (
    <TouchableOpacity
      key={key}
      style={[
        {
          flexDirection: "row",
          alignItems: "center",
          flexGrow: 1,
          flexShrink: 1,
          width: "100%",
          padding: 3,
          paddingHorizontal: MARGIN,
        },
        currentValue && { backgroundColor: theme.colors.lightGray },
        style,
      ]}
      onPress={onPress}
    >
      <>
        {icon && (
          <View
            style={{
              paddingRight: PADDING,
            }}
          >
            <Image key={"image" + data.value} source={icon} />
          </View>
        )}
        <AppText>{data?.label}</AppText>
      </>
    </TouchableOpacity>
  )
}

type ScrollListProps = {
  data: ListItem[]
  placeholder?: string
  isMultipleSelect?: boolean
  onClose?: () => void
  withSearchBar?: boolean
  fullscreen?: boolean
  values?: Array<string>
  icons?: [string]
  rowStyle?: StyleProp<ViewStyle>
  searchBarStyle?: StyleProp<ViewStyle>
  containerStyle?: StyleProp<ViewStyle>
  listStyle?: StyleProp<ViewStyle>
  selectedListItem: (value: Array<any>) => void
}

export function ScrollList({
  data,
  withSearchBar = true,
  isMultipleSelect = false,
  rowStyle,
  fullscreen = false,
  icons,
  values,
  onClose = () => {},
  searchBarStyle,
  containerStyle,
  listStyle,
  placeholder = "Vyhledat",
  selectedListItem,
}: ScrollListProps) {
  const theme: Theme = useTheme()
  const [searchText, setSearchText] = useState<string>("")
  const styles = getStyles(theme)
  const newArray = new Array<string>(...values)
  const filteredData = data.filter((item) => JSON.stringify(item).toLowerCase().includes(searchText.toLowerCase()))

  const handleSearch = (text: string) => {
    setSearchText(text)
  }

  const renderItem = ({ item }) => (
    <ItemRow
      currentValue={values && values.includes(item.value)}
      style={rowStyle}
      data={item}
      key={item.key}
      icon={icons && icons[item.value]}
      onPress={() => {
        setSearchText("")
        if (!isMultipleSelect) {
          selectedListItem(item.value)
          onClose()
        } else {
          if (newArray.includes(item.value)) {
            const updatedArray = newArray.filter((value) => value !== item.value)
            console.log("updatedArray", updatedArray)
            selectedListItem(updatedArray)
          } else {
            newArray.push(item.value)
            console.log("updatedArray", newArray)
            selectedListItem(newArray)
          }
        }
      }}
    />
  )

  return (
    <>
      {withSearchBar && data.length > 3 && (
        <View style={[theme.shadows, { overflow: "hidden" }, containerStyle]}>
          <TextInput
            placeholder={placeholder}
            value={searchText}
            onChangeText={handleSearch}
            style={[{ height: "45%", overflow: "hidden" }, searchBarStyle]}
            withHelperText={false}
          />
          {data.length > 1 && <Divider />}
        </View>
      )}
      <FlatList data={filteredData} renderItem={renderItem} style={[styles.listStyle, listStyle]} />
    </>
  )
}

type ItemProps = {
  data: ListItem
  key: Key
  onPress: () => void
  style?: StyleProp<ViewStyle>
}

ModalPicker.HeaderItem = (props: ItemProps) => {
  return <ItemRow key={props.key} data={props.data} style={props.style} onPress={props.onPress} />
}

ModalPicker.FooterItem = (props: ItemProps) => {
  return <ItemRow key={props.key} data={props.data} style={props.style} onPress={props.onPress} />
}

function getStyles(theme: Theme) {
  return StyleSheet.create({
    container: {
      position: "absolute",
      minWidth: 250,
      backgroundColor: theme.colors.background,
      borderRadius: RADIUS,
      borderWidth: 1,
      borderColor: theme.colors.borderPrimary,
      overflow: "hidden",
      ...theme.shadows,
    },
    header: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: PADDING,
    },
    body: {
      maxHeight: 250,
    },
    listStyle: {},
    bodyItem: {
      paddingHorizontal: PADDING,
    },
    footer: {
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "space-between",
      padding: PADDING,
    },
  })
}
