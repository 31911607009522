import React, { useState } from "react"
import { FAB, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import AppText from "../../components/general/AppText"
import Screen from "../../components/general/Screen"
import ActionCard from "../../components/container/ActionCard"
import Grid from "../../components/container/Grid"
import ActionCardBody from "../../components/container/ActionCardBody"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import DialogWindow from "../../components/general/DialogWindow"
import { errorCodes } from "../../constants/ErrorMessages"
import { Project } from "../../shared/entity/Project"
import ProjectOptions from "../../components/header/ProjectOptions"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { useUserProvider } from "../../providers/UserProvider"
import { ScrollView } from "react-native"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.PROJECT_LIST>

export function ProjectListScreen({ route, navigation }: Props) {
  console.log("ProjectListScreen")
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { projects, selectProject, deleteProject, selectedProject, createProject, todayDiary } = useProjectProvider()
  const { isOperationAuthorized } = useUserProvider()
  const { company } = useCompanyProvider()
  const [visibleDialog, setVisibleDialog] = useState(false)
  const { setStatusError, setStatusSuccess, setStatusUndetermined } = useStatusProvider()

  const handleDelete = async () => {
    try {
      if (selectedProject) {
        setStatusUndetermined(i18n.t("deleting_project"))
        await deleteProject(new Project(selectedProject))
        setStatusSuccess(i18n.t("project_deleted"))
        setVisibleDialog(false)
      }
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }
  return (
    <Screen>
      <ScrollView>
        <Grid>
          {projects.length !== 0 ? (
            projects
              .filter(isChildOfSelected)
              .sort()
              .map((p) => (
                <ActionCard
                  key={p.id}
                  imageSource={{ uri: p.titleUri }}
                  onPress={() => {
                    /*navigation.push(ROUTE.PROJECT_LIST, { projectId: p.id })*/
                    selectProject(p)
                    if (p.id == undefined) throw "Project ID is undefined"
                    navigation.navigate(ROUTE.PROJECT_DETAIL, { projectId: p.id })
                  }}
                  actions={<ProjectOptions project={p} />}
                >
                  <ActionCardBody
                    heading={p.title || ""}
                    text={i18n.t(p.stateLabel)}
                    buttonStyle={
                      todayDiary &&
                      todayDiary.find((d) => d.projectId == p.id) && { backgroundColor: theme.colors.lime }
                    }
                    buttonText={
                      todayDiary && todayDiary.find((d) => d.projectId == p.id)
                        ? i18n.t("edit_daily_record")
                        : i18n.t("add_daily_record")
                    }
                    buttonIcon={todayDiary && todayDiary.find((d) => d.projectId == p.id) ? "pencil" : "plus"}
                    button={() => {
                      selectProject(p)
                      if (p.id == undefined) throw "Project ID is undefined"
                      navigation.navigate(ROUTE.DIARY_NEW, {
                        projectId: p.id,
                      })
                    }}
                  />
                </ActionCard>
              ))
          ) : (
            <ActionCard
              key={"newProject"}
              onPress={async () => {
                const newProject = await createProject(
                  new Project({
                    parentProjectId: "",
                    title: i18n.t("untitled_project"),
                    companyId: company?.id,
                  })
                )
                if (newProject.id == undefined) throw "Project ID is undefined"
                selectProject(newProject)
                navigation.navigate(ROUTE.PROJECT_DETAIL, { projectId: newProject.id })
              }}
            >
              <ActionCardBody
                heading={i18n.t("new_project")}
                text={i18n.t("click_a_card_to_create_a_new_project")}
                buttonText={i18n.t("create_new_project")}
                buttonIcon={"plus"}
              />
            </ActionCard>
          )}
        </Grid>
      </ScrollView>
      <FAB
        //TODO: selection of sorts or filters
        visible
        style={[
          theme.fab,
          {
            margin: 32,
            right: 0,
            bottom: 0,
          },
        ]}
        color={theme.colors?.primary}
        icon="plus"
        onPress={async () => {
          const newProject = await createProject(
            new Project({
              parentProjectId: "",
              title: i18n.t("untitled_project"),
              companyId: company?.id,
            })
          )
          if (newProject.id == undefined) throw "Project ID is undefined"
          selectProject(newProject)
          navigation.navigate(ROUTE.PROJECT_DETAIL, { projectId: newProject.id })
        }}
      />
      <DialogWindow
        visible={visibleDialog}
        onDismiss={() => setVisibleDialog(false)}
        onPress={handleDelete}
        title={`${i18n.t("delete_project")} ${selectedProject?.title}`}
      >
        <AppText>{i18n.t("do_you_want_this_project_removed")}</AppText>
      </DialogWindow>
    </Screen>
  )

  function isChildOfSelected(p: Project) {
    const paramId = route.params?.projectId || ""
    return paramId == p.parentProjectId
  }
}
