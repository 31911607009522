import React from "react"
import { SafeAreaView, View } from "react-native"
import Screen from "./../../components/general/Screen"
import AppText from "./../../components/general/AppText"
import AppLink from "./../../components/general/AppLink"
import Container from "./../../components/general/Container"
import ForgottenPasswordForm from "./../../components/auth/ForgottenPasswordForm"
import { AppLogo } from "../../components/svg"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_PASSWORD_FORGOT>

export function UserPasswordForgotScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()

  return (
    <Screen>
      <Container style={{ flex: 1, justifyContent: "center" }}>
        <SafeAreaView style={{ justifyContent: "center", alignItems: "center" }}>
          <AppLogo style={{ maxWidth: 400, width: "80%", height: 200 }} />
        </SafeAreaView>
        <ForgottenPasswordForm />
        <View style={{ alignItems: "center", padding: 10 }}>
          <AppText>
            {i18n.t("you_remember_the_password")}&nbsp;
            <AppLink to={ROUTE.USER_LOGIN} title={i18n.t("login")} navigation={navigation} />
          </AppText>
        </View>
      </Container>
    </Screen>
  )
}
