export class AppInfo {
  version?: string
  environment?: string
  commitId?: string
  buildDate?: string

  vopFirestoreUrl?: string

  constructor(params?: Partial<AppInfo>) {
    Object.assign(this, params)
  }

  getPathSegment() {
    return "AppInfo"
  }
}
