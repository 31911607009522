import { CustomInputDefinition, ModuleDefinition } from "../../shared/common/CustomInputDefinition"
import { ExpandableCard } from "../container/ExpandableCard"
import React, { useEffect, useState } from "react"
import { ModuleInputDefinition } from "./ModuleInputDefinition"
import { useTheme } from "react-native-paper"
import { TouchableOpacity, useWindowDimensions, View } from "react-native"
import { Theme } from "../../../types"
import { MARGIN, PADDING } from "../../constants/style"
import { FontAwesome } from "@expo/vector-icons"
import AppText from "../general/AppText"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { showMessage } from "react-native-flash-message"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  name: string
}

export function ModuleComponent(props: Props) {
  const { width } = useWindowDimensions()
  const [moduleDefinition, setModuleDefinition] = useState([] as CustomInputDefinition[])
  const { company, updateDefinitions } = useCompanyProvider()
  const { i18n } = useSettingsProvider()

  useEffect(() => {
    setModuleDefinition(company?.companyModules[props.name]?.inputDefinitions ?? ([] as CustomInputDefinition[]))
  }, [company?.companyModules[props.name]?.inputDefinitions])

  const theme: Theme = useTheme()
  const ModuleOptions = (
    <View style={{ flexDirection: "row", gap: MARGIN }}>
      <TouchableOpacity
        style={{ padding: PADDING, flexDirection: "row", alignItems: "center", gap: MARGIN, marginRight: MARGIN }}
        onPress={() => {
          const definition = new ModuleDefinition({ name: props.name, inputDefinitions: moduleDefinition })
          updateDefinitions(definition).then(() => showMessage({ message: i18n.t("changes_saved"), type: "success" }))
        }}
      >
        <FontAwesome color={theme.colors.white} size={24} name={"save"} />
        {width > 480 && (
          <AppText noPadding white>
            {i18n.t("save")}
          </AppText>
        )}
      </TouchableOpacity>
      <TouchableOpacity
        style={{ padding: PADDING, flexDirection: "row", alignItems: "center", gap: MARGIN, marginRight: MARGIN }}
        onPress={() => {
          moduleDefinition.push(new CustomInputDefinition())
          setModuleDefinition([...moduleDefinition])
        }}
      >
        <FontAwesome color={theme.colors.white} size={24} name={"plus"} />
        {width > 480 && (
          <AppText noPadding white>
            {i18n.t("add")}
          </AppText>
        )}
      </TouchableOpacity>
    </View>
  )
  if (!company) return <></>
  return (
    <ExpandableCard name={props.name} noMargin noPadding noRadius actionElement={ModuleOptions} offset>
      {moduleDefinition.map((d, i) => (
        <ModuleInputDefinition
          isSwappable={i != 0}
          key={i}
          customModule={
            company.companyModules[props.name] ?? new ModuleDefinition({ name: props.name, inputDefinitions: [] })
          }
          definition={d}
          swap={() => {
            if (i == 0) return
            const temp = moduleDefinition[i]
            moduleDefinition[i] = moduleDefinition[i - 1]
            moduleDefinition[i - 1] = temp
            setModuleDefinition([...moduleDefinition])
          }}
          setDefinition={(newValue) => {
            if (newValue) {
              moduleDefinition[i] = newValue
            } else {
              moduleDefinition.splice(i, 1)
            }
            setModuleDefinition([...moduleDefinition])
          }}
        />
      ))}
    </ExpandableCard>
  )
}
