import { IExportable, IExporter, PDFcontent } from "../export/export"
import { extractDateFromTimestamp } from "../entity/DocObject"
import { YYYYMMDD } from "../utils/date"
import { CustomEntry } from "./Entry"
import { PARTICIPANT_TYPE } from "../entity/Participant"

export class SignatureEntryData extends CustomEntry implements IExportable {
  user?: string
  role?: string
  uri?: string
  date?: Date

  constructor(data?: Partial<SignatureEntryData>) {
    super(data)
    if (data == undefined) return
    Object.assign(this, data)
    if ("date" in data) this.date = extractDateFromTimestamp(data["date"])
  }

  get dateString() {
    if (this.date) return YYYYMMDD(this.date)
    return ""
  }

  async exportData(exporter: IExporter): Promise<PDFcontent> {
    const signature = await exporter.getBase64ImageFromUri(this.uri)
    exporter.addImage(signature, 250, false)
    if (this.user) exporter.addText(this.user)
    if (this.role) exporter.addText(PARTICIPANT_TYPE[this.role as keyof typeof PARTICIPANT_TYPE])
    if (this.date) exporter.addText(YYYYMMDD(this.date))
  }
}
