import React, { useState } from "react"
import { ScrollView } from "react-native"
import Button from "../../components/button/Button"
import AppText from "../../components/general/AppText"
import Container from "../../components/general/Container"
import Screen from "../../components/general/Screen"
import PasswordForm from "../../components/user/PasswordForm"
import { RootStackParamList } from "../../navigation/types"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { ROUTE } from "../../navigation/routes"
import { useUserProvider } from "../../providers/UserProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export type ChangePasswordFormType = {
  oldPassword: string
  password: string
  passwordAgain: string
}

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_PASSWORD_CHANGE>

export function UserPasswordChangeScreen({ route, navigation }: Props) {
  const { i18n } = useSettingsProvider()
  const { changePassword } = useUserProvider()

  const [passwordFormData, setPasswordFormData] = useState<ChangePasswordFormType>({
    password: "",
    passwordAgain: "",
    oldPassword: "",
  })

  const handleChangePassword = (prop: string, val: string) => {
    setPasswordFormData({ ...passwordFormData, [prop]: val })
  }

  const handleSubmitPassword = () => {
    changePassword(passwordFormData)
  }

  const isChangePasswordDisabled = (): boolean => {
    return (
      !passwordFormData.oldPassword ||
      !passwordFormData.password ||
      !passwordFormData.passwordAgain ||
      passwordFormData.password !== passwordFormData.passwordAgain
    )
  }

  return (
    <Screen style={{ flex: 1 }}>
      <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
        <Container>
          <AppText type="heading" style={{ paddingHorizontal: 10 }}>
            {i18n.t("password_change")}
          </AppText>
          <PasswordForm data={passwordFormData} onChange={handleChangePassword} />
          <Button title={i18n.t("change")} disabled={isChangePasswordDisabled()} onPress={handleSubmitPassword} />
        </Container>
      </ScrollView>
    </Screen>
  )
}
