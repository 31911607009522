import AppText from "../general/AppText"
import { View } from "react-native"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { useTheme } from "react-native-paper"
import { WrapRow } from "../container/WrapRow"
import React, { useEffect, useState } from "react"
import { Theme } from "../../../types"
import { WeatherSettings } from "../../shared/common/settings/WeatherSettings"
import { compareNumbers } from "../../shared/utils/common"
import { EntryProps } from "../../shared/entry/Entry"
import Dropdown from "../input/Dropdown"
import { ListItem } from "../general/ModalPicker"
import BoolInput from "../input/BoolInput"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function WeatherSettingsComponent(props: EntryProps<WeatherSettings>) {
  const [weatherSettings, setWeatherSettings] = useState(props.entry)
  const theme: Theme = useTheme()
  const timesArray: ListItem[] = []
  const { i18n } = useSettingsProvider()

  for (let index = 0; index < 23 + 1; index) {
    timesArray.push({ label: `${index}:00:00`, value: index.toString() })
    index++
  }

  useEffect(() => {
    console.log("weatherSettings props: ", props.entry)
    if (!weatherSettings) {
      setWeatherSettings(props.entry)
    }
  }, [weatherSettings])

  const onSave = () => {
    if (props.onSave) props.onSave(weatherSettings)
  }

  const onChange = <K extends keyof WeatherSettings>(prop: K, value: WeatherSettings[K]) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return weatherSettings
    }
    const newWeatherSettings = new WeatherSettings({ ...weatherSettings, [prop]: value })
    setWeatherSettings(newWeatherSettings)
    console.log(newWeatherSettings)
    if (props.onSave) props.onSave(newWeatherSettings)
    return newWeatherSettings
  }

  return (
    <View>
      <AppText type={"heading"}>{i18n.t("load_weather")}</AppText>
      <AppText type={"regular"}>{i18n.t("weather_settings_info") + "."}</AppText>
      <View
        style={{
          marginHorizontal: MARGIN,
          flexDirection: "column",
          alignItems: "stretch",
          justifyContent: "flex-start",
        }}
      >
        <BoolInput
          label={i18n.t("automatic_weather")}
          value={props.entry?.automaticWeather || false}
          onChange={(value: boolean) => {
            onChange("automaticWeather", value)
            !props.entry?.timeRange ? onChange("timeRange", [7, 12, 16]) : console.log(props.entry?.timeRange)
          }}
        />
      </View>
      {props.entry?.automaticWeather && (
        <View
          style={{
            margin: MARGIN,
            padding: PADDING,
            backgroundColor: theme.colors.background,
            borderRadius: RADIUS,
          }}
        >
          <AppText type={"heading"}>{i18n.t("time_range")}</AppText>
          <WrapRow>
            <Dropdown
              value={props.entry?.timeRange ? props.entry?.timeRange.sort(compareNumbers) : []}
              label={i18n.t("time")}
              data={timesArray}
              onChange={(selected) => onChange("timeRange", selected)}
              isMultipleSelect
            />
          </WrapRow>
        </View>
      )}
    </View>
  )
}
