import { Divider, useTheme } from "react-native-paper"
import React, { ReactNode } from "react"
import { TouchableOpacity, useWindowDimensions, View } from "react-native"
import { Theme } from "../../../types"
import { ExpandableCard } from "./ExpandableCard"
import { DocObject } from "../../shared/entity/DocObject"
import { MARGIN, PADDING } from "../../constants/style"
import { FieldConfig, ItemProps } from "../flatlist/Item"
import { FontAwesome } from "@expo/vector-icons"
import AppText from "../general/AppText"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export interface ItemListProps<T extends DocObject> {
  onCreateItem?: () => void
  onSaveItem: (item: T) => void
  onDeleteItem?: (item: T) => void
  startOpen?: boolean
  name?: ReactNode
  icon?: ReactNode
  childrenIcon?: ReactNode
  itemList?: T[]
  template: (props: ItemProps<T>) => JSX.Element
  fieldConfig?: FieldConfig
}

export function ExpandableItemListCard<T extends DocObject>(props: ItemListProps<T>) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const { width } = useWindowDimensions()

  const addItem = (
    <>
      {props.onCreateItem && (
        <TouchableOpacity
          style={{ flexDirection: "row", alignItems: "center", gap: MARGIN, marginRight: MARGIN }}
          onPress={() => {
            if (props.onCreateItem) props.onCreateItem()
          }}
        >
          <FontAwesome size={24} color={theme.colors.white} name={"plus"} />
          {width >= 480 && (
            <AppText noPadding white>
              {i18n.t("add")}
            </AppText>
          )}
        </TouchableOpacity>
      )}
    </>
  )

  const removeItem = (item: T) => (
    <TouchableOpacity
      style={{ flexDirection: "row", alignItems: "center", gap: MARGIN, marginRight: MARGIN }}
      onPress={() => {
        if (props.onDeleteItem) props.onDeleteItem(item)
      }}
    >
      <FontAwesome size={24} color={theme.colors.white} name={"minus"} />
      {width >= 480 && (
        <AppText noPadding white>
          {i18n.t("delete")}
        </AppText>
      )}
    </TouchableOpacity>
  )

  return (
    <ExpandableCard
      noPadding
      startOpen={!!props.startOpen}
      name={props.name}
      icon={props.icon}
      info={props.itemList?.length || 0}
      actionElement={props.onCreateItem && addItem}
    >
      <>
        {(!props.itemList || props.itemList.length == 0) && (
          <View style={{ alignSelf: "center", padding: PADDING }}>
            <AppText>{i18n.t("there_are_no_entries")}</AppText>
          </View>
        )}
        {props.itemList &&
          props.itemList.length > 0 &&
          props.itemList.map((item, index) => (
            <>
              <Divider style={{ zIndex: -10, backgroundColor: theme.colors.borderPrimary }} />
              <ExpandableCard
                noRadius
                noMargin
                key={item.getId()}
                startOpen={false}
                name={item.getLabel()}
                icon={props.childrenIcon}
                actionElement={props.onDeleteItem && removeItem(item)}
              >
                {React.createElement(props.template, {
                  onSave: (item: T) => {
                    props.onSaveItem(item)
                  },
                  item: item,
                  fieldConfig: props.fieldConfig,
                })}
              </ExpandableCard>
            </>
          ))}
      </>
    </ExpandableCard>
  )
}
