import { View } from "react-native"
import { UploadFile } from "../file/UploadFile"
import React, { useState } from "react"
import { Project, PROJECT_STATE } from "../../shared/entity/Project"
import { showMessage } from "react-native-flash-message"
import { PlaceLocation } from "../../shared/common/PlaceLocation"
import PlaceLocationComponent from "../map/PlaceLocationComponent"
import { DateInput } from "../input/DateInput"
import { WrapRow } from "../container/WrapRow"
import { TextInput } from "../input/TextInput"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useLocationProvider } from "../../providers/LocationProvider"
import Dropdown from "../input/Dropdown"
import { translateEnums } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function ProjectInformation() {
  const { selectedProject, updateProject } = useProjectProvider()
  const { location } = useLocationProvider()
  const { i18n } = useSettingsProvider()
  const [project, setProject] = useState(new Project(selectedProject))

  const save = async () => {
    await updateProject(project)
    showMessage({ message: i18n.t("saved"), type: "success" })
  }
  if (!selectedProject) return <></>
  return (
    <View style={{ flexDirection: "column", flexWrap: "wrap", paddingBottom: 20 }}>
      <View style={{ flexGrow: 1, maxWidth: "100%" }}>
        <TextInput
          prop={"title"}
          value={project.title || ""}
          onChangeText={(val: string) => {
            project.title = val
            setProject(new Project(project))
          }}
          returnKeyType={"done"}
          label={i18n.t("title")}
          onBlur={save}
        />
        <View style={{ zIndex: 1 }}>
          <Dropdown
            value={project.state}
            prop={"state"}
            label={i18n.t("status")}
            withIcons={false}
            data={translateEnums(PROJECT_STATE, i18n)}
            onChange={async (key) => {
              const newProject = new Project(project)
              newProject.state = key
              await updateProject(newProject)
              showMessage({ message: `${i18n.t("saved")}: ${i18n.t(PROJECT_STATE[key])}`, type: "success" })
              setProject(new Project(newProject))
            }}
          />
        </View>
        {/*<TextInput
            value={project.identifier || ""}
            onChangeText={(val) => {
              project.identifier = val
              setProject(new Project(project))
            }}
            label={i18n.t("identifier")}
            onBlur={save}
          ></TextInput>*/}
        <WrapRow minima={[200, 200]}>
          <DateInput
            value={project.startDate}
            label={i18n.t("start")}
            onSave={async (date) => {
              const newProject = new Project(project)
              newProject.startDate = date
              setProject(newProject)
              await updateProject(newProject)
              showMessage({ message: i18n.t("saved"), type: "success" })
            }}
          />
          <DateInput
            value={project.endDate}
            label={i18n.t("finish")}
            onSave={async (date) => {
              const newProject = new Project(project)
              newProject.endDate = date
              await updateProject(newProject)
              showMessage({ message: i18n.t("saved"), type: "success" })
              setProject(new Project(newProject))
            }}
          />
        </WrapRow>
        <PlaceLocationComponent
          label={i18n.t("address")}
          hintText={i18n.t("enter_address")}
          autocomplete={true}
          value={project.location ?? { coordinates: location }}
          showMap={true}
          withInfo={true}
          showCurrentLocation={false}
          currentLocationLabel={project?.location?.address?.address}
          onSave={async (placeLocation: PlaceLocation) => {
            const newProject = new Project(project)
            newProject.location = placeLocation
            await updateProject(newProject)
            showMessage({ message: i18n.t("saved"), type: "success" })
          }}
        />
      </View>
      <View style={{ aspectRatio: 1, paddingBottom: 10, flexGrow: 1, minHeight: 400, maxWidth: "100%" }}>
        <UploadFile
          type={"thumbnail"}
          uriList={project.titleUri ? [project.titleUri] : []}
          onChange={async (uriList) => {
            if (selectedProject) {
              const newProject = new Project(project)
              newProject.titleUri = uriList[0]
              setProject(newProject)
              await updateProject(newProject)
            }
          }}
          path={"Project/" + project.id + "/projectImage"}
        />
      </View>
    </View>
  )
}
