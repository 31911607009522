import React, { useEffect, useRef, useState } from "react"
import { GooglePlacesAutocomplete, GooglePlacesAutocompleteRef } from "react-native-google-places-autocomplete"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { GOOGLE_API_KEY, LANGUAGE } from "../../../environments"
import { PlaceLocation } from "../../shared/common/PlaceLocation"
import { MyLocationIcon } from "../svg"
import { StyleProp, View, ViewStyle } from "react-native"
import { fetchGoogleMapPlaces } from "../../api/googleApi"
import { MARGIN, RADIUS } from "../../constants/style"
import { TextInput } from "../input/TextInput"
import { useLocationProvider } from "../../providers/LocationProvider"

type Props = {
  label: string
  showCurrentLocation?: boolean
  value?: PlaceLocation
  autocomplete?: boolean
  currentLocationLabel?: string | undefined
  onAddressSelected: (location: PlaceLocation) => void
  required?: boolean
  error?: boolean
  predefinedPlaces?: []
  hintText?: string
  errorText?: string
  children?: React.ReactNode
  style?: StyleProp<ViewStyle>
}

/**
 *
 * @param label - Placeholder
 * @param location {PlaceLocation} - Input for PlaceLocation object e.g ({address: "Prague, Czechia", coordinates: { latitude: 50.0755381, longitude: 14.4378005}})
 * @param onAddressSelected Output for address
 * @param currentLocationLabel {String} - Current text for location label e.g (Current location: Prague, Czechia)
 * @param showCurrentLocation {boolean} - show location label under search text input. Default is false.
 * @param predefinedPlaces {Array} - Array e.g. [{type: 'favorite', description: 'Dominos Pizza', geometry: {location: {lat: 48.8152937, lng: 2.4597668}}, }]
 * @param style - Style
 * @constructor
 */
export default function GooglePlacesInput({
  label,
  onAddressSelected,
  value,
  hintText,
  errorText,
  currentLocationLabel,
  showCurrentLocation,
  predefinedPlaces,
  children,
  autocomplete = true,
  error = false,
  required = false,
  style,
}: Props) {
  const theme: Theme = useTheme()
  const { location } = useLocationProvider()
  const inputRef = useRef<GooglePlacesAutocompleteRef>()
  const [valueSearch, setValueSearch] = useState<string>()
  const [automaticLocation, setAutomaticLocation] = useState<boolean>(autocomplete)

  useEffect(() => {
    if (value) {
      console.log("GooglePlacesAutocompleteRef setAddressText ", value)
      inputRef.current?.setAddressText(value.address?.address || valueSearch || "")
    }
  }, [value])

  async function loadDataLocation(value?: string) {
    if (location) {
      if (automaticLocation) {
        const placeLocation: PlaceLocation | undefined = await fetchGoogleMapPlaces(
          {
            address: undefined,
            coordinates: {
              latitude: location.latitude,
              longitude: location.longitude,
            },
          } || {}
        )
        onAddressSelected(placeLocation || {})
        console.debug("address loaded! ", placeLocation)
        setValueSearch(placeLocation?.address?.address || "")
        inputRef.current?.setAddressText(value || placeLocation?.address?.address || "")
      }
    } else {
      setAutomaticLocation(false)
    }
  }

  return (
    <View style={[style]}>
      <GooglePlacesAutocomplete
        ref={inputRef}
        minLength={2}
        listViewDisplayed={false}
        renderDescription={(row) => row.description}
        keyboardShouldPersistTaps={"always"}
        onPress={async (data, detail) => {
          console.log("GooglePlacesAutocomplete onPress: ", data, detail)
          onAddressSelected(
            new PlaceLocation({
              address: {
                address: data.description,
              },
            })
          )
        }}
        enablePoweredByContainer={false}
        query={{ key: GOOGLE_API_KEY, language: LANGUAGE }}
        fetchDetails={true}
        onFail={(error) => console.error("GooglePlacesAutocomplete error:", error)}
        onNotFound={() => console.log("GooglePlacesAutocomplete no results")}
        currentLocation={showCurrentLocation || false}
        suppressDefaultStyles={true}
        currentLocationLabel={currentLocationLabel || ""}
        numberOfLines={1}
        returnKeyType={"search"}
        keepResultsAfterBlur
        predefinedPlaces={predefinedPlaces}
        textInputProps={{
          InputComp: TextInput,
          hintText: hintText,
          errorText: errorText,
          error: error,
          label: label,
          grow: true,
          customIconAction: async () => {
            setAutomaticLocation(true)
            await loadDataLocation()
          },
          rightSideIcon: location ? <MyLocationIcon /> : <></>,
          style: [
            {
              flexShrink: 1,
              flexGrow: 1,
              height: 70,
              marginBottom: MARGIN,
              overflow: "hidden",
              width: "100%",
            },
            theme.input,
          ],
          underlineColor: theme.colors.white,
          activeUnderlineColor: theme.colors.white,
        }}
        requestUrl={{
          useOnPlatform: "web",
          url: "https://corsanywhere.herokuapp.com/https://maps.googleapis.com/maps/api",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }}
        styles={{
          container: [
            {
              flexDirection: "row",
              flexShrink: 1,
              flexGrow: 1,
              marginBottom: 10,
              marginTop: 5,
              alignItems: "flex-start",
            },
          ],
          textInputContainer: {
            alignItems: "center",
            flexDirection: "row",
            borderRadius: RADIUS,
            flexGrow: 1,
            flexShrink: 1,
          },
          poweredContainer: {
            borderColor: theme.colors.borderPrimary,
          },
          textInput: {
            borderColor: theme.colors.primary,
            marginTop: 6,
            borderRadius: RADIUS,
            marginBottom: 10,
            flexGrow: 1,
          },
          row: {
            backgroundColor: "#eeeeee",
            paddingVertical: 2,
          },
          description: theme.fontSize.regular,
          listView: {
            position: "absolute",
            width: "100%",
            marginTop: 60,
          },
          separator: {
            backgroundColor: theme.colors.borderPrimary,
          },
        }}
        placeholder={""}
      />
    </View>
  )
}
