import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { Attendance } from "../shared/entity/Attendance"
import { FirebaseObject } from "../persistence/FirebaseObject"
import {
  onAttendanceFirstOpenOrderByDate,
  onAttendanceManagerOrderByDate,
  onAttendanceOwnerOrderByDate,
} from "../persistence/FirebaseCollection"
import { isUserOperationAuthorized, UserContext, useUserProvider } from "./UserProvider"
import { CompanyContext } from "./CompanyProvider"
import { OPERATION } from "../shared/entity/Role"

const initialVal: {
  attendances: Attendance[]
  setAttendances: (attendances: Attendance[]) => void
  openAttendance: Attendance | undefined
  setOpenAttendance: (attendance: Attendance) => void
} = {
  attendances: [],
  setAttendances: () => {},
  openAttendance: undefined,
  setOpenAttendance: () => {},
}

export const AttendanceContext = createContext(initialVal)

export default function AttendanceProvider({ children }: { children: ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const { company } = useContext(CompanyContext)
  const [attendances, setAttendances] = useState<Attendance[]>([])
  const [openAttendance, setOpenAttendance] = useState<Attendance>()

  function attendanceSetter(values: Attendance[]) {
    console.debug("attendanceSetter: ", attendanceSetter)
    setAttendances(values)
  }

  useEffect(() => {
    console.log("AttendanceProvider useEffect currentUser", currentUser)
    if (currentUser?.id && company?.id) {
      if (isUserOperationAuthorized(OPERATION.ATTENDANCE_MANAGE, currentUser, company)) {
        onAttendanceManagerOrderByDate(company?.id, currentUser?.id, attendanceSetter)
          .then((unsubscribe) => {
            return () => unsubscribe()
          })
          .catch((e) => console.error(e))
      } else {
        onAttendanceOwnerOrderByDate(company?.id, currentUser?.id, attendanceSetter)
          .then((unsubscribe) => {
            return () => unsubscribe()
          })
          .catch((e) => console.error(e))
      }
      onAttendanceFirstOpenOrderByDate(company?.id, currentUser?.id, setOpenAttendance)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser, company])

  return (
    <AttendanceContext.Provider
      value={{
        attendances,
        setAttendances,
        openAttendance,
        setOpenAttendance,
      }}
    >
      {children}
    </AttendanceContext.Provider>
  )
}

export const useAttendanceProvider = () => {
  const { attendances, openAttendance } = useContext(AttendanceContext)
  const { currentUser } = useUserProvider()

  const createAttendance = async (attendance: Attendance): Promise<Attendance> => {
    if (currentUser?.id == undefined) throw Error("createAttendance: currentUser.id have to be defined")
    attendance.authorizeOwner(currentUser?.id)
    await FirebaseObject.create(attendance)
    return attendance
  }

  const readAttendance = async (attendanceId: string): Promise<Attendance> => {
    if (currentUser?.id == undefined) throw Error("createAttendance: currentUser.id have to be defined")
    const attendance = new Attendance({ id: attendanceId })
    await FirebaseObject.read(attendance)
    if (!attendance.isAuthorizedRead(currentUser?.id))
      throw Error(
        "Not authorized for current user: " + JSON.stringify(currentUser) + " attendance: " + JSON.stringify(attendance)
      )
    return attendance
  }
  const updateAttendance = async (attendance: Attendance): Promise<void> => {
    await FirebaseObject.update(attendance)
  }
  const deleteAttendance = async (attendance: Attendance): Promise<void> => {
    await FirebaseObject.delete(attendance)
  }

  return {
    attendances,
    openAttendance,
    createAttendance,
    updateAttendance,
    readAttendance,
    deleteAttendance,
  }
}
