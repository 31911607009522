import React, { useState } from "react"
import { TextInput } from "../input/TextInput"
import { STATUS, UserInvitation } from "../../shared/entity/UserInvitation"
import { FieldChangeHandler, Item, ItemProps } from "./Item"
import { View } from "react-native"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import Button from "../button/Button"
import { isEmailValid } from "../../shared/utils/validations"
import { ProgressBar } from "react-native-paper"
import AppText from "../general/AppText"
import { Entypo } from "@expo/vector-icons"
import { ROLES } from "../../shared/entity/Role"
import Dropdown from "../input/Dropdown"
import ChipPicker from "../input/ChipPicker"
import { translateEnums } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function ItemUserInvitation(props: ItemProps<UserInvitation>): JSX.Element {
  return Item<UserInvitation>(props, ItemUserInvitationRenderer)
}

export function ItemUserInvitationRenderer(item: UserInvitation, fieldChangeHandler: FieldChangeHandler) {
  const { company, sendInvitation } = useCompanyProvider()
  const { i18n } = useSettingsProvider()
  console.debug("ItemUserInvitationRenderer with item:", item)

  async function invitation() {
    console.log("invitation item:" + item)
    if (item.invitedUserEmail && item.invitedUserName && item.invitedUserRoles) {
      setInvitationState(INVITATION_STATE.LOADING)
      try {
        await sendInvitation(item)
        setInvitationState(INVITATION_STATE.FINISHED)
      } catch (e) {
        setError(e.toString())
        setInvitationState(INVITATION_STATE.ERROR)
      }
    }
  }

  function getConfirmLabel() {
    let label = i18n.t("send_invitation")
    if (item.invitedUserRoles == null || item.invitedUserRoles.length == 0)
      label = i18n.t("at_least_one_user_role_must_be_assigned")
    if (!isEmailValid(item.invitedUserEmail)) label = i18n.t("email_must_be_valid")
    if (item.status == STATUS.INVITED) label = i18n.t("send_invitation_again")
    return label
  }

  enum INVITATION_STATE {
    OPEN,
    LOADING,
    FINISHED,
    ERROR,
  }

  const [invitationState, setInvitationState] = useState<INVITATION_STATE>(INVITATION_STATE.OPEN)
  const [error, setError] = useState()

  return (
    <View style={{ flexDirection: "column" }}>
      <>
        <TextInput
          value={item?.status || STATUS.DRAFT}
          prop="status"
          label={i18n.t("invitation_status")}
          editable={false}
        />
      </>
      <>
        <TextInput
          value={item?.invitedUserName || ""}
          prop="invitedUserName"
          onChangeText={(value: string) => {
            fieldChangeHandler.onChange("invitedUserName", value)
          }}
          label={i18n.t("firstname_and_lastname")}
          editable={!item.status || item.status === STATUS.DRAFT}
        />
      </>
      <>
        <TextInput
          value={item?.invitedUserEmail || ""}
          prop="invitedUserEmail"
          onChangeText={(value: string) => {
            fieldChangeHandler.onChange("invitedUserEmail", value)
          }}
          label="Email"
          editable={!item.status || item.status === STATUS.DRAFT}
        />
      </>
      <>
        <View>
          {item.invitedUserRoles && item.invitedUserRoles.length >= 20 ? (
            <Dropdown
              value={item.invitedUserRoles}
              label={i18n.t("role")}
              data={translateEnums(ROLES, i18n)}
              isMultipleSelect
              onChange={(selected) => {
                fieldChangeHandler.onChange("invitedUserRoles", selected)
              }}
            />
          ) : (
            <ChipPicker
              prop={"invitedUserRoles"}
              values={item.invitedUserRoles}
              label={i18n.t("role")}
              editable
              data={translateEnums(ROLES, i18n)}
              isMultipleSelect
              onChange={fieldChangeHandler.onChange}
            />
          )}
        </View>
      </>
      <>
        {invitationState === INVITATION_STATE.OPEN && <Button onPress={() => invitation()} title={getConfirmLabel()} />}
        {invitationState === INVITATION_STATE.LOADING && (
          <View style={{ flexDirection: "column" }}>
            <ProgressBar indeterminate={true} />
            <View style={{ alignSelf: "center" }}>
              <AppText>
                {`${i18n.t("send_invitation_to")}: ${item.invitedUserName} ${i18n.t("at")} ${item.invitedUserEmail}`}
              </AppText>
            </View>
          </View>
        )}
        {invitationState === INVITATION_STATE.FINISHED && (
          <View style={{ flexDirection: "column" }}>
            <View style={{ alignSelf: "center" }}>
              <Entypo name="check" size={50} color="green" />
              <AppText>
                {`${i18n.t("invitation_was_sent_to")}: ${item.invitedUserName} ${i18n.t("at")} ${
                  item.invitedUserEmail
                }`}
              </AppText>
            </View>
            <Button title={i18n.t("ok")} onPress={() => setInvitationState(INVITATION_STATE.OPEN)} />
          </View>
        )}
        {invitationState === INVITATION_STATE.ERROR && (
          <View style={{ flexDirection: "column" }}>
            <View style={{ alignSelf: "center" }}>
              <Entypo name="cross" size={50} color="red" />
              <AppText>{`${i18n.t("there_was_a_mistake")}: ${error}`}</AppText>
            </View>
            <Button title={i18n.t("ok")} onPress={() => setInvitationState(INVITATION_STATE.OPEN)} />
          </View>
        )}
      </>
    </View>
  )
}
