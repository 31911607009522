import React, { useRef, useState } from "react"
import { getValidCondition, WEATHER_CONDITIONS } from "../../shared/common/Weather"
import { WrapRow } from "../container/WrapRow"
import { WeatherEntryData } from "../../shared/entry/WeatherEntryData"
import { View } from "react-native"
import { TimeInput } from "../input/TimeInput"
import { TextInput } from "../input/TextInput"
import Dropdown from "../input/Dropdown"
import { EntryProps } from "../../shared/entry/Entry"
import { getWeatherIcon } from "../../constants/WeatherIcon"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { translateEnums } from "../../utils/util"

export function WeatherEntry(props: EntryProps<WeatherEntryData>) {
  const { i18n } = useSettingsProvider()
  const [weather, setWeather] = useState(new WeatherEntryData(props.entry))
  const tempInputRef = useRef<typeof TextInput>(null)
  const onSave = () => {
    if (props.onSave) props.onSave(weather)
  }

  const data: Record<string, string> = {}
  const weatherOptions = new Set(WEATHER_CONDITIONS.values())
  for (const [k, v] of WEATHER_CONDITIONS) {
    if (weatherOptions.has(v)) {
      data[getValidCondition(k)] = v
      weatherOptions.delete(v)
    }
  }

  const onChange = <K extends keyof WeatherEntryData>(prop: K, value: WeatherEntryData[K]) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return weather
    }
    const newWeather = new WeatherEntryData({ ...weather, [prop]: value })
    setWeather(newWeather)
    console.log(newWeather)
    return newWeather
  }

  return (
    <>
      <WrapRow>
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "row",
            position: "relative",
            zIndex: 20,
          }}
        >
          <View style={{ flexGrow: 1, flexShrink: 1, zIndex: 30 }}>
            <Dropdown
              key={i18n.t("weather")}
              value={weather.icon}
              data={translateEnums(data, i18n)}
              onChange={(value) => {
                if (props.onSave) props.onSave(onChange("icon", value))
              }}
              label={i18n.t("weather")}
              withIcons
              icons={getWeatherIcon(weather.icon)}
            />
          </View>
        </View>
        <TextInput
          ref={tempInputRef}
          required={false}
          value={weather.temp || ""}
          prop="temp"
          keyboardType="number-pad"
          onChangeText={(value: string) => onChange("temp", value)}
          onBlur={onSave}
          label={i18n.t("temp")}
          returnKeyType={"done"}
          /*onSubmitEditing={() => handleNextPress(windspeedInputRef)}*/
        />
        <TimeInput
          time={{ hours: weather.datetime?.split(":")[0] ?? 0, minutes: weather.datetime?.split(":")[1] ?? 0 }}
          onSave={(time) => {
            if (props.onSave)
              props.onSave(onChange("datetime", time.hours + ":" + time.minutes.toString().padStart(2, "0")))
          }}
          label={i18n.t("time")}
        />
        {/*<TextInput
          ref={windspeedInputRef}
          required={false}
          value={weather.windspeed || ""}
          prop="windspeed"
          keyboardType="number-pad"
          onChange={onChange}
          onBlur={onSave}
          label={i18n.t("windspeed")}
          onSubmitEditing={() => handleNextPress(humitidyInputRef)}
        />
        <TextInput
          ref={humidityInputRef}
          required={false}
          value={weather.humidity || ""}
          prop="humidity"
          onChange={onChange}
          onBlur={onSave}
          label={i18n.t('humidity') + " %"}
          onSubmitEditing={() => handleNextPress(precipInputRef)}
        />
        <TextInput
          ref={precipInputRef}
          returnTextEntry={"done"}
          value={weather.precip || ""}
          prop="precip"
          onChange={onChange}
          onBlur={onSave}
          label={i18n.t('precip') +" mm"}
        />*/}
      </WrapRow>
    </>
  )
}
