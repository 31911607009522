import React, { View } from "react-native"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { FontAwesome } from "@expo/vector-icons"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { UserInvitation } from "../../shared/entity/UserInvitation"
import { onMyInvitationList } from "../../persistence/FirebaseCollection"
import { useEffect, useState } from "react"
import { useUserProvider } from "../../providers/UserProvider"
import { ExpandableCard } from "../container/ExpandableCard"
import { UserMessageElement } from "../user/UserMessage"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { MyInvitationView } from "../flatlist/MyInvitationView"

export default function MyInvitationList() {
  const { currentUser, userMessages } = useUserProvider()
  const { updateInvitation } = useCompanyProvider()
  const [myInvitationList, setMyInvitationList] = useState<UserInvitation[]>()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  console.log("MyInvitationList: ", myInvitationList)

  useEffect(() => {
    console.log("MyInvitationList useEffect on currentUser", currentUser)
    if (currentUser?.email) {
      onMyInvitationList(currentUser?.email, setMyInvitationList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  return (
    <View style={{ flexDirection: "column" }}>
      <ExpandableCard
        icon={<FontAwesome name={"envelope-o"} color={theme.colors.white} size={theme.iconSize.regular} />}
        name={i18n.t("announcement")}
      >
        {userMessages.map((msg) => (
          <UserMessageElement userMessage={msg} />
        ))}
        {myInvitationList?.map((inv) => (
          <MyInvitationView item={inv} />
        ))}
      </ExpandableCard>
    </View>
  )
}
