import React from "react"
import { StyleSheet, View } from "react-native"
import AppText from "../general/AppText"

interface Props {
  image: JSX.Element
  header: JSX.Element
  text: JSX.Element
}

export function TutorialContent(props: Props) {
  return (
    <View style={styles.container}>
      <AppText maxLines={1} type="heading" style={{ textAlign: "center" }}>
        {props.header}
      </AppText>
      <View style={styles.imageWrapper}>{props.image}</View>
      <AppText maxLines={2} style={{ textAlign: "center", marginTop: 5 }}>
        {props.text}
      </AppText>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 5,
    textAlign: "center",
    alignItems: "center",
    flex: 1,
    marginTop: 30,
  },
  imageWrapper: {
    width: "100%",
    height: "100%",
    flexShrink: 1,
    marginVertical: 10,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
})
