import { Image, TouchableOpacity, View } from "react-native"
import { Camera, CameraType } from "expo-camera"
import { FAB, useTheme } from "react-native-paper"
import { FlipType, manipulateAsync, SaveFormat } from "expo-image-manipulator"
import { FILE_TYPE, useStorageProvider } from "../../providers/StorageProvider"
import { PADDING } from "../../constants/style"
import AppText from "../general/AppText"
import React, { useState } from "react"
import { useDeviceProvider } from "../../providers/DeviceProvider"
import { Theme } from "../../../types"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  imageUri: string | undefined
  onSave: (uri: string) => void
  disabled?: boolean
}

export function CameraInput({ imageUri, onSave, disabled = false }: Props) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  let camera: Camera | null
  const { uploadFileOnFirebase } = useStorageProvider()
  const { cameraAvailable, checkCamera } = useDeviceProvider()
  const [cameraVisible, setCameraVisible] = useState(false)

  if (!cameraAvailable) checkCamera()

  return (
    <View
      style={{
        alignItems: "center",
      }}
    >
      <View
        style={{
          alignItems: "center",
          justifyContent: "center",
          width: 250,
          height: 250,
          borderWidth: 2,
          borderStyle: "dashed",
          borderColor: theme.colors.primary,
          borderRadius: 10,
          overflow: "hidden",
        }}
      >
        {cameraVisible && (
          <Camera
            style={{ height: 250, width: 250, aspectRatio: 1, borderRadius: 10, position: "relative" }}
            type={CameraType.front}
            ref={(r) => (camera = r)}
            ratio={"1:1"}
          >
            <FAB
              style={[
                theme.fab,
                {
                  margin: 32,
                  right: 0,
                  bottom: 0,
                },
              ]}
              icon={"camera"}
              onPress={() => {
                if (camera)
                  camera.takePictureAsync().then((pic) => {
                    setCameraVisible(false)
                    manipulateAsync(pic.uri, [{ resize: { width: 250 } }, { flip: FlipType.Horizontal }], {
                      format: SaveFormat.PNG,
                    }).then(async (result) => {
                      const imageData = await (await fetch(result.uri)).blob()
                      const uri = await uploadFileOnFirebase(imageData, "Custom/", {
                        contentType: FILE_TYPE.PNG,
                      })
                      onSave(uri)
                    })
                  })
              }}
            />
          </Camera>
        )}
        {!cameraVisible && imageUri != undefined && (
          <>
            <Image source={{ uri: imageUri }} style={{ height: 250, width: 250 }} />
          </>
        )}
        {!cameraVisible && (
          <TouchableOpacity
            onPress={() => {
              setCameraVisible(true)
            }}
            style={{
              padding: PADDING,
              position: "absolute",
              alignSelf: "center",
              bottom: 10,
              right: 10,
              backgroundColor: theme.colors.primary,
              borderRadius: 10,
            }}
            disabled={!cameraAvailable || disabled}
          >
            <AppText noPadding white>
              {cameraAvailable ? i18n.t("add_photo") : i18n.t("camera_unavailable")}
            </AppText>
          </TouchableOpacity>
        )}
      </View>
    </View>
  )
}
