/**
 * Google Map API
 */
export const GOOGLE_API_KEY = "AIzaSyDFATdwPyr3_LVEZ89HDxNiLo2L5nQ5HkY"

/**
 * Weather API
 */
export const WEATHER_API_KEY = "L5ND2APNM6CHSXPF6QJYJR8VT"

/**
 * Selected language
 */
export const LANGUAGE = "cs"
