import React, { createContext, useContext, useEffect, useState } from "react"
import Constants from "expo-constants"
import { UserContext } from "./UserProvider"
import { Subscription } from "../shared/entity/payment/Subscription"
import {
  onCreateCheckoutSession,
  onCreateCheckoutSessionOnMobile,
  onCustomerSubscriptions,
  onProductList,
  onProductPrices,
  onTransactionList,
} from "../persistence/FirebaseCollection"
import { Product } from "../shared/entity/payment/Product"
import { Transaction } from "../shared/entity/payment/Transaction"
import { Linking, Platform } from "react-native"
import { Checkout } from "../shared/entity/payment/Checkout"

const initialVal: {
  publishableKey: string
  setPublishableKey?: (publishableKey: string) => void
  merchantIdentifier: string
  setMerchantIdentifier: (merchantIdentifier: string) => void
  stripeAccountId: string
  setStripeAccountId: (stripeAccountId: string) => void
  transactionList: Transaction[]
  setTransactionList: (transaction: Transaction[]) => void
  productList: Product[]
  setProductList: (products: Product[]) => void
  subscriptions: Subscription[]
  setSubscriptions: (subscriptions: Subscription[]) => void
  checkout: Checkout
  setCheckout: (checkout: Checkout) => void
} = {
  publishableKey: "",
  setPublishableKey: () => "",
  merchantIdentifier: "",
  setMerchantIdentifier: () => "",
  stripeAccountId: "",
  setStripeAccountId: () => "",
  transactionList: [],
  setTransactionList: () => "",
  productList: [],
  setProductList: () => [],
  subscriptions: [],
  setSubscriptions: () => [],
  checkout: new Checkout(),
  setCheckout: () => new Checkout(),
}

export const PaymentContext = createContext(initialVal)

export default function PaymentProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const [publishableKey, setPublishableKey] = useState<string>("")
  const [stripeAccountId, setStripeAccountId] = useState<string>("")
  const [merchantIdentifier, setMerchantIdentifier] = useState<string>("")
  const [productList, setProductList] = useState<Product[]>([])
  const [checkout, setCheckout] = useState<Checkout>(new Checkout())
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])
  const [transactionList, setTransactionList] = useState<Transaction[]>([])

  useEffect(() => {
    if (setPublishableKey && setMerchantIdentifier && setStripeAccountId) {
      console.log("PaymentProviderInit")
      setPublishableKey(Constants?.manifest?.extra?.stripeConfig.publishableKey)
      setMerchantIdentifier(Constants?.manifest?.extra?.stripeConfig.merchantIdentifier)
      setStripeAccountId(Constants?.manifest?.extra?.stripeConfig.stripeAccountId)
    }
    setCheckout(new Checkout())
  }, [])

  useEffect(() => {
    console.log("PaymentProvider: load priceList")
    onProductList(setProductList)
      .then((unsubscribe) => {
        return () => unsubscribe()
      })
      .catch(console.error)
  }, [])

  useEffect(() => {
    console.log("PaymentProvider: load subscriptions based on change: " + JSON.stringify(currentUser))
    if (currentUser?.id) {
      onCustomerSubscriptions(currentUser?.id, false, setSubscriptions)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch(console.error)
    }
  }, [currentUser])

  useEffect(() => {
    console.log("PaymentProvider: load transactions")
    if (currentUser?.id) {
      onTransactionList(currentUser.id, setTransactionList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch(console.error)
    }
  }, [currentUser])

  useEffect(() => {
    console.log("PaymentProvider: load priceList prices")
    if (productList) {
      productList.forEach(
        (value) =>
          value.id &&
          value.prices &&
          onProductPrices(value.id, value.setPrices)
            .then((unsubscribe) => {
              return () => unsubscribe()
            })
            .catch(console.error)
      )
    }
  }, [productList])

  return (
    <PaymentContext.Provider
      value={{
        stripeAccountId,
        setPublishableKey,
        setStripeAccountId,
        publishableKey,
        transactionList,
        setTransactionList,
        productList,
        setProductList,
        subscriptions,
        setSubscriptions,
        merchantIdentifier,
        setMerchantIdentifier,
        setCheckout,
        checkout,
      }}
    >
      {children}
    </PaymentContext.Provider>
  )
}

export const usePaymentProvider = () => {
  const payment = useContext(PaymentContext)
  const { currentUser } = useContext(UserContext)
  const originUrl = Platform.OS === "web" ? window.location.origin : "https://app.stavx.cz/"

  const isSubscriber = (): boolean => {
    const currentDate = new Date()
    for (const { currentPeriodEnd } of payment.subscriptions) {
      if (currentPeriodEnd && currentPeriodEnd > currentDate) {
        return true
      }
    }
    return false
  }

  const checkOut = async (priceId: string, amount?: number) => {
    console.log("createSubscription", priceId)
    if (currentUser?.id) {
      await onCreateCheckoutSession(currentUser.id, priceId, originUrl, originUrl, (url) => {
        console.log("URL", url)
        if (Platform.OS === "web") {
          window.location.assign(url)
        } else Linking.openURL(url)
      })
    }
  }

  const manageSubscription = async (product?: string): Promise<void> => {
    console.log("Subscription")
    if (product) {
      const url = product
      if (Platform.OS === "web") {
        window.location.assign(url)
      } else await Linking.openURL(url)
    }
  }

  const getInvoice = async (invoiceId: string): Promise<string> => {
    return ""
  }

  const createOneTimeTransactionOnMobile = async (amount: number, currency: string) => {
    await onCreateCheckoutSessionOnMobile(
      currentUser?.id || "",
      "payment",
      amount,
      "mobile",
      currency || "czk",
      (value) => {
        console.log("createOneTimeTransactionOnMobile:", value)
        payment.setCheckout(new Checkout(value))
      }
    )
  }

  const createOneTimeTransaction = async (priceId: string, amount?: number): Promise<void> => {
    console.log("createTransaction", priceId)
    if (currentUser?.id) {
      await onCreateCheckoutSession(
        currentUser.id,
        priceId,
        originUrl,
        originUrl,
        (url) => {
          console.log("URL", url)
          if (Platform.OS === "web") {
            window.location.assign(url)
          } else Linking.openURL(url)
        },
        "payment",
        amount
      )
    }
  }

  return {
    checkOut,
    isSubscriber,
    manageSubscription,
    createOneTimeTransaction,
    createOneTimeTransactionOnMobile,
    getInvoice,
    ...payment,
  }
}
