import React, { useEffect, useRef, useState } from "react"
import { isValidICO, shouldWarningInput } from "../../shared/utils/validations"
import LoadingComponent from "../general/LoadingComponent"
import { handleNextPress, TextInput } from "../input/TextInput"
import { Company } from "../../shared/entity/Company"
import PlaceLocationComponent from "../map/PlaceLocationComponent"
import { PlaceLocation } from "../../shared/common/PlaceLocation"
import { showMessage } from "react-native-flash-message"
import { View } from "react-native"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { SearchIcon } from "../svg"
import DialogWindow from "../general/DialogWindow"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import IconButton from "../button/IconButton"
import { FontAwesome5 } from "@expo/vector-icons"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"

export default function CompanyDetail({
  visible,
  setVisible,
  company,
}: {
  visible: boolean
  setVisible: (visible: boolean) => void
  company: Company
}) {
  const { fetchCompanyData, updateCompany, deleteCompany } = useCompanyProvider()
  const [loading, setLoading] = useState<boolean>(false)
  const [loadingError, setLoadingError] = useState<string | undefined>()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const [ico, setIco] = useState<string | undefined>(company?.ico)
  const [name, setName] = useState<string | undefined>(company?.name)
  const [location, setLocation] = useState<PlaceLocation | undefined>(company?.location)

  const icoInputRef = useRef<TextInput>(null)
  const nameInputRef = useRef<TextInput>(null)

  useEffect(() => {
    if (company) {
      setIco(company.ico ?? "")
      setName(company.name ?? "")
      setLocation(company.location ?? new PlaceLocation())
    }
  }, [company])

  useEffect(() => {
    if (ico && isValidICO(ico)) {
      handleFetchCompanyData(ico)
        .then((result) => {
          console.log("handleFetchCompanyData result:", result)
          setLoadingError(undefined)
        })
        .catch((error) => {
          console.log("handleFetchCompanyData error:", error)
          setLoadingError(error)
        })
    }
  }, [ico])

  const handleFetchCompanyData = async (ico: string) => {
    if (!isValidICO(ico)) console.log("Error handleFetchCompanyData ico is not valid", ico)
    try {
      setLoading(true)
      const companyDetailFromAres = await fetchCompanyData(ico)
      console.debug("handleFetchCompanyData: ", companyDetailFromAres)
      setName(companyDetailFromAres?.name)
      setLocation(companyDetailFromAres?.location)
      setLoading(false)
    } catch (e) {
      console.error("Error handleFetchCompanyData:" + e)
      setLoading(false)
      throw e
    }
  }

  const onSubmit = async () => {
    const updatedCompany = new Company({ ...company, ico, name, location })
    setVisible(false)
    await updateCompany(updatedCompany)
    showMessage({ message: i18n.t("saved"), type: "success" })
  }

  return (
    <DialogWindow
      dismissButtonTitle={i18n.t("delete")}
      customDismissOnPress={async () => {
        await deleteCompany(company)
        setVisible(false)
      }}
      confirmButtonTitle={i18n.t("save")}
      onPress={onSubmit}
      title={i18n.t("company_detail")}
      onDismiss={() => {
        setVisible(false)
      }}
      visible={visible}
    >
      <View>
        <TextInput
          ref={icoInputRef}
          prop="ico"
          required={true}
          error={!isValidICO(ico) || loadingError != undefined}
          errorText={
            !isValidICO(ico)
              ? i18n.t("enter_valid_registration_number_for_ares")
              : loadingError != undefined
              ? loadingError.toString()
              : ""
          }
          hintText={loading ? i18n.t("data_is_loaded_from_ares") : i18n.t("company_was_loaded")}
          label={i18n.t("registration_number")}
          onSubmitEditing={() => handleNextPress(nameInputRef)}
          onChangeText={(val: string) => setIco(val)}
          customIconAction={async () => {
            if (ico) await handleFetchCompanyData(ico)
          }}
          rightSideIcon={(loading && <LoadingComponent />) || (ico && isValidICO(ico) && <SearchIcon />)}
          value={ico}
        />
        <TextInput
          ref={nameInputRef}
          prop="name"
          returnKeyType={"done"}
          error={!shouldWarningInput(name)}
          label={i18n.t("company_name")}
          onChangeText={(val: string) => setName(val)}
          value={name}
        />
        <PlaceLocationComponent
          autocomplete={false}
          hintText={i18n.t("enter_company_address")}
          label={i18n.t("company_address")}
          onSave={(placeLocation: PlaceLocation | undefined) => {
            setLocation(placeLocation)
          }}
          value={location}
        />
      </View>
    </DialogWindow>
  )
}
