import { Pressable, StyleProp, View, ViewStyle } from "react-native"
import * as ImagePicker from "expo-image-picker"
import { MediaTypeOptions } from "expo-image-picker"
import { manipulateAsync, SaveFormat } from "expo-image-manipulator"
import { Avatar } from "react-native-paper"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  onChange?: (image: string) => void
  imageUri?: string
  prop?: string
  name?: string
  editable?: boolean
  size?: number
  style?: StyleProp<ViewStyle>
}
export default function AvatarImage({
  onChange,
  style,
  editable = false,
  name,
  imageUri = "",
  size = 124,
  prop,
}: Props) {
  const { i18n } = useSettingsProvider()
  const initials =
    name
      ?.split(" ")
      .map((n) => (n.length > 0 ? n[0].toUpperCase() : ""))
      .join("") || "AA"

  const pickImage = async () => {
    ImagePicker.launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
      aspect: [1, 1],
      base64: true,
    }).then(async (result) => {
      // @ts-ignore
      const image = result?.assets[0]
      if (result.canceled) console.log("LOG: User cancelled image picker")
      if ((image.width || image.height) > 512) {
        const croppedResult = await manipulateAsync(
          image.uri,
          [
            {
              resize: { height: 512, width: 512 },
            },
          ],
          { compress: 1, format: SaveFormat.WEBP }
        )
        if (onChange) onChange(croppedResult.uri)
      } else {
        if (onChange) onChange(image.uri)
      }
    })
  }

  return (
    <View style={[style]}>
      {imageUri ? (
        <Pressable disabled={!editable} onPress={pickImage}>
          <Avatar.Image size={size} source={{ uri: imageUri }} />
        </Pressable>
      ) : (
        <Pressable disabled={!editable} onPress={pickImage}>
          <Avatar.Text size={size} label={initials} />
        </Pressable>
      )}
    </View>
  )
}
