import React, { useEffect, useState } from "react"
import UserComponent, { FIELD_MODE } from "../../components/user/UserComponent"
import Button from "../../components/button/Button"
import Screen from "../../components/general/Screen"
import AppText from "../../components/general/AppText"
import AppLink from "../../components/general/AppLink"
import { isEmailValid, isPasswordValid, isPhoneNum, isRequired } from "../../shared/utils/validations"
import AppCheckbox from "../../components/input/AppCheckbox"
import { SafeAreaView, View } from "react-native"
import { AppLogo } from "../../components/svg"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { User } from "../../shared/entity/User"
import { UserInvitation } from "../../shared/entity/UserInvitation"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { Chip } from "react-native-paper"
import { useUserProvider } from "../../providers/UserProvider"
import ChipPicker from "../../components/input/ChipPicker"
import { ROLES } from "../../shared/entity/Role"
import MaxWidth from "../../components/container/MaxWidth"
import { translateEnums } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.USER_REGISTER>

export function UserRegisterScreen({ navigation, route }: Props) {
  const { registerUser, signIn } = useUserProvider()
  const { i18n } = useSettingsProvider()
  const [user, setUser] = useState<User>()
  const [invitation, setInvitation] = useState<UserInvitation>()
  const [vopChecked, setVopChecked] = useState<boolean>(true)

  const handleRegister = async (): Promise<void> => {
    if (user && user.email && user.password) {
      const success = await registerUser(user.email, user.password, user.name, user.phoneNumber)
      if (success && user.email && user.password) {
        console.log("User successfully registered and now signing...")
        await signIn(user.email, user.password)
      }
    }
  }

  const isDisabled = (): boolean => {
    return !(
      user &&
      isEmailValid(user.email) &&
      isPhoneNum(user.phoneNumber) &&
      isPasswordValid(user.password) &&
      isRequired(user.name) &&
      vopChecked
    )
  }

  function handleSubmit(user: User) {
    console.log("handleSubmit user:", user)
    setUser(user)
  }

  useEffect(() => {
    if (route.params) {
      console.log("useEffect UserRegisterScreen", route.params)
      if (route.params.invitationId) {
        const invitationId = route.params.invitationId
        FirebaseObject.readById<UserInvitation>(
          invitationId,
          "UserInvitation",
          (data) => new UserInvitation({ ...data })
        ).then((userInvitation) => {
          console.log("useEffect UserRegisterScreen UserInvitation:", userInvitation)
          setInvitation(userInvitation)
        })
      }
    }
  }, [route])

  // useEffect(() => {
  //   if (invitation) {
  //     console.log("useEffect UserRegisterScreen with invitation:", invitation)
  //     if (invitation.invitedUserEmail)
  //       isUserWithEmail(invitation.invitedUserEmail).then((exists) => {
  //         setInvitedUserEmailExists(exists)
  //       })
  //   }
  // }, [invitation])

  return (
    <Screen>
      <MaxWidth>
        <SafeAreaView style={{ justifyContent: "center", alignItems: "center" }}>
          <AppLogo style={{ maxWidth: 400, width: "80%", height: 200 }} />
        </SafeAreaView>
        {invitation?.invitedIntoCompanyName && (
          <View style={{ alignItems: "center", padding: 10 }}>
            <AppText>{i18n.t("join_company")}:</AppText>
            <Chip icon={"domain"}>{invitation?.invitedIntoCompanyName}</Chip>
          </View>
        )}
        {invitation?.invitedUserRoles && (
          <View style={{ alignItems: "center", padding: 10 }}>
            <AppText>{i18n.t("with_those_permissions")}:</AppText>
            <View>
              {invitation?.invitedUserRoles && (
                <ChipPicker
                  label={i18n.t("role")}
                  editable={false}
                  values={invitation?.invitedUserRoles}
                  data={translateEnums(ROLES, i18n)}
                />
              )}
            </View>
          </View>
        )}
        {invitation && (
          <UserComponent
            initialUser={invitation.getUser()}
            handleSubmit={handleSubmit}
            fieldModeImage={FIELD_MODE.HIDDEN}
            fieldModeEmail={FIELD_MODE.READONLY}
            fieldModePassword={FIELD_MODE.EDIT}
            fieldModeRoles={FIELD_MODE.HIDDEN}
            fieldModeStatus={FIELD_MODE.HIDDEN}
          />
        )}
        {!invitation && (
          <UserComponent
            handleSubmit={handleSubmit}
            fieldModeImage={FIELD_MODE.HIDDEN}
            fieldModeEmail={FIELD_MODE.EDIT}
            fieldModePassword={FIELD_MODE.EDIT}
            fieldModeStatus={FIELD_MODE.HIDDEN}
          />
        )}

        <View style={{ justifyContent: "center", paddingVertical: 10, alignItems: "center", flexDirection: "row" }}>
          <AppCheckbox
            checked={vopChecked}
            onPress={() => setVopChecked(!vopChecked)}
            label={i18n.t("by_registering_you_agree_to")}
          />
          <AppLink to={ROUTE.GENERAL_VOP} title={i18n.t("with_terms_and_conditions")} navigation={navigation} />
        </View>
        <Button onPress={handleRegister} title={i18n.t("register")} disabled={isDisabled()} />
        <View style={{ alignItems: "center", padding: 10 }}>
          <AppText>
            {i18n.t("already_have_an_account")} &nbsp;
            <AppLink to={ROUTE.USER_LOGIN} title={i18n.t("login")} navigation={navigation} />
          </AppText>
        </View>
      </MaxWidth>
    </Screen>
  )
}
