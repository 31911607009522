import React, { useRef } from "react"
import { isPasswordValid } from "../../shared/utils/validations"
import AppText from "../general/AppText"
import Container from "../general/Container"
import { handleNextPress, TextInput } from "../input/TextInput"
import { ChangePasswordFormType } from "../../screens/user/UserPasswordChangeScreen"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function PasswordForm({
  onChange,
  data,
}: {
  onChange(prop: string, val: string): void
  data: ChangePasswordFormType
}) {
  const { i18n } = useSettingsProvider()
  const oldPasswordInputRef = useRef<TextInput>(null)
  const passwordInputRef = useRef<TextInput>(null)
  const passwordAgainInputRef = useRef<TextInput>(null)

  return (
    <Container>
      <TextInput
        ref={oldPasswordInputRef}
        value={data.oldPassword || ""}
        prop="oldPassword"
        onChangeText={(value) => onChange("oldPassword", value)}
        onSubmitEditing={() => handleNextPress(passwordInputRef)}
        label={i18n.t("old_password")}
        secureTextEntry
        autoCapitalize="none"
        required
      />
      <TextInput
        ref={passwordInputRef}
        value={data.password || ""}
        prop="password"
        onChangeText={(value) => onChange("password", value)}
        onSubmitEditing={() => handleNextPress(passwordAgainInputRef)}
        label={i18n.t("password")}
        secureTextEntry
        autoCapitalize="none"
        required
        errorText={i18n.t("minimum_password_length_is")}
        error={!isPasswordValid(data.password)}
      />
      <TextInput
        ref={passwordAgainInputRef}
        value={data.passwordAgain || ""}
        prop="passwordAgain"
        onChangeText={(value) => onChange("passwordAgain", value)}
        label={i18n.t("again_password")}
        secureTextEntry
        returnKeyType={"done"}
        autoCapitalize="none"
        required
        error={!isPasswordValid(data.password)}
      />
      <AppText error>
        {data.password && data.passwordAgain && data.password !== data.passwordAgain && i18n.t("password_do_not_match")}
      </AppText>
    </Container>
  )
}
