import { useState } from "react"
import { openFileStorage, openLibrary } from "../api/platformFeatureRequest"
import { UploadMetadata } from "@firebase/storage"
import { deleteObject, getDownloadURL, getMetadata, ref, uploadBytesResumable, uploadString } from "firebase/storage"
import { storage } from "../persistence/FirebaseConfig"
import { v4 as uuidv4 } from "uuid"
import { useStatusProvider } from "./StatusProvider"

export const useStorageProvider = () => {
  const { setStatusError } = useStatusProvider()
  const [uploading, setUploading] = useState(false)

  const pickAndUploadFile = async (
    storagePath?: string,
    customMetadata?: any,
    type?: "*/*" | "image/png" | "image/*" | undefined,
    oldFileUri?: string,
    callback?: UploadCallback
  ) => {
    setUploading(true)
    try {
      const { data } = await openFileStorage(type)
      console.log("markdata:", data)
      if (data) {
        // if (metadata.mimeType !== "*/*" && data.mimeType !== metadata.mimeType) throw new Error("Špatný formát")
        if (data.size && data?.size > 10000000) throw new Error("Maximální povolená velikost je 10MB")
        if (oldFileUri) {
          await deleteFileFromFirebase(oldFileUri)
        }

        //Content-Disposition: inline - displayed directly
        //Content-Disposition: attachment - should be downloaded
        //Content-Disposition: attachment; filename="filename.jpg"
        //Content-Type: text/html; charset=utf-8
        //Content-Type: multipart/form-data; boundary=something
        const metadata: UploadMetadata = {
          customMetadata: { ...customMetadata, fileName: data.name },
          contentType: data.mimeType,
          contentDisposition: "inline",
        }

        const remoteUri = await uploadFileOnFirebase(
          await (await fetch(data.uri)).blob(),
          storagePath,
          metadata,
          callback
        )
        console.log("remoteUri", remoteUri)
        setUploading(false)
        return remoteUri
      }
    } catch (error) {
      console.error(error)
      setStatusError(error.message)
    }
    setUploading(false)
  }

  const pickAndUploadImage = async (storagePath: string, oldFileUri?: string): Promise<string | undefined> => {
    setUploading(true)
    try {
      const imageInfo = await openLibrary()
      console.log("imageInfo", JSON.stringify(imageInfo))
      if (imageInfo && imageInfo.uri) {
        if (oldFileUri) {
          try {
            await deleteFileFromFirebase(oldFileUri)
          } catch (error) {
            console.error(error)
          }
        }
        const blob = await fetchImageFromUri(imageInfo.uri)
        console.log("blob:" + blob)

        const metadata: UploadMetadata = {
          customMetadata: { fileName: imageInfo.fileName || "", type: imageInfo.type || "" },
          contentType: `${imageInfo.type}/*`,
          contentDisposition: `attachment; filename=${imageInfo.fileName}`,
        }

        const remoteUri = await uploadFileOnFirebase(blob, storagePath, metadata)
        console.log("remoteUri", remoteUri)
        setUploading(false)
        return remoteUri
      }
    } catch (error) {
      console.error(error)
      setStatusError(error.message)
    }
    setUploading(false)
  }

  async function fetchImageFromUri(uri: string) {
    try {
      const response = await fetch(uri)
      const blob = await response.blob()
      return blob
    } catch (error) {
      console.log("fetchImageFromUri error:", error)
      throw new Error("fetchImageFromUri")
    }
  }

  type UploadCallback = {
    onProgress: (progress: number) => {}
    onState: (state: "running" | "paused" | "success" | "canceled" | "error") => {}
    onError: (error: string) => {}
    onComplete: (duration: number) => {}
    onResult: (uri: string) => {}
  }

  async function uploadFileOnFirebase(
    data: Blob | Uint8Array | ArrayBuffer | any,
    storagePath?: string,
    metadata?: UploadMetadata,
    callback?: UploadCallback
  ) {
    console.log("uploadFileOnFirebase")
    return await uploadFileOnFirebaseWithUUID(data, storagePath, uuidv4(), metadata, callback)
  }

  async function uploadFileOnFirebaseWithUUID(
    data: Blob | Uint8Array | ArrayBuffer | any,
    storagePath?: string,
    uid?: string,
    metadata?: UploadMetadata,
    callback?: UploadCallback
  ): Promise<string> {
    console.log("data", data)
    console.log("storagePath", storagePath)
    console.log("metadata", metadata)
    const startTime = new Date()

    //todo fix cors https://stackoverflow.com/questions/42039282/firebase-storage-cors-strange-behaviour
    try {
      const storageRef = ref(storage, `${storagePath}/${uid}`)
      const uploadTask = uploadBytesResumable(storageRef, data, metadata)
      return await new Promise((resolve, reject) => {
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            console.log(snapshot)
            callback?.onState(snapshot.state)
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            console.log("Upload is " + progress + "% done")
            callback?.onProgress(progress)
            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused")
                break
              case "running":
                console.log("Upload is running")
                break
            }
          },
          (error) => {
            console.error("Error loading image to firebase storage: ", error)
            callback?.onError(error.message)
            reject(error)
          },
          () => {
            const stopTime = new Date()
            const deltaTime = stopTime.getTime() - startTime.getTime()
            callback?.onComplete(deltaTime)
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              callback?.onResult(downloadURL)
              console.log("uploadFileOnFirebaseWithUUID: DOWNLOAD: ", downloadURL)
              resolve(downloadURL)
            })
          }
        )
      })
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  async function uploadFileStringOnFirebase(
    file: string,
    folderName: string,
    type: "data_url" | "base64url" | "base64" = "data_url"
  ): Promise<string> {
    try {
      console.log("file", file)
      const storageRef = ref(storage, `${folderName}/${uuidv4()}`)
      const snapshot = await uploadString(storageRef, file, type)
      const url = await getDownloadURL(snapshot.ref)
      return url
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  /**
   * @param {String} uri firebase full downloadable uri
   */
  async function deleteFileFromFirebase(uri?: string) {
    try {
      if (uri) {
        const imageRef = ref(storage, uri)
        await deleteObject(imageRef)
      }
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  /**
   * @param {String} uri firebase full downloadable uri
   * @returns {Object} firebase file metadata
   */
  async function getFileMetadata(uri: string): Promise<StorageMetadata> {
    try {
      console.debug("getFileMetadata uri:", uri)
      const imageRef = ref(storage, uri)
      const metadata = await getMetadata(imageRef)
      return { ...metadata, uri: uri } as StorageMetadata
    } catch (error) {
      console.error(error)
      throw error
    }
  }

  // export function getDocRef(collection) {
  //   try {
  //     return firebase.firestore().collection(collection).doc()
  //   } catch (error) {
  //     console.error(error)
  //     throw error
  //   }
  // }

  const getBase64FromUrl = async (url: string) => {
    const data = await fetch(url)
    const blob = await data.blob()
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        const base64data = reader.result
        resolve(base64data)
      }
    })
  }

  async function getBlobFromFile(url: string) {
    const res = await fetch(url)
    return await res.blob()
  }

  //  const downloadURL = `${origin}/v0/b/${bucketName}/o/diary-export%2F${docName}?alt=media&token=${result[1].metadata.firebaseStorageDownloadTokens}`
  //  console.debug("DownloadURL: ", downloadURL)
  //  res.status(200).send({ downloadURL: downloadURL })

  async function uploadPDF(name: string, pdfFilePath: Blob) {
    const storageRef = ref(storage, `pdf/${name}`)
    const metadata = {
      contentType: "application/pdf",
      contentDisposition: "attachment",
      metadata: {
        firebaseStorageDownloadTokens: uuidv4(),
      },
    }
    return uploadBytesResumable(storageRef, pdfFilePath, metadata)
  }

  return {
    uploading,
    pickAndUploadFile,
    pickAndUploadImage,
    deleteFileFromFirebase,
    fetchImageFromUri,
    getFileMetadata,
    uploadFileStringOnFirebase,
    uploadFileOnFirebase,
    getBlobFromFile,
    uploadFileOnFirebaseWithUUID,
  }
}

/**
 * @param {String} uri - firebase storage download uri
 * @returns {String} parsed name from uri
 */
export function getNameFromFirebaseUri(uri: string): string {
  // TODO create unit test for firebase storage uri
  return uri.split(RegExp(/(.*?)\?alt/))[1]
}

export enum FILE_TYPE {
  PNG = "image/png",
  WEBP = "image/webp",
  IMAGE = "image/*",
  PDF = "application/pdf",
  VIDEO = "video/*",
  OTHER = "Other",
}

export type StorageMetadata = {
  type?: FILE_TYPE
  uri?: string
  accessToken?: string
  name?: string
  size?: number
  timeCreated?: string
  updated?: string
  customMetadata?: any
}
