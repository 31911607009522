import React, { useEffect, useState } from "react"
import { View } from "react-native"
import { Attendance, ATTENDANCE_STATE } from "../../shared/entity/Attendance"
import { useUserProvider } from "../../providers/UserProvider"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { ExpandableCard } from "../container/ExpandableCard"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { showMessage } from "react-native-flash-message"
import Dropdown from "../input/Dropdown"
import { ItemWithCustomFields } from "../flatlist/ItemWithCustomFields"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { TimeButton } from "../button/TimeButton"
import { FontAwesome5 } from "@expo/vector-icons"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { TimeInput } from "../input/TimeInput"
import { WrapRow } from "../container/WrapRow"
import { CUSTOM_MODULES } from "../../shared/common/CustomInputDefinition"
import Button from "../button/Button"
import { ROUTE } from "../../navigation/routes"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { Project } from "../../shared/entity/Project"
import { useTaskProvider } from "../../providers/TaskProvider"
import { TASK_STATUS } from "../../shared/entity/Task"

export function LogAttendance() {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const { company } = useCompanyProvider()
  const { createAttendance, openAttendance, updateAttendance } = useAttendanceProvider()
  const { projects } = useProjectProvider()
  const { currentUser } = useUserProvider()
  const { taskList, selectedTask, selectTask } = useTaskProvider()
  const [ready, setReady] = useState(true)
  const [attendance, setAttendance] = useState(new Attendance({ userId: currentUser?.id, companyId: company?.id }))
  const [selectedProject, setSelectedProject] = useState<Project>()

  if (attendance.start != undefined && isNaN(attendance.start.valueOf())) attendance.start = undefined

  useEffect(() => {
    setAttendance(
      new Attendance({
        ...attendance,
        definitions: company?.companyModules[CUSTOM_MODULES.ATTENDANCE].inputDefinitions,
      })
    )
  }, [company])

  useEffect(() => {
    if (openAttendance) setAttendance(openAttendance)
    console.log("openAttendance", openAttendance)
  }, [openAttendance])

  return (
    <ExpandableCard
      closable={false}
      startOpen
      name={i18n.t("attendance")}
      icon={<FontAwesome5 size={theme.iconSize.small} color={theme.colors.white} name={"clipboard"} />}
    >
      <Dropdown
        label={i18n.t("project")}
        value={attendance.projectId}
        onChange={(selected) => {
          setAttendance(new Attendance({ ...attendance, projectId: selected }))
          setSelectedProject(new Project(projects.find((p) => p.id === selected)))
        }}
        data={projects.map((p) => ({ value: p.id, label: p.title }))}
      />
      {attendance.projectId && selectedProject && taskList.find((task) => task.projectId === selectedProject.id) && (
        <Dropdown
          label={i18n.t("select_task")}
          value={attendance.taskId}
          withEmptyValue
          data={taskList
            .filter(
              (task) =>
                task.id &&
                task.projectId === selectedProject.id &&
                task.status !== (TASK_STATUS.CLOSED || TASK_STATUS.FINISHED)
            )
            .map((task) => ({ value: task.id, label: task.getLabel() }))}
          onChange={(selected) => {
            const task = taskList.find((t) => t.id === selected)
            if (task) {
              selectTask(task)
              setAttendance(new Attendance({ ...attendance, taskId: selected }))
            }
          }}
        />
      )}
      <View>
        <View>
          {company?.companyModules[CUSTOM_MODULES.ATTENDANCE]?.inputDefinitions != undefined && (
            <ItemWithCustomFields
              definition={company?.companyModules[CUSTOM_MODULES.ATTENDANCE].inputDefinitions ?? []}
              customFields={attendance.customFields}
              onSave={(newFields, ready) => {
                if (attendance.start != undefined) return
                attendance.customFields = newFields
                attendance.definitions = company?.companyModules[CUSTOM_MODULES.ATTENDANCE].inputDefinitions ?? []
                setAttendance(new Attendance(attendance))
                console.log("ready", ready)
                setReady(ready ?? false)
              }}
              navigate={{ link: ROUTE.ATTENDANCE, payload: undefined }}
            ></ItemWithCustomFields>
          )}
        </View>
        {attendance.isWork() ? (
          <WrapRow>
            <TimeButton
              running={attendance.start != undefined}
              timeFrom={attendance.start}
              onPress={() => {
                if (attendance.start == undefined) {
                  const startedAttendance = new Attendance(attendance)
                  startedAttendance.start = new Date()
                  startedAttendance.state = ATTENDANCE_STATE.STARTED
                  startedAttendance.taskId = selectedTask?.id
                  setAttendance(startedAttendance)
                  createAttendance(startedAttendance).then(() => {
                    showMessage({
                      message: i18n.t("start_marked"),
                      type: "success",
                    })
                  })
                } else {
                  const stoppedAttendance = new Attendance(attendance)
                  stoppedAttendance.end = new Date()
                  stoppedAttendance.taskId = selectedTask?.id
                  stoppedAttendance.state = ATTENDANCE_STATE.FINISHED
                  setAttendance(new Attendance({ userId: currentUser?.id, companyId: company?.id }))
                  updateAttendance(stoppedAttendance).then(() => {
                    showMessage({
                      message: i18n.t("end_marked"),
                      type: "success",
                    })
                  })
                }
              }}
              disabled={!ready && attendance.start == undefined}
            />
            <TimeInput
              label={i18n.t("start")}
              time={{ hours: attendance.start?.getHours(), minutes: attendance.start?.getMinutes() }}
              onSave={(time) => {
                const updatedAttendance = new Attendance(attendance)
                updatedAttendance.start = new Date(new Date().setHours(time.hours, time.minutes))
                setAttendance(new Attendance({ userId: currentUser?.id, companyId: company?.id }))
                updateAttendance(updatedAttendance).then(() =>
                  showMessage({
                    message: i18n.t("changes_saved"),
                    type: "success",
                  })
                )
              }}
            />
          </WrapRow>
        ) : (
          <WrapRow>
            <Button
              title={i18n.t("recorded")}
              onPress={() => {
                const updatedAttendance = new Attendance(attendance)
                updatedAttendance.start = new Date()
                updatedAttendance.end = new Date()
                updatedAttendance.state = ATTENDANCE_STATE.FINISHED
                updatedAttendance.taskId = selectedTask?.id
                setAttendance(new Attendance({ userId: currentUser?.id, companyId: company?.id }))
                createAttendance(updatedAttendance).then(() =>
                  showMessage({
                    message: i18n.t("changes_saved"),
                    type: "success",
                  })
                )
              }}
            />
          </WrapRow>
        )}
      </View>
    </ExpandableCard>
  )
}
