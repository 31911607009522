import React from "react"
import { TextInput } from "../input/TextInput"
import { View } from "react-native"
import { FieldChangeHandler, Item, ItemProps } from "./Item"
import { Issue } from "../../shared/entity/Issue"
import LabeledImage from "../general/LabeledImage"
import BoolInput from "../input/BoolInput"
import { YYYYMMDD } from "../../shared/utils/date"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export function ItemIssueReadonlyUser(props: ItemProps<Issue>): JSX.Element {
  return Item<Issue>(props, ItemIssueReadonlyUserRenderer)
}

export function ItemIssueAdmin(props: ItemProps<Issue>): JSX.Element {
  return Item<Issue>(props, ItemIssueAdminRenderer)
}

function ItemIssueReadonlyUserRenderer(item: Issue, fieldChangeHandler: FieldChangeHandler) {
  const { i18n } = useSettingsProvider()
  return (
    <View style={{ flexDirection: "column" }}>
      <>
        <TextInput
          value={item?.solved ? i18n.t("solved") : i18n.t("processing")}
          prop="solved"
          disabled={true}
          label="Stav problému"
        />
        <TextInput value={item?.name || ""} prop="id" disabled={true} label={i18n.t("identifier")} />
        <TextInput
          value={YYYYMMDD(item?.createdAt || new Date())}
          prop="createdAt"
          disabled={true}
          label={i18n.t("created_at")}
        />
        <TextInput
          value={item?.createdBy || ""}
          prop="createdBy"
          disabled={true}
          label={i18n.t("firstname_and_lastname")}
        />
        <TextInput value={item?.description || ""} prop="description" disabled={true} label={i18n.t("description")} />
        <LabeledImage
          uri={item.uri}
          prop={"uri"}
          disabled={false}
          label={`${i18n.t("taken")} ${i18n.t("screenshot")}`}
        />
      </>
    </View>
  )
}

const ItemIssueAdminRenderer = (item: Issue, fieldChangeHandler: FieldChangeHandler) => {
  const { i18n } = useSettingsProvider()

  return (
    <View style={{ flexDirection: "column" }}>
      <BoolInput
        stateNames={{ yes: "solved", no: "processing" }}
        label={i18n.t("status")}
        value={item.solved || false}
        onChange={(value) => fieldChangeHandler.onChange("solved", value)}
      />
      {ItemIssueReadonlyUserRenderer(item, fieldChangeHandler)}
    </View>
  )
}
