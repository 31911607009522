import React, { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { Settings } from "../shared/entity/Settings"
import { UserContext } from "./UserProvider"
import { FirebaseObject } from "../persistence/FirebaseObject"
import { onSettings } from "../persistence/FirebaseCollection"
import { User } from "../shared/entity/User"
import { Appearance, Platform } from "react-native"
import Constants from "expo-constants"
import { fetchAppInfo } from "../api/app"
import { AppearanceSettings } from "../shared/common/settings/AppearanceSettings"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { LanguageSettings } from "../shared/common/settings/LanguageSettings"
import { I18n } from "i18n-js"
import * as Localization from "expo-localization"
//@ts-ignore
import en from "../localization/languages/en.json"
//@ts-ignore
import cs from "../localization/languages/cs.json"

const initialVal: {
  settings: Settings | undefined
  setSettings: (settings: Settings) => void
  i18n: I18n
  setI18n: (i18n: I18n) => void
} = {
  settings: new Settings({ appearance: new AppearanceSettings({ theme: "light" }) }),
  setSettings: () => {},
  i18n: new I18n(),
  setI18n: () => {},
}

export const SettingsContext = createContext(initialVal)

export default function SettingsProvider({ children }: { children: ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const [settings, setSettings] = useState<Settings>(new Settings())
  const [i18n, setI18n] = useState<I18n>(new I18n())

  const locales = Localization.getLocales()
  const code = locales.map((locale) => locale.languageCode)[0]

  i18n.defaultLocale = "cs"
  i18n.translations = { en, cs }
  if (code) i18n.locale = code
  if (settings && settings?.language && settings?.language?.language) i18n.locale = settings?.language?.language
  i18n.enableFallback = true

  useEffect(() => {
    AsyncStorage.getItem("theme").then((theme) => {
      if (theme) {
        setSettings(
          new Settings({
            ...settings,
            appearance: new AppearanceSettings({ theme: theme }),
          })
        )
      } else {
        setSettings(
          new Settings({
            ...settings,
            appearance: new AppearanceSettings({ theme: Appearance.getColorScheme() || "light" }),
          })
        )
      }
    })
    AsyncStorage.getItem("language").then((lang) => {
      if (lang) {
        setSettings(
          new Settings({
            ...settings,
            language: new LanguageSettings({ language: lang }),
          })
        )
      }
    })
  }, [])

  useEffect(() => {
    if (currentUser?.id) {
      console.log("settingsProvider useEffect")
      onSettings(currentUser.id, setSettings)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  async function fetchAppInfoFromBackend() {
    const appInfo = await fetchAppInfo()
    console.debug("appInfo: " + JSON.stringify(appInfo))
    return appInfo
  }

  useEffect(() => {
    if (currentUser) {
      fetchAppInfoFromBackend().then((appInfo) => {
        const version = Constants.manifest?.version || ""
        const versionNumber = Number(version)
        console.debug(`isNewAppVersion: frontend version: ${versionNumber}, backend version ${appInfo.version}`)
        const backendVersionNumber = Number(appInfo.version)
        if (backendVersionNumber > versionNumber) {
          console.debug(`new version is available ${backendVersionNumber}`)
          if (Platform.OS === "web") {
            console.debug(`force reload in case of new version ${backendVersionNumber}`)
            //window.location.reload()
          } else {
            // TODO for ios and android
          }
        }
      })
    }
  }, [currentUser])

  return (
    <SettingsContext.Provider
      value={{
        settings,
        setSettings,
        i18n,
        setI18n,
      }}
    >
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettingsProvider = () => {
  const { settings, setSettings, i18n, setI18n } = useContext(SettingsContext)
  const { currentUser } = useContext(UserContext)

  const updateSettings = async (newSettings: Settings) => {
    try {
      console.log("updateSettings newSettings:" + JSON.stringify(newSettings))
      if (currentUser) {
        setSettings(newSettings)
        await FirebaseObject.merge<User>(currentUser, "settings", newSettings)
      }
    } catch (error) {
      console.error("Error updateSettings:", error)
      throw error
    }
  }

  return { settings, updateSettings, i18n }
}
