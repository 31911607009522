import { WeatherSettings } from "./WeatherSettings"
import { CustomEntry } from "../../entry/Entry"

export class ProjectSettings extends CustomEntry {
  weather?: WeatherSettings

  constructor(params?: Partial<ProjectSettings>) {
    super(params)
    Object.assign(this, params)
  }
}
