import { WrapRow } from "../container/WrapRow"
import React, { useState } from "react"
import { TextInput } from "../input/TextInput"
import { DocObject } from "../../shared/entity/DocObject"
import { Switch } from "react-native-paper"
import { MARGIN, PADDING } from "../../constants/style"
import AppText from "../general/AppText"
import { View } from "react-native"
import { separateCamelCaseString } from "../../utils/util"

export enum FIELD_MODE {
  "EDIT" = "EDIT",
  "HIDDEN" = "HIDDEN",
  "READONLY" = "READONLY",
}

export class FieldConfig extends Map<string, FIELD_MODE> {
  add(field: string, mode: FIELD_MODE) {
    this.set(field, mode)
    return this
  }
}

export interface ItemProps<T extends DocObject> {
  onSave: (item: T) => void
  item: T
  fieldConfig?: FieldConfig
}

export interface FieldChangeHandler {
  onChange: (prop: string | undefined, value: any) => void
}

export function Item<T extends DocObject>(
  props: ItemProps<T>,
  renderer?: (item: T, fieldChangeHandler: FieldChangeHandler, fieldConfig?: FieldConfig) => JSX.Element
) {
  const [item, setItem] = useState<T>(props.item)

  const onChange = (prop: string | undefined, value: any) => {
    console.debug("Item onChange:", prop, value)
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return
    }
    const newItem = { ...item, [prop]: value }
    console.debug("setItem:", newItem)
    setItem(newItem)
    props.onSave(newItem)
  }

  function getTypeofProperty<T, K extends keyof T>(o: T, name: K) {
    return typeof o[name]
  }

  if (renderer && typeof renderer === "function") {
    console.debug("Using custom renderer:", renderer)
    return renderer(item, { onChange }, props.fieldConfig)
  } else {
    console.debug("Using universal default renderer")
    return (
      <>
        <WrapRow>
          {Object.getOwnPropertyNames(item).map((name) => {
            type ObjectKey = keyof typeof item
            const fieldType = getTypeofProperty(item, name as ObjectKey)
            switch (fieldType) {
              case "string":
                return (
                  <TextInput
                    key={name}
                    value={item[name as ObjectKey] as string}
                    prop={name}
                    style={{ flexGrow: 1, flexShrink: 1 }}
                    onChangeText={(value: string) => onChange(name, value)}
                    label={separateCamelCaseString(name)}
                    // onSubmitEditing={() => handleNextPress(typeInputRef)}
                  />
                )
              case "boolean":
                return (
                  <View style={{ flexDirection: "row", flexGrow: 1, alignItems: "center", padding: PADDING }}>
                    <AppText noPadding style={{ marginRight: MARGIN }}>
                      {separateCamelCaseString(name) + ": "}
                    </AppText>
                    <Switch
                      style={{ flexGrow: 1, flexShrink: 1 }}
                      value={item[name as ObjectKey] as boolean}
                      onValueChange={(value) => {
                        onChange(name, value)
                      }}
                    />
                  </View>
                )
            }
          })}
        </WrapRow>
      </>
    )
  }
}
