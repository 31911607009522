import MaxWidth from "../container/MaxWidth"
import StateInput from "../input/StateInput"
import { ExpandableCard } from "../container/ExpandableCard"
import { MaterialIcons } from "@expo/vector-icons"
import { Platform, View } from "react-native"
import { MARGIN } from "../../constants/style"
import AppText from "../general/AppText"
import { getDateTime } from "../../shared/utils/date"
import ProductCard from "./ProductCard"
import Screen from "../general/Screen"
import React, { ReactNode, useState } from "react"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { usePaymentProvider } from "../../providers/PaymentProvider"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { StateNames } from "../../screens/payment/PaymentScreen"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = { transactionList: ReactNode }

type PaymentComponentProps = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_PAYMENT> & Props

export default function PaymentComponentStripe({ route, navigation, transactionList }: PaymentComponentProps) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const [showMode, setShowMode] = useState(StateNames.SUBSCRIPTION)
  const [selectedProduct, setSelectedProduct] = useState<{ priceId: string; amount: number }>()
  const {
    publishableKey,
    productList,
    checkOut,
    manageSubscription,
    subscriptions,
    createOneTimeTransaction,
    isSubscriber,
  } = usePaymentProvider()

  return (
    <Screen>
      <MaxWidth>
        <StateInput translation={i18n} data={StateNames} value={showMode} onChange={setShowMode} />
        {showMode === StateNames.TRANSACTION ? (
          <></>
        ) : showMode === StateNames.SUBSCRIPTION ? (
          <ExpandableCard
            icon={<MaterialIcons color={theme.colors.white} name="attach-money" size={theme.iconSize.regular} />}
            name={StateNames.SUBSCRIPTION}
            startOpen
            closable={false}
            actionElement={
              isSubscriber() &&
              subscriptions && (
                <View style={{ margin: MARGIN }}>
                  <AppText style={{ color: theme.colors.white }}>
                    {i18n.t("subscription_ends") +
                      " " +
                      getDateTime({
                        i18n: i18n,
                        date: subscriptions[0].currentPeriodEnd,
                        format: "FULLMONTH",
                      }) +
                      " " +
                      subscriptions[0].currentPeriodEnd?.getFullYear()}
                  </AppText>
                </View>
              )
            }
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {productList &&
                productList
                  .filter((product) => product.prices[0].type !== "one_time")
                  .map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      actionButtonTitle={
                        subscriptions.find((sub) => sub.priceId === product.prices[0].id)
                          ? i18n.t("manage")
                          : i18n.t("subscribe")
                      }
                      action={(id, quantity) => {
                        subscriptions.find((sub) => sub.priceId === product.prices[0].id)
                          ? manageSubscription(product.prices[0].id)
                          : checkOut(id, quantity)
                      }}
                    />
                  ))}
            </View>
          </ExpandableCard>
        ) : (
          <ExpandableCard
            icon={<MaterialIcons color={theme.colors.white} name="attach-money" size={theme.iconSize.regular} />}
            name={StateNames.ONE_TIME_PAYMENT}
            startOpen
            closable={false}
          >
            <View
              style={{
                flexDirection: "row",
                flexWrap: "wrap",
                justifyContent: "space-evenly",
              }}
            >
              {productList &&
                productList
                  .filter((product) => product.prices[0].type === "one_time")
                  .map((product) => (
                    <ProductCard
                      key={product.id}
                      product={product}
                      withInterval={false}
                      withQuantity={false}
                      actionButtonTitle={i18n.t("pay")}
                      action={(id, amount) => {
                        Platform.OS === "web"
                          ? createOneTimeTransaction(id, amount)
                          : setSelectedProduct({ priceId: id, amount: amount })
                      }}
                    />
                  ))}
            </View>
          </ExpandableCard>
        )}
        {transactionList}
      </MaxWidth>
    </Screen>
  )
}
