import React, { createContext, useContext, useEffect, useRef, useState } from "react"
import { FirebaseObject } from "../persistence/FirebaseObject"
import { getLatestMessages, getMemoRooms, getRecentMemosInRoom } from "../persistence/FirebaseCollection"
import { UserContext } from "./UserProvider"
import { Memo } from "../shared/entity/Memo"
import { MemoRoom } from "../shared/entity/MemoRoom"

const initialVal: {
  memoList: Memo[]
  rooms: MemoRoom[]
  currentRoom: MemoRoom | undefined
  latestMemos: Record<string, Memo[]>
  setMemoList: (memos: Memo[]) => void
  setCurrentRoom: (room: MemoRoom) => void
} = {
  memoList: [],
  rooms: [],
  currentRoom: undefined,
  latestMemos: {},
  setMemoList: (memos: Memo[]) => {},
  setCurrentRoom: (room: MemoRoom) => {},
}

export const MemoContext = createContext(initialVal)

export default function MemoProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const [memoList, setMemoList] = useState<Memo[]>([])
  const [rooms, setRooms] = useState<MemoRoom[]>([])
  const [currentRoom, setCurrentRoom] = useState<MemoRoom>()
  const [latestMemos, setLatestMemos] = useState<Record<string, Memo[]>>({})

  useEffect(() => {
    console.debug("MessageProvider: useEffect", currentUser)
    if (currentUser?.id) {
      getMemoRooms((rooms) => {
        if (!currentRoom) {
          console.log("setting current room to", rooms[0])
          setCurrentRoom(rooms[0])
        }

        setRooms(rooms)
        const newLatest = { ...latestMemos }
        for (const room of rooms) {
          if (room && room.id)
            getLatestMessages(room.id, (objects) => {
              if (room.id) newLatest[room.id] = objects
            })
        }
        setLatestMemos(newLatest)
      })
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  return (
    <MemoContext.Provider
      value={{
        memoList,
        rooms,
        currentRoom,
        latestMemos,
        setMemoList,
        setCurrentRoom,
      }}
    >
      {children}
    </MemoContext.Provider>
  )
}

export const useMemoProvider = () => {
  const { currentUser } = useContext(UserContext)
  const { memoList, currentRoom, rooms, setMemoList, latestMemos, setCurrentRoom } = useContext(MemoContext)
  const lastRequestLenght = useRef(15)

  const fetchMemos = (count: number) => {
    lastRequestLenght.current = count
    console.log("fetching...")
    if (currentRoom && currentRoom.id) getRecentMemosInRoom(count, currentRoom.id, setMemoList)
  }

  useEffect(() => {
    fetchMemos(lastRequestLenght.current)
  }, [latestMemos, currentRoom])

  async function createMemo(text: string, parentId?: string): Promise<boolean> {
    if (currentUser && currentUser.id && currentRoom && currentRoom.id) {
      const memo = Memo.prepareToSend(currentUser?.id, currentRoom.id, text, parentId)
      await FirebaseObject.create(memo)
      return true
    }
    return false
  }

  async function sendMemo(memo: Memo) {
    return await FirebaseObject.create(memo)
  }

  async function createRoom(memoRoom: MemoRoom) {
    await FirebaseObject.create(memoRoom)
    return memoRoom
  }

  function switchRoom(memoRoom: MemoRoom) {
    setCurrentRoom(memoRoom)
    setMemoList([])
  }

  async function readMessage(messageId: string): Promise<Memo> {
    return await FirebaseObject.readById<Memo>(messageId, "Message", (data) => new Memo({ ...data }))
  }

  async function updateMessage(message: Memo): Promise<boolean> {
    return await FirebaseObject.update(message)
  }

  async function deleteMessage(message: Memo): Promise<boolean> {
    return await FirebaseObject.softDelete(message)
  }

  return {
    createMemo,
    memoList,
    latestMemos,
    rooms,
    currentRoom,
    switchRoom,
    fetchMemos,
    sendMemo,
    createRoom,
  }
}
