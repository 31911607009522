import React, { useRef, useState } from "react"
import { View } from "react-native"
import { isEmailValid, isPasswordValid, isPhoneNum } from "../../shared/utils/validations"
import { User, UserStatus } from "../../shared/entity/User"
import { TextInput } from "../input/TextInput"
import { Switch } from "react-native-paper"
import { useUserProvider } from "../../providers/UserProvider"
import AvatarImage from "../file/AvatarImage"
import { FILE_TYPE, useStorageProvider } from "../../providers/StorageProvider"
import { MARGIN } from "../../constants/style"
import { CheckIcon, ErrorIcon } from "../svg"
import { useStatusProvider } from "../../providers/StatusProvider"
import { showMessage } from "react-native-flash-message"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import ChipPicker from "../input/ChipPicker"
import { ROLES } from "../../shared/entity/Role"
import AppText from "../general/AppText"
import { usePaymentProvider } from "../../providers/PaymentProvider"
import { translateEnums } from "../../utils/util"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export enum FIELD_MODE {
  "EDIT",
  "EDIT_BUT_EMAIL_UNIQUE",
  "HIDDEN",
  "READONLY",
}

type Props = {
  handleSubmit(user: User): void
  initialUser?: User
  systemRoles?: Record<string, string>
  fieldModeImage?: FIELD_MODE
  fieldModeName?: FIELD_MODE
  fieldModeEmail?: FIELD_MODE
  fieldModePhone?: FIELD_MODE
  fieldModePassword?: FIELD_MODE
  fieldModeStatus?: FIELD_MODE
  fieldModeRoles?: FIELD_MODE
}

export default function UserComponent({
  handleSubmit,
  initialUser,
  systemRoles,
  fieldModeImage = FIELD_MODE.EDIT,
  fieldModeName = FIELD_MODE.EDIT,
  fieldModeEmail = FIELD_MODE.READONLY,
  fieldModePhone = FIELD_MODE.EDIT,
  fieldModePassword = FIELD_MODE.HIDDEN,
  fieldModeStatus = FIELD_MODE.READONLY,
  fieldModeRoles = FIELD_MODE.READONLY,
}: Props) {
  const { isUserWithEmail, resendVerificationEmail, currentUser } = useUserProvider()
  const { setStatusError, setStatusUndetermined, setStatusSuccess } = useStatusProvider()
  const { company } = useCompanyProvider()
  const [user, setUser] = useState<User>(initialUser || new User())
  const { uploadFileOnFirebaseWithUUID } = useStorageProvider()
  const [userExists, setUserExists] = useState<boolean>(false)
  const { isSubscriber } = usePaymentProvider()
  const { i18n } = useSettingsProvider()

  console.log("UserComponent with user", initialUser)

  const onChange = (prop: string, val: any): void => {
    console.log("onChange:", prop, val)
    const changedUser = new User({ ...user, [prop]: val })
    console.debug("USER:", changedUser)
    if (prop != "roles") {
      setUser(changedUser)
    }
    if (prop === "email" && fieldModeEmail == FIELD_MODE.EDIT_BUT_EMAIL_UNIQUE) {
      isUserWithEmail(val).then((exist) => {
        console.log("isUserWithEmail:" + exist)
        setUserExists(exist)
      })
    }
    if (prop === "status" || prop === "imageUri") {
      onSubmit(changedUser)
    }
  }

  const handleResendVerificationEmail = async (): Promise<void> => {
    if (user.id == currentUser?.id && !currentUser?.emailVerified) {
      setStatusUndetermined(i18n.t("sending_an_email"))
      try {
        await resendVerificationEmail()
        setStatusSuccess(i18n.t("email_sent"))
      } catch (error) {
        console.error(error)
        setStatusError(error.message)
      }
    }
    showMessage({ message: i18n.t("email_is_verified"), type: "success" })
  }

  const onSubmit = (changedUser?: User) => {
    console.log("onSubmit", user)
    if (changedUser) handleSubmit(changedUser)
    else if (user) handleSubmit(user)
    // if (inputRef === nameInputRef) {
    //   handleNextPress(phoneInputRef)
    // }
  }

  // TODO tab index based on dynamic layout not by definition
  const nameInputRef = useRef<TextInput>(null)
  const emailInputRef = useRef<TextInput>(null)
  const passwordInputRef = useRef<TextInput>(null)
  const phoneInputRef = useRef<TextInput>(null)

  return (
    <View>
      <View style={{ flexDirection: "row" }}>
        <>
          {!(fieldModeImage === FIELD_MODE.HIDDEN) && (
            <AvatarImage
              prop={"imageUri"}
              name={user.name}
              style={{ margin: MARGIN }}
              editable
              imageUri={user.imageUri}
              onChange={async (value: string) => {
                const imageData = await (await fetch(value)).blob()
                const uri = await uploadFileOnFirebaseWithUUID(imageData, user.getPathSegment(), user.id, {
                  contentType: FILE_TYPE.WEBP,
                })
                onChange("imageUri", uri)
              }}
            />
          )}
        </>
        <>
          {!(fieldModeStatus === FIELD_MODE.HIDDEN) && (
            <View
              style={{
                flexDirection: "row",
                flexGrow: 1,
                flexShrink: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <View style={{ flexGrow: 1, flexShrink: 1 }}>
                <TextInput value={i18n.t(user?.status) || ""} label={i18n.t("status")} editable={false} />
              </View>
              {fieldModeStatus === FIELD_MODE.EDIT && (
                <View style={{ flexShrink: 1, justifyContent: "center", marginHorizontal: MARGIN }}>
                  <Switch
                    value={user?.isActive()}
                    onValueChange={(value: boolean) => {
                      onChange("status", value ? UserStatus.ACTIVE : UserStatus.DISABLED)
                      console.log("switch change")
                    }}
                  />
                </View>
              )}
              {isSubscriber() && (
                <View style={{ marginHorizontal: MARGIN }}>
                  <AppText>{i18n.t("he_has_a_subscription")}</AppText>
                </View>
              )}
            </View>
          )}
        </>
      </View>
      <>
        {!(fieldModeName === FIELD_MODE.HIDDEN) && (
          <TextInput
            ref={nameInputRef}
            value={user?.name || ""}
            prop="name"
            onChangeText={(value: string) => {
              onChange("name", value)
            }}
            label={i18n.t("firstname_and_lastname")}
            autoComplete={"name"}
            disabled={fieldModeName === FIELD_MODE.READONLY}
            onBlur={() => onSubmit()}
          />
        )}
      </>
      <>
        {!(fieldModeEmail === FIELD_MODE.HIDDEN) && (
          <TextInput
            ref={emailInputRef}
            value={user?.email || ""}
            prop="email"
            autoComplete={"email"}
            onChangeText={(value: string) => onChange("email", value)}
            label={i18n.t("email")}
            disabled={fieldModeEmail === FIELD_MODE.READONLY}
            required
            rightSideIcon={
              user.id && (user.id === currentUser?.id && currentUser?.emailVerified ? <CheckIcon /> : <ErrorIcon />)
            }
            customIconAction={handleResendVerificationEmail}
            error={
              !isEmailValid(user?.email) ||
              userExists ||
              (!!user.id && user.id === currentUser?.id && !currentUser.emailVerified)
            }
            errorText={
              (!!user.id &&
                user.id === currentUser?.id &&
                !currentUser.emailVerified &&
                i18n.t("email_is_not_verified") + "! " + i18n.t("click_on_exclamation_mark") + ".") ||
              (userExists ? i18n.t("email_is_already_exists") : i18n.t("email_is_not_valid"))
            }
            onBlur={() => onSubmit()}
          />
        )}
      </>
      <>
        {!(fieldModePhone === FIELD_MODE.HIDDEN) && (
          <TextInput
            ref={phoneInputRef}
            value={user?.phoneNumber || ""}
            isValid={() => isPhoneNum(user?.phoneNumber)}
            prop="phoneNumber"
            autoComplete={"tel"}
            onChangeText={(value: string) => onChange("phoneNumber", value)}
            label={i18n.t("phone")}
            disabled={fieldModePhone === FIELD_MODE.READONLY}
            error={!isPhoneNum(user?.phoneNumber)}
            errorText={i18n.t("phone_is_not_valid")}
            onBlur={() => onSubmit()}
          />
        )}
      </>
      <>
        {!(fieldModePassword === FIELD_MODE.HIDDEN) && (
          <TextInput
            ref={passwordInputRef}
            value={user?.password || ""}
            prop="password"
            onChangeText={(value: string) => onChange("password", value)}
            label={i18n.t("password")}
            secureTextEntry
            autoComplete={"password"}
            required
            disabled={fieldModePassword === FIELD_MODE.READONLY}
            errorText={i18n.t("minimum_password_length_is")}
            error={!isPasswordValid(user?.password)}
            returnKeyType={"done"}
            onBlur={() => onSubmit()}
            blurOnSubmit={true}
          />
        )}
      </>
      <>
        {!(fieldModeRoles === FIELD_MODE.HIDDEN) && (
          <View style={{ flexWrap: "wrap", flexDirection: "row" }}>
            {currentUser?.id && company && (
              <ChipPicker
                editable={!(fieldModeRoles === FIELD_MODE.READONLY)}
                values={company?.getPermissions(currentUser?.id)}
                data={translateEnums(ROLES, i18n)}
              />
            )}
          </View>
        )}
      </>
    </View>
  )
}
