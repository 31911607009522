import MaxWidth from "../../components/container/MaxWidth"
import { ExpandableCard } from "../../components/container/ExpandableCard"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import React, { useEffect, useState } from "react"
import { Task, TASK_STATUS } from "../../shared/entity/Task"
import { TextInput } from "../../components/input/TextInput"
import Screen from "../../components/general/Screen"
import TaskListItem from "../../components/tasks/TaskListItem"
import { useTaskProvider } from "../../providers/TaskProvider"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { Feather, FontAwesome } from "@expo/vector-icons"
import { FAB, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { useCompanyProvider } from "../../providers/CompanyProvider"
import { useUserProvider } from "../../providers/UserProvider"
import { ScrollView, View } from "react-native"
import { OPERATION } from "../../shared/entity/Role"
import { ActionElement } from "../../components/container/ActionElement"
import StateInput from "../../components/input/StateInput"
import { MARGIN, RADIUS } from "../../constants/style"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.GENERAL_TASKS>

enum PageNames {
  ALL = "all",
}

export default function TaskManagerScreen({ navigation, route }: Props) {
  const { i18n } = useSettingsProvider()
  const { currentUser, isOperationAuthorized } = useUserProvider()
  const { companyUsers } = useCompanyProvider()
  const { projects } = useProjectProvider()
  const theme: Theme = useTheme()
  const [searchText, setSearchText] = useState("")
  const { taskList, selectTask, selectedTask } = useTaskProvider()
  const [showMode, setShowMode] = useState<PageNames | TASK_STATUS>(TASK_STATUS.OPEN)

  return (
    <Screen>
      <ScrollView>
        <MaxWidth>
          <View
            style={{
              margin: MARGIN,
              borderRadius: RADIUS,
              ...theme.shadows,
              backgroundColor: theme.card.backgroundColor,
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            <View style={{ width: "50%" }}>
              <TextInput label={i18n.t("search")} value={searchText} onChangeText={setSearchText} />
            </View>
            <View style={{ width: "50%" }}>
              <StateInput
                label={i18n.t("filter_by_status")}
                data={PageNames && TASK_STATUS}
                value={showMode}
                onChange={(value) => (value === showMode ? setShowMode(PageNames.ALL) : setShowMode(value))}
                translation={i18n}
              />
            </View>
          </View>
          <ExpandableCard
            noPadding
            startOpen={taskList.length < 20}
            actionElement={
              isOperationAuthorized(
                OPERATION.PROJECT_MANAGE ||
                  OPERATION.ATTENDANCE_MANAGE ||
                  OPERATION.COMPANY_MANAGE ||
                  OPERATION.SUPERADMIN ||
                  OPERATION.TASK_MANAGE
              ) && (
                <ActionElement
                  actionList={[
                    {
                      iconName: "plus",
                      label: i18n.t("new"),
                      action: () => {
                        selectTask(new Task())
                        navigation.navigate(ROUTE.TASK_DETAIL, { task: new Task() })
                      },
                    },
                  ]}
                />
              )
            }
            name={i18n.t("task_list")}
            info={taskList.filter((task) => showMode === PageNames.ALL || showMode === task.status).length || "0"}
            icon={<FontAwesome color={theme.colors.white} name={"tasks"} size={theme.iconSize.regular} />}
          >
            {taskList
              .filter(
                (task) =>
                  task.title.toLowerCase().includes(searchText.toLowerCase()) &&
                  (showMode === PageNames.ALL || showMode === task.status)
              )
              .map((task) => (
                <ExpandableCard
                  noPadding
                  noRadius
                  noMargin
                  icon={
                    task.status === TASK_STATUS.FINISHED ? (
                      <Feather color={theme.colors.white} name={"check-square"} size={theme.iconSize.regular} />
                    ) : task.status === TASK_STATUS.CLOSED ? (
                      <Feather color={theme.colors.white} name={"x-square"} size={theme.iconSize.regular} />
                    ) : (
                      <Feather color={theme.colors.white} name={"square"} size={theme.iconSize.regular} />
                    )
                  }
                  name={task.title}
                  info={projects.find((p) => p.id === task.projectId)?.title}
                  actionElement={
                    <ActionElement
                      actionList={[
                        {
                          iconName: "info",
                          label: i18n.t("detail"),
                          action: () => {
                            selectTask(task)
                            navigation.navigate(ROUTE.TASK_DETAIL, { task: task })
                          },
                        },
                      ]}
                    />
                  }
                >
                  <TaskListItem
                    disabled={
                      !isOperationAuthorized(
                        OPERATION.PROJECT_MANAGE ||
                          OPERATION.ATTENDANCE_MANAGE ||
                          OPERATION.COMPANY_MANAGE ||
                          OPERATION.SUPERADMIN ||
                          OPERATION.TASK_MANAGE
                      )
                    }
                    task={task}
                  />
                </ExpandableCard>
              ))}
          </ExpandableCard>
        </MaxWidth>
      </ScrollView>
      <FAB
        visible
        style={[
          theme.fab,
          {
            margin: 32,
            right: 0,
            bottom: 0,
          },
        ]}
        color={theme.colors?.primary}
        icon="plus"
        disabled={
          !isOperationAuthorized(
            OPERATION.PROJECT_MANAGE ||
              OPERATION.ATTENDANCE_MANAGE ||
              OPERATION.COMPANY_MANAGE ||
              OPERATION.SUPERADMIN ||
              OPERATION.TASK_MANAGE
          )
        }
        onPress={() => {
          selectTask(new Task())
          navigation.navigate(ROUTE.TASK_DETAIL, { task: new Task() })
        }}
      />
    </Screen>
  )
}
