import { Image, Linking, Platform, StyleProp, TouchableOpacity, View, ViewStyle } from "react-native"
import { useTheme } from "react-native-paper"
import AppText from "../general/AppText"
import LoadingComponent from "../general/LoadingComponent"
import { Theme } from "../../../types"
import Grid from "../container/Grid"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import CloseButton from "../button/CloseButton"
import { useEffect, useState } from "react"
import { formatBytes } from "../../shared/utils/size"
import { StorageMetadata, useStorageProvider } from "../../providers/StorageProvider"
import { RADIUS } from "../../constants/style"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  uriList: string[]
  onChange: (uriList: string[]) => void
  type?: "thumbnail"
  path: string
}

export function UploadFile(props: Props) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const style = getFileStyle(theme)
  const { pickAndUploadFile, pickAndUploadImage, uploading, deleteFileFromFirebase, getFileMetadata } =
    useStorageProvider()

  async function handleUploadFile() {
    const uri = await pickAndUploadFile(props.path)
    if (uri) {
      if (props.type == "thumbnail") props.uriList.splice(0, 1)
      props.uriList.push(uri)
      props.onChange(props.uriList)
    }
  }

  async function handleUploadImage() {
    const uri = await pickAndUploadImage(props.path)
    if (uri) {
      if (props.type == "thumbnail") props.uriList.splice(0, 1)
      props.uriList.push(uri)
      props.onChange(props.uriList)
    }
  }

  async function handleDeleteFile(uri: string) {
    try {
      try {
        await deleteFileFromFirebase(uri)
      } catch (error) {
        console.error(error)
      }
      const updatedUriList = new Set(props.uriList)
      updatedUriList.delete(uri)
      props.onChange(Array.from(updatedUriList))
    } catch (error) {
      console.error(error.message)
    }
  }

  const [storageMetadataList, setStorageMetadataList] = useState<StorageMetadata[]>([])

  const getMetaDataList = async () => {
    const storageMetadataList: StorageMetadata[] = []
    const promises: Promise<number>[] = []
    if (props.uriList) {
      props.uriList.forEach((value) => {
        promises.push(getFileMetadata(value).then((result) => storageMetadataList.push(result)))
      })
    }
    await Promise.all(promises)
    console.debug("getMetaDataList", storageMetadataList)
    setStorageMetadataList(storageMetadataList)
  }

  useEffect(() => {
    console.debug("useLayoutEffect getMetaDataList")
    getMetaDataList()
  }, [uploading, props.uriList, props.uriList ? props.uriList.length : undefined])

  console.debug("storageMetadataList", storageMetadataList)

  const defaultRenderer = () => (
    <View style={{ margin: 10, borderColor: theme.colors.primary, borderStyle: "dashed", borderWidth: 1 }}>
      <Grid>
        <>
          {storageMetadataList.map((value) => {
            console.log("render value", value)
            if (value.uri) {
              return (
                <TouchableOpacity
                  key={value.uri}
                  style={[style, { paddingVertical: 5, position: "relative" }]}
                  onPress={() => Linking.openURL(value.uri)}
                >
                  <AppText noPadding maxLines={2}>
                    {value.customMetadata.fileName}
                  </AppText>
                  {value.contentType.includes("image") ? (
                    <Image style={{ height: 64, width: 64 }} source={value}></Image>
                  ) : (
                    <MaterialCommunityIcons name={"link"} size={64} color="black" />
                  )}
                  <View
                    style={{
                      position: "absolute",
                      bottom: -15,
                      borderRadius: RADIUS,
                      padding: 2,
                      backgroundColor: theme.colors.primary,
                    }}
                  >
                    <AppText white noPadding>
                      {formatBytes(value.size)}
                    </AppText>
                  </View>
                  <CloseButton onPress={() => handleDeleteFile(value.uri)} top={-10} right={-10} />
                </TouchableOpacity>
              )
            }
          })}
          <TouchableOpacity style={[style]} onPress={handleUploadFile}>
            <MaterialCommunityIcons name="file-upload" size={30} color={theme.colors.primary} />
            <AppText style={{ width: "100%", textAlign: "center" }}>
              {`${i18n.t("upload")} ${i18n.t("attachment")}`}
            </AppText>
            {uploading && <LoadingComponent />}
          </TouchableOpacity>
          {Platform.OS === "ios" && (
            <TouchableOpacity style={[style]} onPress={handleUploadImage}>
              <MaterialCommunityIcons name="file-image" size={30} color={theme.colors.primary} />
              <AppText style={{ width: "100%", textAlign: "center" }}>{`${i18n.t("upload")} ${i18n.t(
                "image"
              )}`}</AppText>
              {uploading && <LoadingComponent />}
            </TouchableOpacity>
          )}
        </>
      </Grid>
    </View>
  )

  const thumbnailRenderer = () => (
    <TouchableOpacity
      style={{
        margin: 10,
        height: "100%",

        borderColor: theme.colors.primary,
        borderStyle: "dashed",
        borderWidth: 1,
      }}
      onPress={handleUploadImage}
    >
      {storageMetadataList.length > 0 ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
            position: "relative",
          }}
        >
          <View style={{ flexGrow: 1, width: "100%" }}>
            <Image
              style={{ resizeMode: "contain", flexGrow: 1 }}
              key={storageMetadataList[0].uri}
              source={storageMetadataList[0]}
            />
          </View>
          <View
            style={{
              backgroundColor: theme.colors.primary,
              padding: 2,
              borderRadius: RADIUS,
              bottom: 10,
              right: 10,
              position: "absolute",
            }}
          >
            <AppText noPadding white>
              {`${i18n.t("change")} ${i18n.t("attachment")}`}
            </AppText>
          </View>
        </View>
      ) : (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <AppText>{`${i18n.t("upload")} ${i18n.t("image")}`}</AppText>
          <MaterialCommunityIcons name="file-upload" size={30} color={theme.colors.primary} />
          {uploading && <LoadingComponent />}
        </View>
      )}
    </TouchableOpacity>
  )

  switch (props.type) {
    case "thumbnail":
      return thumbnailRenderer()
    default:
      return defaultRenderer()
  }
}

function getFileStyle(theme: Theme): StyleProp<ViewStyle> {
  return {
    marginHorizontal: 20,
    marginBottom: 15,
    marginTop: 25,
    width: 96,
    height: 124,
    borderColor: theme.colors.primary,
    borderStyle: "solid",
    borderWidth: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: RADIUS,
  }
}
