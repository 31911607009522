import React from "react"
import { View } from "react-native"

type Props = {
  children?: React.ReactNode
}

export default function Grid(props: Props) {
  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        justifyContent: "center",
      }}
    >
      {props.children}
    </View>
  )
}
