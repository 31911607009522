import AppText from "../general/AppText"
import { ExpandableCard } from "../container/ExpandableCard"
import Dropdown from "../input/Dropdown"
import { LANGUAGE, LanguageSettings } from "../../shared/common/settings/LanguageSettings"
import { useEffect, useState } from "react"
import { translateEnums } from "../../utils/util"
import AsyncStorage from "@react-native-async-storage/async-storage"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  data: LanguageSettings
  prop: string
  onSave: (prop: string, data: LanguageSettings) => void
}

export default function LanguageSettingsComponent(props: Props) {
  const [languageSettings, setLanguageSettings] = useState(new LanguageSettings(props.data))
  const { i18n } = useSettingsProvider()

  useEffect(() => {
    if (!languageSettings) {
      setLanguageSettings(props.data)
    }
  }, [languageSettings])

  const onChange = (prop: string | undefined, value: any) => {
    if (prop == undefined) {
      console.error("prop is undefined, maybe it was not supplied to the calling component?")
      return languageSettings
    }
    const newLanguageSettings = new LanguageSettings({ ...languageSettings, [prop]: value })
    setLanguageSettings(newLanguageSettings)
    console.log(newLanguageSettings)
    props.onSave(props.prop, newLanguageSettings)
    return newLanguageSettings
  }

  return (
    <ExpandableCard name={i18n.t("language")} startOpen closable={false}>
      <AppText type={"heading"}>{i18n.t("language")}</AppText>
      <Dropdown
        value={props.data.language || "cs"}
        label={i18n.t("select_language")}
        data={translateEnums(LANGUAGE, i18n)}
        onChange={(selected) => {
          onChange("language", selected)
          AsyncStorage.setItem("language", selected).catch(console.error)
        }}
      />
    </ExpandableCard>
  )
}
