import { useTheme } from "react-native-paper"
import React, { useState } from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Theme } from "../../../types"
import AppText from "../general/AppText"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { FontAwesome } from "@expo/vector-icons"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  children?: React.ReactNode
  startOpen?: boolean
  closable?: boolean
  icon?: React.ReactNode
  name?: React.ReactNode
  info?: React.ReactNode
  noPadding?: boolean
  noRadius?: boolean
  noMargin?: boolean
  ref?: any
  onPressed?: (isOpen: boolean) => void
  actionElement?: React.ReactNode | React.ReactNode[]
  headerColor?: string
  offset?: boolean
}

export function ExpandableCard({
  children,
  info,
  name,
  icon,
  ref = null,
  closable = true,
  startOpen = false,
  noPadding = false,
  noRadius = false,
  noMargin = false,
  onPressed,
  headerColor,
  actionElement,
  offset = false,
}: Props) {
  const [isOpen, setIsOpen] = useState(startOpen)
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const styles = getStylesExpandableCard(theme)

  return (
    <View style={{ margin: noMargin ? 0 : MARGIN }}>
      <View style={[styles.container, { borderRadius: noRadius ? 0 : RADIUS }]}>
        <TouchableOpacity
          activeOpacity={!closable ? 1 : undefined}
          ref={ref}
          style={{
            flexDirection: "row",
            backgroundColor: headerColor ? headerColor : theme.colors?.primary,
            height: 65,
            alignItems: "center",
            paddingTop: noRadius ? 3 : 0,
          }}
          onPress={() => {
            onPressed && onPressed(!isOpen)
            closable ? setIsOpen(!isOpen) : setIsOpen(true)
          }}
        >
          {closable && (
            <View
              style={{
                marginRight: MARGIN,
                height: "100%",
                width: 45,
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: theme.card.backgroundColor,
                maxWidth: "100%",
              }}
            >
              <FontAwesome size={24} name={isOpen ? "chevron-down" : "chevron-right"} color={theme.colors.primary} />
            </View>
          )}
          <View style={{ width: MARGIN }} />
          {icon}
          <AppText ellipsizeMode={"tail"} noPadding style={styles.name}>
            {name} {info ? `(${info})` : ""}
          </AppText>
          <View style={{ flexShrink: 999 }}></View>
          {actionElement}
          {/* <AppText noPadding style={styles.name}> */}
          {/*   {info} */}
          {/* </AppText> */}
        </TouchableOpacity>
        <View
          style={{
            overflow: "hidden",
            height: isOpen ? undefined : 0,
            padding: isOpen ? (noPadding ? 0 : PADDING) : 0,
            flexDirection: "row",
          }}
        >
          {offset && <View style={{ paddingRight: PADDING }} />}
          <View style={{ flex: 1 }}>
            {children && !(Array.isArray(children) && children.length == 0) ? (
              children
            ) : (
              <View style={{ alignSelf: "center", padding: PADDING }}>
                <AppText>{i18n.t("there_are_no_entries")}</AppText>
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  )
}

export function getStylesExpandableCard(theme: Theme) {
  return StyleSheet.create({
    container: {
      ...theme.shadows,
      flexShrink: 1,
      zIndex: -20,
      backgroundColor: theme.card.backgroundColor,
      overflow: "hidden",
      alignSelf: "flex-start",
      width: "100%",
    },
    name: { flexGrow: 1, flexShrink: 1, marginLeft: 10, color: theme.colors?.white },
    info: { color: theme.colors?.white },
  })
}
