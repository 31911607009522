// @ts-nocheck
import * as React from "react"
import Svg, { ClipPath, Defs, G, LinearGradient, Mask, Path, Rect, Stop, SvgProps } from "react-native-svg"
/* SVGR has dropped some elements not supported by react-native-svg: filter */
const SvgComponent = (props: SvgProps) => (
  <Svg width={764} height={1548} viewBox="0 0 764 1548" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Mask
      id="mask0_166_275"
      style={{
        maskType: "alpha",
      }}
      maskUnits="userSpaceOnUse"
      x={25}
      y={2}
      width={715}
      height={1546}
    >
      <Rect width={714.04} height={1545.27} rx={87.3651} transform="matrix(-1 0 0 1 739.239 2.72949)" fill="#E6E6E6" />
    </Mask>
    <G mask="url(#mask0_166_275)">
      <Rect width={714.04} height={1539.81} rx={87.3651} transform="matrix(-1 0 0 1 739.236 2.72949)" fill="#E6E6E6" />
      <G filter="url(#filter0_d_166_275)">
        <Rect x={24.5635} y={29.9336} width={715.302} height={119.703} fill="#F5F5F5" />
      </G>
      <G clipPath="url(#clip0_166_275)">
        <Path
          d="M57.2448 97.916H97.5772C99.0834 97.916 100.339 99.1532 100.339 100.689C100.339 102.225 99.0834 103.462 97.5772 103.462H57.2448C55.7386 103.462 54.4834 102.225 54.4834 100.689C54.4834 99.1532 55.7386 97.916 57.2448 97.916Z"
          fill="#F39719"
        />
        <Path
          d="M77.2436 73.7686C79.6703 73.7686 81.6367 75.731 81.6367 78.1627V95.6966H72.8506V78.2053C72.8506 75.7736 74.817 73.7686 77.2436 73.7686Z"
          fill="#F39719"
        />
        <Path d="M70.7171 95.7382H57.4961C57.4961 95.7382 58.9186 81.9586 70.7171 76.8818V95.7382Z" fill="#F39719" />
        <Path d="M83.8965 95.7382H97.1175C97.1175 95.7382 95.695 81.9586 83.8965 76.8818V95.7382Z" fill="#F39719" />
        <Path
          d="M90.8852 105.723V117.369H63.7738V105.723H60.1338V120.91C60.1338 124.408 62.9788 127.267 66.4933 127.267H88.1657C91.6801 127.267 94.5252 124.408 94.5252 120.91V105.723H90.8852ZM77.4132 125.475C75.6141 125.475 74.1497 124.024 74.1497 122.19C74.1497 120.398 75.6141 118.905 77.4132 118.905C79.2122 118.905 80.6766 120.356 80.6766 122.19C80.6766 124.024 79.2122 125.475 77.4132 125.475Z"
          fill="#4C4B4B"
        />
        <Path
          d="M77.4128 118.946C79.2119 118.946 80.6762 120.397 80.6762 122.231C80.6762 124.023 79.2119 125.516 77.4128 125.516C75.6138 125.516 74.1494 124.066 74.1494 122.231C74.1913 120.397 75.6138 118.946 77.4128 118.946Z"
          fill="#F7F7F7"
        />
        <Path
          d="M122.513 127.268C118.831 127.268 115.275 126.841 111.886 126.031C108.455 125.178 105.652 124.111 103.435 122.746L108.204 111.91C110.296 113.104 112.597 114.086 115.191 114.854C117.743 115.579 120.254 115.963 122.68 115.963C124.103 115.963 125.233 115.877 126.027 115.664C126.822 115.493 127.45 115.195 127.827 114.854C128.203 114.512 128.37 114.086 128.37 113.574C128.37 112.763 127.952 112.166 127.073 111.697C126.195 111.227 125.023 110.843 123.601 110.545C122.178 110.246 120.588 109.905 118.873 109.521C117.158 109.137 115.442 108.71 113.727 108.113C112.011 107.516 110.422 106.748 108.957 105.767C107.535 104.785 106.363 103.548 105.485 101.97C104.606 100.391 104.188 98.4289 104.188 96.0825C104.188 93.3522 104.941 90.8778 106.489 88.6168C107.995 86.3557 110.296 84.5639 113.308 83.2414C116.321 81.9189 120.086 81.2363 124.605 81.2363C127.575 81.2363 130.504 81.535 133.349 82.1749C136.236 82.8148 138.83 83.7534 141.131 85.0759L136.696 95.8266C134.521 94.7174 132.429 93.9068 130.421 93.3522C128.412 92.7976 126.404 92.5416 124.521 92.5416C123.099 92.5416 121.969 92.6696 121.132 92.9256C120.296 93.1815 119.71 93.5228 119.333 93.9494C118.957 94.3761 118.789 94.8453 118.789 95.3146C118.789 96.0825 119.208 96.6798 120.086 97.1064C120.965 97.533 122.136 97.917 123.559 98.2156C124.981 98.5142 126.571 98.8129 128.329 99.1541C130.044 99.4954 131.801 99.9647 133.475 100.519C135.19 101.117 136.78 101.884 138.202 102.866C139.625 103.847 140.796 105.084 141.675 106.62C142.554 108.156 142.972 110.118 142.972 112.422C142.972 115.11 142.219 117.584 140.671 119.802C139.123 122.021 136.864 123.855 133.893 125.22C130.839 126.585 127.073 127.268 122.513 127.268Z"
          fill="#4C4B4B"
        />
        <Path
          d="M164.646 115.194C163.725 115.834 162.679 116.133 161.466 116.133C160.629 116.133 159.918 115.834 159.374 115.237C158.83 114.64 158.537 113.829 158.537 112.763V104.102H165.566V93.7355H158.537V84.1367H144.396V93.7355H139.71V104.102H144.396V112.891C144.396 117.626 145.693 121.124 148.329 123.428C150.965 125.732 154.646 126.841 159.332 126.841C161.006 126.841 162.596 126.713 164.102 126.414C165.608 126.116 166.947 125.646 168.035 125.049L164.646 115.194Z"
          fill="#4C4B4B"
        />
        <Path
          d="M196.566 95.1882C193.344 92.5432 188.784 91.2207 182.885 91.2207C180.207 91.2207 177.488 91.562 174.684 92.2446C171.881 92.9272 169.496 93.8657 167.488 95.1456L172.007 104.531C173.136 103.678 174.475 103.038 176.065 102.569C177.655 102.099 179.203 101.843 180.751 101.843C183.052 101.843 184.684 102.27 185.73 103.166C186.525 103.849 186.985 104.744 187.152 105.854H181.462C177.906 105.854 174.977 106.238 172.676 107.048C170.375 107.859 168.618 109.011 167.53 110.589C166.4 112.125 165.856 114.045 165.856 116.263C165.856 118.225 166.317 120.017 167.279 121.638C168.241 123.259 169.664 124.539 171.546 125.521C173.429 126.502 175.772 126.971 178.575 126.971C181.546 126.971 183.889 126.331 185.646 125.094C186.776 124.283 187.654 123.217 188.282 121.852V126.246H201.419V107.773C201.419 102.014 199.788 97.8332 196.566 95.1882ZM185.479 117.543C184.642 118.055 183.763 118.311 182.801 118.311C181.755 118.311 180.918 118.055 180.333 117.543C179.747 117.031 179.412 116.348 179.412 115.58C179.412 114.599 179.747 113.874 180.458 113.405C181.128 112.935 182.257 112.679 183.763 112.679H187.278V115.068C186.859 116.178 186.274 117.031 185.479 117.543Z"
          fill="#4C4B4B"
        />
        <Path
          d="M227.57 91.8613L220.207 110.291L213.303 91.8613H198.785L212.55 126.246H227.194L240.959 91.8613H227.57Z"
          fill="#4C4B4B"
        />
        <Path
          d="M268.697 126.246L255.811 108.585L268.404 91.8613H253.552L248.406 99.1564L243.51 91.8613H227.57L240.122 109.225L227.193 126.246H242.506L247.736 118.909L252.464 126.246H268.697Z"
          fill="#F39719"
        />
        <Path d="M63.7744 105.721H90.8859V117.367H63.7744V105.721Z" fill="#F7F7F7" />
      </G>
      <Rect x={61.8125} y={183.243} width={43.9413} height={5.47326} fill="#F39719" />
      <Rect x={61.8125} y={194.19} width={43.9413} height={5.47326} fill="#F39719" />
      <Rect x={61.8125} y={205.135} width={43.9413} height={5.47326} fill="#F39719" />
      <Rect x={127.723} y={174.12} width={281.957} height={45.6105} rx={22.8052} fill="#818181" />
    </G>
    <G filter="url(#filter1_d_166_275)">
      <Path
        d="M54.6074 276.717C54.6074 271.658 58.7086 267.557 63.7676 267.557H701.315C706.374 267.557 710.475 271.658 710.475 276.717V1347.73C710.475 1352.79 706.374 1356.89 701.315 1356.89H63.7676C58.7086 1356.89 54.6074 1352.79 54.6074 1347.73V276.717Z"
        fill="#F7F7F7"
      />
    </G>
    <Rect x={74.7598} y={298.495} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={453.206} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={453.206} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={453.206} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={647.956} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={842.709} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={1037.46} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={74.7598} y={1232.21} width={483.657} height={32.7619} rx={16.381} fill="#A2A2A2" />
    <Rect x={82.0859} y={340.36} width={412.207} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={369.479} width={527.626} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={495.066} width={265.645} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={524.19} width={375.567} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={553.312} width={304.117} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={689.821} width={412.207} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={718.94} width={412.207} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={913.693} width={516.633} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={884.571} width={483.657} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={942.812} width={483.657} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={1108.44} width={483.657} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={1303.19} width={483.657} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={971.937} width={441.52} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={1079.32} width={412.207} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect x={82.0859} y={1274.07} width={412.207} height={20.0212} rx={10.0106} fill="#BBBBBB" />
    <Rect
      x={560.676}
      y={129.313}
      width={156.364}
      height={156.364}
      rx={47.4675}
      fill="#F39719"
      stroke="white"
      strokeWidth={74.4589}
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M647.04 187.534C644.772 186.225 641.936 187.862 641.936 190.481V197.195C636.359 196.71 613.834 196.781 604.923 227.97H622.786C624.453 208.422 636.298 209.173 641.143 209.479C641.434 209.498 641.699 209.515 641.936 209.525V217C641.936 219.619 644.772 221.256 647.04 219.947L670.006 206.687C672.275 205.377 672.275 202.103 670.006 200.794L647.04 187.534Z"
      fill="white"
    />
    <Rect x={242.984} y={1490.67} width={303.048} height={8.19048} rx={4.09524} fill="black" />
    <Path
      d="M677.783 16.1633C716.466 16.1633 747.824 47.5218 747.824 86.2044L747.824 1461.17C747.824 1499.85 716.465 1531.21 677.783 1531.21L86.205 1531.21C47.5223 1531.21 16.1638 1499.85 16.1638 1461.17L16.1639 86.2043C16.1639 47.5217 47.5223 16.1633 86.205 16.1633L677.783 16.1633Z"
      stroke="url(#paint0_linear_166_275)"
      strokeWidth={32.3267}
    />
    <Path
      d="M188 32.3404C188 14.4793 202.479 0 220.34 0H543.744C561.605 0 576.084 14.4793 576.084 32.3404C576.084 50.2015 561.605 64.6807 543.744 64.6807H220.34C202.479 64.6807 188 50.2015 188 32.3404Z"
      fill="url(#paint1_linear_166_275)"
    />
    <Defs>
      <LinearGradient
        id="paint0_linear_166_275"
        x1={556.558}
        y1={1609.32}
        x2={-3.77081}
        y2={-12.3961}
        gradientUnits="userSpaceOnUse"
      >
        <Stop />
        <Stop offset={0.498264} stopColor="#797979" />
        <Stop offset={1} />
      </LinearGradient>
      <LinearGradient
        id="paint1_linear_166_275"
        x1={575.944}
        y1={32.2304}
        x2={155.444}
        y2={32.2304}
        gradientUnits="userSpaceOnUse"
      >
        <Stop stopColor="#212121" />
        <Stop offset={1} stopColor="#0C0C0C" />
      </LinearGradient>
      <ClipPath id="clip0_166_275">
        <Rect width={214.214} height={65.6791} fill="white" transform="translate(54.4883 66.292)" />
      </ClipPath>
    </Defs>
  </Svg>
)
export default SvgComponent
