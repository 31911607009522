import { TimePickerModal } from "react-native-paper-dates"
import React, { useEffect, useState } from "react"
import TextField from "./TextField"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { TouchableOpacity } from "react-native"
import { MaterialCommunityIcons } from "@expo/vector-icons"
import { useSettingsProvider } from "../../providers/SettingsProvider"

type Props = {
  time: { hours?: number; minutes?: number }
  onSave?: (time: { hours: number; minutes: number }) => void
  label: string
  disabled?: boolean
  required?: boolean
}

export function TimeInput(props: Props) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const time = (time: { hours?: number; minutes?: number }) => {
    if (time && time.hours != undefined && time.minutes != undefined) {
      return time.hours.toString() + ":" + time.minutes.toString().padStart(2, "0")
    }
    return props.label
  }
  const [text, setText] = useState(props.time ? time(props.time) : "")
  const [isDatePickerVisible, setDatePickerVisibility] = useState(false)

  useEffect(() => {
    setText(time(props.time))
  }, [props.time?.hours, props.time?.minutes])

  const change = (str: string) => {
    str = str.replace(".", ":")
    str = str.replace(",", ":")
    str = str.replace(" ", "")
    str = str.replace(/[^0-9:]/g, "")
    setText(str)
  }

  const isValid = () => {
    return /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/.test(text)
  }

  const getTime = () => {
    return { hours: parseInt(text.split(":")[0]), minutes: parseInt(text.split(":")[1]) }
  }

  return (
    <>
      <TextField
        label={props.label}
        value={text}
        required={props.required}
        onChange={change}
        errorText={isValid() ? "" : i18n.t("invalid_time")}
        onSave={() => {
          isValid() && props.onSave && props.onSave(getTime())
        }}
      >
        <TouchableOpacity
          style={{
            flexGrow: 1,
            height: "100%",
            paddingHorizontal: 20,
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={props.disabled ?? false}
          onPress={() => setDatePickerVisibility(true)}
        >
          <MaterialCommunityIcons name="clock" size={theme.iconSize.regular} color={theme.colors.white} />
        </TouchableOpacity>
      </TextField>
      <TimePickerModal
        locale={"cs"}
        hours={isNaN(getTime()?.hours) ? new Date().getHours() : getTime()?.hours}
        minutes={isNaN(getTime()?.minutes) ? new Date().getMinutes() : getTime()?.minutes}
        visible={isDatePickerVisible}
        onConfirm={(time) => {
          props.onSave && props.onSave(time)
          setDatePickerVisibility(false)
        }}
        onDismiss={() => setDatePickerVisibility(false)}
      />
    </>
  )
}
