export enum ROUTE {
  DRAWER = "Drawer",
  USER_REGISTER = "UserRegister",
  USER_LOGIN = "UserLogin",
  USER_DELETE = "UserDelete",
  USER_PASSWORD_CHANGE = "UserPasswordChange",
  USER_PASSWORD_FORGOT = "UserPasswordForgot",
  USER_DETAIL = "UserDetail",
  USER_PROFILE = "UserProfile",
  USER_NEW = "UserNew",
  USER_LIST = "UserList",
  USER_PICKER = "UserPicker",

  PROJECT_LIST = "ProjectList",
  PROJECT_NEW = "ProjectNew",
  PROJECT_EDIT = "ProjectEdit",
  PROJECT_DETAIL = "ProjectDetail",
  PROJECT_DELETE = "ProjectDelete",

  TASK_DETAIL = "Task",

  DIARY_OVERVIEW = "DiaryOverview",
  DIARY_TITLE = "DiaryTitle",
  DIARY_MAIN_DIRECTORY = "DiaryMainDirectory",
  DIARY_DETAIL = "DiaryDetail",
  DIARY_NEW = "DiaryNew",

  DIARY_DOCUMENT_OVERVIEW = "DiaryDocumentOverview",
  DIARY_DOCUMENT_DETAIL = "DiaryDocumentDetail",
  DIARY_SIGNATURE = "DiarySignature",

  COMPANY = "Company",
  COMPANY_LIST = "CompanyList",
  COMPANY_DETAIL = "CompanyDetail",

  ATTENDANCE = "Attendance",
  ATTENDANCE_LIST = "AttendanceList",

  ADMIN_COMPANY = "AdminCompany",
  ADMIN_COMPANY_EDIT = "AdminCompanyEdit",
  ADMIN_COMPANY_OVERVIEW = "AdminCompanyOverview",
  ADMIN_MODULES = "AdminModules",

  ARTICLE_DETAIL = "ArticleDetail",
  ARTICLE_LIST = "News",

  GENERAL_ABOUT = "GeneralAbout",
  GENERAL_INFO = "GeneralInfo",
  GENERAL_PAYMENT = "GeneralPayment",
  GENERAL_PREVIEW = "GeneralPreview",
  GENERAL_REPORT = "GeneralReport",
  GENERAL_TASKS = "GeneralTasks",
  GENERAL_SETTINGS = "GeneralSettings",
  GENERAL_TUTORIAL = "GeneralTutorial",
  GENERAL_VOP = "GeneralVop",
  GENERAL_SUPPORT = "GeneralSupport",

  PAYMENT_PRODUCT = "PaymentProduct",

  APPLICATION_SETTINGS = "ApplicationSettings",

  MODAL = "Modal",
  NOTFOUND = "NotFound",

  LOADING = "Loading",
  DEV_SCREEN = "DevScreen",

  MEMO_ROOMS = "MemoRooms",
  MEMO_MESSAGES = "MemoMessages",

  SUPERADMIN_RELEASE = "SuperadminRelease",
}
