/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native"
import * as Linking from "expo-linking"
import { RootStackParamList } from "./types"

const linking: LinkingOptions<RootStackParamList> = {
  enabled: true,
  prefixes: [Linking.createURL("/"), "https://app.stavx.cz/"],
  config: {
    screens: {
      Drawer: {
        screens: {
          ProjectList: "DrawerProjectList",
          UserList: "DrawerUserList",
          GeneralSettings: "DrawerGeneralSettings",
          TaskList: "DrawerTaskList",
          GeneralInfo: "DrawerGeneralInfo",
        },
      },
      GeneralVop: "GeneralVop",
      GeneralSupport: "GeneralSupport",
      GeneralAbout: "GeneralAbout",
      GeneralReport: "GeneralReport",
      UserDetail: "UserDetail",
      UserNew: "UserNew",
      UserLogin: "UserLogin",
      UserRegister: "UserRegister",
      UserPasswordForgot: "UserPasswordForgot",
      UserPasswordChange: "UserPasswordChange",
      ProjectList: "ProjectList",
      ProjectDetail: "ProjectDetail",
      ProjectNew: "ProjectNew",
      ProjectEdit: "ProjectEdit",
      ProjectDelete: "ProjectDelete",
      DiaryOverview: "DiaryOverview",
      DiaryTitle: "DiaryTitle",
      Company: "Company",
      CompanyList: "CompanyList",
      CompanyDetail: "CompanyDetail",
      AdminCompany: "AdminCompany",
      AdminCompanyEdit: "AdminCompanyEdit",
      AdminCompanyOverview: "AdminCompanyOverview",
      //      Modal: "modal",
      //      NotFound: "*",
      //      Loading: "/",
    },
  },
}

export default linking
