/**
 * @param {Date} date
 * @returns {String} date in String format "yyyy-mm-dd"
 */
import { MONTH_NAMES } from "../constants/label"

export function getDateSimpleString(date: Date): string {
  return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(
    2,
    "0"
  )}`
}

type DateFormat = "FULLMONTH" | "MONTHASNUMBER" | "MONTHANDYEAR"

/**
 *
 * @param i18n {I18n} Translation
 * @param date {Date} Enter the current date e.g (new Date())
 * @param format {DateFormat} If the format is true the months will be displayed as full names e.g (23. October) and if format is false the months will be displayed as numbers (23. 10.)
 * @return {string} Returns the date e.g (23. October or 23. 10.)
 */
export function getDateTime({
  i18n,
  date = new Date(),
  format,
}: {
  i18n?: any
  date?: Date
  format?: DateFormat
}): string {
  if (format == "FULLMONTH") {
    return (
      date.getDate() + ". " + i18n?.t(MONTH_NAMES[date.getMonth().toString() as unknown as keyof typeof MONTH_NAMES])
    )
  } else if (format == "MONTHASNUMBER") {
    return date.getDate() + ". " + (date.getMonth() + 1) + "."
  } else if (format == "MONTHANDYEAR") {
    return (
      i18n?.t(MONTH_NAMES[date.getMonth().toString() as unknown as keyof typeof MONTH_NAMES]) + " " + date.getFullYear()
    )
  } else {
    return date.getDate() + ". " + (date.getMonth() + 1) + "."
  }
}

export function getTime(date: Date): string {
  return date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0")
}

export function getTimeWithSeconds(date: Date): string {
  return (
    date.getUTCHours() +
    ":" +
    date.getUTCMinutes().toString().padStart(2, "0") +
    ":" +
    date.getUTCSeconds().toString().padStart(2, "0")
  )
}

export function toDiaryItemDay(date: Date, i18n: any): string {
  if (!date) return ""
  const weekdays = [
    i18n.t("week_sun"),
    i18n.t("week_mon"),
    i18n.t("week_tue"),
    i18n.t("week_wed"),
    i18n.t("week_thu"),
    i18n.t("week_fri"),
    i18n.t("week_sat"),
  ]
  return weekdays[date.getDay()].toUpperCase()
}

export function toDateString(date?: Date | number) {
  if (!date) return ""
  const myDate = new Date(date)
  return `${myDate.getDate()}. ${myDate.getMonth() + 1}. ${myDate.getFullYear()}`
}

/**
 * @param {Number} number - number of the hours
 * @returns {String} hours label in correct declesion form
 */
export function getHourDeclensionForm(number: number): string {
  if (1 === number) {
    return "hodina"
  } else if (1 < number && number < 5) {
    return "hodiny"
  } else {
    return "hodin"
  }
}

export function YYYYMMDD(date: Date) {
  return (
    date.getFullYear() +
    "-" +
    (date.getMonth() + 1).toString().padStart(2, "0") +
    "-" +
    date.getDate().toString().padStart(2, "0")
  )
}

export const roundUpHourString = (currentTime: Date): string => {
  const currentHour = currentTime.getHours()
  const currentMinutes = currentTime.getMinutes()
  let roundedHour = currentMinutes >= 30 ? currentHour + 1 : currentHour
  if (roundedHour === 24) {
    roundedHour = 0
  }
  let hourString = currentHour.toString()
  if (hourString.length === 1) hourString = "0" + hourString
  return currentHour.toString() + ":00:00"
}
export const toCurrentDate = (date: any): string => {
  let myDate = new Date(date)
  if (date?.seconds) myDate = date.toDate()
  myDate.setHours(myDate.getHours() + 3)
  return "[" + myDate.getDate() + ". " + (myDate.getMonth() + 1) + ". " + myDate.getFullYear() + "]"
}
