import axios from "axios"
import { I18n } from "i18n-js"
import { v4 as uuid } from "uuid"

function position(currencyPosition, value, currencyCode) {
  return currencyPosition === "left" ? `${currencyCode} ${value}` : `${value} ${currencyCode}`
}

export const formatCurrency = (value, currencyCode, vat, wrap = false) => {
  const string = "string"
  let result
  let currencyPosition = "right" // left or right
  const maxFractionDigits = 2
  const decimalSeparator = " , "

  currencyPosition = currencyCode === "USD" ? "left" : "right"

  const thousandSeparator = currencyCode === "USD" ? "," : " "
  const vatString = vat === 0 ? "bez DPH" : vat === -1 ? "" : "s DPH"
  if (value === 0 || value === null || value === undefined || value === "0" || typeof value === string) {
    return `${position(currencyPosition, 0, currencyCode)}${wrap ? "\n" : " "}${vatString}`
  }

  //let currencyCheck = currencyCode.replace(/\s/g, '').toLowerCase();
  //if (currencyCheck === 'idr' || currencyCheck === 'rp') {
  // value = Math.ceil(value)
  //}
  const valueSplit = (
    String(value).includes(".") ? value.toFixed(maxFractionDigits).replace(".", ", ") : String(value)
  ).split(`${thousandSeparator}`)
  const firstvalue = valueSplit[0]
  const secondvalue = valueSplit[1]
  const valueReal = String(firstvalue).replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)

  if (Number(secondvalue) > 0) {
    result = position(currencyPosition, `${valueReal} ${secondvalue}`, currencyCode)
  } else {
    result = position(currencyPosition, `${valueReal}`, currencyCode)
  }
  return `${result}${wrap ? "\n" : " "}${vatString}`
}

export function formatNumber(number) {
  const thousandSeparator = " "

  const parts = String(number).split(".")
  if (parts.length > 1) {
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)
    parts[1] = parts[1]?.slice(0, 2)
    return parts.join(".")
  } else {
    return String(number).replace(/\B(?=(\d{3})+(?!\d))/g, `${thousandSeparator}`)
  }
}

export function separateCamelCaseString(string: string): string {
  return string
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/([A-Z][a-z])/g, " $1")
    .replace(/^./, (match) => match.toUpperCase())
}

export const getEnumKeys = (enumObject) => {
  return Object.keys(enumObject).reduce((arr, key) => {
    if (!arr.includes(key)) {
      arr.push(enumObject[key])
    }
    return arr
  }, [])
}
export const getEnumValues = (enumObject) => {
  return Object.values(enumObject).reduce((arr, key) => {
    if (!arr.includes(key)) {
      arr.push(enumObject[key])
    }
    return arr
  }, [])
}

export function objectWithoutKey<T>(object: T, key: keyof T) {
  const { [key]: deletedKey, ...otherKeys } = object
  return otherKeys
}

export const getBase64Image = async (uri?: string): Promise<string> => {
  try {
    if (uri) {
      const res = await axios({
        url: uri,
        method: "GET",
        responseType: "arraybuffer",
      })
      const raw = Buffer.from(res.data, "binary").toString("base64")
      const img = "data:" + res.headers["content-type"] + ";base64," + raw
      return img
    }
  } catch (error) {
    console.error("getBase64Image", error)
  }
  return ""
}

export function translateEnums(data: Record<string, any>, i18n: I18n) {
  return Object.entries(data).map(([label, value]) => ({
    value: label,
    label: i18n.t(value),
  }))
}

export function formatInterval(data: string, i18n: I18n): string {
  switch (data) {
    case "year":
      return i18n.t("per_year")
    case "month":
      return i18n.t("per_month")
    case "week":
      return i18n.t("per_week")
    case "day":
      return i18n.t("per_day")
    default:
      return data
  }
}

export function createFBID(): string {
  const id = uuid().replace(/-/g, "")
  return id.substring(0, 20)
}
