import { dateFromDB, DocObject } from "./DocObject"
import { CustomInputDefinition, isRendered, MODULE_TAGS, stringFrom } from "../common/CustomInputDefinition"
import { Entry } from "../entry/Entry"
import { IExporter, ITableExportable } from "../export/export"
import { CompanyUser } from "./User"
import { getTime } from "../utils/date"

export class Attendance extends DocObject implements Entry, ITableExportable {
  userId?: string
  start?: Date
  end?: Date
  companyId?: string
  projectId?: string
  taskId?: string
  state: ATTENDANCE_STATE = ATTENDANCE_STATE.PREPARED
  customFields: Record<string, any> = {}
  definitions: CustomInputDefinition[] = []

  fieldNames(): string[] {
    return ["Jméno", "Počet hodin"].concat(this.definitions.filter((d) => d.type != "TAG").map((d) => d.label))
  }

  exportTableData(exporter: IExporter, heads: string[]) {
    const companyUsers = exporter.extraData.companyUsers as CompanyUser[]
    for (const head of heads) {
      if (head == "Jméno") {
        exporter.addCellText(companyUsers.find((u) => u.id == this.userId)?.name ?? "")
        continue
      }
      if (head == "Počet hodin") {
        if (this.end && this.start)
          exporter.addCellText(
            (this.isWork() ? "" : "*") + getTime(new Date(this.end.valueOf() - this.start.valueOf()))
          )
        else exporter.addCellText("")
        continue
      }
      const definition = this.definitions.find((d) => d.label == head)
      if (!definition) {
        exporter.addCellText("")
        continue
      }
      exporter.addCellText(stringFrom(this.customFields[head], definition))
    }
  }

  constructor(params?: Partial<Attendance>) {
    super(params)
    this.assign(params)
  }

  getPathSegment() {
    return "Attendance"
  }

  assign(data: any) {
    super.assign(data)
    for (const key in data) {
      if (key == "start") this.start = dateFromDB(data["start"])
      if (key == "end") this.end = dateFromDB(data["end"])
    }
    this.definitions = this.definitions ?? []
  }

  isWork() {
    return !this.definitions.find(
      (d) =>
        isRendered(d, this.definitions, this.customFields) &&
        d.tags?.includes(MODULE_TAGS.ATTENDANCE_NO_WORK) &&
        this.customFields[d.label]
    )
  }

  deltaOfTimes(unit?: TimeUnit, rounded?: number): number {
    if (this.start && this.end) {
      let time = this.end.getTime() - this.start.getTime()
      switch (unit) {
        case TimeUnit.DAYS:
          time /= 1000 * 60 * 60 * 24
          break
        case TimeUnit.HOURS:
          time /= 1000 * 60 * 60
          break
        case TimeUnit.MINUTES:
          time /= 1000 * 60
          break
        case TimeUnit.SECONDS:
          time /= 1000
          break
        default:
          break
      }
      if (rounded) {
        return Math.abs(Number(time.toFixed(rounded || 2)))
      } else {
        return Math.abs(time)
      }
    }
    return 0
  }
}

export enum ATTENDANCE_STATE {
  PREPARED,
  STARTED,
  FINISHED,
}

export enum TimeUnit {
  DAYS = "days",
  HOURS = "hours",
  MINUTES = "minutes",
  SECONDS = "seconds",
  MILLISECONDS = "milliseconds",
}
