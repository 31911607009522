import { v4 as uuidv4 } from "uuid"
import { Issue } from "../shared/entity/Issue"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"
import { UserContext, useUserProvider } from "./UserProvider"
import {
  onIssueListWhereUserIdIsAuthorized,
  onIssueListWhereUserIdIsSuperAdmin,
} from "../persistence/FirebaseCollection"
import { FirebaseObject } from "../persistence/FirebaseObject"

const initialVal: {
  issueList: Issue[]
  setIssueList: (issueList: Issue[]) => void
} = {
  issueList: [],
  setIssueList: () => {},
}

export const IssueContext = createContext(initialVal)

export default function IssueProvider({ children }: { children: ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const [issueList, setIssueList] = useState<Issue[]>([])

  useEffect(() => {
    console.debug("IssueProvider: useEffect", currentUser)
    if (currentUser?.id) {
      onIssueListWhereUserIdIsAuthorized(currentUser.id, setIssueList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  return (
    <IssueContext.Provider
      value={{
        issueList,
        setIssueList,
      }}
    >
      {children}
    </IssueContext.Provider>
  )
}

export const useIssueProvider = () => {
  const { currentUser, isSuperAdmin } = useUserProvider()
  const { issueList, setIssueList } = useContext(IssueContext)
  const uuid = uuidv4()

  useEffect(() => {
    if (currentUser?.emailVerified && isSuperAdmin)
      onIssueListWhereUserIdIsSuperAdmin(isSuperAdmin, setIssueList)
        .then((unsubscribe) => {
          return () => unsubscribe
        })
        .catch((e) => console.error(e))
  }, [currentUser?.emailVerified])

  const createIssue = async (issue: Issue): Promise<Issue> => {
    if (!currentUser?.id) throw Error("createIssue currentUser?.id have to be defined")
    issue.authorizeOwner(currentUser?.id)
    issue.name = `#${uuid.slice(-5)}`
    await FirebaseObject.create(issue)
    return issue
  }

  const updateIssue = async (issue: Issue) => {
    console.debug("updateIssue", issue)
    if (!issue) throw Error("No issue is selected")
    if (!issue.name) {
      issue.name = `#${uuid.slice(-5)}`
    }
    await FirebaseObject.update(new Issue({ id: issue.id, ...issue }))
  }

  const deleteIssue = async (issue: Issue) => {
    console.debug("deleteIssue", issue)
    if (!issue) throw Error("No issue is selected")
    if (!issue.id) throw Error("No issue with id is selected")
    await FirebaseObject.delete(new Issue({ id: issue.id }))
  }

  return {
    issueList,
    updateIssue,
    deleteIssue,
    createIssue,
  }
}
