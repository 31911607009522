import React, { useState } from "react"
import { ScrollView, View } from "react-native"
import Screen from "../../components/general/Screen"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import MaxWidth from "../../components/container/MaxWidth"
import { useWorldProvider } from "../../providers/WorldProvider"
import AppText from "../../components/general/AppText"
import { FAB, useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import { A } from "@expo/html-elements"
import { useUserProvider } from "../../providers/UserProvider"
import { OPERATION } from "../../shared/entity/Role"
import { News } from "../../shared/common/News"
import { FontAwesome5 } from "@expo/vector-icons"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import { createFBID } from "../../utils/util"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.ARTICLE_LIST>

export function ArticleScreen({ navigation }: Props) {
  const { news } = useWorldProvider()
  const { i18n } = useSettingsProvider()
  const { isOperationAuthorized } = useUserProvider()
  const theme: Theme = useTheme()
  const [article, setArticle] = useState(new News({ id: createFBID(), title: i18n.t("article") }))

  return (
    <Screen>
      <ScrollView>
        <MaxWidth>
          <View>
            {news.map((n) => (
              <View
                style={{
                  backgroundColor: theme.colors.background,
                  padding: PADDING,
                  margin: MARGIN,
                  borderRadius: RADIUS,
                  ...theme.shadows,
                }}
              >
                <View style={{ flexDirection: "row", alignItems: "center" }}>
                  <AppText style={{ color: theme.colors.primary }} type={"heading"}>
                    {n.title}
                  </AppText>
                  <View style={{ flexGrow: 1 }} />

                  {isOperationAuthorized(OPERATION.SUPERADMIN) && (
                    <FontAwesome5
                      style={{ backgroundColor: theme.colors.primary, padding: PADDING, borderRadius: 10 }}
                      color={theme.colors.white}
                      size={theme.iconSize.regular}
                      name={"pen"}
                      onPress={() => navigation.navigate(ROUTE.ARTICLE_DETAIL, { article: n })}
                    />
                  )}
                </View>
                <AppText type={"small"}>{n.publishNote}</AppText>
                <AppText>{n.description}</AppText>
                {n.link && (
                  <View style={{ flexDirection: "row" }}>
                    <A
                      href={n.link}
                      hrefAttrs={{ target: "_blank" }}
                      style={{
                        backgroundColor: theme.colors.primary,
                        flexShrink: 0,
                        padding: PADDING,
                        color: theme.colors.text,
                        borderRadius: 10,
                      }}
                    >
                      {i18n.t("read_full_article")}
                    </A>
                    <View style={{ flexGrow: 1 }} />
                  </View>
                )}
              </View>
            ))}
          </View>
        </MaxWidth>
      </ScrollView>
      <FAB
        visible={isOperationAuthorized(OPERATION.SUPERADMIN)}
        style={[
          theme.fab,
          {
            margin: 32,
            right: 0,
            bottom: 0,
          },
        ]}
        color={theme.colors?.primary}
        icon="plus"
        onPress={() => navigation.navigate(ROUTE.ARTICLE_DETAIL, { article: new News() })}
      />
    </Screen>
  )
}
