import { DocObject } from "./DocObject"

export class UserMessage extends DocObject {
  title = "Zpráva"
  source = "system"
  userId = ""
  link?: string
  payload?: any
  isRead = false

  constructor(params?: Partial<UserMessage>) {
    super(params)
    this.assign(params)
  }

  assign(data: any) {
    Object.assign(this, data)
  }

  getPathSegment() {
    return "UserMessage"
  }

  getLabel(): string {
    return this.title
  }

  unlink() {
    return this.softDelete()
  }

  read() {
    if (!this.isRead) this.isRead = true
  }
}

enum MESSAGE_RENDERER {
  DEFAULT = "Default",
}
