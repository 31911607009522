import { Entypo, FontAwesome, FontAwesome5 } from "@expo/vector-icons"
import React from "react"
import { TouchableOpacity, View } from "react-native"
import { Menu, useTheme } from "react-native-paper"
import { errorCodes } from "../../constants/ErrorMessages"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import { Project } from "../../shared/entity/Project"
import { Theme } from "../../../types"
import { RADIUS } from "../../constants/style"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function HeaderMenu({ id, onEdit }: { id: string; onEdit?: () => void }) {
  const theme: Theme = useTheme()
  const { i18n } = useSettingsProvider()
  const [visible, setVisible] = React.useState(false)
  const { setStatusError, setStatusUndetermined, setStatusSuccess } = useStatusProvider()
  const { deleteProject } = useProjectProvider()
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()

  const openMenu = () => setVisible(true)
  const closeMenu = () => setVisible(false)

  const handleDelete = async () => {
    closeMenu()
    try {
      setStatusUndetermined(i18n.t("delete_project"))
      await deleteProject(new Project({ id }))
      setStatusSuccess(i18n.t("project_deleted"))
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }
  const handleEdit = async () => {
    closeMenu()
    onEdit && onEdit()
  }

  return (
    <View>
      <Menu
        style={{ borderRadius: RADIUS }}
        contentStyle={theme.menu}
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <TouchableOpacity onPress={openMenu}>
            <Entypo name="dots-three-vertical" size={24} color={theme.colors.primary} />
          </TouchableOpacity>
        }
      >
        {onEdit !== undefined && (
          <Menu.Item
            titleStyle={{ color: theme.body.textColor }}
            onPress={handleEdit}
            title={i18n.t("edit")}
            leadingIcon={({ size }) => <FontAwesome5 name="edit" size={size} color={theme.colors.primary} />}
          />
        )}
        <Menu.Item
          titleStyle={{ color: theme.body.textColor }}
          onPress={handleDelete}
          title={i18n.t("delete")}
          leadingIcon={({ size }) => <FontAwesome name="trash-o" size={size} color={theme.colors.primary} />}
        />
      </Menu>
    </View>
  )
}
