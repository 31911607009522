import { dateFromDB, DocObject } from "./DocObject"
import { MaterialEntryData } from "../entry/MaterialEntryData"
import { WorkEntryData } from "../entry/WorkEntryData"
import { RecordEntryData } from "../entry/RecordEntryData"
import { WorkerEntryData } from "../entry/WorkerEntryData"
import { SignatureEntryData } from "../entry/SignatureEntryData"
import { IExportable, IExporter, PDFcontent } from "../export/export"
import { PlaceLocation } from "../common/PlaceLocation"
import { WeatherEntryData } from "../entry/WeatherEntryData"
import { YYYYMMDD } from "../utils/date"
import { MechanizationEntryData } from "../entry/MechanizationEntryData"
import { Attendance } from "./Attendance"

export class Diary extends DocObject implements IExportable {
  projectId?: string
  date?: Date
  locationEntry?: PlaceLocation
  weatherEntry?: WeatherEntryData[]
  workerEntry?: WorkerEntryData[]
  mechanizationEntry?: MechanizationEntryData[]
  materialEntry?: MaterialEntryData[]
  workEntry?: WorkEntryData[]
  recordEntry?: RecordEntryData[]
  signatureEntry?: SignatureEntryData[]

  constructor(params: Partial<Diary>) {
    super(params)
    this.weatherEntry = []
    this.workerEntry = []
    this.mechanizationEntry = []
    this.materialEntry = []
    this.workEntry = []
    this.recordEntry = []
    this.signatureEntry = []
    this.assign(params)
  }

  getPathSegment() {
    return "Project/" + this.projectId + "/Diary"
  }

  getPathSegmentWithId() {
    return "Project/" + this.projectId + "/Diary/" + this.id
  }

  assign(data: any) {
    super.assign(data)
    if ("date" in data) this.date = dateFromDB(data["date"])

    this.locationEntry = new PlaceLocation(data.locationEntry)
    this.weatherEntry = data.weatherEntry
      ? data.weatherEntry.map((item: WeatherEntryData) => new WeatherEntryData(item))
      : []
    this.workerEntry = data.workerEntry
      ? data.workerEntry.map((item: WorkerEntryData) => new WorkerEntryData(item))
      : []
    this.mechanizationEntry = data.mechanizationEntry ?? []
    this.materialEntry = data.materialEntry
      ? data.materialEntry.map((item: MaterialEntryData) => new MaterialEntryData(item))
      : []
    this.workEntry = data.workEntry ? data.workEntry.map((item: WorkEntryData) => new WorkEntryData(item)) : []
    this.recordEntry = data.recordEntry
      ? data.recordEntry.map((item: RecordEntryData) => new RecordEntryData(item))
      : []
    this.signatureEntry = data.signatureEntry
      ? data.signatureEntry.map((item: SignatureEntryData) => new SignatureEntryData(item))
      : []
  }

  async exportData(exporter: IExporter): Promise<PDFcontent> {
    if (this.date) exporter.addHeading(`Denní záznam ze stavby ${YYYYMMDD(this.date)}`)

    if (this.weatherEntry && this.weatherEntry.length > 0) {
      exporter.addHeading("Počasí")
      exporter.exportTable(this.weatherEntry)
    }
    if (this.workerEntry && this.workerEntry.length > 0) {
      exporter.addHeading("Osoby")
      exporter.exportTable(this.workerEntry)
    }

    const attendance = exporter.extraData.attendance as Attendance[]
    const relevantAttendance = attendance.filter(
      (a) => a.start && this.date && YYYYMMDD(a.start) == YYYYMMDD(this.date) && a.projectId == this.projectId
    )
    console.log(relevantAttendance)
    if (relevantAttendance.length > 0) {
      exporter.exportTable(relevantAttendance)
    }

    if (this.mechanizationEntry && this.mechanizationEntry.length > 0) {
      exporter.addHeading("Mechanizace")
      exporter.exportTable(this.mechanizationEntry)
    }
    if (this.materialEntry && this.materialEntry.length > 0) {
      exporter.addHeading("Materiál")
      exporter.exportTable(this.materialEntry)
    }
    if (this.workEntry && this.workEntry.length > 0) {
      exporter.addHeading("Práce")
      exporter.exportTable(this.workEntry)
    }

    if (this.signatureEntry && this.signatureEntry.length > 0) {
      await Promise.all(this.signatureEntry.map((signatureEntry) => signatureEntry.exportData(exporter)))
    }

    if (this.recordEntry && this.recordEntry.length > 0) {
      await Promise.all(this.recordEntry.map((recordEntry) => recordEntry.exportData(exporter)))
    }

    exporter.pageBreak()
  }
}
