import { DocObject } from "../DocObject"
import { ProductPrice } from "./ProductPrice"

export class Product extends DocObject {
  active?: boolean
  description?: string
  images?: []
  metadata?: {}
  name?: string
  role?: string
  tax_code?: string
  prices: ProductPrice[] = []

  getLabel(): string {
    return this.name || ""
  }

  setPrices = (prices: ProductPrice[]): void => {
    prices.forEach((value) => this.prices?.push(value))
  }

  getId(): string {
    return this.id || ""
  }

  constructor(params?: Partial<Product>) {
    super(params)
    this.assign(params)
  }
}
