import React from "react"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { RootStackParamList } from "../navigation/types"
import { ROUTE } from "../navigation/routes"
import { useMemoProvider } from "../providers/MemoProvider"
import MaxWidth from "../components/container/MaxWidth"
import { TouchableOpacity, View } from "react-native"
import Screen from "../components/general/Screen"
import { useUserProvider } from "../providers/UserProvider"
import AppText from "../components/general/AppText"
import { MARGIN, PADDING, RADIUS } from "../constants/style"
import { Theme } from "../../types"
import { useTheme } from "react-native-paper"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.DEV_SCREEN>

export function DevScreen({ navigation }: Props) {
  const { switchRoom, rooms, latestMemos } = useMemoProvider()
  const { currentUser } = useUserProvider()
  const theme: Theme = useTheme()

  return (
    <Screen>
      <MaxWidth height={"100%"}>
        {rooms.map((r) => {
          if (r.id)
            return (
              <TouchableOpacity
                style={{
                  backgroundColor: theme.colors.background,
                  padding: PADDING,
                  margin: MARGIN,
                  borderRadius: RADIUS,
                  ...theme.shadows,
                }}
                key={r.id}
                onPress={() => {
                  switchRoom(r)
                  navigation.navigate(ROUTE.MEMO_MESSAGES)
                }}
              >
                <AppText type={"heading"}>{r.name}</AppText>
                <View style={{ flexDirection: "row" }}>
                  <AppText noPadding style={{ marginRight: MARGIN, backgroundColor: theme.colors.background }}>
                    {latestMemos[r.id]?.find(() => true)?.date() ?? ""}
                  </AppText>
                  <AppText noPadding>{latestMemos[r.id]?.find(() => true)?.short(30) ?? ""}</AppText>
                </View>
              </TouchableOpacity>
            )
        })}
      </MaxWidth>
    </Screen>
  )
}
