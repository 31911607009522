import { StatusBar } from "expo-status-bar"
import { Platform, StyleSheet, View } from "react-native"
import { Entypo } from "@expo/vector-icons"
import Button from "../components/button/Button"
import AppText from "../components/general/AppText"
import LoadingComponent from "../components/general/LoadingComponent"
import { AppLogo } from "../components/svg"
import { STATUS, useStatusProvider } from "../providers/StatusProvider"
import { Theme } from "../../types"
import { useTheme } from "react-native-paper"
import { useSettingsProvider } from "../providers/SettingsProvider"

export function ModalScreen() {
  const { status, clearStatus } = useStatusProvider()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  console.log("status", status)

  const handlePress = () => {
    clearStatus(status.value)
  }

  return (
    <View style={styles.container}>
      <View
        // static background color because of black color when automatic dark theme is set from user side
        style={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
          backgroundColor: theme.colors.background,
        }}
      >
        {status.value === STATUS.NONE && (
          <>
            <View
              style={{
                width: "100%",
                height: "100%",
                padding: 20,
                backgroundColor: theme.colors.background,
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <AppLogo style={{ maxHeight: 100 }} />
              <AppText style={{ textAlign: "center" }} type="heading">
                {i18n.t("loading_application")} ...
              </AppText>
            </View>
          </>
        )}

        {status.value === STATUS.UNDETERMINED && (
          <>
            <AppText type="heading">{status.message}</AppText>
            <LoadingComponent />
          </>
        )}

        {status.value === STATUS.SUCCESS && (
          <>
            <Entypo name="check" size={50} color="green" />
            <AppText type="heading">{status.message}</AppText>
          </>
        )}

        {status.value === STATUS.ERROR && (
          <>
            <Entypo name="cross" size={50} color="red" />
            <AppText type="heading">{status.message}</AppText>
          </>
        )}
      </View>

      {(status.value === STATUS.SUCCESS || status.value === STATUS.ERROR) && (
        <>
          <Button title="Ok" onPress={handlePress} standalone />
        </>
      )}
      {/* Use a light status bar on iOS to account for the black space above the modal */}
      <StatusBar style={Platform.OS === "ios" ? "light" : "auto"} />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
  },
})
