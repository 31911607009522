import { Image, TouchableOpacity, View } from "react-native"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { Memo } from "../../shared/entity/Memo"
import { useUserProvider } from "../../providers/UserProvider"
import AppText from "../../components/general/AppText"
import { MARGIN, PADDING, RADIUS } from "../../constants/style"
import React, { useState } from "react"
import { User } from "../../shared/entity/User"
import { FontAwesome } from "@expo/vector-icons"
import { FirebaseObject } from "../../persistence/FirebaseObject"
import { News } from "../../shared/common/News"
import { useWorldProvider } from "../../providers/WorldProvider"
import LoadingComponent from "../../components/general/LoadingComponent"
import { A } from "@expo/html-elements"
import { ROUTE } from "../../navigation/routes"
import { useProjectProvider } from "../../providers/ProjectProvider"
import { useNavigation } from "@react-navigation/native"
import { useSettingsProvider } from "../../providers/SettingsProvider"
import AvatarImage from "../../components/file/AvatarImage"

interface Props {
  memo: Memo
  onPress: (memo: Memo) => void
}

export function MemoElement({ memo, onPress }: Props) {
  const theme: Theme = useTheme()
  const [author, setAuthor] = useState<User>()
  const [replyTo, setReplyTo] = useState<Memo>()
  const { currentUser, readUser } = useUserProvider()
  const isUserAuthor = (currentUser?.id ?? "") == memo.userId
  const rightMessage = isUserAuthor

  if (!author && memo.userId) readUser(memo.userId).then(setAuthor)
  if (memo.parentId && !replyTo) {
    const parent = new Memo({ id: memo.parentId })
    FirebaseObject.read(parent).then(() => {
      setReplyTo(parent)
    })
  }

  let Renderer = DefaultRenderer
  switch (memo.renderer) {
    case "ArticleRenderer":
      Renderer = ArticleRenderer
      break
    case "NavigateRenderer":
      Renderer = NavigateRenderer
      break
    default:
      break
  }
  return (
    <View
      style={{
        alignItems: rightMessage ? "flex-end" : "flex-start",
        flexGrow: 1,
        width: "100%",
        marginVertical: MARGIN,
      }}
    >
      {memo.isReply && (
        <View style={{ flexDirection: "row" }}>
          <FontAwesome name={"reply"} /> <AppText>{replyTo?.short()}</AppText>
        </View>
      )}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          maxWidth: "90%",
          marginBottom: memo.reactions.length ? MARGIN : undefined,
          justifyContent: rightMessage ? "flex-end" : "flex-start",
        }}
      >
        <TouchableOpacity
          style={{
            backgroundColor: isUserAuthor ? theme.colors.primary : theme.colors.background,
            flexShrink: 1,
            minWidth: 100,
            width: "100%",
            padding: PADDING,
            paddingBottom: 20,
            borderRadius: RADIUS,
            ...theme.shadows,
          }}
          onPress={() => {
            onPress(memo)
          }}
        >
          <Renderer memo={memo} isUserAuthor={isUserAuthor} author={author} />
          <View style={{ flexDirection: "row", position: "absolute", bottom: -15, gap: 5 }}>
            {Object.entries(
              memo.reactions.reduce((acc: Record<string, { count: number; userReacted: boolean }>, obj) => {
                acc[obj.reactionName] ??= { count: 0, userReacted: false }
                acc[obj.reactionName].count++
                if (obj.ownerId == currentUser?.id) acc[obj.reactionName].userReacted = true
                return acc
              }, {})
            ).map(([k, v]) => (
              <TouchableOpacity
                style={{
                  flexDirection: "row",
                  backgroundColor: v.userReacted ? theme.colors.blue : theme.colors.background,
                  borderColor: v.userReacted ? theme.colors.lightBlue : theme.colors.primary,
                  borderWidth: 2,
                  borderRadius: 10,
                  alignItems: "center",
                  padding: 5,
                }}
                onPress={async () => {
                  if (currentUser?.id) memo.react(currentUser.id, k)
                  await FirebaseObject.update(new Memo(memo))
                }}
              >
                <FontAwesome
                  //@ts-ignore
                  name={k}
                  style={{
                    marginRight: MARGIN,
                    padding: 2,
                  }}
                />
                <AppText noPadding>{v.count}</AppText>
              </TouchableOpacity>
            ))}
          </View>
        </TouchableOpacity>
      </View>
    </View>
  )
}

function DefaultRenderer({ memo, author, isUserAuthor }: { memo: Memo; author?: User; isUserAuthor: boolean }) {
  const theme: Theme = useTheme()
  return (
    <>
      {!isUserAuthor && (
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <View style={{ margin: MARGIN / 2 }}>
            <AvatarImage size={35} name={author?.name} editable={false} imageUri={author?.imageUri} />
          </View>
          <View style={{ margin: MARGIN / 2 }}>
            <AppText style={{ color: theme.colors.text, fontWeight: "bold" }}>{author?.name}</AppText>
          </View>
        </View>
      )}
      <View style={{ margin: MARGIN / 2 }}>
        <AppText noPadding style={{ color: isUserAuthor ? theme.colors.text : theme.colors.primary }}>
          {memo.text}
        </AppText>
      </View>
    </>
  )
}

function ArticleRenderer({ memo, author, isUserAuthor }: { memo: Memo; author?: User; isUserAuthor: boolean }) {
  const [article, setArticle] = useState<News>()
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const { getArticle } = useWorldProvider()
  if (!article) getArticle(memo.extra).then(setArticle)

  if (article)
    return (
      <>
        <AppText type={"heading"} style={{ color: theme.colors.primary }}>
          {article.title}
        </AppText>
        <AppText noPadding style={{ color: theme.colors.text }}>
          {article.description}
        </AppText>
        {article.link && (
          <View style={{ flexDirection: "row" }}>
            <A
              href={article.link}
              hrefAttrs={{ target: "_blank" }}
              style={{
                backgroundColor: theme.colors.primary,
                flexShrink: 0,
                padding: PADDING,
                color: theme.colors.text,
                borderRadius: 10,
              }}
            >
              {i18n.t("read_full_article")}
            </A>
            <View style={{ flexGrow: 1 }} />
          </View>
        )}
      </>
    )

  return <LoadingComponent />
}

function NavigateRenderer({ memo, author, isUserAuthor }: { memo: Memo; author?: User; isUserAuthor: boolean }) {
  const theme: Theme = useTheme()
  const { navigate } = useNavigation()
  const { projects, selectProject } = useProjectProvider()
  const { i18n } = useSettingsProvider()

  function navigateToLink() {
    if (memo.extra.link == ROUTE.PROJECT_DETAIL) {
      const projectToSelect = projects.find((p) => p.id == memo.extra.payload.projectId)
      if (projectToSelect) selectProject(projectToSelect)
    }
    //@ts-ignore
    navigate(memo.extra.link, memo.extra.payload)
  }

  return (
    <>
      <View style={{ flexDirection: "row", alignItems: "center" }}>
        <Image
          source={{ uri: author?.imageUri }}
          style={{ borderRadius: 10, marginRight: MARGIN, marginBottom: MARGIN, width: 32, height: 32 }}
        />
        <AppText style={{ color: theme.colors.text, fontWeight: "bold" }}>
          {i18n.t("request_created_by_user") + " " + author?.name}
        </AppText>
      </View>
      <AppText style={{ color: theme.colors.text }}>{memo.text}</AppText>
      <A onPress={navigateToLink}>
        <AppText style={{ color: isUserAuthor ? theme.colors.text : theme.colors.primary }}>{i18n.t("show")}</AppText>
      </A>
    </>
  )
}
