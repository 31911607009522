import React from "react"
import { Image, Platform, StyleProp, StyleSheet, Text, TouchableOpacity, View, ViewStyle } from "react-native"
import { FontAwesome5 } from "@expo/vector-icons"
import { useTheme } from "react-native-paper"
import { Theme } from "../../../types"
import { ROUTE } from "../../navigation/routes"
import { useNavigation } from "@react-navigation/native"
import { NativeStackNavigationProp } from "@react-navigation/native-stack"
import { RootStackParamList } from "../../navigation/types"
import * as FileSystem from "expo-file-system"
import { useStorageProvider } from "../../providers/StorageProvider"

const Buffer = require("buffer/").Buffer

const SIG_WIDTH = 120

export default function Signature({
  title,
  onSave,
  uri,
  path,
  disabled = false,
  style,
}: {
  title: string
  onSave: (uri: any) => void
  uri?: string
  path: string
  disabled?: boolean
  style?: StyleProp<ViewStyle>
}) {
  const theme: Theme = useTheme()
  const styles = getStyles(theme)
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>()
  const { uploadFileOnFirebase, fetchImageFromUri } = useStorageProvider()

  //TODO: improvoe readability of this
  async function uploadSignature(signature: any) {
    let data
    if (Platform.OS == "web") {
      data = Buffer.from(signature.replace("data:image/png;base64,", ""), "base64")
    } else {
      await FileSystem.writeAsStringAsync(
        FileSystem.cacheDirectory + "temp.png",
        signature.replace("data:image/png;base64,", ""),
        {
          encoding: FileSystem.EncodingType.Base64,
        }
      )
      const response = await fetch(FileSystem.cacheDirectory + "temp.png")
      data = await response.blob()
    }
    const newUri = await uploadFileOnFirebase(data, path, {
      contentType: "image/*",
    })
    onSave(newUri)
    navigation.goBack()
  }

  return (
    <View style={[style, { aspectRatio: 125 / 75 }]}>
      <TouchableOpacity
        disabled={disabled}
        onPress={() => navigation.navigate(ROUTE.DIARY_SIGNATURE, { onSave: uploadSignature, title })}
        style={[styles.signatureWrapper, { flexGrow: 1 }]}
      >
        {uri ? (
          <Image source={{ uri }} style={{ flexGrow: 1, aspectRatio: 125 / 75 }} />
        ) : (
          <Text style={{ textAlign: "center" }}>{title}</Text>
        )}
      </TouchableOpacity>

      <FontAwesome5
        style={{ fontSize: theme.fontSize.small, position: "absolute", top: 15, right: 5 }}
        name="signature"
        size={theme.iconSize.small}
        color={theme.colors.primary}
      />
      {!!uri && (
        <Text
          style={{
            fontSize: theme.fontSize.small,
            position: "absolute",
            top: 10,
            left: -5,
            color: theme.colors.background,
          }}
        >
          {title}
        </Text>
      )}
    </View>
  )
}

function getStyles(theme: Theme) {
  return StyleSheet.create({
    signatureWrapper: {
      marginVertical: 10,
      borderRadius: 5,
      borderWidth: 1,
      borderColor: theme.colors.border,
      justifyContent: "center",
      alignItems: "center",
    },
  })
}
