import React from "react"
import Screen from "../../components/general/Screen"
import { RootStackParamList } from "../../navigation/types"
import { ROUTE } from "../../navigation/routes"
import { NativeStackScreenProps } from "@react-navigation/native-stack"
import { LogAttendance } from "../../components/attendance/LogAttendance"
import MaxWidth from "../../components/container/MaxWidth"
import { ScrollView } from "react-native"
import { ShowAttendance } from "../../components/attendance/ShowAttendance"

type Props = NativeStackScreenProps<RootStackParamList, ROUTE.ATTENDANCE>

export function AttendanceScreen({ route, navigation }: Props) {
  console.log("AttendanceScreen", route.params)
  return (
    <Screen>
      <MaxWidth>
        <ScrollView style={{ flexShrink: 0 }}>
          <LogAttendance />
          <ShowAttendance />
        </ScrollView>
      </MaxWidth>
    </Screen>
  )
}
