export enum API {
  sendInvitation = "sendInvitation",
  weatherFetch = "weatherFetch",
  stripeTransactionFetch = "stripeTransactionFetch",
  stripeSubscriptionFetch = "stripeSubscriptionFetch",
  stripeCancelSubscription = "stripeCancelSubscription",
  aresFetch = "aresFetch",
  contractHtml = "contractHtml",
  projectExport = "projectExport",
  appInfo = "appInfo",
  fetchNews = "fetchNews",
}
