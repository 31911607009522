import React from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"

export default function WizardProgressDots({
  count,
  currentIndex,
  onClick,
}: {
  count: number
  currentIndex: number
  onClick: (n: number) => void
}) {
  const dotsArr = [...Array(count).keys()]

  const renderDot = (dot: number, i: number) => {
    if (onClick) {
      return (
        <TouchableOpacity onPress={() => onClick(i + 1)} key={i}>
          <View style={[currentIndex === i && { backgroundColor: "gray" }, styles.dot]}></View>
        </TouchableOpacity>
      )
    } else {
      return <View style={[currentIndex === i && { backgroundColor: "gray" }, styles.dot]} key={i}></View>
    }
  }

  return (
    <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "center", paddingVertical: 5 }}>
      {dotsArr.map((dot, i) => renderDot(dot, i))}
    </View>
  )
}

const styles = StyleSheet.create({
  dot: {
    width: 50,
    height: 10,
    borderRadius: 5,
    borderColor: "gray",
    borderWidth: 2,
    marginHorizontal: 5,
  },
})
