import React, { createContext, useContext, useEffect, useLayoutEffect, useState } from "react"
import { Project } from "../shared/entity/Project"
import { UserContext } from "./UserProvider"
import { FirebaseObject } from "../persistence/FirebaseObject"
import {
  onDiaryListOrderByDate,
  onDocumentListOrderByDate,
  onProjectListWhereUserIdIsOwner,
  onUpdatedProjects,
} from "../persistence/FirebaseCollection"
import { Diary } from "../shared/entity/Diary"
import { Document } from "../shared/entity/Document"
import { Platform } from "react-native"
import { useStatusProvider } from "./StatusProvider"
import { useStorageProvider } from "./StorageProvider"
import { YYYYMMDD } from "../shared/utils/date"
import { CompanyContext } from "./CompanyProvider"

const initialVal: {
  selectedProject: Project | undefined
  projects: Project[]
  setProjects: (projects: Project[]) => void
  setSelectedProject: (project: Project) => void
  diaryList: Diary[]
  setDiaryList: (diaryList: Diary[]) => void
  documents: Document[]
  setDocuments: (documents: Document[]) => void
  todayDiary: Diary[]
} = {
  selectedProject: undefined,
  projects: [],
  setProjects: (projects: Project[]) => {},
  setSelectedProject: (project: Project) => {},
  diaryList: [],
  setDiaryList: (diaryList: Diary[]) => {},
  documents: [],
  setDocuments: (documents: Document[]) => {},
  todayDiary: [],
}

export const ProjectContext = createContext(initialVal)

export default function ProjectProvider({ children }: { children: React.ReactNode }) {
  const { currentUser } = useContext(UserContext)
  const [projects, setProjects] = useState<Project[]>([])
  const [selectedProject, setSelectedProject] = useState<Project>()
  const [diaryList, setDiaryList] = useState(new Array<Diary>())
  const [todayDiary, setTodayDiary] = useState(new Array<Diary>())
  const [documents, setDocuments] = useState(new Array<Document>())

  useEffect(() => {
    console.log("useProjectProvider effect")
    if (currentUser?.id) {
      onProjectListWhereUserIdIsOwner(currentUser.id, setProjects)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
      onUpdatedProjects(setTodayDiary)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [currentUser])

  useEffect(() => {
    if (selectedProject) {
      if (!selectedProject) throw Error("No project is selected")
      if (!selectedProject.id) throw Error("project.id is not defined")
      onDiaryListOrderByDate(selectedProject.id, setDiaryList)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [selectedProject])

  useEffect(() => {
    if (selectedProject && selectedProject.id) {
      onDocumentListOrderByDate(selectedProject.id, setDocuments)
        .then((unsubscribe) => {
          return () => unsubscribe()
        })
        .catch((e) => console.error(e))
    }
  }, [selectedProject])

  return (
    <ProjectContext.Provider
      value={{
        projects,
        setProjects,
        selectedProject,
        setSelectedProject,
        diaryList,
        setDiaryList,
        documents,
        setDocuments,
        todayDiary,
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export const useProjectProvider = () => {
  const { currentUser } = useContext(UserContext)
  const { projects, selectedProject, setSelectedProject, diaryList, documents, todayDiary } = useContext(ProjectContext)
  const { company } = useContext(CompanyContext)
  const [currentDiary, setCurrentDiary] = useState<Diary>()
  const [nextDiary, setNextDiary] = useState<Diary>()
  const [previousDiary, setPreviousDiary] = useState<Diary>()

  const selectProject = (project: Project) => {
    setSelectedProject(project)
  }
  const createProject = async (project: Project): Promise<Project> => {
    if (!currentUser?.id) throw Error("createProject currentUser?.id have to be defined")
    project.authorizeOwner(currentUser?.id)
    project.companyId = company?.id
    await FirebaseObject.create(project)
    return project
  }
  const readProject = async (id: string): Promise<Project> => {
    if (!currentUser?.id) throw Error("readProject currentUser?.id have to be defined")
    const project = new Project({ id: id })
    await FirebaseObject.read(project)
    if (!project.isAuthorizedRead(currentUser?.id))
      throw Error(
        "Not accessible for current user: " + JSON.stringify(currentUser) + " project: " + JSON.stringify(project)
      )
    return project
  }
  const updateProject = async (project: Project): Promise<void> => {
    await FirebaseObject.update(project)
  }
  const deleteProject = async (project: Project): Promise<void> => {
    await FirebaseObject.delete(project)
  }

  // diary
  const createDiary = async (diary?: Diary): Promise<Diary> => {
    if (!selectedProject) throw Error("No project is selected")
    if (!diary) {
      diary = new Diary({ projectId: selectedProject.id, date: new Date() })
    } else {
      diary.projectId = selectedProject.id
    }
    diary.authorizeOwner(currentUser?.id)
    await FirebaseObject.create(diary)
    return diary
  }

  const readDiary = async (id: string): Promise<Diary> => {
    if (!selectedProject) throw Error("No project is selected")
    const diary = new Diary({ projectId: selectedProject.id, id: id })
    await FirebaseObject.read(diary)
    return diary
  }

  const updateDiary = async (diary: Diary) => {
    if (!selectedProject) throw Error("No project is selected")
    if (!diary.id) throw Error("No id is in diary")
    await FirebaseObject.update(new Diary({ projectId: selectedProject.id, ...diary }))
  }

  const deleteDiary = async (id: string) => {
    if (!selectedProject) throw Error("No project is selected")
    await FirebaseObject.delete(new Diary({ projectId: selectedProject.id, id: id }))
  }

  // documents

  const createDiaryDocument = async (document: Document) => {
    if (!selectedProject) throw Error("No project is selected")
    document.projectId = selectedProject.id
    document.authorizeOwner(currentUser)
    await FirebaseObject.create(document)
  }
  const readDiaryDocument = async (id: string) => {
    if (!selectedProject) throw Error("No project is selected")
    await FirebaseObject.read(new Document({ projectId: selectedProject.id, id: id }))
  }
  const updateDiaryDocument = async (document: Document) => {
    if (!selectedProject) throw Error("No project is selected")
    await FirebaseObject.update(new Document({ projectId: selectedProject.id, ...document }))
  }
  const deleteDiaryDocument = async (id: string) => {
    if (!selectedProject) throw Error("No project is selected")
    await FirebaseObject.delete(new Document({ projectId: selectedProject.id, id: id }))
  }

  useLayoutEffect(() => {
    const currentDay = new Date(currentDiary?.date)
    if (!currentDiary?.date) console.log("Missing date")
    const newNext = diaryList.find(
      (e) =>
        e.date &&
        YYYYMMDD(e.date) ==
          YYYYMMDD(new Date(currentDay?.setDate((currentDiary?.date?.getDate() || new Date().getDate()) + 1)))
    )
    setNextDiary(newNext)
    const newPrevious = diaryList.find(
      (e) =>
        e.date &&
        YYYYMMDD(e.date) ==
          YYYYMMDD(new Date(currentDay?.setDate((currentDiary?.date?.getDate() || new Date().getDate()) - 1)))
    )
    setPreviousDiary(newPrevious)
  }, [currentDiary, diaryList])

  return {
    selectedProject,
    projects,
    diaryList,
    documents,
    currentDiary,
    todayDiary,
    setCurrentDiary,
    selectProject,
    createProject,
    updateProject,
    readProject,
    deleteProject,
    createDiary,
    readDiary,
    updateDiary,
    deleteDiary,
    createDiaryDocument,
    readDiaryDocument,
    updateDiaryDocument,
    deleteDiaryDocument,
    nextDiary,
    setNextDiary,
    previousDiary,
    setPreviousDiary,
  }
}

export const useSignatureSave = (selectedProject: Project) => {
  const { setStatusError, setStatusSuccess, setStatusUndetermined } = useStatusProvider()
  const { uploadFileStringOnFirebase, uploadFileOnFirebase } = useStorageProvider()

  function getPathSegment() {
    return "Project/" + selectedProject.id + "/" + "Signatures"
  }

  async function saveSignature(signature: any, role: string, callback: (data: any) => void) {
    try {
      setStatusUndetermined("Nahrávám podpis")
      let firebaseUri
      if (Platform.OS === "web") {
        firebaseUri = await uploadFileStringOnFirebase(signature, getPathSegment())
      } else {
        const base64 = await fetch(signature)
        firebaseUri = await uploadFileOnFirebase(await base64.blob(), getPathSegment(), { contentType: "image/png" })
      }
      callback({ [role]: firebaseUri })
      setStatusSuccess("Podpis uložen")
    } catch (error) {
      console.error(error)
      setStatusError(error.message)
    }
  }

  return { saveSignature }
}
