import React from "react"
import { YYYYMMDD } from "../../shared/utils/date"
import { Theme } from "../../../types"
import { useTheme } from "react-native-paper"
import { User } from "../../shared/entity/User"
import { useAttendanceProvider } from "../../providers/AttendanceProvider"
import { Calendar } from "react-native-calendario"
import { MarkedDays } from "react-native-month"
import { shouldTag } from "../../shared/common/CustomInputDefinition"
import { useWorldProvider } from "../../providers/WorldProvider"
import AppText from "../general/AppText"
import { View } from "react-native"
import { FontAwesome5 } from "@expo/vector-icons"
import { PADDING } from "../../constants/style"
import { useSettingsProvider } from "../../providers/SettingsProvider"

interface Props {
  month: Date
  user: User
  onPress: (date: Date) => void
}

export function AttendanceCalendar({ month, user, onPress }: Props) {
  const { i18n } = useSettingsProvider()
  const theme: Theme = useTheme()
  const { attendances } = useAttendanceProvider()
  const { holidays, findHoliday } = useWorldProvider()
  findHoliday(YYYYMMDD(month))
  const relevantAttendance = attendances.filter((a) => a.start?.getMonth() == month.getMonth() && a.userId == user.id)
  const markDays = () => {
    const marks: MarkedDays = {}
    for (const day in holidays) {
      marks[day] = {
        theme: {
          dayContainerStyle: {
            borderColor: theme.colors.border,
            borderWidth: 2,
          },
        },
      }
      if (holidays[day].isHoliday)
        marks[day] = {
          theme: {
            dayContainerStyle: {
              borderColor: theme.colors.green,
              borderWidth: 2,
            },
          },
        }
    }
    for (const entry of relevantAttendance) {
      if (!entry.start) continue
      marks[YYYYMMDD(entry.start)].theme.dayContainerStyle.backgroundColor = theme.colors.primary
    }
    return marks
  }
  return (
    <Calendar
      key={new Date().toString() + relevantAttendance.length} //doesn't re-render normally for some reason
      numberOfMonths={1}
      startingMonth={YYYYMMDD(month)}
      markedDays={markDays()}
      firstDayMonday={true}
      renderDayContent={(day) => {
        const todayAttendance = relevantAttendance.find((a) => YYYYMMDD(a.start) == YYYYMMDD(day.date))
        if (todayAttendance) {
          const shown = todayAttendance.definitions.filter((d) =>
            shouldTag(d, todayAttendance.definitions, todayAttendance.customFields)
          )
          const tempIcons = new Set([].concat(...shown.map((s) => s.tags.filter((tag) => tag.includes(":")))))
          tempIcons.delete(undefined)
          const icons = Array.from(tempIcons)
          console.log("icons", icons)
          return (
            <View style={{ flexDirection: "row", alignItems: "center", gap: PADDING }}>
              <AppText noPadding>{day.date.getDate()}</AppText>
              {icons.map((d) => (
                <FontAwesome5 size={theme.iconSize.small} name={d.replace(":", "")} />
              ))}
            </View>
          )
        }
        return <AppText noPadding>{day.date.getDate()}</AppText>
      }}
      theme={{
        monthTitleTextStyle: {
          fontWeight: "bold",
          fontSize: 24,
          color: theme.colors.primary,
        },
        todayContainerStyle: {
          borderColor: theme.colors.primary,
          borderWidth: 2,
        },
        todayTextStyle: {
          color: theme.colors.primary,
          fontWeight: "bold",
        },
        dayContainerStyle: {
          marginVertical: 2,
          marginHorizontal: 2,
          borderRadius: 5,
          borderWidth: 1,
          borderColor: theme.colors.borderPrimary,
        },
        weekColumnsContainerStyle: {
          paddingVertical: 10,
        },
        weekColumnTextStyle: {
          fontSize: 20,
        },
      }}
      monthNames={[
        i18n.t("january"),
        i18n.t("february"),
        i18n.t("march"),
        i18n.t("april"),
        i18n.t("may"),
        i18n.t("june"),
        i18n.t("july"),
        i18n.t("august"),
        i18n.t("september"),
        i18n.t("october"),
        i18n.t("november"),
        i18n.t("december"),
      ]}
      dayNames={[
        i18n.t("week_mon"),
        i18n.t("week_tue"),
        i18n.t("week_wed"),
        i18n.t("week_thu"),
        i18n.t("week_fri"),
        i18n.t("week_sat"),
        i18n.t("week_sun"),
      ]}
      onPress={onPress}
      monthHeight={350}
    />
  )
}
