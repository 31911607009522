import React, { useState } from "react"
import { View } from "react-native"
import { TextInput } from "../input/TextInput"
import Button from "../button/Button"
import { isEmailValid } from "../../shared/utils/validations"
import { errorCodes } from "../../constants/ErrorMessages"
import { useUserProvider } from "../../providers/UserProvider"
import { useStatusProvider } from "../../providers/StatusProvider"
import { useSettingsProvider } from "../../providers/SettingsProvider"

export default function ForgottenPasswordForm() {
  const { sendResetLink } = useUserProvider()
  const [email, setEmail] = useState<string>("")
  const { i18n } = useSettingsProvider()
  const { setStatusSuccess, setStatusUndetermined, setStatusError } = useStatusProvider()

  const handleSendLink = async (): Promise<void> => {
    console.log("email", email)
    try {
      setStatusUndetermined(i18n.t("sending_an_email"))
      await sendResetLink(email)
      setStatusSuccess(i18n.t("email_sent"))
    } catch (error) {
      setStatusError(i18n.t(errorCodes.get(error.code) || "unknown_error") ?? error.message)
    }
  }

  return (
    <View>
      <TextInput
        autoFocus
        error={!isEmailValid(email)}
        errorText={!isEmailValid(email) ? i18n.t("email_is_not_valid") : ""}
        value={email}
        autoComplete={"email"}
        prop="email"
        onChangeText={(value: string) => {
          setEmail(value ? value.toLowerCase() : value)
        }}
        label={i18n.t("email")}
        returnKeyType={"done"}
        required={true}
      />
      <Button title={i18n.t("reset_password")} onPress={handleSendLink} disabled={!isEmailValid(email)} />
    </View>
  )
}
